<template>
    <div :class="{'form-item-capsule' : editable}">
        <el-select v-model="container[model]" :placeholder="placeholder" :multiple="multiple" clearable filterable @change="handleChange" :class="{'is-multiple' : multiple}">
            <el-option v-for="(option, index) in items" :label="option.title" :value="option.id" :key="index" />
            <template slot="prefix">
                <icon :icon="icon" class="el-input__icon" />
            </template>
        </el-select>

        <el-tooltip content="Düzenle" placement="bottom" v-if="editable">
            <el-button icon="el-icon-s-operation" size="large" class="capsule-button" type="basic" @click="indexDialogVisible = true" />
        </el-tooltip>

        <el-dialog title="Durumlar" :visible.sync="indexDialogVisible" width="40%" append-to-body class="custom-padding">
            <div class="box no-padding no-shadow" v-loading="loading">
                <div class="body list large overflow-300">
                    <div class="li" v-for="item in items" :key="item.id">
                        <div class="text">
                            <div class="title">{{ item.title }}</div>
                        </div>
                        <div class="actions inline visible">
                            <el-tooltip content="Duzenle" placement="bottom">
                                <el-button icon="el-icon-edit" class="flat dark  green" @click="edit(item.id)" />
                            </el-tooltip>
                            <el-tooltip content="Sil" placement="bottom">
                                <el-button icon="el-icon-delete" class="flat dark  red" @click="destroy(item.id)" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <template slot="footer" class="dialog-footer">
                <el-button type="primary" class="modern large fluid" size="large" @click="create">
                    Yeni Durum Oluştur
                </el-button>
            </template>
        </el-dialog>

        <el-dialog title="Durum Bilgileri" :visible.sync="dialogVisible" width="40%" append-to-body class="custom-padding">
            <div class="box no-shadow" v-loading="loading">
                <div class="body">
                    <el-form ref="form" :model="form">
                        <el-form-item label="Baslik">
                            <el-input v-model="form.title" placeholder="Baslik" />
                        </el-form-item>

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Ikon">
                                    <el-input v-model="form.icon" placeholder="Ikon" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="Arka Plan Rengi">
                                    <el-color-picker v-model="form.bg_color" show-alpha :predefine="appConfig.predefineColors"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="Yazi Rengi">
                                    <el-color-picker v-model="form.text_color" show-alpha :predefine="appConfig.predefineColors"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item label="Siralama" class="margin-bottom-0">
                            <el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <template slot="footer" class="dialog-footer">
                <el-button type="success" class="modern large green fluid" @click="store" v-if="!form.id" :loading="loading" :disabled="loading">
                    Kaydet
                </el-button>
                <el-button type="success" class="modern large green fluid" @click="update" v-if="form.id" :loading="loading" :disabled="loading">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'StatusBox',
    props: {
        icon: {
            type: String,
            default: 'tune'
        },
        alias: {
            type: String,
            default: 'orders'
        },
        container: {
            type: Object,
        },
        model: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Seçiminiz'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        change: {
            type: Function,
        },
        editable: {
            type: Boolean,
            default : true
        },
    },
    data() {
        return {
            path: null,
            loading: false,
            submitted: false,
            page : 1,
            form: {
                meta : {
                    //
                }
            },

            indexDialogVisible: false,
            dialogVisible : false,

            filter : {
                //
            },

            items: [],
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = 'system/' + this.alias + '/statuses';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        /**
         * All items
         */
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                .then(response => {

                    this.items = response.body.rows;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         *  Create modal
         */
        create() {
            this.form = {
                meta : {
                    //
                }
            };
            this.dialogVisible = true;
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {};
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form save
         */
        store() {
            this.loading = true;

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.loading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.loading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.loading = true;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.loading = false;

                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.loading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        handleChange(element) {
            if (this.change) {
                return this.change(element)
            }
        }
    },
};
</script>
