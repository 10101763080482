<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-sub-title">Metin</div>
                <div class="card-custom-padding-box-items">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Baslik">
                                <el-input v-model="form.title" placeholder="Baslik" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Yayinlanma Basligi">
                                <el-input v-model="form.public_title" placeholder="Yayinlanma Basligi" />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="Yayınlanma Tarihi">
                        <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayınlanma Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                    </el-form-item>

                    <el-form-item label="Aciklama" class="is-editor">
                        <editor :container="form" model="description" :small="true" />
                    </el-form-item>
                </div>
                <div class="card-sub-title">Medya</div>
                <div class="card-custom-padding-box-items">
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <el-form-item label="Listeleme Sayfasi Resmi" class="margin-bottom-0">
                                <fs v-model="form.meta.index_cover" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="Detay Sayfasi Resmi" class="margin-bottom-0">
                                <fs v-model="form.meta.show_cover" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="Pdf On Kapak Resmi" class="margin-bottom-0">
                                <fs v-model="form.meta.page_front_cover" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="Pdf Arka Kapak Resmi" class="margin-bottom-0">
                                <fs v-model="form.meta.page_back_cover" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                <div class="card-sub-title">Baglantilar</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Etiketler">
                        <definition-box alias="word-lists" :container="form" model="tag_requests" :multiple="true" placeholder="Etiketler" />
                    </el-form-item>

                    <el-form-item label="Kelimeler">
                        <education-content-remote-select alias="words" :container="form" model="word_requests" :multiple="true" :key="form.id" />
                    </el-form-item>

                    <el-form-item label="Urunler">
                        <product-transfer :container="form" model="product_requests" :types="['group', 'subscription']" />
                    </el-form-item>
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'WordList',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                file: {
                    path : null
                },
                tag_requests: [],
                product_requests: [],
                meta: {
                    //
                },
            },
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    computed: {
        //
    },

    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            //
                        };
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
