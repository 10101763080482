import Vue from 'vue'
import Router from 'vue-router'
import LayoutConfig from '../config/layout'
import store from '../store'

/**
 * Pages
 */
import LoginAuth from '../app/Pages/Auth/Login'
import NotFound from '../app/Pages/Errors/NotFound'

/**
 * Routes group
 */
import DashboardRoutes from './dashboard'
import AccountRoutes from './account'
import SystemRoutes from './system'
import ToolRoutes from './tools'
import CrmRoutes from './crm'
import CmsRoutes from './cms'
import CatalogRoutes from './catalog'
import MarketingRoutes from './marketing'
import ShopRoutes from './shop'
import EducationRoutes from './educations'
import ArchiveRoutes from './archives'
import ReportRoutes from './reports'
import PluginRoutes from './plugins'
import ThemeRoutes from './themes'
import BoardRoutes from './boards'

Vue.use(Router);

const router = new Router({
	mode: 'hash',
	routes: [
		DashboardRoutes,
		AccountRoutes,
		SystemRoutes,
		ToolRoutes,
		CrmRoutes,
		CmsRoutes,
		CatalogRoutes,
		MarketingRoutes,
		ShopRoutes,
		EducationRoutes,
		ArchiveRoutes,
		ReportRoutes,
		PluginRoutes,
		ThemeRoutes,
		BoardRoutes,
		{
			path: '/',
			name: 'api.auth.login.store',
			component: LoginAuth,
			meta: {
				auth: true,
				layout: LayoutConfig.content,
				title: 'Login',
			}
		},
		{
			path: '*',
			name: 'api.errors.404.index',
			component: NotFound,
			meta: {
				auth: true,
				layout: LayoutConfig.content,
				title: 'Upss! Page Not Found',
			}
		}
	]
});

router.afterEach(() => {

});

router.beforeEach((to, from, next) => {
	if (!to.meta) {
		document.title = 'RHPozitif LMS Admin Panel';
	} else {
		document.title = to.meta.title + ' | RHPozitif LMS Admin Panel';
	}

	if (!to) {
		window.location.href = '/';
	}

	if (!localStorage.access_token && to.name !== 'api.auth.login.store') {
		return window.location.href = '/';
	}

	if (to.name === 'api.auth.login.store') {
		store.commit('setPreloader', false)
	}

	next();
});

export default router;
