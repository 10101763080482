<template>
    <el-form-item :label="item.label">
        <editor :container="container" :model="item.name" />
    </el-form-item>
</template>

<script>
export default {
    name: 'Text',
    props : ['form', 'container', 'item']
}
</script>
