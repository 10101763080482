<template>
    <div v-loading="pageLoading">
        <el-card >
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Baslik" />
                </el-form-item>

                <el-form-item label="Yayinlanma Basligi">
                    <el-input v-model="form.public_title" placeholder="Yayinlanma Basligi" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../../Components/CrudHeader";

export default {
    name: 'TestCreate',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                tag_requests: [],
                meta: {
                    //
                },
            }
        }
    },
    mounted() {
        //
    },
    methods : {
        //
    }
}
</script>
