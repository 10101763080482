<template>
    <div>
        <div class="card-sub-title">Video</div>
        <div class="card-custom-padding-box-items">
            <el-row :gutter="24">
                <el-col :span="18">
                    <el-form-item label="Baslik">
                        <el-input v-model="container.video_title" placeholder="Baslik" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Sure">
                        <el-input v-model="container.video_time" placeholder="Sure" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="Aciklama">
                <el-input v-model="container.video_description" type="textarea" placeholder="Aciklama" :rows="2" />
            </el-form-item>
            <div class="input-tabs">
                <template v-for="tab in tabs">
                    <div class="tab" :class="{active : tab.value === currentTab }" :key="tab.value" @click="handleChangeTab(tab.value)">
                        {{ tab.label }}
                    </div>
                </template>
            </div>
            <div class="input-tabs-container">
                <el-form-item label="Kaynak" v-if="currentTab !== 'file'">
                    <el-input v-model="container.video_source" type="textarea" placeholder="Kaynak" :rows="2" />
                </el-form-item>
                <el-form-item label="Kaynak" v-if="currentTab === 'file'">
                    <fs v-model="container.video_source" :isFile="true" />
                </el-form-item>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoForm',
    props: ['container'],
    data() {
        return {
            currentTab : 'youtube',
            tabs : [
                {
                    value : 'youtube',
                    label : 'Youtube'
                },
                {
                    value : 'embed',
                    label : 'Embed'
                },
                {
                    value : 'link',
                    label : 'Link'
                },
                {
                    value : 'file',
                    label : 'Dosya Yoneticisi'
                }
            ],
        }
    },
    mounted() {
        this.container.video_source_type = 'youtube'
    },
    methods : {
        handleChangeTab(value) {
            this.container.video_source_type = value
            this.currentTab = value
        }
    }
};
</script>
