<template>
    <div class="auth login">
        <el-card>
            <div class="wrapper">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <div class="content">

                            <div class="head">
                                Hoş geldiniz!
                                <div class="sub" v-if="activeTenant.id">{{ activeTenant.title + ' Giris Yap' }}</div>
                                <div class="sub">
                                    {{ form.tenant ? 'E-Posta Adresiniz ve Sifreniz ile giris yapabilirsiniz.' : 'Lutfen giris yapmak istediginiz web sitesini secin' }}
                                </div>
                            </div>

                            <el-form :model="form" label-position="top" ref="LoginForm" @submit.native.prevent="send">
                                <template v-if="!form.tenant">
                                    <div class="tenants">
                                        <template v-for="tenant in sites">
                                            <div class="item" @click="handleChangeTenant(tenant)">
                                                {{ tenant.title }}
                                            </div>
                                        </template>
                                    </div>
                                </template>

                                <template v-if="form.tenant">
                                    <el-form-item label="E-Posta Adresiniz"
                                                  :class="{ 'is-error': submitted && $v.form.email.$error }">
                                        <el-input v-model="form.email" type="email" prefix-icon="el-icon-user"
                                                  placeholder="E-Posta Adresiniz:" class="large" />
                                    </el-form-item>

                                    <el-form-item label="Sifreniz"
                                                  :class="{ 'is-error': submitted && $v.form.password.$error }">
                                        <el-input type="password" v-model="form.password" prefix-icon="el-icon-key"
                                                  placeholder="Sifreniz" class="large" show-password />
                                    </el-form-item>

                                    <div style="display: flex; align-items: center; justify-content: space-between">
                                        <div>
                                            <el-checkbox v-model="form.remember_me" label="Beni Hatirla"
                                                         class="no-margin" />
                                        </div>
                                        <div>
                                            <a title="" @click="handleRemoveTenant()">
                                                Geri Don!
                                            </a>
                                        </div>
                                    </div>

                                    <el-button native-type="submit" class="modern fluid large margin-top-30"
                                               :loading="pageLoading">Giris Yap!
                                    </el-button>
                                </template>
                            </el-form>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="cover">
                            <div class="logo">
                                <img src="@/resources/img/auth-logo.svg" alt="tisuite">
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-card>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import Vue from "vue";
import settings from "@/config/settings";

export default {
    name: 'LoginAuth',
    data() {
        return {
            pageLoading: false,
            submitted: false,
            form: {
                tenant : ''
            },
            activeTenant : {
                //
            },
            sites : [
                {
                    id : 'minidil',
                    title : 'Minidil',
                },
                {
                    id : 'pozitifdil',
                    title : 'Pozitifdil',
                },
                {
                    id : 'aleskursum',
                    title : 'AlesKursum',
                },
                {
                    id : 'miniwo',
                    title : 'Miniwo',
                },
                {
                    id : 'dijigeek',
                    title : 'DijiGeek',
                },
                {
                    id : 'teachsuite',
                    title : 'TeachSuite',
                },
                {
                    id : 'tisuite',
                    title : 'TiSuite',
                }
            ],
        }
    },
    validations: {
        form: {
            email: {required},
            password: {required},
            tenant: {required},
        }
    },
    mounted() {
        if (localStorage.getItem('access_token')) {
            return this.goLink('/#/dashboard')
        }
    },
    methods: {
        send() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            localStorage.setItem('tenant', this.form.tenant)

            Vue.http.headers.common['Tenant'] = this.form.tenant;

            this.pageLoading = true;

            this.$http
                .post('auth/login', this.form)
                .then(response => {
                    localStorage.setItem('access_token', response.body.access_token);

                    setTimeout(() => {
                        window.location.reload()
                    }, 500)

                    this.responseSuccess(response);
                })
                .catch((response) => {
                    this.pageLoading = false;
                    this.responseError(response);
                });
        },

        handleChangeTenant(tenant) {
            this.form.tenant = tenant.id
            this.activeTenant = tenant

            localStorage.setItem('tenant', tenant.id)
        },

        handleRemoveTenant() {
            this.form.tenant = '';
            this.activeTenant = {};

            localStorage.removeItem('tenant')
        }
    }
}
</script>
