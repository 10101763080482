<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<div class="card-sub-title">Genel</div>
				<div class="card-custom-padding-box-items">
					<el-form-item label="Baslik">
						<el-input v-model="form.title" placeholder="Baslik" />
					</el-form-item>

					<el-form-item label="Yayınlanma Tarihi">
						<el-date-picker v-model="form.publish_at" type="datetime"
										placeholder="Yayınlanma Tarihibi Seçiniz"
										value-format="yyyy-MM-dd HH:mm:ss" />
					</el-form-item>

					<el-form-item label="Kisa Aciklama" class="is-editor">
						<editor :container="form" model="short_description" :small="true" />
					</el-form-item>

					<el-form-item label="Aciklama" class="is-editor">
						<editor :container="form" model="description" />
					</el-form-item>
				</div>

				<div class="card-sub-title">Baglantilar</div>
				<div class="card-custom-padding-box-items">
					<el-form-item label="Kategoriler">
						<el-select v-model="form.category_requests" placeholder="Bagli Oldugu Kategori" clearable
								   filterable multiple>
							<el-option v-for="item in categories" :key="item.id" :label="item.title_ui"
									   :value="item.id" />
						</el-select>
					</el-form-item>

					<el-form-item label="Onerilen Sayfalar">
						<el-select v-model="form.suggestion_requests" placeholder="Onerilen Sayfalar" clearable
								   filterable multiple>
							<el-option v-for="item in contents" :key="item.id" :label="item.title"
									   :value="item.id" />
						</el-select>
					</el-form-item>
				</div>

				<div class="card-sub-title">Resim</div>
				<div class="card-custom-padding-box-items">
					<el-row :gutter="24">
						<el-col :span="4">
							<el-form-item label="Kapak Resmi">
								<fs v-model="form.file.path" />
							</el-form-item>
						</el-col>
                        <el-col :span="4">
                            <el-form-item label="Detay">
                                <fs v-model="form.meta.detail_img_path" />
                            </el-form-item>
                        </el-col>
					</el-row>
				</div>

				<div class="card-sub-title d-flex justify-content-between align-items-center">
					<div>
						Seo
					</div>
					<div v-if="form.seo.slug">
						<el-tooltip content="Duzenle" placement="left">
							<el-button class="flat dark green" icon="el-icon-edit" @click="openBlankWindow('/#/tools/seo/links/' + form.seo.id)" />
						</el-tooltip>
						<el-tooltip content="Web Sitesinde Gor" placement="right">
							<el-button class="flat dark yellow" icon="el-icon-s-promotion"
									   @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/' + form.seo.slug)" />
						</el-tooltip>
					</div>
				</div>
				<div class="card-custom-padding-box-items">
					<seo :container="form.seo" />
				</div>

				<div class="card-sub-title">Ozellikler</div>
				<div class="card-custom-padding-box-items">
					<el-form-item>
						<el-switch v-model="form.is_featured" active-text="One Cikar" />
					</el-form-item>

					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item label="Kampanya Baslangic Tarihi">
								<el-date-picker v-model="form.meta.start_at" type="date" placeholder="Kampanya Baslangic Tarihi" value-format="yyyy-MM-dd"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Kampanya Bitis Tarihi">
								<el-date-picker v-model="form.meta.end_at" type="date" placeholder="Kampanya Bitis Tarihi" value-format="yyyy-MM-dd"/>
							</el-form-item>
						</el-col>
					</el-row>

					<el-form-item label="Hedef Linki">
						<el-input v-model="form.target_link" placeholder="Hedef Linki" />
					</el-form-item>

					<el-form-item label="Siralama">
						<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
					</el-form-item>
				</div>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";
    import Images from "./Contents/Images";
    import Tabs from "./Contents/Tabs";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader,
        },
        data() {
            return {
                pageLoading: false,
                form: {
                    meta: {
                        start_at : null,
                        end_at : null,
					},
                    file: {},
                    seo: {
                        slug : null,
                    },

                    category_requests: [],
                    badge_requests: [],
                    suggestion_requests: [],
                },

                categories: [],
                contents: [],
                layouts: [
                    //
                ],
            }
        },
        methods: {

            getCategories() {
                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.apiPoint.replace('contents', 'categories'), {params: {perPage: 1000}})
                    .then(response => {
                        this.categories = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getContents() {
                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.apiPoint, {params: {perPage: 1000, view: 'dropdown'}})
                    .then(response => {
                        this.contents = response.body.data;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        if (!this.lodash.size(this.form.meta)) {
                            this.form.meta = {};
                        }

                        if (!this.form.seo) {
                            this.form.seo = {};
                        }

                        if (!this.form.file) {
                            this.form.file = {};
                        }

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            handleSelect(event) {
                this.activeTab = event
            }
        },
        mounted() {

            this.getCategories();
            this.getContents();

            if (this.$route.params.id) {
                this.show();
            }
        },
    }
</script>
