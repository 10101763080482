<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form"/>
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-sub-title">Genel</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Etiketler">
                        <definition-box alias="words" :container="form" model="tag_requests" :multiple="true"
                                        placeholder="Etiketler"/>
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Tür">
                                <definition-box alias="word-types" :container="form" model="type_definition_id"
                                                placeholder="Tür"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Gunun Kelimesi Tarihi">
                                <el-date-picker v-model="form.publish_at" type="date" placeholder="Tarih Seçiniz"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="Kategoriler">
                        <definition-box alias="word-categories" :container="form" model="category_requests"
                                        :multiple="true"
                                        placeholder="Kategoriler"/>
                    </el-form-item>

                    <el-form-item label="Kelime">
                        <el-input v-model="form.title" placeholder="Kelime"/>
                    </el-form-item>
                    <el-form-item label="Örnek Cümle">
                        <el-input v-model="form.sentence" placeholder="Cümle"/>
                    </el-form-item>
                </div>
                <el-row :gutter="24">
                    <el-col :span="12">
                        <div class="card-sub-title">Kelimenin Çevirileri</div>
                        <div class="card-custom-padding-box-items">
                            <template v-for="(loopItem, index) in form.meanings">
                                <el-form-item :label="loopItem.language_title + ' Çeviri'" :key="index"
                                              v-if="loopItem.language_code !== 'en'">
                                    <el-input v-model="loopItem.description"
                                              :placeholder="loopItem.language_title + ' Çeviri'">
                                        <i slot="prefix" class="el-input__icon fi "
                                           :class="'fi-' + loopItem.language_code "></i>
                                    </el-input>
                                </el-form-item>
                            </template>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="card-sub-title">Cümlenin Çevirileri</div>
                        <div class="card-custom-padding-box-items">
                            <template v-for="(loopItem, index) in form.sentences">
                                <el-form-item :label="loopItem.language_title + ' Çeviri'" :key="index"
                                              v-if="loopItem.language_code !== 'en'">
                                    <el-input v-model="loopItem.description"
                                              :placeholder="loopItem.language_title + ' Çeviri'">
                                        <i slot="prefix" class="el-input__icon fi "
                                           :class="'fi-' + loopItem.language_code "></i>
                                    </el-input>
                                </el-form-item>
                            </template>
                        </div>
                    </el-col>
                </el-row>
                <div class="card-sub-title">Medya</div>
                <div class="card-custom-padding-box-items">
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <el-form-item label="Resim" class="margin-bottom-0">
                                <fs v-model="form.image_path" class="two"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="20">
                            <el-form-item label="Video">
                                <fs v-model="form.video_path" :isFile="true"/>
                            </el-form-item>
                            <el-form-item label="Seslendirme" class="margin-bottom-0">
                                <fs v-model="form.sound_path" :isFile="true"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="card-sub-title">Ozellikler</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item >
                        <el-switch v-model="form.is_active" active-text="Aktif"/>
                    </el-form-item>
                </div>
                <div class="card-sub-title" v-if="form.id">On Izleme</div>
                <div class="card-custom-padding-box-items margin-bottom-20" v-if="form.id">
                    <el-row :gutter="24">
                        <el-col :span="8">
                            <div class="asset-preview" :key="form.image_path">
                                <img :src="appConfig.s3SourcePath + '/' + form.image_path" alt="image">
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="asset-preview" :key="form.video_path">
                                <video preload :src="appConfig.s3SourcePath + '/' + form.video_path" controls></video>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="asset-preview" :key="form.sound_path">
                                <audio controls ref="WordSoundContainer">
                                    <source :src="appConfig.s3SourcePath + '/' + form.sound_path" type="audio/mp3">
                                    Your browser does not support the audio tag.
                                </audio>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../../Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                meta: {
                    //
                },
                tag_requests: [],
                category_requests: [],
                meanings: [],
                sentences: []
            }
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        } else {
            this.lodash.forEach(this.$store.state.ui.languages, (lang) => {
                this.form.meanings.push({
                    language_id: lang.id,
                    language_title: lang.title,
                    language_code: lang.iso_code,
                    description: null,
                })

                this.form.sentences.push({
                    language_id: lang.id,
                    language_title: lang.title,
                    language_code: lang.iso_code,
                    description: null,
                })
            });
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.pageLoading = false;
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            //
                        };
                    }
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
