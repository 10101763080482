<template>
    <div v-loading="pageLoading">
        <el-row :gutter="24">
            <el-col :span="!finder ? 12 : 24" v-for="item in items" :key="item.id">
                <el-card class="custom-padding">
                    <template slot="header">
                        <div class="title" v-if="item.type === 'invoice'">Fatura Adresi</div>
                        <div class="title" v-if="item.type !== 'invoice'">Teslimat Adresi</div>
                        <div class="actions">
                            <el-tooltip content="Duzenle" placement="left">
                                <el-button icon="el-icon-edit" class="flat dark teal" @click="edit(item.id)"/>
                            </el-tooltip>
                        </div>
                    </template>
                    <div class="box-list">
                        <div class="box-list-item">
                            <div class="name">Onaylandi</div>
                            <div class="description">
                                <div class="badge"
                                     :class="{'bg-green text-white' : item.is_confirmed, 'bg-yellow text-white' : !item.is_confirmed}">
                                    <icon icon="check"></icon>
                                </div>
                            </div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">Baslik</div>
                            <div class="description">{{ item.title }}</div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">Firma Adi</div>
                            <div class="description">{{ item.company_name }}</div>
                        </div>
                        <div class="box-list-item">
                            <div class="name"> Vergi No</div>
                            <div class="description">{{ item.tax_no }}</div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">Vergi Daires</div>
                            <div class="description">{{ item.tax_center }}</div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">Cinsiyet</div>
                            <div class="description">{{ item.gender }}</div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">T.C. Kimlik No</div>
                            <div class="description">{{ item.identification_no }}</div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">Adi Soyadi</div>
                            <div class="description">
                                {{ item.first_name }} {{ item.last_name }}
                            </div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">Telefon Numarasi</div>
                            <div class="description">
                                <phone :item="item.phone" :key="item.id"/>
                            </div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">Posta Kodu</div>
                            <div class="description">{{ item.zip_code }}</div>
                        </div>
                        <div class="box-list-item justify-content-end">
                            {{ item.address_1 }}
                        </div>
                        <div class="box-list-item justify-content-end">
                            {{ item.address_2 }}
                        </div>
                        <div class="box-list-item justify-content-end">
                            {{ item.town }} / {{ item.city }}
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog title="Adres bilgileri" :visible.sync="dialogVisible" v-loading="dialogLoading">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item>
                    <el-switch v-model="form.is_confirmed" active-text="Onayli Adres"/>
                </el-form-item>

                <el-form-item label="Adres Turu">
                    <lv-select :options="addressTypes" :container="form" model="address_type"/>
                </el-form-item>

                <el-form-item label="Adres Başlığı">
                    <el-input v-model="form.title" placeholder="Adres Başlığı"/>
                </el-form-item>

                <div v-show="form.address_type === 'company'">
                    <el-form-item label="Firma Adi">
                        <el-input v-model="form.company_name" placeholder="Firma Adi"/>
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Vergi Numarasi">
                                <el-input v-model="form.tax_no" placeholder="Vergi Numarasi"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Vergi Dairesi">
                                <el-input v-model="form.tax_center" placeholder="Vergi Dairesi"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                <div v-show="form.address_type === 'customer'">
                    <el-form-item label="T.C. No">
                        <el-input v-model="form.identification_no" placeholder="T.C. No" v-mask="'###########'"/>
                    </el-form-item>

                    <el-form-item label="Cinsiyet">
                        <definitions alias="genders" model="gender_definition_id" :container="form"/>
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Adı">
                                <el-input v-model="form.first_name" placeholder="Adı"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Soyadı">
                                <el-input v-model="form.last_name" placeholder="Soyadı"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                <el-form-item label="Telefon">
                    <el-input v-model="form.phone" placeholder="Telefon" v-mask="'0 (###) ### ## ##'"/>
                </el-form-item>

                <el-form-item label="Adres">
                    <el-input v-model="form.address_1" placeholder="Adres"/>
                </el-form-item>

                <el-form-item label="Adres 2">
                    <el-input v-model="form.address_2" placeholder="Adres 2"/>
                </el-form-item>

                <el-form-item label="Ülke">
                    <el-select v-model="form.country_zone_id" placeholder="Ülke seçiniz" @change="selectedCountry"
                               clearable>
                        <el-option v-for="item in countries" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Şehir">
                            <el-select v-model="form.city_zone_id" placeholder="Şehir seçiniz" filterable
                                       @change="selectedCity"
                                       clearable>
                                <el-option v-for="item in cities" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="İlçe">
                            <el-select v-model="form.town_zone_id" placeholder="İlçe seçiniz" filterable
                                       @change="selectedTown"
                                       clearable>
                                <el-option v-for="item in towns" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="update" v-if="form">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name   : 'Address',
        props  : ['order', 'finder'],
        data() {
            return {
                pageLoading  : false,
                dialogVisible: false,
                dialogLoading: false,
                form         : {
                    //
                },

                items: [],

                countries    : [],
                cities       : [],
                towns        : [],
                districts    : [],
                neighborhoods: [],

                addressTypes: [
                    {
                        value: 'customer',
                        label: 'Sahis',
                    },
                    {
                        value: 'company',
                        label: 'Sirket',
                    },
                ],
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            endPoint(suffix) {

                const path = 'shop/orders/' + this.order.id + '/addresses';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params: {sorts: {created_at: 'desc'}}})
                    .then(response => {

                        if (this.finder) {
                            this.items = this.lodash.filter(response.body.rows, (o) => {
                                return o.type === this.finder
                            });
                        } else {
                            this.items = response.body.rows;
                        }

                        this.$emit('addressLoaded', true)

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;
                        this.dialogLoading = false;
                        this.pageLoading = false;

                        this.setZones();
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.dialogLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
            },

            setZones() {
                this.getZones('countries', 0);

                if (this.form.country_zone_id) {
                    this.getZones('cities', this.form.country_zone_id);
                }

                if (this.form.city_zone_id) {
                    this.getZones('towns', this.form.city_zone_id);
                }

                if (this.form.town_zone_id) {
                    this.getZones('districts', this.form.town_zone_id);
                }

                if (this.form.district_zone_id) {
                    this.getZones('neighborhoods', this.form.district_zone_id);
                }
            },

            getZones(alias, parentId) {

                this.dialogLoading = true;

                this.$http
                    .get('system/' + alias + '/zones', {
                        params: {
                            perPage: 1000,
                            filters: [
                                {
                                    relation: null,
                                    inputs  : [
                                        {
                                            name : 'parent_id',
                                            field: 'parent_id',
                                            type : 'singleSelect',
                                            value: parentId
                                        }
                                    ]
                                },
                            ]

                        }
                    })
                    .then(response => {
                        this[alias] = response.body.rows;
                        this.dialogLoading = false;
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                        return this.responseError(e);
                    });
            },

            selectedCountry() {

                if (!this.form.country_zone_id) {
                    return null;
                }

                this.form.city_zone_id = null;
                this.form.town_zone_id = null;
                this.form.district_zone_id = null;
                this.form.neighborhood_zone_id = null;
                this.cities = [];
                this.towns = [];
                this.districts = [];
                this.neighborhoods = [];

                this.getZones('cities', this.form.country_zone_id)
            },

            selectedCity() {

                if (!this.form.city_zone_id) {
                    return null;
                }

                this.form.town_zone_id = null;
                this.form.district_zone_id = null;
                this.form.neighborhood_zone_id = null;
                this.towns = [];
                this.districts = [];
                this.neighborhoods = [];

                this.getZones('towns', this.form.city_zone_id)
            },

            selectedTown() {

                if (!this.form.town_zone_id) {
                    return null;
                }

                this.form.district_zone_id = null;
                this.form.neighborhood_zone_id = null;
                this.districts = [];
                this.neighborhoods = [];

                this.getZones('districts', this.form.town_zone_id)
            },

            selectedDistrict() {

                if (!this.form.district_zone_id) {
                    return null;
                }

                this.form.neighborhood_zone_id = null;
                this.neighborhoods = [];

                this.getZones('neighborhoods', this.form.district_zone_id)
            },
        }
    }
</script>
