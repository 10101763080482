<template>
    <div v-loading="pageLoading">
        <el-form :label-position="'top'" ref="form" :model="form">
            <el-card class="custom-padding">
                <template slot="header">
                    <CrudHeader ref="CrudHeader" :key="$route.name" :form="form"/>
                </template>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Baslik" class="margin-bottom-0">
                        <el-input v-model="form.title" placeholder="Baslik"/>
                    </el-form-item>
                    <el-form-item label="Urunler" v-if="!form.id && !pageLoading" class="margin-top-20">
                        <el-transfer filterable :filter-method="filterMethod" filter-placeholder="Ürünlerde Ara" :titles="['Tüm ürünler', 'Seçili Olanlar']" v-model="form.product_requests" :props="{key: 'id', label: 'description_title'}" :data="products"/>
                    </el-form-item>
                </div>
                <div v-if="form.id">
                    <div class="box-list">
                        <template v-for="product in form.products">
                            <div class="box-list-item">
                                <div v-html="product"></div>
                            </div>
                        </template>
                    </div>
                </div>
            </el-card>
        </el-form>

        <AccessCodeCode :id="form.id" v-if="form.id" />
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";
import AccessCodeCode from "./AccessCodeCode";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader,
        AccessCodeCode
    },
    data() {
        return {
            pageLoading: false,
            form: {
                id : null,
                product_requests: [],
            },
            products: [],
            filterMethod(query, item) {
                return item.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
            },
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        } else {
            this.getProducts();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getProducts() {
            this.pageLoading = true;

            this.$http
                .get('catalog/products', {params: {perPage: 1000}})
                .then(response => {
                    this.pageLoading = false;
                    this.products = response.body.rows;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    },
}
</script>
