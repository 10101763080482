<template>
    <div v-loading="pageLoading" v-if="status">
        <el-tooltip :content="status.message" placement="left">
            <div class="status" :style="{background: status.status.bg_color, color: status.status.text_color}" @click="itemsDialogVisible = true">
                {{ status.status.title }}
            </div>
        </el-tooltip>

        <el-dialog :visible.sync="itemsDialogVisible" width="50%" class="custom-padding">
            <template slot="title">
                <div class="d-flex align-items-center">
                    <div>
                        <el-tooltip content="Ekle" placement="right">
                            <el-button icon="el-icon-plus" class="flat dark blue" @click="create"/>
                        </el-tooltip>
                    </div>
                    <div class="ml-2 font-weight-600 fs-18">
                        Durumlar
                    </div>
                </div>
            </template>
            <el-card class="card-flat-list">
                <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                    <div class="item" v-for="item in items" :key="item.id">
                        <div class="text">
                            <div class="title" :style="{color : item.status.bg_color}">
                                {{ item.status.title }}
                            </div>
                            <div class="date">{{ item.created_at }}</div>
                            <div class="description">
                                {{ item.message }}
                            </div>
                        </div>
                        <div class="actions">
                            <el-dropdown @command="handleRowActionCommand">
                                <el-button type="text" class="flat dark grey" icon="el-icon-arrow-down"/>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="{type : 'edit', id : item.id}">
                                        <i class="el-icon-edit text-green"></i> Duzenle
                                    </el-dropdown-item>
                                    <el-dropdown-item :command="{type : 'destroy', id : item.id}">
                                        <i class="el-icon-delete text-red"></i> Sil
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </el-card>
        </el-dialog>

        <el-dialog title="Durum" :visible.sync="dialogVisible" v-loading="pageLoading">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Durum" v-if="!form.id">
                    <statuses alias="orders" :container="form" model="status_id"/>
                </el-form-item>
                <el-form-item label="Mesajiniz">
                    <el-input type="textarea" placeholder="Mesajiniz" v-model="form.message" rows="4"/>
                </el-form-item>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name   : 'Status',
        props  : ['order', 'handle'],
        data() {
            return {
                pageLoading       : false,
                itemsDialogVisible: false,
                dialogVisible     : false,
                status            : null,
                form              : {
                    //
                },

                items: [],
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            endPoint(suffix) {

                const path = 'shop/orders/' + this.order.id + '/statuses';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {
                        params: {

                            filters: [
                                {
                                    relation: null,
                                    inputs  : [
                                        {
                                            name : 'alias',
                                            field: 'alias',
                                            type : 'singleSelect',
                                            value: 'order'
                                        }
                                    ]
                                },
                            ],
                            sorts  : {created_at: 'desc'}
                        }
                    })
                    .then(response => {

                        this.items = response.body.rows;

                        this.status = this.items[0];
                        this.order.status_id = this.status.id

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {};
                this.dialogVisible = true;
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.form.alias = 'order';
                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();
                        this.handle();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.dialogVisible = false;

                        this.index();
                        this.handle();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {
                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText : 'Iptal',
                        type             : 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type   : 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            /**
             * Item dropdown actions
             *
             * @param command
             */
            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroy(command.id);
                }

                this.edit(command.id);
            },
        }
    }
</script>

<style scoped lang="scss">
    .status {
        text-align: center;
        min-width: 140px;
        padding: 3px 6px;
        border-radius: 4px;
        font-weight: 500;
        cursor: pointer;
    }
</style>