<template>
	<el-select v-model="container[model]" :placeholder="placeholder ? placeholder : 'Seçiminiz'" :multiple="multiple" clearable filterable :class="{'is-multiple' : multiple}">
		<el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id" />
        <template slot="prefix">
            <icon icon="tune" class="el-input__icon" />
        </template>
	</el-select>
</template>

<script>
    export default {
        name: 'Statuses',
        props: ['container', 'model', 'alias', 'label', 'multiple', 'placeholder'],
        data() {
            return {
                options: []
            }
        },
        mounted() {
            if (this.$store.state.ui && this.$store.state.ui.statuses[this.alias]) {
                this.options = this.$store.state.ui.statuses[this.alias];
			}
        }
    };
</script>
