<template>
	<div id="QuestionPreview" v-loading="pageLoading" class="question-preview">
		<div class="question-content" v-if="questionContent.question_type === 'connected'" v-html="questionContent.description"></div>
		<div class="questions">
			<div class="question" v-for="(question, index) in questionContent.questions" :key="question.id">
				<div class="question-wrap">
					<div class="prefix">
						{{ index + 1 }}.
					</div>
					<div class="content">
                        <div class="margin-bottom-20" v-if="questionContent.view_type === 'listening_multiple_choice'">
                            <audio controls autoplay>
                                <source :src="cdnPath(question.meta.file_src)">
                            </audio>
                        </div>
                        <div v-html="question.description"></div>
                    </div>
				</div>
				<div class="options">
					<div class="option" v-for="(option, optionIndex) in question.options" :key="option.id">
						<div class="prefix">
							<icon :icon="'letter-' + question.option_view_type + '-' + (optionIndex + 1)" />
						</div>
						<div class="content" v-html="option.description"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: 'PreviewQuestion',
    props: ['questionContent'],
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },
        }
    }
}
</script>
