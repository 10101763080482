import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    strict: debug,
    state: {
        preloader: true,
        ui: null,
        account: null,
        icons: [],
        datatable: {},
        shop: {
            create: {
                step: 'product_type',
                product_type: null,
                form: {
                    basket_id: null,
                    product_id: null,
                    content_id: null,
                    quantity: 1,
                    events: [],
                    user_id: null,
                    student_id: null,
                    invoice_address_id: null,
                    delivery_address_id: null,
                }
            }
        },
        //education test
        educationTestArchiveQuestionContents: [],
        filemanager: {
            dialog: false,
            input: null,
        },
        products : null
    },
    mutations: {
        setPreloader(state, payload) {
            state.preloader = payload
        },
        setUi(state, payload) {
            state.ui = payload
        },
        setAccount(state, payload) {
            state.account = payload
        },
        setIcons(state, payload) {
            state.icons = payload
        },
        setDatatable(state, payload) {
            state.datatable[payload.key] = payload
        },
        setDatatablePage(state, payload) {
            state.datatable[payload.key].page = payload.page
        },
        visibleFullPageDialog(state, payload) {
            document.body.classList.add('full-page-dialog-visible');
        },
        hideFullPageDialog(state, payload) {
            document.body.classList.remove('full-page-dialog-visible');
        },
        setShopCreateProductType(state, payload) {
            state.shop.create.product_type = payload;
        },
        setShopCreateChangeStep(state, payload) {
            state.shop.create.step = payload;
        },
        setShopCreateSetForm(state, payload) {
            if (payload.basket_id) {
                state.shop.create.form.basket_id = payload.basket_id;
            }

            if (payload.user_id) {
                state.shop.create.form.user_id = payload.user_id;
            }

            if (payload.student_id) {
                state.shop.create.form.student_id = payload.student_id;
            }

            if (payload.invoice_address_id) {
                state.shop.create.form.invoice_address_id = payload.invoice_address_id;
            }

            if (payload.delivery_address_id) {
                state.shop.create.form.delivery_address_id = payload.delivery_address_id;
            }

            if (payload.product_id) {
                state.shop.create.form.product_id = payload.product_id;
            }

            if (payload.content_id) {
                state.shop.create.form.content_id = payload.content_id;
            }

            if (payload.quantity) {
                state.shop.create.form.quantity = payload.quantity;
            }

            if (payload.events) {
                state.shop.create.form.events = payload.events;
            }
        },

        //education test

        setEducationTestArchiveQuestionContents(state, payload) {
            state.educationTestArchiveQuestionContents = payload;
        },
        setSortEducationTestContentQuestion(state, payload) {
            state.educationTestArchiveQuestionContents = payload;
        },
        removeEducationTestContentQuestion(state, index) {
            state.educationTestArchiveQuestionContents.splice(index, 1)
        },
        addEducationTestContentQuestion(state, payload) {
            state.educationTestArchiveQuestionContents.push({
                'archive_question_content': payload,
                'sort': state.educationTestArchiveQuestionContents.length + 2,
                'archive_question_contents_id': payload.id
            })
        },
        setEmptyEducationTestContentQuestion(state) {
            state.educationTestArchiveQuestionContents = [];
        },
        setVisibleDialogFileManager(state, payload) {
            state.filemanager.dialog = payload;
        },
        setInputFileManager(state, payload) {
            state.filemanager.input = payload;
        },
        setProducts(state, payload) {
            state.products = payload;
        }

    },
    actions: {
        //
    },
    getters: {
        preloader(state) {
            return state.preloader
        },
        user(state) {
            return state.user
        },
        getEducationTestArchiveQuestionContents(state) {
            return state.educationTestArchiveQuestionContents
        },
        products(state) {
            return state.products
        },
    },
    plugins: []
})
