import LayoutConfig from '../config/layout'
import Container from '../app/Container/Main'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Datatable'
import Delivery from '../app/Pages/Shop/Delivery/Delivery'
import ReturnOrder from '../app/Pages/Shop/ReturnOrder'

import OrderIndex from '../app/Pages/Shop/Order/Index'
import OrderEdit from '../app/Pages/Shop/Order/Edit'
import OrderCreate from '../app/Pages/Shop/Order/Create'

import BasketCreate from '../app/Pages/Shop/Basket/Create'
import BasketEdit from '../app/Pages/Shop/Basket/Edit'

import PrintMonitor from '../app/Pages/Shop/PrintMonitor'

export default {
    path: '/shop',
    name: 'api.shop.index',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Satis',
    },
    children: [
        {
            path: 'baskets',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Sepetler',
                breadcrumb: 'api.shop.baskets.index',
            },
            children: [
                {
                    name: 'api.shop.baskets.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Sepetler',
                        apiPoint: 'shop/baskets',
                    },
                },
                {
                    name: 'api.shop.baskets.create',
                    path: 'create',
                    component: BasketCreate,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Sepet Olustur',
                        apiPoint: 'shop/baskets',
                    },
                },
                {
                    name: 'api.shop.baskets.edit',
                    path: ':id',
                    component: BasketEdit,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Sepet Bilgileri',
                        apiPoint: 'shop/baskets',
                        createDisable: true
                    },
                }
            ]
        },
        {
            path: 'orders',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Siparisler',
                breadcrumb: 'api.shop.orders.index',
            },
            children: [
                {
                    name: 'api.shop.orders.index',
                    path: '',
                    component: OrderIndex,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Siparisler',
                        apiPoint: 'shop/orders',
                        createDisable: true
                    },
                },
                {
                    name: 'api.shop.orders.create',
                    path: 'create/:basketId',
                    component: OrderCreate,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Siparis Olustur',
                        apiPoint: 'shop/orders',
                    },
                },
                {
                    name: 'api.shop.orders.edit',
                    path: ':id',
                    component: OrderEdit,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Siparis Bilgileri',
                        apiPoint: 'shop/orders',
                        createDisable: true
                    },
                }
            ]
        },
        {
            path: 'deliveries',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Teslimatlar',
                breadcrumb: 'api.shop.deliveries.index',
            },
            children: [
                {
                    name: 'api.shop.deliveries.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Teslimatlar',
                        apiPoint: 'shop/deliveries',
                        createDisable: true
                    },
                },
                {
                    name: 'api.shop.deliveries.edit',
                    path: ':id',
                    component: Delivery,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Teslimat Bilgileri',
                        apiPoint: 'shop/deliveries',
                        createDisable: true
                    },
                }
            ]
        },
        {
            path: 'returns',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Iadeler',
                breadcrumb: 'api.shop.returns.index',
            },
            children: [
                {
                    name: 'api.shop.returns.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Iadeler',
                        apiPoint: 'shop/returns',
                        createDisable: true
                    },
                },
                {
                    name: 'api.shop.returns.edit',
                    path: ':id',
                    component: ReturnOrder,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Iade Bilgileri',
                        apiPoint: 'shop/returns',
                        createDisable: true
                    },
                }
            ]
        },
        {
            path: 'print-monitors',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Baskı Monitörü',
                breadcrumb: 'api.shop.print_monitors.index',
            },
            children: [
                {
                    name: 'api.shop.print_monitors.index',
                    path: '',
                    component: PrintMonitor,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Baskı Monitörü',
                        apiPoint: 'shop/print-monitors',
                        createDisable: true
                    },
                }
            ]
        },
    ]
}