<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list overflow-400">
			<template slot="header">
				<div class="title">
					Iadeler
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text">
						<div class="title">#{{ item.order.code }}</div>
						<div class="vertical-flex-items has-dimmer">
							<div>{{ $n(item.total, 'currency') }}</div>
							<div>{{ item.created_at }}</div>
							<div>{{ item.payment_plugin.name }}</div>
							<div v-html="item.status_badge"></div>
						</div>
					</div>
					<div class="actions">
						<el-button class="modern" @click="openBlankWindow('/#/shop/returns/' + item.id)">
							Detay
						</el-button>
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
    export default {
        name: 'OrderReturns',
        props: ['id'],
        data() {
            return {
                pageLoading: false,
                items: []
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            /**
             * All items
             */
            index() {
                this.$http
                    .get('shop/returns', {
                        params: {
                            perPage: 1000,
                            filters: [
                                {
                                    relation: 'order',
                                    with: true,
                                    inputs: [
                                        {
                                            name: 'user_id',
                                            field: 'user_id',
                                            type: 'singleSelect',
                                            value: this.id
                                        }
                                    ]
                                },
                            ],
                        }
                    })
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            }
        }
    }
</script>