<template>
    <div class="book" v-loading="pageLoading">
        <div v-if="form.id">
            <Note :path="$route.fullPath" class="margin-bottom-20" />

            <app-tab :items="appTabs" active="default" :update="handleChangeTab" />

            <div class="margin-top-20">
                <el-card class="custom-padding" v-show="activeAppTab === 'default'">
                    <template slot="header">
                        <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
                    </template>
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <div class="card-sub-title">Metin</div>
                        <div class="card-custom-padding-box-items">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="Baslik">
                                        <el-input v-model="form.title" placeholder="Baslik" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Yayinlanma Basligi">
                                        <el-input v-model="form.public_title" placeholder="Yayinlanma Basligi" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-form-item label="Yayınlanma Tarihi">
                                <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayınlanma Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                            </el-form-item>

                            <el-form-item label="Aciklama" class="is-editor">
                                <editor :container="form" model="description" :small="true" />
                            </el-form-item>
                        </div>

                        <div class="card-sub-title">Medya</div>
                        <div class="card-custom-padding-box-items">
                            <el-row :gutter="24">
                                <el-col :span="4">
                                    <el-form-item label="Listeleme Sayfasi Resmi" class="margin-bottom-0">
                                        <fs v-model="form.meta.index_cover" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Detay Sayfasi Resmi" class="margin-bottom-0">
                                        <fs v-model="form.meta.show_cover" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Pdf On Kapak Resmi" class="margin-bottom-0">
                                        <fs v-model="form.meta.page_front_cover" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Pdf Arka Kapak Resmi" class="margin-bottom-0">
                                        <fs v-model="form.meta.page_back_cover" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Pdf Filigran" class="margin-bottom-0">
                                        <fs v-model="form.meta.page_watermark" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="card-sub-title">
                            <div class="title">Dosyalar</div>
                        </div>
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Kitap Pdf">
                                <fs v-model="form.meta.pdf_path" :isFile="true" />
                            </el-form-item>
                        </div>

                        <div class="card-sub-title">Baglantilar</div>
                        <div class="card-custom-padding-box-items" :key="form.id">
                            <el-form-item label="Etiketler">
                                <definition-box alias="books" :container="form" model="tag_requests" :multiple="true" placeholder="Etiketler" />
                            </el-form-item>

                            <el-form-item label="Urunler">
                                <product-transfer :container="form" model="product_requests" :types="['group', 'subscription']" />
                            </el-form-item>
                        </div>
                    </el-form>
                </el-card>

                <el-row :gutter="24" v-show="activeAppTab === 'directory'">
                    <el-col :span="14">
                        <template v-if="sectionId">
                            <BookExercise :id="sectionId" :key="sectionId" />
                        </template>

                        <template v-if="!sectionId">
                            <empty title="Alistirmalari goruntuleye bilmek icin bolum secimi yapmaniz gerekiyor." />
                        </template>
                    </el-col>
                    <el-col :span="10">
                        <BookDirectory :id="form.id" :findExercise="handleGetExercise" />
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import BookDirectory from "@/app/Pages/Education/Book/Directory";
import BookExercise from "@/app/Pages/Education/Book/Exercise";
import CrudHeader from "@/app/Components/CrudHeader";
import Note from "@/app/Components/Note";

export default {
    name: 'BookEdit',
    components: {
        Note,
        CrudHeader,
        BookExercise,
        BookDirectory
    },
    data() {
        return {
            pageLoading: false,
            form: {
                tag_requests: [],
                product_requests: [],
                meta: {
                    //
                },
            },

            sectionId : null,

            activeAppTab : 'default',
            appTabs : [
                {
                    key : 'default',
                    title : 'Kitap Bilgileri'
                },
                {
                    key : 'directory',
                    title : 'Dizin'
                }
            ],
        }
    },
    mounted() {
        this.show();
    },
    methods: {
        handleChangeTab(value) {
            this.activeAppTab = value;
        },

        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {};
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleGetExercise(sectionId) {
            this.sectionId = sectionId;
        },
    }
}
</script>
