<template>
	<div class="page theme-page">
		<div class="page-header">
			<div class="content">
				<div class="header">
					{{ $route.meta.title }}
				</div>
			</div>
			<div class="actions">
				<el-button class="modern teal large extract" @click="goRoute('/themes/create')">
					<icon icon="cloud-upload-outline" style="margin-right: 5px" /> Yukle
				</el-button>
			</div>
		</div>
		<div class="page-content" :key="$route.name">
			<div class="theme_list" v-loading="pageLoading">
				<el-card class="custom-padding">
					<div class="item" v-for="item in items" :key="item.id">
						<div class="content">
							<div class="media">
								<img :src="appConfig.cdnPath + item.preview" alt="preview">
							</div>
							<div class="text">
								<div class="title">{{ item.name }}</div>
								<div class="description">{{ item.description }}</div>
							</div>
						</div>
						<div class="actions">
							<el-button @click="$router.push('/themes/' + item.id)" class="primary">
								Düzenle
							</el-button>
							<el-button @click="destroy(item.id)" class="red" icon="el-icon-delete" />
						</div>
					</div>
				</el-card>
			</div>
		</div>

		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'ThemeIndex',
		data() {
			return {
				pageLoading: false,
				form: {
					//
				},
				items : []
			}
		},
		mounted() {
			this.index();
		},
		methods: {
			index() {
				this.pageLoading = true;

				this.$http
					.get(this.$route.meta.apiPoint)
					.then(response => {
						this.items = response.body.data;
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			/**
			 * Destroy item
			 */
			destroy(id) {

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {

						this.$http
							.delete(this.$route.meta.apiPoint + '/' + id)
							.then(response => {
								this.responseSuccess(response);
								this.index();
							})
							.catch(response => {
								this.responseError(response);
							});


						return true;

					})
					.catch((response) => {
						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},
		}
	}
</script>