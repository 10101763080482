<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">

				<el-form-item label="Gonderim Tipi">
					<lv-select :container="form" model="view_access" :options="aliases" placeholder="Gonderim Tipi" />
				</el-form-item>

				<div v-if="form.view_access && form.view_access !== 'all'">
					<el-form-item label="Urunler">
						<el-select v-model="form.product_requests" placeholder="Urunler" clearable filterable multiple>
							<el-option v-for="item in products" :key="item.id" :label="item.title_ui" :value="item.id">
								<span style="float: left">{{ item.title }}</span>
								<span style="float: right; color: #8492a6; font-size: 13px">{{ item.reference }}</span>
							</el-option>
						</el-select>
					</el-form-item>
				</div>

				<el-form-item label="Baslik">
					<el-input v-model="form.title" placeholder="Baslik" />
				</el-form-item>

				<el-form-item label="Aciklama" class="is-editor">
					<editor :container="form" model="description" />
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import CrudHeader from "../../Components/CrudHeader";

	export default {
		name: 'Announcement',
		components: {
			CrudHeader
		},
		data() {
			return {
				pageLoading: false,
				form: {
					//
				},
				products: [],
				aliases: [
					{
						value: 'all',
						label: 'Herkes'
					},
					{
						value: 'teachers',
						label: 'Ogretmenlere'
					},
					{
						value: 'students',
						label: 'Ogrencilere'
					},
				]
			}
		},

		mounted() {

			this.getProducts();

			if (this.$route.params.id) {
				this.show();
			}
		},
		methods: {
			getProducts() {
				this.pageLoading = true;

				this.$http
					.get('tools/search/products', {params: {process: 'dropdown'}})
					.then(response => {
						this.products = response.body.data;
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			show() {
				this.pageLoading = true;

				this.$http
					.get(this.createShowPageLink())
					.then(response => {
						this.form = response.body.data;

						this.handleSelectedContent();

						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},
		}
	}
</script>