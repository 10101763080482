<template>
    <div>
        Select
    </div>
</template>

<script>
export default {
    name: 'Select',
    props : ['form', 'container', 'item'],
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>