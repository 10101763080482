<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list overflow-400">
			<template slot="header">
				<div class="title">
					Adresler
				</div>
				<div class="actions">
					<el-tooltip content="Ekle" placement="left">
						<el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
					</el-tooltip>
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text" style="display: flex; align-items: center">
						<div class="mr-4">
							<div class="badge" :class="{'bg-green text-white' : item.is_main, 'bg-yellow text-white' : !item.is_main}">
								<icon icon="check"></icon>
							</div>
						</div>
						<div class="title" style="margin: 0">
							<div>{{ item.title }}</div>
							<div style="font-size: 12px; font-weight: 300">{{ item.address_1 }}</div>
						</div>
					</div>
					<div class="actions" style="right: 20px">
						<el-dropdown @command="handleRowActionCommand">
							<el-button type="text" class="flat dark grey" icon="el-icon-arrow-down" />
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{type : 'edit', id : item.id}">
									<i class="el-icon-edit text-green"></i> Duzenle
								</el-dropdown-item>
								<el-dropdown-item :command="{type : 'destroy', id : item.id}">
									<i class="el-icon-delete text-red"></i> Sil
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog title="Adres Bilgileri" :visible.sync="dialogVisible" v-loading="dialogLoading">
			<el-form :label-position="'top'" ref="form" :model="form">

				<el-form-item>
					<el-switch v-model="form.is_main" active-text="Varsayılan Iletisim Adresi"/>
				</el-form-item>

				<el-form-item label="Adres Turu">
					<lv-select :options="addressTypes" :container="form" model="address_type" />
				</el-form-item>

				<el-form-item label="Adres Başlığı">
					<el-input v-model="form.title" placeholder="Adres Başlığı" />
				</el-form-item>

				<div v-show="form.address_type === 'company'">
					<el-form-item label="Firma Adi">
						<el-input v-model="form.company_name" placeholder="Firma Adi" />
					</el-form-item>

					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item label="Vergi Numarasi">
								<el-input v-model="form.tax_no" placeholder="Vergi Numarasi" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Vergi Dairesi">
								<el-input v-model="form.tax_center" placeholder="Vergi Dairesi" />
							</el-form-item>
						</el-col>
					</el-row>
				</div>

				<div v-show="form.address_type === 'customer' || !form.address_type">
					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item label="Adı">
								<el-input v-model="form.first_name" placeholder="Adı" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Soyadı">
								<el-input v-model="form.last_name" placeholder="Soyadı" />
							</el-form-item>
						</el-col>
					</el-row>

					<el-form-item label="T.C. No">
						<el-input v-model="form.identification_no" placeholder="T.C. No" v-mask="'###########'" />
					</el-form-item>
				</div>

				<el-form-item label="Telefon">
					<el-input v-model="form.phone" placeholder="Telefon" v-mask="'0 (###) ### ## ##'" />
				</el-form-item>

                <el-form-item label="Ülke">
                    <el-select v-model="form.country_zone_id" placeholder="Ülke seçiniz" @change="handleChangeZone('country')" clearable>
                        <el-option v-for="item in countries" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Şehir">
                            <el-select v-model="form.city_zone_id" placeholder="Şehir seçiniz" filterable @change="handleChangeZone('city')" clearable>
                                <el-option v-for="item in cities" :key="item.id" :label="item.title" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="İlçe">
                            <el-select v-model="form.town_zone_id" placeholder="İlçe seçiniz" filterable @change="handleChangeZone('town')" clearable>
                                <el-option v-for="item in towns" :key="item.id" :label="item.title" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

				<el-form-item label="Adres">
					<el-input v-model="form.address_1" placeholder="Adres" />
				</el-form-item>

				<el-form-item label="Adres 2">
					<el-input v-model="form.address_2" placeholder="Adres 2" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_active" active-text="Aktif" />
				</el-form-item>
			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
					Kaydet
				</el-button>
				<el-button class="fluid flat dark green large" @click="update" v-if="form.id">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'UserRole',
        props: ['id'],
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                dialogLoading: false,
                form: {
                    //
                },

                items: [],
                countries: [],
                cities: [],
                towns: [],
                districts: [],
                neighborhoods: [],

                addressTypes: [
                    {
                        value: 'customer',
                        label: 'Sahis',
                    },
                    {
                        value: 'company',
                        label: 'Sirket',
                    },
                ],
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = 'crm/users/' + this.id + '/addresses';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params : {perPage : 1000}})
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {};
                this.dialogVisible = true;
                this.setZones();
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.dialogLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        this.dialogLoading = false;

                        this.setZones();
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.dialogLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.dialogLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.dialogLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            /**
             * Item dropdown actions
             *
             * @param command
             */
            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroy(command.id);
                }

                this.edit(command.id);
            },

            setZones() {
                this.getZones('countries', 0);

                if (this.form.country_zone_id) {
                    this.getZones('cities', this.form.country_zone_id);
                }

                if (this.form.city_zone_id) {
                    this.getZones('towns', this.form.city_zone_id);
                }

                if (this.form.district_zone_id) {
                    this.getZones('neighborhoods', this.form.district_zone_id);
                }
            },

            getZones(alias, parentId) {

                this.dialogLoading = true;

                this.$http
                    .get('tools/search/zones', {
                        params: {
                            parent_id: parentId,
                        }
                    })
                    .then(response => {
                        this[alias] = response.body.data;
                        this.dialogLoading = false;
                    })
                    .catch((e) => {
                        this.dialogLoading = false;
                        return this.responseError(e);
                    });
            },

            handleChangeZone(type) {
                switch (type) {

                    case 'country':

                        this.cities = [];
                        this.towns = [];
                        this.districts = [];
                        this.neighborhoods = [];

                        this.getZones('cities', this.form.country_zone_id)

                        break;

                    case 'city':

                        this.towns = [];
                        this.districts = [];
                        this.neighborhoods = [];

                        this.getZones('towns', this.form.city_zone_id)

                        break;

                    case 'town':
                        this.districts = [];
                        this.neighborhoods = [];

                        this.getZones('neighborhoods', this.form.town_zone_id)

                        break;

                    case 'district':
                        this.neighborhoods = [];

                        this.getZones('neighborhoods', this.form.district_zone_id)

                        break;

                    default :
                        this.towns = [];
                        this.districts = [];
                        this.neighborhoods = [];

                        this.form.town_zone_id = null;
                        this.form.district_zone_id = null;
                        this.form.neighborhood_zone_id = null;

                        break;
                }
            },
        }
    }
</script>