4
<template>
    <div class="card-custom-padding-box margin-0">
        <div class="card-sub-title">Kayit Islemleri</div>
        <div class="card-custom-padding-box-items">
            <el-form-item label="Eposta Dogrulamasini Ac">
                <lv-select :container="form" model="customer_register_email_verify_status_id" :options="approveOptionsInteger"/>
            </el-form-item>
        </div>

        <div class="card-sub-title">Roller</div>
        <div class="card-custom-padding-box-items">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="Root">
                        <el-select v-model="form.root_role_id" placeholder="Select">
                            <el-option v-for="role in roles" :key="role.id" :label="role.title" :value="role.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Admin">
                        <el-select v-model="form.admin_role_id" placeholder="Select">
                            <el-option v-for="role in roles" :key="role.id" :label="role.title" :value="role.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Personel">
                        <el-select v-model="form.staff_role_id" placeholder="Select">
                            <el-option v-for="role in roles" :key="role.id" :label="role.title" :value="role.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Ogretmen">
                        <el-select v-model="form.teacher_role_id" placeholder="Select">
                            <el-option v-for="role in roles" :key="role.id" :label="role.title" :value="role.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Müşteri">
                        <el-select v-model="form.customer_role_id" placeholder="Select">
                            <el-option v-for="role in roles" :key="role.id" :label="role.title" :value="role.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Ziyaretci">
                        <el-select v-model="form.guest_role_id" placeholder="Select">
                            <el-option v-for="role in roles" :key="role.id" :label="role.title" :value="role.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>

        <div class="card-sub-title">Sosyal Medya Giris</div>
        <div class="card-custom-padding-box-items">
            <el-row :gutter="24">
                <el-col :span="8">
                    <div class="box">
                        <div class="headline large">
                            <div class="title">
                                <i class="mdi mdi-google"></i>
                                Google
                            </div>
                        </div>
                        <div class="body">
                            <el-form-item label="Giris Islemini Ac">
                                <lv-select :container="form" model="google_auth_service_status" :options="approveOptionsInteger"/>
                            </el-form-item>

                            <el-form-item label="Client Id">
                                <el-input v-model="form.google_auth_service_client_key" placeholder="Client Id"/>
                            </el-form-item>

                            <el-form-item label="Client Secret">
                                <el-input v-model="form.google_auth_service_client_secret" placeholder="Client Secret"/>
                            </el-form-item>

                            <div class="ui message yellow fs-14">
                                <code>{domain}/auth/social/callback/google</code>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="box">
                        <div class="headline large">
                            <div class="title">
                                <i class="mdi mdi-facebook"></i>
                                Facebook
                            </div>
                        </div>
                        <div class="body">
                            <el-form-item label="Giris Islemini Ac">
                                <lv-select :container="form" model="facebook_auth_service_status" :options="approveOptionsInteger"/>
                            </el-form-item>

                            <el-form-item label="Client Id">
                                <el-input v-model="form.facebook_auth_service_client_key" placeholder="Client Id"/>
                            </el-form-item>

                            <el-form-item label="Client Secret">
                                <el-input v-model="form.facebook_auth_service_client_secret" placeholder="Client Secret"/>
                            </el-form-item>

                            <div class="ui message yellow fs-14">
                                <code>{domain}/auth/social/callback/facebook</code>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="box">
                        <div class="headline large">
                            <div class="title">
                                <i class="mdi mdi-apple"></i>
                                Apple
                            </div>
                        </div>
                        <div class="body">
                            <el-form-item label="Giris Islemini Ac">
                                <lv-select :container="form" model="apple_auth_service_status" :options="approveOptionsInteger"/>
                            </el-form-item>

                            <el-form-item label="Client Id">
                                <el-input v-model="form.apple_auth_service_client_key" placeholder="Client Id"/>
                            </el-form-item>

                            <el-form-item label="Client Secret">
                                <el-input v-model="form.apple_auth_service_client_secret" placeholder="Client Secret"/>
                            </el-form-item>

                            <div class="ui message yellow fs-14">
                                <code>{domain}/auth/social/callback/apple</code>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    export default {
        name   : 'CustomerSetting',
        props  : ['form', 'roles']
    }
</script>
