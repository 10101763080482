<template>
    <div v-loading="pageLoading">
        <el-row :gutter="24">
            <el-col :span="16">
                <template v-if="!elementSize(items)">
                    <empty />
                </template>
                <draggable tag="div" handle=".move-question" v-model="items" :group="{ name: 'questios' }" @end="setQuestionSort">
                    <transition-group>
                        <template v-for="(question, index) in items">
                            <div class="test-question" :key="index">
                                <div class="instruction" v-if="question.instruction"
                                     v-html="question.instruction.description"></div>
                                <div class="test-question-content" v-if="question.content.description"
                                     v-html="question.content.description"></div>
                                <div class="metas">
                                    <div class="meta">
                                        {{ question.content.branch }}
                                    </div>
                                    <div class="meta">
                                        {{ $t('quesion_content_owner_' + question.content.owner_type) }}
                                    </div>
                                    <div class="meta" v-if="question.content.owner_type === 'owner'">
                                        {{ question.content.owner }}
                                    </div>
                                    <div class="meta" v-if="question.content.owner_type !== 'owner'">
                                        {{ question.content.examination }}
                                    </div>
                                </div>
                                <div class="test-question-wrp">
                                    <div class="prefix-wrp">
                                        <el-tooltip content="Tasi" placement="right">
                                            <div class="prefix move-question">
                                                {{ index + 1 }}
                                            </div>
                                        </el-tooltip>

                                        <el-tooltip content="Duzenle" placement="right">
                                            <div class="delete"
                                                 @click="openBlankWindow('/#/archives/question-contents/' + question.content_id)">
                                                <el-button class="modern large default icon" icon="el-icon-edit" />
                                            </div>
                                        </el-tooltip>

                                        <el-tooltip content="Sil" placement="right">
                                            <div class="delete" @click="destroy(question.deleted_id)">
                                                <el-button class="modern large red icon" icon="el-icon-delete" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                    <div class="content">
                                        <el-card>
                                            <div class="question-root" v-html="question.question.description"></div>
                                            <div class="options">
                                                <template v-for="(option, optionIndex) in question.options">
                                                    <div class="option" :key="optionIndex">
                                                        <div class="option-prefix"
                                                             :class="{active : option.is_correct}">
                                                            <icon :icon="'letter-alpha' + '-' + (optionIndex + 1)" />
                                                        </div>
                                                        <div class="option-content" v-html="option.description"></div>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="metas margin-bottom-0">
                                                <div class="meta"
                                                     v-for="(knowledge, knowledgeIndex) in question.question.knowledges"
                                                     :key="knowledgeIndex">
                                                    {{ knowledge }}
                                                </div>
                                                <div class="meta"
                                                     v-for="(level, levelIndex) in question.question.levels"
                                                     :key="levelIndex">
                                                    {{ level }}
                                                </div>
                                                <div class="meta">
                                                    <span v-if="question.question.is_access_video_solve === 'yes'">Videolu Çözüm Yapılabilir</span>
                                                    <span v-if="question.question.is_access_video_solve === 'no'">Videolu Çözüm Yapılamaz</span>
                                                </div>
                                            </div>
                                        </el-card>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </transition-group>
                </draggable>
            </el-col>
            <el-col :span="8">
                <el-row :gutter="24">
                    <el-col :span="4">
                        <el-tooltip content="Yenile">
                            <el-button class="modern large teal fluid large-icon" icon="el-icon-refresh" @click="index" />
                        </el-tooltip>
                    </el-col>
                    <el-col :span="4">
                        <el-tooltip content="Soru Getir">
                            <el-button class="modern large blue fluid large-icon" icon="el-icon-search" @click="questionDialogVisible = true" />
                        </el-tooltip>
                    </el-col>
                    <el-col :span="4">
                        <el-tooltip content="Soru Pdf Olustur">
                            <el-button class="modern large pink fluid large-icon" icon="mdi mdi-progress-question" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/prints/educations/questions/' + id)" />
                        </el-tooltip>
                    </el-col>
                    <el-col :span="4">
                        <el-tooltip content="Test Pdf Olustur">
                            <el-button class="modern large violet fluid large-icon" icon="mdi mdi-ab-testing" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/prints/educations/tests/' + id)" />
                        </el-tooltip>
                    </el-col>
                    <el-col :span="4">
                        <el-tooltip content="Seviye Tespit Sinavi Pdf">
                            <el-button class="modern large green fluid large-icon" icon="mdi mdi-school" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/prints/educations/tests/' + id + '?process=leveling-exam')" />
                        </el-tooltip>
                    </el-col>
                </el-row>
                <el-card class="custom-padding margin-top-20">
                    <div class="box-list">
                        <div class="box-list-item">
                            <div class="name">
                                Toplam
                            </div>
                            <div class="description">
                                {{ stats.total }} Soru
                            </div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">
                                Sinavlar
                            </div>
                            <div class="description">
                                {{ stats.examination }} Soru
                            </div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">
                                Orjinal
                            </div>
                            <div class="description">
                                {{ stats.original }} Soru
                            </div>
                        </div>
                    </div>
                    <div class="card-sub-title text-blue">Şık Dağılımı</div>
                    <div class="box-list">
                        <template v-for="(option, optionIndex) in stats.options">
                            <div class="box-list-item" :key="optionIndex">
                                <div class="name fs-24">
                                    <icon :icon="'letter-alpha' + '-' + (parseInt(optionIndex) + 1)" />
                                </div>
                                <div class="description">
                                    {{ option }} Soru
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="card-sub-title text-blue">Konu Etiketleri</div>
                    <div class="box-list">
                        <template v-for="(topic, topicIndex) in stats.topics">
                            <div class="box-list-item" :key="topicIndex">
                                <div class="name fs-13" style="width: 100%">
                                    {{ topic.title }}
                                </div>
                                <div class="description">
                                    {{ topic.counter }} Soru
                                </div>
                            </div>
                        </template>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog title="Sorular" :visible.sync="questionDialogVisible" class="dialog-with-page custom-padding">
            <FilterQuestionTest :store="store" :questions="items" />
        </el-dialog>
    </div>
</template>

<script>
import FilterQuestionTest from "@/app/Pages/Education/Test/Filter";
import draggable from "vuedraggable";

export default {
    name: 'TestQuestion',
    components: {
        FilterQuestionTest,
        draggable
    },
    props: ['id'],
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },

            items: [],
            stats: [],

            questionDialogVisible: false,
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = this.$route.meta.apiPoint + '/' + this.id + '/questions';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        /**
         * All items
         */
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                .then(response => {

                    this.items = response.body.data.items;
                    this.stats = response.body.data.stats;

                    setTimeout(() => {
                        this.initMathJax(window.document.getElementsByTagName('math'));
                        this.pageLoading = false;
                    }, 500)
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        store(id) {
            this.pageLoading = true;

            this.$http
                .post(this.endPoint(), {question_id: id})
                .then(response => {
                    this.pageLoading = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {
                            this.index();
                            this.responseSuccess(response);
                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        /**
         *
         */
        setQuestionSort() {
            let sorts = [];

            this.lodash.forEach(this.items, (item, index) => {
                sorts.push({
                    id: item.deleted_id,
                    sort: index
                })
            });

            this.$http
                .post(this.endPoint(), {
                    process: 'sortable',
                    items: sorts
                })
                .then(response => {
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    return this.responseError(response);
                })
        }

    }
}
</script>
