<template>
	<div class="card-custom-padding-box margin-0" v-loading="pageLoading">
		<div class="card-sub-title">Arsiv Genel Tanimlar</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Ingilizce Bransi">
				<ti-select :options="branches" :container="form" model="english_archive_branch_id" title="title_ui" placeholder="Ingilizce Bransi" />
			</el-form-item>

            <el-form-item label="Seviye Belirleme Sinavi Branslari">
                <ti-select :options="branches" :container="form" model="leveling_exams" title="title_ui" placeholder="Seviye Belirleme Sinavi Branslari" :multiple="true" />
            </el-form-item>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ArchiveSetting',
		props: ['form'],
		data() {
			return {
				pageLoading : false,
				branches : []
			}
		},
		mounted() {
			this.getBranches();
		},
		methods: {
			getBranches() {
				this.pageLoading = true;

				this.$http
					.get('archives/branches', {params: {perPage: 10000}})
					.then(response => {
						this.pageLoading = false;
						this.branches = response.body.rows;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},
		}
	}
</script>
