<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding card-flat-list margin-top-30">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">
                        Ders Bilgileri
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri" placement="bottom">
                            <el-button class="flat dark grey" icon="el-icon-back" @click="processHandle('choice')" />
                        </el-tooltip>
                        <el-tooltip content="Filtre" placement="bottom">
                            <el-button class="flat dark blue" icon="el-icon-search"
                                       @click="filterDialogVisible = true" />
                        </el-tooltip>
                        <el-tooltip content="Tarih Bilgilerini Güncelle" placement="bottom">
                            <el-button class="flat dark green" icon="el-icon-refresh"
                                       @click="changeTimeDialogVisible" v-if="elementSize(items)" />
                        </el-tooltip>
                        <el-tooltip content="Filtre Sonucuna GÖre Tüm Dersleri Sil" placement="bottom">
                            <el-button class="flat dark red" icon="el-icon-delete"
                                       @click="destroy" v-if="elementSize(items)" />
                        </el-tooltip>
                    </div>
                </div>
            </template>

            <div class="card-flat-list-items sm">
                <div class="item" v-for="(item, index) in items" :key="index" v-if="!item.disable">
                    <div class="text display-flex align-items-center">
                        <div>
                            <div class="title">{{ item.name }}</div>
                            <div class="description">{{ item.description }}</div>
                            <div class="metas">
                                <div>
                                    {{ $d(new Date(item.start_at), 'long') }} - {{ $d(new Date(item.end_at), 'long') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>

        <el-dialog title="Filtre" :visible.sync="filterDialogVisible" width="80%">
            <el-form :label-position="'top'" ref="filterForm" :model="filterForm" v-loading="dialogLoading">

                <el-form-item class="margin-bottom-20">
                    <product-dropdown :container="filterForm" model="product_id" :types="['group', 'subscription']"  />
                </el-form-item>
                <el-alert :closable="false" type="warning">
                    Ogrenci secimini sadece <b>abonelik</b> ile urun almis kullanicilar icin kullaniniz.
                </el-alert>
                <el-row :gutter="24" class="margin-top-20">
                    <el-col :span="12">
                        <el-form-item label="Ogretmen">
                            <user :container="form" model="teacher_id" placeholder="Ogretmen" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Ogrenci">
                            <user :container="form" model="customer_id" placeholder="Ogrenci" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item :class="{ 'is-error': filterSubmitted && $v.filterForm.plugin_id.$error }">
                            <lv-select :container="filterForm" model="plugin_id" :options="plugins" placeholder="Servis" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item class="margin-bottom-0" :class="{ 'is-error': filterSubmitted && $v.filterForm.start_at.$error }">
                            <el-date-picker v-model="filterForm.start_at" type="date" placeholder="Başlangıç Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item class="margin-bottom-0" :class="{ 'is-error': filterSubmitted && $v.filterForm.end_at.$error }">
                            <el-date-picker v-model="filterForm.end_at" type="date" placeholder="Bitiş Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item :class="{ 'is-error': filterSubmitted && $v.filterForm.days.$error }"
                              class="margin-bottom-30">
                    <lv-select :container="filterForm" model="days" :options="days" placeholder="Gunler"
                               :multiple="true" />
                </el-form-item>
                <el-row :gutter="24" v-for="day in filterForm.days" :key="day" class="margin-bottom-30">
                    <el-col :span="12">
                        <div class="day-title">{{ days[day - 1].label }}</div>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item class="margin-bottom-0">
                            <el-time-select placeholder="Saat" v-model="filterForm.day_start_time[day]"
                                            :picker-options="{start: '00:00', step: '00:01',end: '23:00'}"
                                            value-format="YYYY-MM-DD" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <template slot="footer">
                <el-button class="modern large fluid blue" icon="el-icon-search" @click="filterIndex">
                    Filtreye Uygun Dersleri Listele
                </el-button>
            </template>
        </el-dialog>

        <el-dialog title="Tarih Bilgilerini Değiştir" :visible.sync="dialogVisible" width="60%">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-row :gutter="24" v-for="(day, index) in form.days" :key="index" class="margin-bottom-30">
                    <el-col :span="6">
                        <el-form-item>
                            <lv-select :container="day" model="current_day" :options="days" placeholder="Gunler"
                                       :disabled="true" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item class="margin-bottom-0">
                            <el-time-select placeholder="Saat" v-model="day.current_time"
                                            :picker-options="{start: '00:00', step: '00:01',end: '23:00'}"
                                            :disabled="true" />
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item>
                            <lv-select :container="day" model="next_day" :options="days" placeholder="Gunler" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item class="margin-bottom-0">
                            <el-time-select placeholder="Saat" v-model="day.next_time"
                                            :picker-options="{start: '00:00', step: '00:01',end: '23:00'}" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <template slot="footer">
                <el-button class="modern large fluid blue" icon="el-icon-check" @click="update">
                    Kosula Uygun Dersleri Güncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
    name: 'LessonManagementEdit',
    props: ['processHandle'],
    data() {
        return {
            pageLoading: false,
            dialogLoading: false,
            dialogVisible: false,
            filterDialogVisible: false,
            filterSubmitted: false,
            formSubmitted: false,
            filterForm: {
                days: [],
                day_start_time: [],
                day_end_time: [],
            },

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
                firstDayOfWeek: 1,
            },
            products: [],
            plugins: [],
            types: [
                {
                    value: 'webinar',
                    label: 'Webinar'
                },
                {
                    value: 'meeting',
                    label: 'Meeting'
                }
            ],
            days: [
                {
                    value: 1,
                    label: 'Pazartesi'
                },
                {
                    value: 2,
                    label: 'Sali'
                },
                {
                    value: 3,
                    label: 'Carsamba'
                },
                {
                    value: 4,
                    label: 'Persembe'
                },
                {
                    value: 5,
                    label: 'Cuma'
                },
                {
                    value: 6,
                    label: 'Cumartesi'
                },
                {
                    value: 7,
                    label: 'Pazar'
                }
            ],

            items: [],
            counter: 1,

            form: {
                days: [],
            },
        }
    },
    validations: {
        filterForm: {
            product_id: {},
            plugin_id: {},
            start_at: {required},
            end_at: {required},
            days: {required},
        }
    },
    mounted() {
        this.getPlugins();
    },
    methods: {
        getPlugins() {
            this.pageLoading = true;

            this.$http
                .get('tools/search/plugins', {params: {alias: 'services', sub_alias: 'meetings'}})
                .then(response => {
                    this.plugins = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        filterIndex() {
            this.filterSubmitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.pageLoading = true;
            this.dialogLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {params: this.filterForm})
                .then(response => {
                    this.pageLoading = false;
                    this.dialogLoading = false;
                    this.filterDialogVisible = false;

                    this.items = response.body.data;
                    this.counter = response.body.counter;
                })
                .catch(response => {
                    this.pageLoading = false;
                    this.dialogLoading = false;
                    return this.responseError(response);
                })
        },

        changeTimeDialogVisible() {
            let days = [];

            this.lodash.forEach(this.filterForm.days, (item) => {
                days.push({
                    current_day: item,
                    current_time: this.filterForm.day_start_time[item],
                    next_day: null,
                    next_time: null,
                });
            });

            this.form.days = days;
            this.dialogVisible = true;
        },

        update() {
            this.$confirm('Ders programınız girdiğiniz tarih bilgilerine göre güncellenecek.. Onaylıyor musunuz?', 'Uyari', {
                confirmButtonText: 'Onayla',
                cancelButtonText: 'Iptal',
                type: 'warning'
            }).then(() => {

                this.pageLoading = true;
                this.dialogLoading = true;

                this.filterForm.change_days = this.form.days;

                this.$http
                    .put(this.$route.meta.apiPoint + '/9999999', this.filterForm)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogLoading = false;

                        this.goRoute('/educations/lessons');
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })

                return true;
            }).catch((response) => {
                this.$notify({
                    type: 'success',
                    message: 'Isleminiz basarili bir sekilde iptal edildi!'
                });

                return false;
            });
        },

        destroy() {
            this.$confirm('Listede bulunan tüm dersleriniz silinecek! Onayliyor musunuz?', 'Uyari', {
                confirmButtonText: 'Onayla',
                cancelButtonText: 'Iptal',
                type: 'warning'
            }).then(() => {

                let ids = [];

                this.lodash.forEach(this.items, (row) => {
                    ids.push(row.id);
                })

                this.$http
                    .delete(this.$route.meta.apiPoint + '/9999999', {
                        params: {
                            ids: ids,
                            process: 'multiple'
                        }
                    })
                    .then(response => {
                        this.filterIndex();
                        this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.responseError(response);
                    });

                return true;
            }).catch((response) => {
                this.$notify({
                    type: 'success',
                    message: 'Isleminiz basarili bir sekilde iptal edildi!'
                });

                return false;
            });
        }
    }
}
</script>
