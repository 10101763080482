<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list">
            <template slot="header">
                <div class="actions d-flex fluid">
                    <el-form :label-position="'top'" ref="filter" :model="filter" class="fluid">
                        <div class="d-flex fluid">
                            <el-form-item class="margin-bottom-0 margin-right-10 fluid">
                                <user :container="filter" model="user_id" placeholder="Ogretmen" />
                            </el-form-item>

                            <el-form-item class="margin-bottom-0 margin-right-10 fluid">
                                <lv-select :container="filter" model="type" :options="types" placeholder="Etkinlik Turu" />
                            </el-form-item>
                        </div>
                    </el-form>

                    <el-tooltip content="Ara" placement="bottom">
                        <el-button class="flat dark teal large extract fs-24" icon="el-icon-search" @click="refreshEvents" style="height: 55px" />
                    </el-tooltip>

                    <el-tooltip content="Ekle" placement="bottom">
                        <el-button class="flat dark blue large extract fs-24" icon="el-icon-plus" @click="create" style="height: 55px" />
                    </el-tooltip>

                    <el-tooltip content="Toplu Sil" placement="bottom">
                        <el-button class="flat dark red large extract fs-24" icon="el-icon-delete" @click="destroyDialogVisible = true" style="height: 55px" />
                    </el-tooltip>
                </div>
            </template>
            <FullCalendar ref="AppCalendar" :options="calendar.options" />
        </el-card>

        <el-dialog title="Etkinlik Olustur" :visible.sync="createDialogVisible">
            <div v-loading="dialogLoading">
                <el-form :label-position="'top'" ref="form" :model="form">
                    <el-form-item label="Etkinlik Turu">
                        <definitions alias="event_types" model="relation_type" :container="form"  />
                    </el-form-item>

                    <el-form-item label="Baslik">
                        <el-input v-model="form.title" placeholder="Baslik" />
                    </el-form-item>

                    <el-form-item label="Ogretmen">
                        <user :container="form" model="user_id" placeholder="Ogretmen" :key="form.id" />
                    </el-form-item>

                    <el-form-item label="Tarih" v-if="!form.is_multiple">
                        <el-date-picker v-model="form.start_at" type="date" placeholder="Tarih" value-format="yyyy-MM-dd" />
                    </el-form-item>

                    <el-form-item label="Tarih Araligi" v-if="form.is_multiple">
                        <el-date-picker v-model="form.date" type="daterange" range-separator="ve" start-placeholder="Baslangic Tarihi" end-placeholder="Bitis Tarihi" />
                    </el-form-item>

                    <el-form-item label="Saat Araligi">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-time-select placeholder="Baslangic Saati" v-model="form.start_time" :picker-options="{start: '00:00', step: '00:01',end: '23:00'}" value-format="YYYY-MM-DD" />
                            </el-col>
                            <el-col :span="12">
                                <el-time-select placeholder="Bitis Saati" v-model="form.end_time" :picker-options="{start: '00:00',step: '00:01',end: '23:00'}" value-format="YYYY-MM-DD" />
                            </el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label="Gunler" v-if="form.is_multiple">
                        <lv-select :container="form" model="days" :options="days" placeholder="Gunler" :multiple="true" />
                    </el-form-item>

                    <el-form-item class="margin-bottom-0">
                        <el-switch v-model="form.is_multiple" active-text="Coklu Tarih Girisi" />
                    </el-form-item>
                </el-form>
            </div>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store">
                    Kaydet
                </el-button>
            </template>
        </el-dialog>

        <el-dialog title="Etkinlik Detayi" :visible.sync="showDialogVisible">
            <div v-if="form.id && form.type === 'lesson'">

                <div class="ui message violet">
                    <div class="ui header">
                        <span v-if="form.product && form.product.content"> {{ form.product.content.title }} ({{ findProductType(form.product.variant_type) }})</span>
                        <div class="sub header" v-if="form.customer">Ozel Ders</div>
                        <div class="sub header">{{ form.title }}</div>
                    </div>
                </div>

                <div v-if="form.teacher">
                    <div class="ui message blue">
                        <div class="ui header">
                            {{ form.teacher.name }}
                            <div class="sub header">
                                {{ form.teacher.email }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="form.customer">
                    <div class="ui message">
                        <div class="ui header">
                            {{ form.customer.name }}
                            <div class="sub header">
                                {{ form.customer.email }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-between margin-top-20">
                    <div>{{ form.start }}</div>
                    <div>{{ form.end }}</div>
                </div>
            </div>
            <div v-if="form.id && form.type !== 'lesson'">

                <div class="ui message green">
                    <div class="ui header ">
                        Musait
                    </div>
                </div>

                <div v-if="form.user">
                    <div class="ui message blue">
                        <div class="ui header">
                            {{ form.user.name }}
                            <div class="sub header">
                                {{ form.user.email }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between margin-top-20">
                    <div>{{ form.start_at }}</div>
                    <div>{{ form.end_at }}</div>
                </div>
            </div>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark red large" @click="destroy(form.id)" v-if="form.type !== 'lesson'">
                    Sil
                </el-button>
                <el-button class="fluid flat dark green large" @click="openBlankWindow('/#/educations/lessons/lessons/' + form.id)" v-if="form.type === 'lesson'">
                    Derse Git
                </el-button>
            </template>
        </el-dialog>

        <el-dialog title="Toplu Sil" :visible.sync="destroyDialogVisible">
            <el-form :label-position="'top'" ref="destroyForm" :model="destroyForm">

                <el-form-item label="Etkinlik Turu">
                    <definitions alias="event_types" model="relation_type" :container="destroyForm"  />
                </el-form-item>

                <el-form-item label="Ogretmen">
                    <user :container="destroyForm" model="user_id" placeholder="Ogretmen" :key="destroyForm.id" />
                </el-form-item>

                <el-form-item label="Tarih Araligi">
                    <el-date-picker v-model="destroyForm.date" type="datetimerange" range-separator="ve" start-placeholder="Baslangic Tarihi" end-placeholder="Bitis Tarihi" />
                </el-form-item>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark red large" @click="multipleDestroy" :disabled="dialogLoading" :loading="dialogLoading">
                    Filtreye Utgun Olarak Verileri Sil
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all';

export default {
    name: 'Events',
    components: {
        FullCalendar
    },
    data() {
        return {
            pageLoading: false,
            showDialogVisible: false,
            createDialogVisible: false,
            dialogLoading: false,
            calendar: {
                instance: null,
                options: {
                    plugins: [
                        dayGridPlugin,
                        timeGridPlugin,
                        listPlugin,
                        interactionPlugin // needed for dateClick
                    ],
                    initialView: 'dayGridMonth',
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    },
                    editable: false,
                    selectable: false,
                    selectMirror: false,
                    dayMaxEvents: true,
                    weekends: true,
                    locales: allLocales,
                    locale: 'tr',
                    events : this.index,
                    eventClick : this.show,
                },
            },
            form: {
                //
            },

            filter: {
                //
            },
            types: [
                {
                    value: 'event',
                    label: 'Musaitlik Durumu'
                },
                {
                    value: 'lesson',
                    label: 'Ders'
                }
            ],
            days: [
                {
                    value: 1,
                    label: 'Pazartesi'
                },
                {
                    value: 2,
                    label: 'Sali'
                },
                {
                    value: 3,
                    label: 'Carsamba'
                },
                {
                    value: 4,
                    label: 'Persembe'
                },
                {
                    value: 5,
                    label: 'Cuma'
                },
                {
                    value: 6,
                    label: 'Cumartesi'
                },
                {
                    value: 7,
                    label: 'Pazar'
                }
            ],

            destroyDialogVisible : false,
            destroyForm : {
                //
            }
        }
    },

    mounted() {
        this.calendar.instance = this.$refs.AppCalendar.getApi();
    },

    methods: {

        endPoint(suffix) {

            const path = 'crm/events';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        refreshEvents() {
            this.calendar.instance.refetchEvents()
        },

        index(info, successCallback, failureCallback) {

            this.pageLoading = true;

            this.$http
                    .get(this.endPoint(), {
                        params: {
                            start_at: info.startStr,
                            end_at: info.endStr,
                            user_id: this.filter.user_id,
                            type: this.filter.type,
                        }
                    })
                    .then(response => {

                        this.pageLoading = false;

                        return successCallback(response.body.data)

                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
        },

        create() {
            this.form = {
                //
            }

            this.createDialogVisible = true;
        },

        store() {
            this.dialogLoading = true;

            this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        //this.createDialogVisible = false;

                        this.refreshEvents();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
        },

        show(info) {
            this.pageLoading = true;

            this.$http
                    .get(this.endPoint(info.event.id) + '?type=' + info.event.extendedProps.type)
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                        this.showDialogVisible = true;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
        },

        destroy(id) {

            this.dialogLoading = true;

            this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                                .delete(this.endPoint(id))
                                .then(response => {

                                    this.dialogLoading = false;
                                    this.showDialogVisible = false;
                                    this.refreshEvents();

                                    this.responseSuccess(response);

                                })
                                .catch(response => {
                                    this.responseError(response);
                                    this.dialogLoading = false;
                                });

                        return true;

                    })
                    .catch((response) => {

                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        this.dialogLoading = false;

                        return false;
                    });
        },

        multipleDestroy() {

            this.dialogLoading = true;

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    this.$http
                        .delete(this.endPoint(1), {params : {json : JSON.stringify(this.destroyForm)}})
                        .then(response => {
                            this.dialogLoading = false;
                            this.destroyForm = {
                                //
                            };
                            this.refreshEvents();
                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                            this.dialogLoading = false;
                        });

                    return true;

                })
                .catch((response) => {

                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    this.dialogLoading = false;

                    return false;
                });
        },
    }
}
</script>