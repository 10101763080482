<template>
    <div v-loading="pageLoading">
        <div class="card-custom-padding-box-items">
            <div class="page-filters">
                <ti-select :container="filter" model="table" :options="layouts" :change="create" placeholder="Eklemek istediginiz icerik turunu seciniz" />
            </div>
        </div>

        <div class="box-list">
            <template v-for="(item, index) in items">
                <div class="box-list-item" :key="index">
                    <div class="name" style="width: 100%">
                        <div>{{ item.title }}</div>
                        <div>{{ item.relation }}</div>
                    </div>
                    <div class="description">
                        <el-button type="text" class="flat dark green" icon="el-icon-edit" @click="edit(item.id)" />
                        <el-button type="text" class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)" />
                    </div>
                </div>
            </template>
        </div>

        <el-dialog title="Icerik Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="50%">
            <el-form :label-position="'top'" ref="form" :model="form" :key="form.id">
                <el-form-item :label="layout.title" v-if="layout && !form.id">
                    <education-content-remote-select :alias="layout.id" :container="form" model="contentable_id" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslik">
                            <el-input v-model="form.title" placeholder="Baslik" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Yayinlanma Basligi">
                            <el-input v-model="form.public_title" placeholder="Yayinlanma Basligi" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Aciklama" class="is-editor">
                    <editor :container="form" model="description" :small="true" />
                </el-form-item>

                <el-form-item label="Siralama" class="margin-bottom-0">
                    <el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
                </el-form-item>
            </el-form>
            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'BookExerciseContent',
    props: ['id'],
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            form: {
                //
            },

            filter: {
                layout : null
            },

            items: [],
            layout: null,
            layouts: [
                {
                    id: 'word_lists',
                    title: 'Kelime Listeleri'
                },
                {
                    id: 'tests',
                    title: 'Testler'
                },
                {
                    id: 'videos',
                    title: 'Videolar'
                },
                {
                    id: 'listenings',
                    title: 'Dinlemeler'
                },
                {
                    id: 'readings',
                    title: 'Okuma Parcalari'
                },
                {
                    id: 'documents',
                    title: 'Dokumanlar'
                }
            ],
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = this.$route.meta.apiPoint + '/' + this.id + '/contents';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        /**
         * All items
         */
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                .then(response => {

                    this.items = response.body.rows;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         *  Create modal
         */
        create(value) {
            this.form = {
                //
            };
            this.layout = this.lodash.filter(this.layouts, (e) => e.id === value)[0];
            this.dialogVisible = true;
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form save
         */
        store() {
            this.pageLoading = true;
            this.form.alias = this.layout.id;

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;
                    this.layout = null;
                    this.filter.layout = null;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;

                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },
    }
}
</script>
