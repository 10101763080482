<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form"/>
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-sub-title">Genel</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Baslik">
                        <el-input v-model="form.title" placeholder="Baslik"/>
                    </el-form-item>

                    <el-form-item label="Siralama">
                        <el-input v-model="form.sort" placeholder="Siralama" type="number"
                                  prefix-icon="mdi mdi-counter"/>
                    </el-form-item>
                </div>
                <div class="card-sub-title">Seviye Belirleme Sinavi</div>
                <div class="card-custom-padding-box-items">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Soru Sayisi">
                                <el-input v-model="form.meta.question_count" placeholder="Soru Sayisi" type="number"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Sonraki Seviyeye Gecmesi Icin Gerekli Soru Sayisi">
                                <el-input v-model="form.meta.question_correct_count"
                                          placeholder="Sonraki Seviyeye Gecmesi Icin Gerekli Soru Sayisi"
                                          type="number"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="card-sub-title">
                    <div class="title">Konu Etiketlerine Gore Soru Dagilimi</div>
                    <div class="actions">
                        <el-tooltip content="Ekle" placement="left">
                            <el-button class="flat dark blue" icon="el-icon-plus" @click="create"/>
                        </el-tooltip>
                    </div>
                </div>
                <div class="card-custom-padding-box-items">
                    <template v-for="(topic, index) in form.meta.topics ">
                        <div class="topic-row" :key="index">
                            <el-row :gutter="24">
                                <el-col :span="18">
                                    <el-form-item label="Konu Etiketi">
                                        <ti-select :options="topics" :container="topic" model="topic_id" placeholder="Konu Etiketi"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Soru Sayisi">
                                        <el-input v-model="topic.count" placeholder="Soru Sayisi" type="number"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">
                                    <el-tooltip content="Sil" placement="left">
                                        <el-button class="flat dark red large fluid" icon="el-icon-delete" @click="destroy(index)" style="margin-top: 26px"/>
                                    </el-tooltip>
                                </el-col>
                            </el-row>
                        </div>
                    </template>
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                meta: {
                    topics: []
                }
            },

            parents: [],
            topics : []
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }

        this.getTopics();
    },
    methods: {
        getTopics() {
            this.pageLoading = true;

            this.$http
                .get('archives/topics', {
                    params: {
                        perPage: 10000,
                    }
                })
                .then(response => {
                    this.pageLoading = false;
                    this.topics = response.body.rows;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.pageLoading = false;
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            topics: []
                        };
                    }
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        create() {
            this.form.meta.topics.push({
                topic_id: null,
                count: null
            })
        },

        destroy(index) {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.form.meta.topics.splice(index, 1);

                    return true;

                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        }
    }
}
</script>
