<template>
	<div v-scroll:throttle="{ throttle: 500 }" class="page">
		<Map :key="$route.name" />
		<router-view :key="$route.fullPath" />
	</div>
</template>

<script>
    import Map from "../Components/Map";
    export default {
        name: 'Container',
        components: {Map},
        data() {
            return {
                pageLoading: true,
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>