import LayoutConfig from '../config/layout'
import Container from '../app/Container/Main'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Datatable'
import Role from "../app/Pages/Crm/Role";
import Address from "../app/Pages/Crm/Addresses";
import Permission from "../app/Pages/Crm/Permission";
import UserCreate from "../app/Pages/Crm/Users/Create";
import UserEdit from "../app/Pages/Crm/Users/Edit";
import Event from "../app/Pages/Crm/Event";
import Student from "../app/Pages/Crm/Student";

export default {
	path: '/crm',
	name: 'api.crm.index',
	component: Container,
	meta: {
		auth: true,
		layout: LayoutConfig.full,
		title: 'Crm',
	},
	children: [
		{
			path: 'permissions',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Yetkiler',
				breadcrumb: 'api.crm.permissions.index',
			},
			children: [
				{
					name: 'api.crm.permissions.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Yetkiler',
						apiPoint: 'crm/permissions',
					},
				},
				{
					name: 'api.crm.permissions.create',
					path: 'create',
					component: Permission,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Yetki Olustur',
						apiPoint: 'crm/permissions',
					},
				},
				{
					name: 'api.crm.permissions.edit',
					path: ':id',
					component: Permission,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Yetki Bilgileri',
						apiPoint: 'crm/permissions',
					},
				},
			]
		},
		{
			path: 'roles',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Roller',
				breadcrumb: 'api.crm.roles.index',
			},
			children: [
				{
					name: 'api.crm.roles.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Roller',
						apiPoint: 'crm/roles',
					},
				},
				{
					name: 'api.crm.roles.create',
					path: 'create',
					component: Role,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Rol Olustur',
						apiPoint: 'crm/roles',
					},
				},
				{
					name: 'api.crm.roles.edit',
					path: ':id',
					component: Role,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Rol Bilgileri',
						apiPoint: 'crm/roles',
					},
				},
			]
		},
		{
			path: 'users',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Kullanicilar',
				breadcrumb: 'api.crm.users.index',
			},
			children: [
				{
					name: 'api.crm.users.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kullanicilar',
						apiPoint: 'crm/users',
					},
				},
				{
					name: 'api.crm.users.create',
					path: 'create',
					component: UserCreate,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kullanici Olustur',
						apiPoint: 'crm/users',
					},
				},
				{
					name: 'api.crm.users.edit',
					path: ':id',
					component: UserEdit,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kullanici Bilgileri',
						apiPoint: 'crm/users',
					},
				},
			]
		},
        {
            path: 'students',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Ogrenciler',
                breadcrumb: 'api.crm.students.index',
            },
            children: [
                {
                    name: 'api.crm.students.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ogrenciler',
                        apiPoint: 'crm/students',
                    },
                },
                {
                    name: 'api.crm.students.create',
                    path: 'create',
                    component: Student,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ogrenci Olustur',
                        apiPoint: 'crm/students',
                    },
                },
                {
                    name: 'api.crm.students.edit',
                    path: ':id',
                    component: Student,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ogrenci Bilgileri',
                        apiPoint: 'crm/students',
                    },
                },
            ]
        },
		{
			path: 'addresses',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Adresler',
				breadcrumb: 'api.crm.addresses.index',
			},
			children: [
				{
					name: 'api.crm.addresses.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Roller',
						apiPoint: 'crm/addresses',
						createDisable: true,
					},
				},
				{
					name: 'api.crm.addresses.edit',
					path: ':id',
					component: Address,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Adres Bilgileri',
						apiPoint: 'crm/addresses',
						createDisable: true,
					},
				},
			]
		},
		{
			path: 'events',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Takvim',
				breadcrumb: 'api.crm.events.index',
			},
			children: [
				{
					name: 'api.crm.events.index',
					path: '',
					component: Event,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Takvim',
						apiPoint: 'crm/events',
					},
				},
			]
		},
	]
}
