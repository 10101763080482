<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">
                        {{ cardCrudTitle() }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Filtrele" placement="bottom">
                            <el-button class="flat dark secondary" icon="el-icon-search" @click="index" />
                        </el-tooltip>
                    </div>
                </div>
            </template>
            <div class="el-card__filters">
                <el-form :label-position="'top'" ref="filter" :model="filter">
                    <el-form-item class="margin-bottom-0">
                        <el-date-picker ref="SchedulePicker" @change="index" v-model="filter.date" format="dd MMMM yyyy" type="week" :clearable="false" :picker-options="datePickerOptions" placeholder="Rapor alinacak hafta" />
                    </el-form-item>
                </el-form>
            </div>

            <template v-if="response">
                <el-table :data="response" style="width: 100%">
                    <el-table-column prop="name" label="Ogretmen" sortable />
                    <el-table-column prop="current" label="Harcanan Zaman" width="180" sortable :formatter="formatterCurrent" />
                    <el-table-column prop="available" label="Kalan Zaman" width="180" sortable :formatter="formatterAvailable" />
                </el-table>
            </template>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'Performance',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            filter: {
                //
            },
            response : null,
        }
    },
    methods: {
        index() {

            if (!this.filter.date) {
                return false;
            }

            let date = this.moment(this.filter.date).format('YYYY-MM-DD')

            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {params: {date : date}})
                .then(response => {
                    this.response = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        formatter(value) {
            return this.formatSecondTime(value)
        },

        formatterCurrent(row, column) {
            return this.formatter(row.current);
        },

        formatterAvailable(row, column) {
            return this.formatter(row.available);
        },
    }
}
</script>