<template>
    <div class="avatar avatar--text">
        {{ text(name) }}
    </div>
</template>

<script>
export default {
    name: 'Pic',
    props: ['name', 'url', 'color', 'size'],
    methods: {
        text : (text) => {
            if (!text) {
                return
            }

            return text[0].toUpperCase();
        },
    }
};
</script>
