<template>
	<div v-loading="pageLoading">

		<el-card class="custom-padding">
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" :disable="true" />
			</template>

			<file-manager :settings="fileManagerSettings" />
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
		},
        data() {
            return {
                pageLoading: false,
				form : {},
            }
        }
    }
</script>