<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">
                        {{ response.title }} ({{ response.start_at }} - {{ response.end_at}})
                    </div>
                </div>
            </template>
        </el-card>

        <div v-if="report">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-card class="stat-card">
                        <div class="text">
                            <div class="number">{{ report.count }}</div>
                            <div class="title">Satilan Urun Sayisi</div>
                        </div>
                        <div class="media bg-teal-opack text-teal">
                            <icon icon="gift-outline" />
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card class="stat-card">
                        <div class="text">
                            <div class="number">{{ $n(report.amount, 'currency') }}</div>
                            <div class="title">Satilan Urun Fiyati</div>
                        </div>
                        <div class="media bg-navy-blue-opack text-navy-blue">
                            <icon icon="shopping-outline" />
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <el-card class="custom-padding">
                <div class="el-card__tabs custom-padding" ref="TableContainer">
                    <el-tabs class="el-card--tabs fluid" :tab-position="'top'">
                        <el-tab-pane label="Urun Bazli">
                            <el-table :data="report.titles" :style="{width : '100%', 'max-width' : pageWidth + 'px'}" stripe border size="mini">
                                <el-table-column prop="title" label="Urun" width="460" sortable fixed />
                                <template v-for="(monthTitle, monthTitleIndex) in monthTitles">
                                    <el-table-column :label="monthTitle.title" width="160" sortable align="right" :key="monthTitleIndex">
                                        <template slot-scope="scope">
                                            {{ scope.row.months[monthTitle.id].count }} /
                                            {{ $n(scope.row.months[monthTitle.id].total,
                                            'currency') }}
                                        </template>
                                    </el-table-column>
                                </template>
                                <el-table-column label="Toplam" width="160" sortable align="right">
                                    <template slot-scope="scope">
                                        {{ scope.row.count }} / {{ $n(scope.row.total, 'currency') }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>

                        <el-tab-pane label="Grup Bazli" v-if="elementSize(report.teachers)">
                            <table class="el-table">
                                <thead>
                                <th>Urun</th>
                                <template v-for="(monthTitle, monthTitleIndex) in monthTitles">
                                    <th :key="monthTitleIndex">{{ monthTitle.title }}</th>
                                </template>
                                <th>Toplam</th>
                                </thead>
                                <tbody>
                                <template v-for="(tab, index) in report.teachers">
                                    <template v-for="(item, itemIndex) in tab.products">
                                        <tr :key="itemIndex">
                                            <td>
                                                <span v-if="response.product_type === 'service'">{{ tab.title }}</span>
                                                {{ item.title }}
                                            </td>
                                            <template v-for="(monthTitle, monthTitleIndex) in monthTitles">
                                                <td :key="monthTitleIndex">
                                                    {{ item.months[monthTitleIndex + 1].count }} / {{ $n(item.months[monthTitleIndex + 1].total, 'currency') }}
                                                </td>
                                            </template>
                                            <td>
                                                {{ item.count }} /{{ $n(item.total, 'currency') }}
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                </tbody>
                            </table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CrudItem',
    data() {
        return {
            pageWidth: 0,
            pageLoading: false,
            response: {
                //
            },
            report: null,
        }
    },
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.response = response.body.data;
                    this.pageLoading = false;

                    this.getReport();
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getReport() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink(), {
                    params: {
                        process: 'report',
                        id: this.response.id
                    }
                })
                .then(response => {
                    this.report = response.data.data
                    this.pageLoading = false;

                    setTimeout(() => {
                        this.pageWidth = this.$refs.TableContainer.offsetWidth
                    }, 2500)
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>