<template>
    <el-dialog :visible.sync="visible" class="dialog-with-page custom-padding" append-to-body
               :before-close="dialogClose" v-loading="pageLoading">
        <div slot="title" class="el-dialog__crud-title">
            <div class="el-dialog__title">
                {{ form.name }}
            </div>
            <div class="actions">
                <el-tooltip content="Guncelle" placement="bottom">
                    <el-button class="flat dark green " icon="el-icon-check" @click="update" v-loading="pageLoading"
                               style="font-size: 22px; min-width: 50px" />
                </el-tooltip>
            </div>
        </div>
        <div class="el-dialog__body-wrapper theme-show">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-card class="custom-padding no-shadow" shadow="never" style="border: none">
                    <div class="el-card__tabs custom-padding">
                        <el-tabs class="el-card--tabs" :tab-position="'top'">
                            <el-tab-pane label="Genel">
                                <BasicShowTheme :form="form" />
                            </el-tab-pane>
                            <el-tab-pane label="Medya">
                                <AssetShowTheme :form="form" />
                            </el-tab-pane>
                            <el-tab-pane label="Metinler">
                                <TextShowTheme :form="form" />
                            </el-tab-pane>
                            <el-tab-pane label="Bloklar">
                                <BlockShowTheme :form="form" />
                            </el-tab-pane>
                            <el-tab-pane label="Sosyal Medya">
                                <SocialShowTheme :form="form" />
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-card>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import BasicShowTheme from "@/app/Pages/Themes/_Tabs/Basic";
import AssetShowTheme from "@/app/Pages/Themes/_Tabs/Asset";
import TextShowTheme from "@/app/Pages/Themes/_Tabs/Text";
import BlockShowTheme from "@/app/Pages/Themes/_Tabs/Block";
import SocialShowTheme from "@/app/Pages/Themes/_Tabs/Social";

export default {
    name: 'ThemeShow',
    components: {
        BlockShowTheme, TextShowTheme, AssetShowTheme, BasicShowTheme, SocialShowTheme
    },
    data() {
        return {
            visible: true,
            pageLoading: false,
            form: {
                meta: {
                    //
                },

                file_data: {
                    //
                }
            }
        }
    },
    mounted() {
        this.dialogShow();
        this.show();
    },
    methods: {
        dialogShow() {
            this.$store.commit('visibleFullPageDialog');
        },

        dialogClose() {
            this.$store.commit('hideFullPageDialog');
            this.$router.push('/themes')
        },

        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {}
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.$http
                .put(this.createShowPageLink(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
