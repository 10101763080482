<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list">
			<template slot="header">
				<div class="title">
					Ogrenciler
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text">
                        <div>
                            <div class="title">{{ item.user.name }}</div>
                            <div class="description">{{ item.message }}</div>
                        </div>
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
    export default {
        name: 'HomeworkUser',
        props: ['id'],
        data() {
            return {
                pageLoading: false,
                items: [],
            }
        },

		mounted() {
			this.index();
		},

        methods: {

            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = this.$route.meta.apiPoint + '/' + this.id + '/users';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            }
        }
    }
</script>