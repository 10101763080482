<template>
	<div class="page plugin-page">
		<div class="page-header">
			<div class="content">
				<div class="header">
					{{ $route.meta.title }}
				</div>
			</div>
			<div class="actions">
				<el-button class="modern teal large extract" @click="goRoute('/plugins/create')">
					<icon icon="cloud-upload-outline" style="margin-right: 5px" /> Yukle
				</el-button>
			</div>
		</div>
		<div class="page-content" :key="$route.name">
			<div class="plugin-filters">
				<el-card>
					<el-row :gutter="24">
						<el-col :span="12">
							<el-select v-model="form.alias" @change="index" placeholder="Kategoriler" clearable filterable>
								<el-option v-for="category in categories" :key="category.key" :label="category.title" :value="category.key">
									<span style="float: left">{{ category.title }}</span>
									<span style="float: right; color: #8492a6; font-size: 13px">{{ category.count }}</span>
								</el-option>
							</el-select>
						</el-col>
						<el-col :span="12">
							<el-input placeholder="arama yap..." suffix-icon="el-icon-search" v-model="form.name" @change="index()" />
						</el-col>
					</el-row>
				</el-card>
			</div>
			<div class="plugin-list" v-loading="pageLoading">
				<div class="plugin-list-wrapper" v-for="category in categories" :key="category.id" v-if="elementSize(category.items)">
					<div class="head">
						<div class="media">
							<icon :icon="'plugin-category-icon-' + category.key" />
						</div>
						<div class="text">
							<div class="title">
								{{ category.title }}
							</div>
							<div class="description">
								{{ category.description }}
							</div>
						</div>
					</div>
					<div class="plugin margin-bottom-40">
						<el-card class="custom-padding">
							<div class="items">
								<div class="item" v-for="plugin in category.items" :key="plugin.id">
									<div class="content cursor-pointer" @click="$router.push('/plugins/' + plugin.id)">
										<div class="media">
											<img :src="'https://' + $store.state.ui.tenant.domain + plugin.preview" alt="preview">
										</div>
										<div class="text">
											<div class="title">{{ plugin.title }}</div>
											<div class="sub-title">{{ plugin.name }}</div>
											<div class="description">{{ plugin.description }}</div>
										</div>
									</div>
									<div class="actions">
										<el-button @click="destroy(plugin.id)" class="red" icon="el-icon-delete" />
									</div>
								</div>
							</div>
						</el-card>
					</div>
				</div>
			</div>
		</div>

		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'PluginIndex',
		data() {
			return {
				pageLoading: false,
				form: {
					//
				},
				categories: [],
			}
		},
		mounted() {
			this.index();
		},
		methods: {
			index() {
				this.pageLoading = true;

				this.$http
					.get(this.$route.meta.apiPoint, {params : this.form})
					.then(response => {
						this.categories = response.body.data;
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			/**
			 * Destroy item
			 */
			destroy(id) {

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {

						this.$http
							.delete(this.$route.meta.apiPoint + '/' + id)
							.then(response => {
								this.responseSuccess(response);
								this.index();
							})
							.catch(response => {
								this.responseError(response);
							});


						return true;

					})
					.catch((response) => {
						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},
		}
	}
</script>
