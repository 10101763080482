<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list">
            <template slot="header">
                <div class="title">
                    <icon icon="gift" />
                    Kodlar
                </div>
                <div class="actions">
                    <el-tooltip content="Yenile" placement="bottom">
                        <el-button class="flat dark purple" icon="el-icon-refresh" @click="index"/>
                    </el-tooltip>

                    <el-tooltip content="Indir" placement="bottom">
                        <el-button class="flat dark orange" icon="el-icon-download" @click="goPdf"/>
                    </el-tooltip>

                    <el-tooltip content="Ekle" placement="bottom">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
                    </el-tooltip>
                </div>
            </template>
            <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">

                <empty v-if="!elementSize(items)" />

                <div class="item" v-for="item in items" :key="item.id">
                    <div class="text display-flex align-items-center">
                        <div class="cursor-pointer" @click="edit(item.id)">
                            <div class="title">{{ item.code }}</div>
                            <div class="metas">
                                <div class="meta">#{{ item.user_id ? item.user_id : 'kullanilmadi' }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="actions" style="right: 20px">
                        <el-button type="text" class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)" />
                    </div>
                </div>
            </div>
        </el-card>

        <el-dialog title="Kod Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="70%">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Kod Sayisi" class="margin-bottom-0" v-if="!form.id">
                    <el-input v-model="form.count" placeholder="Kod Sayisi" type="number" prefix-icon="mdi mdi-counter" />
                </el-form-item>
                <div v-if="form.id">
                    <el-form-item label="Erisim Kodu">
                        <el-input v-model="form.code" placeholder="Erisim Kodu"/>
                    </el-form-item>

                    <el-form-item label="Musteri" class="margin-bottom-0">
                        <user :container="form" model="user_id" placeholder="Musteri" />
                    </el-form-item>
                </div>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Images',
    props: ['id'],
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            form: {
                //
            },
            items: [],
        }
    },
    mounted() {
        this.index();
    },
    methods: {

        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = this.$route.meta.apiPoint + '/' + this.id + '/codes';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        /**
         * All items
         */
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {perPage: 1000, sorts : {sort : 'asc'}}})
                .then(response => {

                    this.items = response.body.rows;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         *  Create modal
         */
        create() {
            this.form = {
                //
            };
            this.dialogVisible = true;
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form save
         */
        store() {
            this.pageLoading = true;

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;

                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        /**
         * Item dropdown actions
         *
         * @param command
         */
        handleRowActionCommand(command) {
            if (command.type === 'destroy') {
                return this.destroy(command.id);
            }

            this.edit(command.id);
        },

        goPdf() {
            this.openBlankWindow('https://' + this.$store.state.ui.tenant.domain + '/exports/marketing/codes/' + this.id);
        }
    },

}
</script>
