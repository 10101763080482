<template>
    <div v-loading="pageLoading" style="min-height: 400px">
        <el-row :gutter="24" v-if="basket.id">
            <el-col :span="16">
                <el-card>
                    <div class="el-card__tabs custom-padding">
                        <div class="el-card--tabs fluid el-tabs el-tabs--top">
                            <div class="el-tabs__header is-top">
                                <div class="el-tabs__nav-wrap is-top">
                                    <div class="el-tabs__nav-scroll">
                                        <div role="tablist" class="el-tabs__nav is-top">
                                            <template v-for="paymentPlugin in paymentPlugins" >
                                                <div class="el-tabs__item is-top"  v-if="paymentPlugin.is_active" :class="{'is-active' : paymentPlugin.id === activePaymentTab}" @click="handleChangeTab(paymentPlugin)" :key="paymentPlugin.id">
                                                    {{ paymentPlugin.title }}
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="el-tabs__content">
                                <template v-for="paymentPlugin in paymentPlugins" >
                                    <div class="el-tab-pane"  v-if="paymentPlugin.is_active && paymentPlugin.id === activePaymentTab" :key="'-'+paymentPlugin.id">
                                        <div class="margin-top-20">
                                            <CreditCard v-if="paymentPlugin.name !== 'Eft'" :basket="basket" :plugin="paymentPlugin" :key="paymentPlugin.id" :handle="setFormData" :orderData="form" :handleBasket="getBasket" />
                                            <Eft v-if="paymentPlugin.name === 'Eft'" :plugin="paymentPlugin" :key="paymentPlugin.id" :handle="setFormData" />
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </el-card>
                <el-card>
                    <el-switch v-model="form.is_accounting" active-text="Bu bir test siparisidir. Muhasebe programina gönderilmeyecek!" />
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card class="custom-padding card-flat-list">
                    <template slot="header">
                        <div class="title">Sipariş Özeti</div>
                    </template>
                    <div class="card-flat-list-items sm">
                        <div class="item">
                            <div class="text d-flex fluid">
                                <div class="title fluid">{{ basket.user.name }}</div>
                                <div class="title fluid text-right">{{ basket.user.email }}</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="text d-flex fluid">
                                <div class="title fluid">Ürünler Toplami</div>
                                <div class="title fluid text-right">{{ $n(basket.price, 'currency') }}</div>
                            </div>
                        </div>
                        <div class="item" v-if="basket.is_shipping">
                            <div class="text d-flex fluid">
                                <div class="title fluid">Kargo Ucreti</div>
                                <div class="title fluid text-right" v-if="!basket.is_free_shipping">
                                    + {{ $n(basket.shipping_price, 'currency') }}
                                </div>
                                <div class="title fluid text-right" v-if="basket.is_free_shipping">
                                    <del style="font-size: 12px">{{ $n(basket.shipping_price, 'currency') }}</del>
                                    {{ $n(0, 'currency') }}
                                </div>
                            </div>
                        </div>
                        <div class="item" v-if="basket.installment_price">
                            <div class="text d-flex fluid">
                                <div class="title fluid">Taksit Farki</div>
                                <div class="title fluid text-right">
                                    + {{ $n(basket.installment_price, 'currency')}} / {{ basket.installment_no }} Taksit
                                </div>
                            </div>
                        </div>
                        <div class="item" v-if="basket.product_discount_price">
                            <div class="text d-flex fluid">
                                <div class="title fluid">Indirim</div>
                                <div class="title fluid text-right">- {{
                                        $n(basket.product_discount_price, 'currency')
                                    }}
                                </div>
                            </div>
                        </div>
                        <template v-if="basket.campaign_price">
                            <div class="item" v-for="campaign in basket.campaigns" :key="campaign.id">
                                <div class="text d-flex fluid">
                                    <div class="title fluid">{{ campaign.title }}</div>
                                    <div class="title fluid text-right">- {{ $n(campaign.total, 'currency') }}</div>
                                </div>
                            </div>
                        </template>
                        <div class="item" v-if="basket.coupon">
                            <div class="text d-flex fluid">
                                <div class="title fluid">Indirim Kodu</div>
                                <div class="title fluid text-right">- {{ $n(basket.coupon_price, 'currency') }}</div>
                            </div>
                        </div>
                    </div>
                    <footer class="el-card-footer">
                        <div class="card-flat-list-items sm">
                            <div class="item">
                                <div class="text d-flex fluid">
                                    <div class="title fluid">Genel Toplam</div>
                                    <div class="title fluid text-right text-green">
                                        {{ $n(basket.total, 'currency') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </el-card>
                <el-button class="modern large secondary fluid" @click="store" v-loading="buttonLoading" :disabled="buttonLoading">
                    Siparisi Tamamla
                </el-button>
            </el-col>
        </el-row>

        <div id="CreditCardServiceResponse" style="display: none">
            <div v-html="Form3d"></div>
        </div>
    </div>
</template>

<script>

import CreditCard from "./Create/CreditCard";
import Eft from "./Create/Eft";

export default {
    name: 'OrderCreate',
    components: {
        Eft,
        CreditCard
    },
    data() {
        return {
            pageLoading: false,
            buttonLoading : false,
            basket: {
                //
            },
            activePaymentTab: null,
            paymentPlugins: [],
            form: {
                payment_plugin_id : null,
                payment_type : null,
                payment_bank: null,
                credit_card: null,
            },

            Form3d: '',
        }
    },
    mounted() {
        this.getBasket();
        this.getPaymentPlugins();
    },
    methods: {
        getBasket() {
            this.pageLoading = true;

            this.$http
                    .get('shop/baskets/' + this.$route.params.basketId, {params : this.form})
                    .then(response => {
                        this.basket = response.body.data;
                        this.pageLoading = false;
                        this.form.basket_id = this.basket.id
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })

        },

        getPaymentPlugins() {
            this.pageLoading = true;

            this.$http
                    .get('plugins', {params: {sorts: {sort: 'asc'}}})
                    .then(response => {
                        this.paymentPlugins = response.body.data.payments.items;
                        this.pageLoading = false;

                        this.handleChangeTab(this.paymentPlugins[0]);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
        },

        handleChangeTab(plugin) {
            this.activePaymentTab = plugin.id;
            this.form.basket_id = this.basket.id;
            this.form.payment_plugin_id = plugin.id;
            this.form.payment_type = plugin.name;
            this.form.payment_bank = null;
            this.form.credit_card = null;
        },

        setFormData(data) {
            if (data.payment_bank) {
                this.form.payment_bank = data.payment_bank
            } else {
                this.form.credit_card = data.credit_card;
            }

            if (data.installment) {
                this.form.installment = data.installment;
            }
        },

        store() {
            this.form.basket_id = this.basket.id;
            this.form.invoice_address_id = this.basket.invoice_address_id;
            this.form.delivery_address_id = this.basket.delivery_address_id;

            this.buttonLoading = true;

            this.$http
                    .post(this.$route.meta.apiPoint, this.form)
                    .then(response => {
                        this.buttonLoading = false;

                        if (response.data.form) {
                            this.Form3d = response.data.form;

                            setTimeout(() => {
                                $('#CreditCardServiceResponse form').submit();
                                this.responseSuccess(response);
                            }, 2000)
                        } else {
                            this.responseSuccess(response);
                            this.goShowPage(response.body.data.id);
                        }
                    })
                    .catch(response => {
                        this.buttonLoading = false;
                        return this.responseError(response);
                    })
        },
    }
}
</script>