<template>
	<div class="card-custom-padding-box margin-0">
		<div class="card-sub-title">Dil Ayarlari</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Admin Paneli Varsayilan Dil">
				<ti-select :options="$store.state.ui.languages" :container="form" model="panel_language_id" placeholder="Admin Paneli Varsayilan Dil" />
			</el-form-item>
			<el-form-item label="Web Sitesi Varsayilan Dil">
				<ti-select :options="$store.state.ui.languages" :container="form" model="language_id" placeholder="Web Sitesi Varsayilan Dil" />
			</el-form-item>
			<el-form-item label="Platform Varsayilan Dil">
				<ti-select :options="$store.state.ui.languages" :container="form" model="platform_language_id" placeholder="Platform Varsayilan Dil" />
			</el-form-item>
		</div>
		<div class="card-sub-title">Konum</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Ulke">
				<ti-select :options="countries" :container="form" model="site_country_id" placeholder="Ulke" />
			</el-form-item>
		</div>
		<div class="card-sub-title">Hesaplama</div>
		<div class="card-custom-padding-box-items">
			<el-form-item>
				<el-switch v-model="form.currency_auto_update" active-value="1" inactive-value="0" active-text="Para Birimini Otomatik Güncelle" />
			</el-form-item>
			<el-form-item label="Varsayilan Para birimi">
				<ti-select :options="$store.state.ui.currencies" :container="form" model="currency_id" placeholder="Varsayilan Para birimi" />
			</el-form-item>
			<el-form-item label="Varsayilan Uzunluk Birimi">
				<ti-select :options="$store.state.ui.definitions['length-classes']" :container="form" model="lenght_class_id" placeholder="Varsayilan Uzunluk Birimi" />
			</el-form-item>
			<el-form-item label="Varsayilan Agirlik Birimi">
				<ti-select :options="$store.state.ui.definitions['weight-classes']" :container="form" model="weight_class_id" placeholder="Varsayilan Agirlik Birimi" />
			</el-form-item>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LocalSetting',
		props: ['form', 'countries'],
		data() {
			return {
				//
			}
		},
		mounted() {
			//
		},
		methods: {
			//
		}
	}
</script>