<template>
    <div v-loading="pageLoading">
        <div class="card-sub-title">Genel</div>
        <div class="padding-20">
            <el-row :gutter="24">
                <el-col :span="4">
                    <el-form-item label="Favicon">
                        <fs v-model="form.favicon" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Logo Koyu">
                        <fs v-model="form.meta.logo_dark" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Logo Acik">
                        <fs v-model="form.meta.logo_light" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Varsayilan Avatar">
                        <fs v-model="form.meta.default_avatar" />
                    </el-form-item>
                </el-col>
            </el-row>
        </div>

        <div class="card-sub-title">Web</div>
        <div class="padding-20">
            <el-row :gutter="24">
                <el-col :span="4">
                    <el-form-item label="Logo">
                        <fs v-model="form.logo" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Footer Logo">
                        <fs v-model="form.footer_logo" />
                    </el-form-item>
                </el-col>

                <el-col :span="4">
                    <el-form-item label="Giris Logosu">
                        <fs v-model="form.auth_logo" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Landing Page Logo">
                        <fs v-model="form.meta.lp_logo" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Platform Sidebar Logo">
                        <fs v-model="form.meta.platform_sidebar_logo" />
                    </el-form-item>
                </el-col>
            </el-row>
        </div>

        <div class="card-sub-title">Platform Login Slider</div>
        <div class="padding-20">
            <el-row :gutter="24">
                <el-col :span="4">
                    <el-form-item>
                        <fs v-model="form.meta.auth_slider_1" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <fs v-model="form.meta.auth_slider_2" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <fs v-model="form.meta.auth_slider_3" />
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AssetShowTheme',
    props: ['form'],
    data() {
        return {
            pageLoading: false,

        }
    }
}
</script>
