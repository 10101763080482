<template>
	<el-card>
		<div class="images">
			<el-row :gutter="24">
				<el-col :span="4" v-for="item in items" :key="item.id">
					<el-tooltip :content="item.file.title ? item.file.title : 'Untitle'" placement="bottom">
						<div class="plus" style="height: auto" @click="openBlankWindow(appConfig.s3Path + '/' + item.file.path)">
							<icon icon="text-box-multiple-outline"/>
						</div>
					</el-tooltip>
				</el-col>
				<el-col :span="4">
					<el-tooltip content="Dosya Ekle" placement="bottom">
						<div class="plus" @click="create" style="height: auto">
							<icon icon="plus-thick"/>
						</div>
					</el-tooltip>
				</el-col>
			</el-row>
		</div>

		<el-dialog title="Dosya Bilgileri" :visible.sync="dialogVisible" v-loading="dialogLoading" append-to-body>
			<el-form :label-position="'top'" :model="form">

				<el-form-item label="Dosya">
					<fs v-model="form.file.path" :isFile="true"/>
				</el-form-item>

				<el-form-item label="Başlık">
					<el-input v-model="form.file.title" placeholder="Başlık"/>
				</el-form-item>

				<el-form-item label="Kisa Aciklama">
					<el-input v-model="form.file.description" :rows="4" type="textarea" placeholder="Kisa Aciklama" />
				</el-form-item>
			</el-form>
			<template slot="footer">
				<el-button class="modern green large fluid" icon="el-icon-check" @click="store">
					Kaydet
				</el-button>
			</template>
		</el-dialog>
	</el-card>
</template>

<script>
	export default {
		name: 'CardFile',
		props : ['cardId'],
		data() {
			return {
				pageLoading : false,
				dialogLoading : false,
				dialogVisible : false,
				form : {
					file : {
						//
					}
				},

				items : []
			}
		},
		mounted() {
			this.index();
		},
		methods: {
			endPoint(key) {
				if (!key) {
					return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.$route.params.stageId + '/cards/' + this.cardId + '/files'
				}

				return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.$route.params.stageId + '/cards/' + this.cardId + '/files/' + key
			},

			index() {
				this.pageLoading = true;

				this.$http
					.get(this.endPoint())
					.then(response => {
						this.items = response.body.rows;
						this.pageLoading = false;
					})
					.catch(response => {
						this.items = [];
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			create() {
				this.dialogVisible = true;
				this.form = {
					file : {
						//
					}
				}
			},

			store() {
				this.dialogLoading = true;

				this.$http
					.post(this.endPoint(), this.form)
					.then(response => {
						this.dialogLoading = false;
						this.dialogVisible = false;
						this.index();
					})
					.catch(response => {
						this.dialogLoading = false;
						return this.responseError(response);
					})
			},

			destroy(id) {
				this.pageLoading = true;

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.delete(this.endPoint(id))
							.then(response => {
								this.pageLoading = false;
								this.responseSuccess(response);

								this.index();
							})
							.catch(response => {
								this.responseError(response);
								this.pageLoading = false;
							});

						return true;
					})
					.catch((response) => {

						this.pageLoading = false;

						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},
		}
	}
</script>