<template>
	<div v-loading="pageLoading">
		<el-row :gutter="24">
			<el-col :span="6">
				<el-card class="stat-card">
					<div class="text">
						<div class="number">{{ form.teacher_count }}</div>
						<div class="title">Ogretmen Sayisi</div>
					</div>
					<div class="media bg-yellow-opack text-yellow">
						<icon icon="school-outline" />
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="stat-card">
					<div class="text">
						<div class="number">{{ form.product_count }}</div>
						<div class="title">Grup Sayisi</div>
					</div>
					<div class="media bg-purple-opack text-purple">
						<icon icon="cart-arrow-down" />
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="stat-card">
					<div class="text">
						<div class="number">{{ $n(form.product_amount, 'currency') }}</div>
						<div class="title">Grup Degeri</div>
					</div>
					<div class="media bg-blue-opack text-blue">
						<icon icon="package-variant-closed" />
					</div>
				</el-card>
			</el-col>
			<el-col :span="6">
				<el-card class="stat-card">
					<div class="text">
						<div class="number">{{ $n(form.order_amount, 'currency') }}</div>
						<div class="title">Toplam Satis Fiyati</div>
					</div>
					<div class="media bg-green-opack text-green">
						<icon icon="credit-card-outline" />
					</div>
				</el-card>
			</el-col>
		</el-row>

		<el-card class="card-flat-list overflow-800">
			<template slot="header">
				<div class="title">
					Ogretmenler
				</div>
				<div class="actions">
					<lv-select :container="form" model="list_view" :options="options" :change="handleChangeIndex" />
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in form.items" :key="item.id">
					<div class="text display-flex align-items-center">
						<div class="avatar margin-right-20">
							<pic classes="avatar" type="avatar" :src="item.avatar" />
						</div>
						<div>
							<div class="title">{{ item.name }}</div>
							<div class="vertical-flex-items has-dimmer">
								<div>
									<a @click="goRoute('/crm/users/' + item.id)">
										#{{ item.id }}
									</a>
								</div>
								<div>Grup Sayisi: {{ item.product_count }}</div>
								<div>Ogrenci Sayisi: {{ item.student_count }}</div>
								<div>Kazanc: {{ $n(item.order_amount , 'currency') }} / {{ $n(item.product_amount ,
									'currency') }}
								</div>
							</div>
						</div>
					</div>
					<div class="actions">
						<el-button class="modern" @click="show(item.id)">
							Detay
						</el-button>
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog title="Rapor Detayi" :visible.sync="dialogVisible" width="94%" append-to-body>
			<el-row :gutter="24">
				<el-col :span="6">
					<el-card class="stat-card">
						<div class="text">
							<div class="number">{{ detail.student_count }}</div>
							<div class="title">Ogrenci</div>
						</div>
						<div class="media bg-yellow-opack text-yellow">
							<icon icon="school-outline" />
						</div>
					</el-card>
				</el-col>
				<el-col :span="6">
					<el-card class="stat-card">
						<div class="text">
							<div class="number">{{ detail.order_return_count }}</div>
							<div class="title">Iade</div>
						</div>
						<div class="media bg-purple-opack text-purple">
							<icon icon="cart-arrow-down" />
						</div>
					</el-card>
				</el-col>
				<el-col :span="6">
					<el-card class="stat-card">
						<div class="text">
							<div class="number">{{ detail.order_count }}</div>
							<div class="title">Siparis</div>
						</div>
						<div class="media bg-green-opack text-green">
							<icon icon="cart-arrow-up" />
						</div>
					</el-card>
				</el-col>
				<el-col :span="6">
					<el-card class="stat-card">
						<div class="text">
							<div class="number">{{ $n(detail.amount_total, 'currency') }}</div>
							<div class="title">Kazanc</div>
						</div>
						<div class="media bg-blue-opack text-blue">
							<icon icon="shopping-search" />
						</div>
					</el-card>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'Teacher',
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    list_view: 'order_amount',
                    items: []
                },

                options: [
                    {
                        value: 'order_amount',
                        label: 'Satis Fiyatina Gore'
                    },
                    {
                        value: 'product_amount',
                        label: 'Grup Degerine Gore'
                    },
                    {
                        value: 'product_count',
                        label: 'Grup Sayisina Gore'
                    },
                    {
                        value: 'student_count',
                        label: 'Ogrenci Sayisina Gore'
                    }
                ],

                detail: {
                    //
				}
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.apiPoint, {params: {list_view: this.form.list_view}})
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.apiPoint + '/' + id)
                    .then(response => {
                        this.detail = response.body.data;

                        this.pageLoading = false;
                        this.dialogVisible = true;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            handleChangeIndex() {
                this.index();
            }
        }
    }
</script>