<template>
    <div v-loading="pageLoading">
        <el-form :label-position="'top'" ref="form" :model="form">
            <el-card class="custom-padding">
                <template slot="header">
                    <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
                </template>

                <div class="card-sub-title">Genel</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Baslik">
                        <el-input v-model="form.title" placeholder="Baslik" />
                    </el-form-item>

                    <el-form-item label="Aciklama">
                        <el-input v-model="form.description" type="textarea" placeholder="Aciklama" />
                    </el-form-item>

                    <el-form-item label="Kod">
                        <el-input placeholder="Kod" v-model="form.code" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" type="success" @click="generateCode" />
                        </el-input>
                    </el-form-item>
                </div>

                <div class="card-sub-title">Fiyat</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="İndirim Orani">
                        <el-row :gutter="24">
                            <el-col :span="4">
                                <el-select v-model="form.discount_type" value-key="value" placeholder="Oran Tipi"
                                           clearable>
                                    <el-option v-for="item in discountTypes" :key="item.value" :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="20">
                                <el-input v-model="form.discount" placeholder="Oran" />
                            </el-col>
                        </el-row>
                    </el-form-item>
                </div>

                <div class="card-sub-title">Genel Kisitlamalar</div>
                <div class="card-custom-padding-box-items">


                    <el-form-item label="Musteri">
                        <user :container="form" model="user_id" placeholder="Musteri" :key="form.id" />
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Baslangic Tarihi">
                                <el-date-picker v-model="form.start_at" type="datetime" placeholder="Tarih Seçiniz"
                                                value-format="yyyy-MM-dd HH:mm:ss" />
                            </el-form-item>
                        </el-col>

                        <el-col :span="12">
                            <el-form-item label="Bitis Tarihi">
                                <el-date-picker v-model="form.end_at" type="datetime" placeholder="Tarih Seçiniz"
                                                value-format="yyyy-MM-dd HH:mm:ss" />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Minimum Sepet Tutarı">
                                <el-input v-model="form.minimum_price" placeholder="Minimum Sepet Tutarı" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Maximum Sepet Tutarı">
                                <el-input v-model="form.maximum_price" placeholder="Maximum Sepet Tutarı" />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Kullanim Limiti">
                                <el-input v-model="form.max_usage_count" placeholder="Kullanim Limiti" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Kullanilan">
                                <el-input v-model="form.current_usage_count" placeholder="Kullanilan" disabled />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item>
                        <el-switch v-model="form.is_active" active-text="Aktif" />
                    </el-form-item>

                    <el-form-item v-if="form.user_id">
                        <el-switch v-model="form.is_notify" active-text="Musteriyi Bilgilendir" />
                    </el-form-item>
                </div>

            </el-card>

            <el-card class="custom-padding">
                <template slot="header">
                    <div class="title">
                        Iliskilere Bagli Kisitlamalar
                    </div>
                </template>
                <div class="el-card__tabs fluid">
                    <el-tabs class="el-card--tabs fluid" :tab-position="'top'">
                        <el-tab-pane label="Ürüne Bağlı">
                            <el-form-item>
                                <el-switch v-model="form.is_product" active-text="Etkinlestir!" />
                            </el-form-item>

                            <el-transfer filterable :filter-method="filterMethod" filter-placeholder="Ürünlerde Ara" :titles="['Tüm ürünler', 'Seçili Olanlar']" v-model="form.product_requests" :props="{key: 'id', label: 'description_title'}" :data="products">
                            </el-transfer>


                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-card>
        </el-form>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                code: null,
                product_requests: [],
            },

            discountTypes: [
                {
                    value: 1,
                    label: 'Fiyat - TL'
                },
                {
                    value: 2,
                    label: 'Yüzde - %'
                },
            ],

            products: [],

            filterMethod(query, item) {
                return item.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
            },
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }

        this.getProducts();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
        },

        generateCode() {
            this.form.code = (Math.random().toString(36).substring(2, 8)).toUpperCase();
        },

        getProducts() {
            this.pageLoading = true;

            this.$http
                    .get('catalog/products', {params: {perPage: 1000}})
                    .then(response => {
                        this.pageLoading = false;
                        this.products = response.body.rows;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
        },
    },
}
</script>