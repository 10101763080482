<template>
    <div class="catalog-page-detail-content-tab">
        <el-card>
            <template slot="header">
                <div class="title">
                    <icon :icon="tab.icon" />
                    {{ tab.title }}
                </div>
                <div class="actions" v-if="form.seo.id">
                    <el-tooltip content="Duzenle" placement="bottom">
                        <el-button class="flat dark green" icon="el-icon-edit" @click="openBlankWindow('/#/tools/seo/links/' + form.seo.id)" />
                    </el-tooltip>
                    <el-tooltip content="Web Sitesinde Gor" placement="bottom">
                        <el-button class="flat dark yellow" icon="el-icon-s-promotion" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/' + form.seo.slug)" />
                    </el-tooltip>
                </div>
            </template>

            <seo :container="form.seo" />
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'ProductSeo',
    props: ['tab', 'form'],
    components: {
        //
    },
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
