<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list overflow-400">
			<template slot="header">
				<div class="title">Icerikler</div>
				<div class="actions">
					<el-tooltip content="Ekle" placement="left">
						<el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
					</el-tooltip>
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text display-flex align-items-center">
                        <div class="title">[{{ (item.table_name).replace('education_', '') }}] {{ item.title }}</div>
					</div>
					<div class="actions" style="right: 20px">
                        <el-button type="text" class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)" />
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog title="Icerik Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="70%">
			<el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item>
                    <el-select v-model="form.table_name" placeholder="Icerik Turu" filterable clearable @change="handleChangeLayout">
                        <el-option v-for="item in layouts" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                </el-form-item>
                <template v-if="layout">
                    <el-form-item :label="layout.title">
                        <education-content-remote-select :alias="layout.id" :container="form" model="items" :multiple="true" />
                    </el-form-item>
                </template>
			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="store">
					Kaydet
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'HomeworkContents',
        props: ['id'],
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    items : []
                },

                items: [],

                layouts: [
                    {
                        id: 'word_lists',
                        title: 'Kelime Listeleri',
                        alias: 'word-lists'
                    },
                    {
                        id: 'tests',
                        title: 'Testler',
                        alias: 'tests'
                    },
                    {
                        id: 'videos',
                        title: 'Videolar',
                        alias: 'videos'
                    },
                    {
                        id: 'listenings',
                        title: 'Dinlemeler',
                        alias: 'listenings'
                    },
                    {
                        id: 'readings',
                        title: 'Okuma Parcalari',
                        alias: 'readings'
                    },
                    {
                        id: 'documents',
                        title: 'Dokumanlar',
                        alias: 'documents'
                    },
                    {
                        id: 'books',
                        title: 'Kitaplar',
                        alias: 'books'
                    },
                ],

                layout :null
            }
        },
        mounted() {
            this.index();
        },
        methods: {

            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = this.$route.meta.apiPoint + '/' + this.id + '/contents';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params: {perPage: 1000, sorts : {sort : 'desc'}}})
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {
                    items : []
				};
                this.dialogVisible = true;
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            handleChangeLayout(value) {
                this.layout = this.lodash.filter(this.layouts, (item) => {
                    return item.id === value
                })[0];

                this.form.items = [];
            }
        },

    }
</script>