<template>
    <div>
        <div class="card-sub-title margin-bottom-20">{{ item.label }}</div>
        <template v-for="(vuexItem, vuexItemIndex) in getSource(item.source)">
            <el-form-item :label="vuexItem.title" :key="vuexItemIndex">
                <el-tag v-for="prop in vuexItem.props" :key="prop" type="success" class="margin-right-10" v-if="prop">
                    {{ prop }}
                </el-tag>
                <el-input v-model="container[vuexItem[item.value_key] + '_template_key']" :placeholder="vuexItem.title" />
            </el-form-item>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Draft',
    props : ['form', 'container', 'item'],
    mounted() {
        //
    },
    methods: {
        getSource(index) {
            let keys = index.split('.');
            let items = this.$store.state;

            this.lodash.forEach(keys, (item) => {
                items = items[item]
            })

            return items;
        }
    }
}
</script>