<template>
    <div class="vplayer" @mouseover="isHovering = true;" @mouseout="isHovering = false">
        <video
            id="video"
            ref="videoPlayer"
            class="vplayer-player"
            :src="videoSrc"
            :poster="coverImg"
            @click="togglePlay"
            controlsList="nodownload"
            controls
        ></video>
        <span style="display: none">{{ videoSrc }}</span>
    </div>
</template>

<script>
import Hls from 'hls.js'

export default {
    name: 'VideoPlayer',
    props: {
        duration: {
            type: [Number, String],
        },
        autoPlay: {
            type: [Boolean],
        },

        options: {
            type: Object,
            default: () => {
            },
        },
        pins: {
            type: Array,
            default: () => [],
        },
        videoSrc: {
            type: String,
        },
        start: {
            type: [String, Number],
        },
    },
    data() {
        return {
            videoDuration: parseInt(this.duration),
            startTime: parseInt(this.startTime),
            player: null,
            coverImg: require("@/resources/img/lesson-cover.jpg"),
            time: 0,
            percentage: 0,
            showPinbar: false,
            timeInSeconds: 0,
            finishStatus: false,
            timeUpdate: true,
            watchTime: parseInt(this.start),
            lastSent: parseInt(this.start),
            timerInterval: null,
            finished: false,
            volumerVisible: true,
            volume: 1,
            isHovering: true,
            paused: false,
        }
    },

    watch: {
        autoPlay: function (newVal, oldVal) {
            if (!oldVal && newVal) {
                this.goTo(this.start)
                this.play()
            }
        },
    },

    computed: {
        currentTime() {
            return this.$nextTick(() => {
                return this.player.currentTime
            })
        },

        hlso() {
            return new Hls({
                xhtSetup: function (xhr, src) {
                    xhr.withCredentials = true
                },
            })
        }
    },

    created() {
        clearInterval(this.timerInterval)
    },

    mounted() {
        let src = this.videoSrc
        let videoObj = document.getElementById('video')
        this.player = videoObj
        let timer = 0
        videoObj.addEventListener('loadedmetadata', () => {
            timer = videoObj.currentTime
            this.$emit('duration', videoObj.duration)
            this.videoDuration = videoObj.duration
        })
        if (Hls.isSupported()) {
            let source = this.$refs.videoPlayer
            source.addEventListener('timeupdate', () => {
                this.watchTime = source.currentTime
                this.videoDuration = source.duration
                this.percentage = (this.watchTime / this.videoDuration) * 100
            })
            source.addEventListener('pause', () => {
                this.paused = true
            })
            source.addEventListener('ended', () => {
                this.setFinished()
                clearInterval(this.timerInterval)
            })

            console.log(src)

            this.hlso.loadSource(src)
            this.hlso.attachMedia(videoObj)
            this.hlso.on(Hls.Events.MANIFEST_PARSED, () => {
                this.player.currentTime = this.start
                this.volume = this.player.volume
                this.timerInterval = setInterval(() => {
                    if (this.lastSent !== this.watchTime) {
                        this.watchLesson(Math.ceil(this.watchTime))
                        this.lastSent = this.watchTime
                    }
                }, 10000)
            })
        } else if (videoObj.canPlayType('application/vnd.apple.mpegurl')) {
            videoObj.src = src
        }
    },

    methods: {
        toggleVolumer() {
            this.volumerVisible = !this.volumerVisible
        },

        togglePlay() {
            this.player.paused ? this.play() : this.pause()
        },

        goTo(val) {
            this.$emit('startFrom', val)
            this.player.currentTime = val
        },

        pause() {
            this.player.pause()
        },

        play() {
            this.paused = false
            this.player.play()
        },

        goStart() {
            this.player.currentTime = 0
            this.play()
        },

        fullScreen() {
            if (this.player.requestFullscreen) {
                this.player.requestFullscreen()
            } else if (this.player.mozRequestFullScreen) {
                this.player.mozRequestFullScreen()
            } else if (this.player.webkitRequestFullscreen) {
                this.player.webkitRequestFullscreen()
            } else if (this.player.msRequestFullscreen) {
                this.player.msRequestFullscreen()
            }
        },

        check() {

        },

        getLocation(e) {
            let location = (e.offsetX / e.target.offsetWidth) * this.player.duration
            this.player.currentTime = location
        },

        watchLesson(val) {
            this.timeUpdate = true
        },

        setFinished() {
            this.finished = true
        },

        setVolume(e) {
            this.volume = 1 - e.offsetY / e.target.offsetHeight
            this.player.volume = this.volume
        },
        showVolumer() {
            this.volumerVisible = true
        },

        hideVolumer() {
            setTimeout(() => {
                this.volumerVisible = false
            }, 1000)
        },
    },
    destroyed() {
        clearInterval(this.timerInterval)
        this.hlso.detachMedia(document.getElementById('video'))
    },
}
</script>

<style lang="scss" scoped>
.vplayer {
    position  : relative;
    width     : 100%;
    max-width : 100%;

    &-controls {
        background     : linear-gradient(
                to top,
                rgba($color : #4442a4, $alpha : 0.5),
                transparent
        );
        transition     : 0.2s;
        opacity        : 0;
        position       : absolute;
        width          : 100%;
        height         : 200px;
        z-index        : 3;
        display        : flex;
        align-items    : flex-end;
        left           : 0;
        bottom         : 6px;
        border-radius  : 1.5rem;
        padding-bottom : 0.5rem;

        &.active {
            opacity : 1;
        }

        &-inner {
            width       : 100%;
            display     : flex;
            align-items : center;
        }

        &-play,
        &-fullscreen,
        &-volume {
            flex-grow       : 0;
            flex-shrink     : 0;
            flex-basis      : 70px;
            height          : 50px;
            display         : inline-flex;
            align-items     : center;
            justify-content : center;
            color           : #fff;
            font-size       : 1.5rem;
        }

        &-volume {
            position        : relative;
            flex-basis      : 50px;
            justify-content : flex-start;
        }
    }

    &-player {
        width         : 100%;
        z-index       : 2;
        border-radius : 1.5rem;
        max-width     : 100%;
        box-shadow    : 0px 0 24px rgba(85, 100, 144, 0.15);
        margin        : 0;
    }
}
</style>
