<template>
	<div class="error-page error-page-404">
		<div class="wrapper">
			<div class="container">
				<div class="content">
					<div class="code">404</div>
					<div class="message">Oops we can’t find what you are looking for :(</div>
					<div class="buttons">
						<el-button class="modern large" icon="el-icon-back" style="padding: 15px 30px !important" @click="$router.go(-1)">
							Geri Dön
						</el-button>
					</div>
				</div>
				<div class="media">
					<img src="/resources/img/error-page.svg" alt="error">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'NotFound',
		data() {
			return {
				//
			}
		},
		mounted() {
			//
		},
		methods: {
			//
		}
	}
</script>