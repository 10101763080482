<template>
    <el-select v-model="container[model]" value-key="id" filterable clearable remote reserve-keyword
               :placeholder="placeholder ? placeholder : 'Seciminiz'" :remote-method="search" @change="handleSelected"
               :loading="loading" :multiple="multiple" class="user-search">
        <el-option class="user-search-item" v-for="item in items" :key="item.id" :label="item.name" :value="item.id">
            <div class="user-search-item-container">
                <div>
                    <pic :src="item.avatar" type="avatar"/>
                </div>
                <div>
                    <div class="title">{{ item.name }}</div>
                    <div class="mini">{{ item.email }}</div>
                    <div class="mini">{{ item.phone }}</div>
                </div>
            </div>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'User',
    props: ['container', 'model', 'change', 'placeholder', 'multiple', 'callback'],
    data() {
        return {
            loading: false,
            items: []
        };
    },
    mounted() {
        if (this.container[this.model]) {
            this.findCustomer(this.container[this.model]);
        }
    },
    methods: {
        handleSelected(e) {
            if (this.change) {
                this.change(e)
            }

            if (this.callback) {
                this.items.forEach((user) => {
                    if (user.id === this.container[this.model]) {
                        this.callback(user)
                    }
                })
            }
        },

        search(query) {
            this.loading = true;

            this
                .$http
                .get('tools/search/users?q=' + query)
                .then(response => {
                    this.loading = false;
                    this.items = response.body.data;
                })
                .catch((e) => {
                    this.loading = false;
                    this.responseError(e);
                });
        },

        findCustomer(id) {
            this.loading = true;

            this.$http
                .get('crm/users/' + id)
                .then(response => {
                    this.loading = false;
                    let item = response.body.data;

                    item.title = item.name;

                    this.items.push(item);
                });
        },
    },
};
</script>

<style lang="scss">
.user-search-item {
    height      : auto;
    line-height : normal;
    padding     : 10px 20px;
    line-height : normal !important;

    .user-search-item-container {
        display     : flex;
        align-items : center;

        img {
            height       : 45px;
            width        : 45px;
            margin-right : 20px;
        }

        .title {
            font-weight : 500;
        }

        .mini {
            font-size : 13px;
        }
    }

}
</style>
