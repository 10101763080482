<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">

				<el-card v-if="form.user">
					<h4 class="margin-bottom-0">{{ form.user.name }}</h4>
					<h5>
						<a :href="appConfig.frontPath + '/' + form.url" target="_blank">
							{{ form.url }}
						</a>
					</h5>
				</el-card>

				<el-form-item class="margin-bottom-0">
					<el-switch v-model="form.is_active" active-text="Onayla" />
				</el-form-item>

				<el-form-item class="margin-bottom-0">
					<el-switch v-model="form.is_feature" active-text="One Cikar" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_notify" active-text="Musteriyi Bilgilendir" />
				</el-form-item>

				<el-form-item label="Mesaj">
					<el-input type="textarea" placeholder="Mesaj" v-model="form.content" rows="8" />
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
		},
        data() {
            return {
                pageLoading: false,
                form: {
                   //
                },
            }
        },
        methods: {

            /**
             * Show page
             */
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },


        },
        mounted() {
            this.show();
        },
    }
</script>