<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list">
            <template slot="header">
                <div class="title">Gruplar</div>
                <div class="actions">
                    <el-tooltip content="Yenile" placement="left">
                        <el-button class="flat dark purple" icon="el-icon-refresh" @click="index" />
                    </el-tooltip>
                    <el-tooltip content="Ekle" placement="left">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
                    </el-tooltip>
                </div>
            </template>
            <div class="card-flat-list-items sm" style="min-height: 400px">
                <empty v-if="!elementSize(items)" />

                <div class="item" v-for="item in items" :key="item.id">
                    <div class="text display-flex align-items-center">
                        <div class="cursor-pointer" @click="edit(item.id)">
                            <div class="title">{{ item.title }}</div>
                            <div class="sub-title">{{ item.translation }}</div>
                        </div>
                    </div>
                    <div class="actions" style="right: 20px">
                        <el-button type="text" class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)" />
                    </div>
                </div>
            </div>
        </el-card>

        <el-dialog title="Grup Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" class="dialog-with-page" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form" :key="form.id">

                <el-form-item label="Başlık">
                    <el-input v-model="form.title" placeholder="Başlık" />
                </el-form-item>

                <el-form-item label="Çeviri" class="mb-0">
                    <el-input v-model="form.translation" placeholder="Çeviri" />
                </el-form-item>
            </el-form>

            <div v-if="form.id" class="margin-top-30">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <GroupQuestionView :id="form.id" />
                    </el-col>
                    <el-col :span="12">
                        <GroupAnswerView :id="form.id" />
                    </el-col>
                </el-row>
            </div>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import GroupQuestionView from "./QuestionView";
import GroupAnswerView from "./AnswerView";

export default {
    name: 'PartItemView',
    props: ['id'],
    components : {
        GroupQuestionView,
        GroupAnswerView,
    },
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            form: {
                title : '',
                translation: ''
            },

            items: [],
        }
    },

    mounted() {
        this.index();
    },
    methods: {
        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = (this.path ? this.path : this.$route.meta.apiPoint) + '/' + this.id + '/groups';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                .then(response => {

                    this.items = response.body.rows;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         *  Create modal
         */
        create() {
            this.form = {
                //
            };
            this.dialogVisible = true;
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form save
         */
        store() {
            this.pageLoading = true;

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;

                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        /**
         * Item dropdown actions
         *
         * @param command
         */
        handleRowActionCommand(command) {
            if (command.type === 'destroy') {
                return this.destroy(command.id);
            }

            this.edit(command.id);
        }
    }
}
</script>
