<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<div class="crud-header">
					<div class="title">
						Yazismalar
					</div>
				</div>
			</template>
			<div class="card-sub-title margin-top-20">Mesajlar</div>
			<div class="card-custom-padding-box-items">
				<el-timeline :reverse="reverse">
					<el-timeline-item v-for="(message, index) in form.messages" :key="index" :timestamp="message.created_at">
						<div>
							<b>{{ message.user.name }}</b>
						</div>
						<div style="font-size:13px">
							{{ message.message }}
						</div>
					</el-timeline-item>
				</el-timeline>
			</div>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
        },
        data() {
            return {
                pageLoading: false,
                form: {
                    //
                },

                reverse: true,
            }
        },
        mounted() {
            this.show();
        },
        methods: {
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink(), {
                        params: {
                            with: [
                                'messages',
                                'messages.user',
                            ]
                        }
                    })
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },
        }
    }
</script>