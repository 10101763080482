<template>
	<el-dialog title="Tema Yukle" :visible.sync="visible" class="dialog-with-page" append-to-body :before-close="dialogClose" v-loading="pageLoading">
		<div class="el-dialog__body-wrapper">
			<div class="theme-page">
				<div class="theme_list" v-loading="pageLoading">
					<el-card class="custom-padding">
						<div class="item" v-for="item in items" :key="item.id">
							<div class="content">
								<div class="media">
									<img :src="appConfig.cdnPath + item.preview" alt="preview">
								</div>
								<div class="text">
									<div class="title">{{ item.name }}</div>
									<div class="description">{{ item.description }}</div>
								</div>
							</div>
							<div class="actions">
								<el-button @click="store(item)">
									Yukle
								</el-button>
							</div>
						</div>
					</el-card>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: 'ThemeCreate',
		data() {
			return {
				visible: true,
				pageLoading: false,
				form: {
					meta : {
						//
					}
				},

				items: [],
			}
		},
		mounted() {
			this.dialogShow();
			this.create();
		},
		methods: {

			dialogShow() {
				this.$store.commit('visibleFullPageDialog');
			},

			dialogClose() {
				this.$store.commit('hideFullPageDialog');
				this.$router.push('/themes')
			},

			/**
			 * Create page
			 */
			create() {
				this.pageLoading = true;

				this.$http
					.get(this.generateApiPointWithAlias(this.$route.meta.apiPoint) + '?view=themes')
					.then(response => {

						this.items = response.body.data;

						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			/**
			 * Store form
			 */
			store(item) {
				this.form = item;
				this.pageLoading = true;

				this.$http
					.post(this.generateApiPointWithAlias(this.$route.meta.apiPoint), this.form)
					.then(response => {
						this.pageLoading = false;

						this.goShowPage(response.body.data.id);

						return this.responseSuccess(response);
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},
		},

	}
</script>
