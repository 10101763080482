<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Ogretmen">
                            <user :container="form" model="teacher_id" placeholder="Ogretmen" :key="form.id" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Ogrenci">
                            <user :container="form" model="user_id" placeholder="Ogrenci" :key="form.id" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Yayinlanma Tarihi">
                            <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayinlanma Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Okunma Tarihi">
                            <el-date-picker v-model="form.read_at" type="datetime" placeholder="Okunma Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Mesaj" class="is-editor">
                    <editor :container="form" model="description" />
                </el-form-item>

                <el-form-item label="Ceviri" class="is-editor">
                    <editor :container="form" model="description_translate" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'InformationLetter',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },

            pickerOptions: {
                firstDayOfWeek: 1,
            },
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>