<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list overflow-two-colums-fluid">
			<template slot="header">
				<div class="title">
					Teslimatlar
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text display-flex align-items-center">
						<div class="media mr-4 text-secondary" style="font-size: 38px">
							<icon icon="truck"></icon>
						</div>
						<div>
							<div class="title text-secondary mb-0">{{ item.delivery_plugin.title }}</div>
							<div class="vertical-flex-items has-dimmer" v-if="item.delivery_at">
								<div>{{ item.delivery_no }}</div>
								<div>{{ item.delivery_at }}</div>
							</div>
						</div>
					</div>
					<div class="actions">
						<el-button class="modern" @click="openBlankWindow('/#/shop/deliveries/' + item.id)">
							Detay
						</el-button>
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
    export default {
        name: 'OrderDeliveries',
        props: ['id'],
        data() {
            return {
                pageLoading: false,
                items: []
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get('shop/deliveries', {
                        params: {
                            perPage: 1000,
                            filters: [
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'user_id',
                                            field: 'user_id',
                                            type: 'singleSelect',
                                            value: this.id
                                        }
                                    ]
                                },
                            ],
                        }
                    })
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            }
        }
    }
</script>