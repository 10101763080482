<template>
    <div class="catalog-page-detail-content-tab">
        <Features :id="form.id" path="cms/products/contents" />
    </div>
</template>

<script>
import Features from "@/app/Pages/Cms/Contents/Features";

export default {
    name: 'ProductFeatures',
    props: ['tab', 'form'],
    components: {
        Features
    },
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>