<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Baslik" />
                </el-form-item>

                <el-form-item label="Açıklama" class="mb-0">
                    <el-input v-model="form.description" placeholder="Açıklama" type="textarea" rows="10" />
                </el-form-item>
            </el-form>
        </el-card>

        <template v-if="form.id">
            <GroupView :id="form.id" />
        </template>
    </div>
</template>

<script>
import CrudHeader from "@/app/Components/CrudHeader";
import GroupView from "./GroupView";

export default {
    name: 'IndividualAssessmentReportShow',
    components: {
        CrudHeader,
        GroupView,
    },
    data() {
        return {
            pageLoading: false,
            form: {
                title : '',
                description : ''
            },
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            //
                        };
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>
