<template>
	<div v-loading="pageLoading">
        <el-card class="card-flat-list">
            <template slot="header">
                <div class="title">Yonergeler</div>
                <div class="actions">
                    <el-tooltip content="Ekle" placement="left">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
                    </el-tooltip>
                </div>
            </template>
            <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                <div class="item" v-for="item in items" :key="item.id">
                    <div class="text display-flex align-items-center">
                        <div class="cursor-pointer" @click="edit(item.id)">
                            <div class="title" v-html="item.description"></div>
                            <div class="sub-title">
                                {{ item.start_question_index }} - {{ item.end_question_index }}
                            </div>
                        </div>
                    </div>
                    <div class="actions" style="right: 20px">
                        <el-button type="text" class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)" />
                    </div>
                </div>
            </div>
        </el-card>

        <el-dialog title="Yonerge Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="50%">
            <el-form :label-position="'top'" ref="form" :model="form" :key="form.id">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslangic Sirasi">
                            <el-input v-model="form.start_question_index" placeholder="Baslangic Sirasi" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitis Sirasi">
                            <el-input v-model="form.end_question_index" placeholder="Bitis Sirasi" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Aciklama" class="is-editor">
                    <editor :container="form" model="description" :small="true" />
                </el-form-item>

                <el-form-item label="Ceviri" class="is-editor margin-bottom-0">
                    <editor :container="form" model="translation" :small="true" />
                </el-form-item>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'TestInstruction',
        props: ['id'],
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    //
                },

                items: [],
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = this.$route.meta.apiPoint + '/' + this.id + '/instructions';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {
                    //
                };
                this.dialogVisible = true;
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },
        }
    }
</script>