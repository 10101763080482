<template>
    <div v-loading="pageLoading">
        <div v-loading="pageLoading">
            <el-card>
                <template slot="header">
                    <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" :store-call="responseStore" />
                </template>
                <el-form :label-position="'top'" ref="form" :model="form">
                    <el-form-item>
                        <el-select v-model="form.content_type" placeholder="Icerik Turu" filterable clearable
                                   @change="handleChangeLayout">
                            <el-option v-for="item in layouts" :key="item.id" :label="item.title" :value="item.id" />
                        </el-select>
                    </el-form-item>

                    <template v-if="layout">
                        <el-form-item label="Etiketler">
                            <definition-box :alias="layout.alias" :container="form" model="tag_id"
                                            placeholder="Etiketler" :editable="false" :key="layout.alias" />
                        </el-form-item>

                        <el-form-item label="Urunler">
                            <product-transfer :container="form" model="products" :types="['group', 'subscription']" />
                        </el-form-item>
                    </template>
                </el-form>
            </el-card>
        </div>
    </div>
</template>

<script>
import CrudHeader from "@/app/Components/CrudHeader";

export default {
    name: 'ContentShare',
    components: {CrudHeader},
    data() {
        return {
            pageLoading: false,
            form: {
                tag_id: null,
                products: []
            },

            layout: null,
            layouts: [
                {
                    id: 'education_word_list',
                    title: 'Kelime Listeleri',
                    alias: 'word-lists'
                },
                {
                    id: 'education_test',
                    title: 'Testler',
                    alias: 'tests'
                },
                {
                    id: 'education_video',
                    title: 'Videolar',
                    alias: 'videos'
                },
                {
                    id: 'education_listening',
                    title: 'Dinlemeler',
                    alias: 'listenings'
                },
                {
                    id: 'education_reading',
                    title: 'Okuma Parcalari',
                    alias: 'readings'
                },
                {
                    id: 'education_book',
                    title: 'Kitaplar',
                    alias: 'books'
                },
                {
                    id: 'education_document',
                    title: 'Dokumanlar',
                    alias: 'documents'
                }
            ],
        }
    },
    methods: {
        handleChangeLayout(value) {
            this.layout = this.lodash.filter(this.layouts, (item) => {
                return item.id === value
            })[0];
            this.form.tag_id = null;
        },

        responseStore(response) {
            return this.responseSuccess({
                body: {
                    success: 'Ilgili urunler ' + response.data.total + ' Adet icerige dagitildi'
                }
            });
        }
    }
}
</script>
