<template>
    <div class="alert" :class="status">
        <div class="media">
            <icon :icon="checkStatusIcon()" />
        </div>
        <div class="text" v-html="text"></div>
    </div>
</template>

<script>
export default {
    name: 'Alert',
    props: {
        status: {
            type: String,
            default: 'info',
        },
        text: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            //
        };
    },
    mounted() {
        //
    },
    methods: {
        checkStatusIcon() {
            if (this.status === 'warning') {
                return 'alert-outline';
            }

            if (this.status === 'success') {
                return 'check';
            }

            if (this.status === 'error') {
                return 'alert-circle-outline';
            }

            return 'information-outline';
        }
    },
};
</script>

<style lang="scss" scoped>
.alert {
    padding          : 0 !important;;
    margin           : 0 !important;
    position         : relative;
    line-height      : initial !important;
    border-radius    : 0 !important;;
    border           : .125rem solid transparent !important;
    display          : flex;
    align-items      : center;
    flex-direction   : column;
    flex-wrap        : wrap;
    flex-flow        : wrap;
    color            : #363a41;
    background-color : #ffffff;

    .media {
        width           : 2.875rem;
        height          : 100%;
        display         : flex;
        align-items     : center;
        justify-content : center;
        font-size       : 1.563rem;
        height          : 100%;
        position        : absolute;
    }

    .text {
        padding     : .75rem !important;
        height      : 100%;
        margin-left : 3.575rem;
        color       : #363a41;
        font-size   : .875rem;
        font-weight : 400;
    }

    &.info {
        border-color     : #25b9d7 !important;

        .media {
            background : #dff5f9;
            color      : #25b9d7;
        }
    }

    &.warning {
        border-color     : #cd9321 !important;

        .media {
            background : #fde7bb;
            color      : #cd9321;
        }
    }

    &.error {
        border-color : #c05c67 !important;

        .media {
            background : #fde1e1;
            color      : #c05c67;
        }
    }

    &.success {
        border-color     : #70b580 !important;

        .media {
            background : #d6f0d8;
            color      : #70b580;
        }
    }
}
</style>