<template>
	<img :src="find()" alt="image">
</template>

<script>
    export default {
        name: 'PluginPic',
        props: ['name'],
		methods : {
            find() {

                if (this.name) {
                    return 'https://' +  this.$store.state.ui.tenant.domain + '/assets/plugins/' + this.name.toLowerCase() + '/preview.png';
				}

                return '/resources/img/dummy.png'
			}
		}
    };
</script>
