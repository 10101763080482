<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list">
			<template slot="header">
				<div class="title">
                    <icon icon="table-column" />
					Sekmeler
				</div>
				<div class="actions">
					<el-tooltip content="Ekle" placement="left">
						<el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
					</el-tooltip>
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">

                <empty v-if="!elementSize(items)" />

				<div class="item" v-for="item in items" :key="item.id">
					<div class="text display-flex align-items-center">
						<div class="cursor-pointer" @click="edit(item.id)">
							<div class="title">{{ item.title }}</div>
						</div>
					</div>
					<div class="actions" style="right: 20px">
                        <el-button type="text" class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)" />
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog title="Sekme Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" class="dialog-with-page" append-to-body>
			<el-form :label-position="'top'" ref="form" :model="form" :key="form.id">

				<el-form-item label="Sablon">
					<lv-select :container="form" model="alias" :options="layouts" />
				</el-form-item>

				<el-form-item label="Başlık">
					<el-input v-model="form.title" placeholder="Başlık" />
				</el-form-item>

				<el-form-item label="Kisa Aciklama" class="is-editor">
					<editor :container="form" model="short_description" :small="true" />
				</el-form-item>

				<el-form-item label="Aciklama" class="is-editor">
					<editor :container="form" model="description" />
				</el-form-item>

				<el-form-item label="Siralama">
					<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
				</el-form-item>
			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
					Kaydet
				</el-button>
				<el-button class="fluid flat dark green large" @click="update" v-if="form.id">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'Tabs',
        props: ['id', 'path'],
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    //
                },

                items: [],
                layouts: [
                    {
                        value: 'basic',
                        label: 'Varsayilan'
                    },
                    {
                        'value': 'education_lesson',
                        'label': 'Egitim Ders Icerigi'
                    }
                ],
            }
        },

		mounted() {
			this.index();
		},

        methods: {

            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = (this.path ? this.path : this.$route.meta.apiPoint) + '/' + this.id + '/tabs';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {
                    //
                };
                this.dialogVisible = true;
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            /**
             * Item dropdown actions
             *
             * @param command
             */
            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroy(command.id);
                }

                this.edit(command.id);
            }
        }
    }
</script>
