<template>
    <el-form-item :label="item.label">
        <el-date-picker v-model="container[item.name]" :placeholder="item.label" type="date" value-format="yyyy-MM-dd" />
    </el-form-item>
</template>

<script>
export default {
    name: 'Date',
    props : ['form', 'container', 'item'],
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>