<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Baslik">
                        <el-input v-model="form.title" placeholder="Baslik" />
                    </el-form-item>

                    <el-form-item label="Sablon">
                        <ti-select :options="$store.state.ui.draft.heads" :container="form" model="draft_layout_head_id" placeholder="Sablon" :change="handleChangeHead" />
                    </el-form-item>

                    <div class="ui message blue" v-if="form.draft_layout_head_id">
                        <div v-html="'Konu ve template kisminda kullanilacak degiskenler. Ornek: Merhaba {{name}} ...'"></div>
                        <div class="margin-top-20">
                            <el-tag v-for="prop in props" :key="prop" class="margin-right-10">
                                <span>{{ prop }}</span>
                            </el-tag>
                        </div>
                    </div>

                    <el-row :gutter="24">
                        <el-col :span="8">
                            <el-form-item>
                                <el-switch v-model="form.is_email" active-text="E-Posta Gonder" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item>
                                <el-switch v-model="form.is_sms" active-text="Sms Gonder" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item>
                                <el-switch v-model="form.is_mobile" active-text="Mobil Bildirim Gonder" />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="Indirim Kodu Olustur">
                        <lv-select :container="form" model="is_discount" :options="approveOptionsBoolean" />
                    </el-form-item>

                    <el-form-item label="İndirim Orani (Yuzde)" v-if="form.is_discount">
                        <el-input v-model="form.discount_percent" placeholder="Indirim Orani" />
                    </el-form-item>

                </div>
                <div class="el-card__tabs">
                    <el-tabs class="el-card--tabs fluid" :tab-position="'top'">
                        <el-tab-pane label="E-Posta" v-if="form.is_email">
                            <el-form-item label="Konu">
                                <el-input v-model="form.subject" placeholder="Konu" />
                            </el-form-item>

                            <el-form-item label="Mesajiniz" class="is-editor margin-bottom-0">
                                <editor :container="form" model="email_message" />
                            </el-form-item>
                        </el-tab-pane>
                        <el-tab-pane label="Sms"  v-if="form.is_sms">
                            <el-form-item label="Mesajiniz" class="margin-bottom-0">
                                <el-input v-model="form.sms_message" placeholder="Mesajiniz" type="textarea" rows="8" />
                            </el-form-item>
                        </el-tab-pane>
                        <el-tab-pane label="Mobil"  v-if="form.is_mobile">
                            <el-form-item label="Mesajiniz" class="margin-bottom-0">
                                <el-input v-model="form.mobile_message" placeholder="Mesajiniz" type="textarea" rows="8" />
                            </el-form-item>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },

            permissions: [],
            heads: [],
            props : [],
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;
                    this.pageLoading = false;

                    this.handleChangeHead();
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleChangeHead() {
            this.props = [];

            this.lodash.forEach(this.$store.state.ui.draft.heads, (item) => {
                if (item.id === this.form.draft_layout_head_id) {
                    this.props = item.props;
                }
            })
        }
    },
}
</script>