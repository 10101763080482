<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding" id="TableContainer">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">
                        {{ cardCrudTitle() }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Filtrele" placement="bottom">
                            <el-button class="flat dark secondary" icon="el-icon-search" @click="handleSearch" />
                        </el-tooltip>
                    </div>
                </div>
            </template>
            <div class="el-card__filters">
                <el-form :label-position="'top'" ref="form" :model="filter" @submit.native.prevent="handleSearch">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item class="margin-bottom-0">
                                <lv-select :container="filter" model="product_type" :options="types" placeholder="Urun Tipi" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <el-date-picker v-model="filter.start_at" type="date" placeholder="Baslangic Tarihi" value-format="yyyy-MM-dd" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <el-date-picker v-model="filter.end_at" type="date" placeholder="Bitis Tarihi" value-format="yyyy-MM-dd" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <template v-if="filter.product_type === 'group'">
                        <el-row :gutter="24" class="margin-top-20">
                            <el-col :span="12">
                                <el-form-item class="margin-bottom-0">
                                    <definitions :container="filter" model="period_definition_id" alias="periods" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item class="margin-bottom-0">
                                    <definitions :container="filter" model="season_definition_id" alias="seasons" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>
                </el-form>
            </div>
        </el-card>

        <template v-if="response">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-card class="stat-card">
                        <div class="text">
                            <div class="number">{{ response.count }}</div>
                            <div class="title">Satis Sayisi</div>
                        </div>
                        <div class="media bg-teal-opack text-teal">
                            <icon icon="gift-outline" />
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card class="stat-card">
                        <div class="text">
                            <div class="number">{{ $n(response.amount, 'currency') }}</div>
                            <div class="title">Satis Degeri</div>
                        </div>
                        <div class="media bg-navy-blue-opack text-navy-blue">
                            <icon icon="shopping-outline" />
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <el-card class="custom-padding">
                <el-table :data="response.summary" :style="{width : '100%', 'max-width' : pageWidth + 'px'}" stripe border size="mini">
                    <el-table-column prop="title" label="Urun" />
                    <el-table-column prop="value" label="Tutar" width="200" align="right">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 'money'">{{ $n(scope.row.value, 'currency') }}</span>
                            <span v-if="scope.row.type === 'count'">{{ scope.row.value }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>

            <el-card class="custom-padding">
                <el-table :data="response.items" :style="{width : '100%', 'max-width' : pageWidth + 'px'}" stripe border size="mini">
                    <el-table-column prop="title" label="Urun" width="460" sortable fixed />
                    <template v-for="(monthTitle, monthTitleIndex) in monthTitles">
                        <el-table-column :label="monthTitle.title" width="160" align="center" :key="monthTitleIndex">
                            <el-table-column label="Adet" width="80" align="right" sortable>
                                <template slot-scope="scope">
                                    {{ scope.row.months[monthTitle.id] ? scope.row.months[monthTitle.id].count : '-' }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Tutar" width="120" align="right" sortable>
                                <template slot-scope="scope">
                                    {{ scope.row.months[monthTitle.id] ? $n(scope.row.months[monthTitle.id].amount, 'currency') : '-' }}
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </template>
                    <el-table-column label="Toplam" width="300" sortable align="right">
                        <el-table-column label="Adet" width="120" align="right" sortable>
                            <template slot-scope="scope">
                                {{ scope.row.count }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Tutar" width="120" align="right" sortable>
                            <template slot-scope="scope">
                                {{ $n(scope.row.amount, 'currency') }}
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </el-card>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Order',
    data() {
        return {
            pageLoading: false,
            filter: {
                //
            },
            types: [
                {
                    value: 'group',
                    label: 'Grup',
                },
                {
                    value: 'package',
                    label: 'Calisma Paketi',
                },
                {
                    value: 'physical',
                    label: 'Fiziksel',
                },
                {
                    value: 'digital',
                    label: 'Dijital',
                },
                {
                    value: 'event',
                    label: 'Etkinlik / Webinar',
                }
            ],
            response: null,
            pageWidth : 0,
        }
    },
    mounted() {

        setTimeout(() => {
            this.pageWidth = window.document.getElementById('TableContainer').offsetWidth;
        }, 500)
    },
    methods: {
        handleSearch() {
            this.response = null;
            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {params : this.filter})
                .then(response => {
                    this.pageLoading = false;
                    this.response = response.data.data;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>