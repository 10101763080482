<template>
    <div v-loading="pageLoading" class="margin-top-20">
        <el-row :gutter="24">
            <el-col :span="18">
                <template v-for="(text, index) in form.file_data.texts">
                    <div class="padding-left-20">
                        <el-form-item :key="text.value" v-show="currentTab === index">
                            <el-input v-model="form.meta[text.value]" :placeholder="text.label" rows="10" type="textarea" />
                        </el-form-item>
                    </div>
                </template>
            </el-col>
            <el-col :span="6">
                <aside class="catalog-page-detail-sidebar">
                    <div class="tabs">
                        <template v-for="(text, index) in form.file_data.texts">
                            <div class="tab" :key="index" :class="{active : index === currentTab}" @click="currentTab = index">
                                <div class="title">{{ text.label }}</div>
                            </div>
                        </template>
                    </div>
                </aside>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'TextShowTheme',
    props: ['form'],
    data() {
        return {
            pageLoading: false,
            currentTab : 0,
        }
    }
}
</script>
