<template>
	<div v-loading="pageLoading">
        <template v-for="(section, index) in items">
            <div class="box-list-item d-block" :key="index" style="padding-left: 40px">
                <div class="name fluid-i">
                    <div>{{ section.title }}</div>
                    <div class="date fs-12 font-weight-400">{{ section.publish_at }}</div>
                </div>
                <div class="metas d-flex">
                    <div class="meta cursor-pointer margin-right-20" @click="exercise(section.id)">
                        <icon icon="eye-outline" class="text-blue" /> Alistirmalar
                    </div>
                    <div class="meta cursor-pointer margin-right-20"  @click="edit(section.id)">
                        <icon icon="book-edit-outline" class="text-green" /> Duzenle
                    </div>
                    <div class="meta cursor-pointer" @click="destroy(section.id)">
                        <icon icon="trash-can-outline" class="text-red" /> Sil
                    </div>
                </div>
            </div>
        </template>

        <el-dialog title="Bolum Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="50%">
            <el-form :label-position="'top'" ref="form" :model="form" :key="form.id">
                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Baslik" />
                </el-form-item>

                <el-form-item label="Yayinlama Basligi">
                    <el-input v-model="form.public_title" placeholder="Yayinlama Basligi" />
                </el-form-item>

                <el-form-item label="Yayınlanma Tarihi">
                    <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayınlanma Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" />
                </el-form-item>

                <el-form-item label="Kisa Aciklama" class="is-editor margin-bottom-0">
                    <editor :container="form" model="short_description" :small="true" />
                </el-form-item>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'BookDirectorySection',
        props : ['id', 'findExercise', 'sections'],
        components: {
            //
		},
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    //
                },

                items: [],
            }
        },
        mounted() {
            this.items = this.sections;
        },
        methods: {
            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = this.$route.meta.apiPoint + '/' + this.id + '/sections';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {
                    //
                };
                this.dialogVisible = true;
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            exercise(sectionId) {
                return this.findExercise(sectionId);
            }
        }
    }
</script>