<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Kategoriler">
                        <el-select v-model="form.category_requests" placeholder="Bagli Oldugu Kategori" clearable filterable multiple>
                            <el-option v-for="item in categories" :key="item.id" :label="item.title_ui" :value="item.id" />
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Konuyu Acan Kullanici">
                        <user :container="form" model="user_id" placeholder="Konuyu Acan Kullanici" :key="form.id" />
                    </el-form-item>

                    <el-form-item label="Baslik">
                        <el-input v-model="form.title" placeholder="Baslik" />
                    </el-form-item>

                    <el-form-item label="Aciklama" class="is-editor">
                        <el-input v-model="form.description" placeholder="Aciklama" type="textarea" rows="8" />
                    </el-form-item>
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader,
    },
    data() {
        return {
            pageLoading: false,
            form: {
                category_requests: [],
            },
            categories: [],
        }
    },
    mounted() {

        this.getCategories();

        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        getCategories() {
            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint.replace('contents', 'categories'), {params: {perPage: 1000}})
                .then(response => {
                    this.categories = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {};
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    },
}
</script>