<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
                <alert status="warning" text="Lutfen eski linkinize Seo yonlendirmeleri kismindan yonlendirme eklemeyi unutmayin" />

                <el-card class="google-seo-preview is-bordered margin-top-20">
                    <div class="title">{{ form.meta_title }}</div>
                    <div class="description">{{ form.meta_description }}</div>
                    <div class="link" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/' + form.slug)">{{ appConfig.frontPath }}/{{ form.slug }}</div>
                </el-card>

				<el-form-item label="Meta Title">
					<el-input v-model="form.meta_title" placeholder="Meta Title" maxlength="60" show-word-limit />
				</el-form-item>

                <el-form-item label="Link">
                    <el-input v-model="form.slug" placeholder="Link">
                        <template slot="prepend">{{ 'https://' + $store.state.ui.tenant.domain }}</template>
                    </el-input>
                </el-form-item>

				<el-form-item label="Meta Description">
					<el-input v-model="form.meta_description" placeholder="Meta Description" type="textarea" maxlength="160" show-word-limit/>
				</el-form-item>

				<el-form-item label="Keywords">
					<el-input v-model="form.meta_keywords" placeholder="Keywords" maxlength="60" show-word-limit />
				</el-form-item>

				<el-form-item class="margin-bottom-0">
					<el-switch v-model="form.meta_no_index" active-text="Arama Motorunda Gözükmesin" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.meta_no_follow" active-text="Linkleri Takip Etme" />
				</el-form-item>

			</el-form>
		</el-card>
	</div>
</template>

<script>
	import CrudHeader from "../../Components/CrudHeader";

	export default {
		name: 'CrudItem',
		components: {
			CrudHeader
		},
		data() {
			return {
				pageLoading: false,
				form: {
					//
				},
			}
		},
		methods: {

			/**
			 * Show page
			 */
			show() {
				this.pageLoading = true;

				this.$http
					.get(this.createShowPageLink())
					.then(response => {
						this.form = response.body.data;

						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			}

		},
		mounted() {
			this.show();
		},
	}
</script>
