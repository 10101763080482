<template>
    <div class="crud-header">
        <div class="title">
            {{ cardCrudTitle() }}
        </div>
        <div class="actions">
            <el-tooltip content="Geri" placement="bottom">
                <el-button class="flat dark grey" icon="el-icon-back" @click="goIndexPage" />
            </el-tooltip>

            <el-tooltip content="Kaydet" placement="bottom" v-if="!$route.params.id && !disable">
                <el-button class="flat dark green" icon="el-icon-check" @click="handleClickStore" v-loading="pageLoading" />
            </el-tooltip>

            <el-tooltip content="Ekle" placement="bottom" v-if="$route.params.id && !$route.meta.createDisable && !disable">
                <el-button class="flat dark blue" icon="el-icon-plus" @click="goCreatePage" />
            </el-tooltip>

            <el-tooltip content="Guncelle" placement="bottom" v-if="$route.params.id && !disable">
                <el-button class="flat dark green crud-edit-button" icon="el-icon-check" @click="handleClickUpdate" v-loading="pageLoading" />
            </el-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CrudHeader',
    props: {
        store: null,
        update: null,
        form: {},
        disable : false,
        storeCall : null
    },
    data() {
        return {
            pageLoading : false
        }
    },
    methods: {

        /**
         * Store button click
         */
        handleClickStore() {
            if (this.store) {
                return this.store()
            }

            return this.storeForm()
        },

        /**
         * Update button click
         */
        handleClickUpdate() {
            if (this.update) {
                return this.update()
            }

            return this.updateForm()
        },

        /**
         * Form save
         */
        storeForm() {
            this.pageLoading = true;

            this.$http
                .post(this.generateApiPointWithAlias(this.$route.meta.apiPoint), this.form)
                .then(response => {
                    this.pageLoading = false;

                    if (this.storeCall) {
                        return this.storeCall(response.body)
                    }

                    if (response.body.data && response.body.data.id) {
                        this.goShowPage(response.body.data.id);
                    }

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        updateForm() {
            this.pageLoading = true;

            this.$http
                .put(this.createShowPageLink(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
