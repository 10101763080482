<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list overflow-400">
			<template slot="header">
				<div class="title">
					Adimlar
				</div>
				<div class="actions">
					<el-tooltip content="Ekle" placement="left">
						<el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
					</el-tooltip>
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text cursor-pointer" @click="edit(item.id)">
						<div class="title">{{ item.title }}</div>
					</div>
					<div class="actions" style="right: 20px">
						<el-dropdown @command="handleRowActionCommand">
							<el-button type="text" class="flat dark grey" icon="el-icon-arrow-down" />
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{type : 'edit', id : item.id}">
									<em class="el-icon-edit text-green"></em> Duzenle
								</el-dropdown-item>
								<el-dropdown-item :command="{type : 'destroy', id : item.id}">
									<em class="el-icon-delete text-red"></em> Sil
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog title="Adim Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" class="custom-padding">
			<el-form :label-position="'top'" ref="form" :model="form">
                <el-card shadow="never" class="no-shadow custom-padding">
                    <div class="card-sub-title">Genel</div>
                    <div class="card-custom-padding-box-items">
                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık" />
                        </el-form-item>

                        <el-form-item label="Siralama">
                            <el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
                        </el-form-item>

                        <el-form-item>
                            <el-switch v-model="form.is_active" active-text="Aktif" />
                        </el-form-item>
                    </div>

                    <div class="card-sub-title">Resimler</div>
                    <div class="card-custom-padding-box-items">
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="Resim">
                                    <fs v-model="form.file.path" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
					Kaydet
				</el-button>
				<el-button class="fluid flat dark green large" @click="update" v-if="form.id">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'FeatureItem',
        props: ['id'],
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    file: {},
                },

                items: [],
            }
        },
        mounted() {
            this.index();
        },
        methods: {

            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = 'cms/features/' + this.id + '/items';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params: {perPage: 1000}})
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {
                    file : {
                        //
                    }
                };
                this.dialogVisible = true;
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {
                    file : {
                        //
                    }
                };
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        if (!this.form.file) {
                            this.form.file = {
                                //
                            }
                        }

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch(() => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            /**
             * Item dropdown actions
             *
             * @param command
             */
            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroy(command.id);
                }

                this.edit(command.id);
            }
        }
    }
</script>
