<template>
	<div v-loading="itemLoading">
		<el-form :label-position="'top'" ref="form" :model="form">
			<el-form-item label="Limit">
				<el-input v-model="block.meta.limit" placeholder="Limit" type="number"/>
			</el-form-item>

			<el-form-item label="Buton Baslik">
				<el-input v-model="block.meta.button_title" placeholder="Buton Baslik"/>
			</el-form-item>

			<el-form-item label="Buton Link">
				<el-input v-model="block.meta.button_url" placeholder="Buton Link"/>
			</el-form-item>

			<el-form-item label="Baslik">
				<el-input v-model="block.meta.ui_title" placeholder="Baslik"/>
			</el-form-item>

			<el-form-item label="Aciklama" class="is-editor">
				<editor :container="block.meta" model="ui_description" :small="true" />
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		name: 'Feedbacks',
		props: ['block', 'items'],
		data() {
			return {
				itemLoading: true,
				tabValue: 'key-0',
				categories: [],
				form: {
					//
				}
			}
		},
		created() {
			this.itemLoading = false;
		},
		methods: {

			getCategories() {
				//
			},

			createItem() {
				this.items.push({
					sort: 100,
					variables: {
						//
					}
				})
			},

			destroyRowItem(targetName) {

				let key = targetName;

				if (!key) {
					key = this.tabValue;
				}

				key = key.replace('key-', '');

				this
					.$confirm('Tab ' + key + ' kalici olarak silinecek!', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {

						this.items.splice(key, 1);

						return true;

					})
					.catch((response) => {
						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},
		}
	}
</script>