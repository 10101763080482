<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                yapim asamasinda
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../../Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },
        }
    },
    methods: {

        /**
         * Show page
         */
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },


    },
    mounted() {
        this.show();
    },
}
</script>