<template>
    <div class="catalog-page-detail-content-tab">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    <icon :icon="tab.icon" />
                    {{ tab.title }}
                </div>
            </template>

            <div class="card-sub-title">Renk Islemleri</div>
            <div class="card-custom-padding-box-items">
                <el-form-item label="Sablon">
                    <lv-select :options="layouts" :container="form" model="view_path" />
                </el-form-item>

                <el-form-item label="Renk">
                    <el-input v-model="form.meta.color" placeholder="Renk" />
                </el-form-item>
            </div>

            <div class="card-sub-title">Resim</div>
            <div class="card-custom-padding-box-items">
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item label="Ikon">
                            <fs v-model="form.meta.icon" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Listeleme Sayfasi">
                            <fs v-model="form.file.path" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Detay Sayfasi">
                            <fs v-model="form.meta.detail_img_path" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Video Kapak Resmi">
                            <fs v-model="form.meta.video_img" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>

            <video-form :container="form.meta" />
        </el-card>


        <Images :id="form.id" path="cms/products/contents" :size="6" />
    </div>
</template>

<script>
import Images from "@/app/Pages/Cms/Contents/Images";
export default {
    name: 'ProductImages',
    props: ['tab', 'form'],
    components: {
        Images
        //
    },
    data() {
        return {
            layouts: [
                //
            ],

            colors: [
                {
                    value: 'blue',
                    label: 'Mavi',
                },
                {
                    value: 'yellow',
                    label: 'Sarı',
                },
                {
                    value: 'orange',
                    label: 'Turuncu',
                },
                {
                    value: 'red',
                    label: 'Kirmizi',
                },
                {
                    value: 'purple',
                    label: 'Mor',
                },
                {
                    value: 'green',
                    label: 'Yeşil',
                },
                {
                    value: 'english',
                    label: 'English',
                },
                {
                    value: 'german',
                    label: 'German',
                },
                {
                    value: 'french',
                    label: 'French',
                },
                {
                    value: 'arabic',
                    label: 'Arabic',
                },
                {
                    value: 'russian',
                    label: 'Russian',
                },
                {
                    value: 'spanish',
                    label: 'Spanish',
                },
                {
                    value: 'dutch',
                    label: 'Dutch',
                },
                {
                    value: 'italian',
                    label: 'Italian',
                }
            ],
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
