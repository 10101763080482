<template>
	<draggable class="nested-items" tag="ul" :list="items" :group="{ name: 'g2' }">
		<li v-for="item in items" :key="item.id" class="nested-item">
			<div>
				<div class="content">
					<div class="text">
						<div class="media mr-2">
							<i class="mdi mdi-menu" />
						</div>
						<div>
							<div class="title">
								{{ item.title }}
							</div>
							<div class="link">
								<a :href="'https://' + $store.state.ui.tenant.domain + '/' + item.url" target="_blank">
									{{ item.url }}
								</a>
							</div>
						</div>
					</div>
					<div class="actions">
						<el-dropdown @command="handleRowActionCommand">
							<el-button type="text" class="flat dark grey" icon="el-icon-arrow-down" />
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{type : 'create', id : item.id}">
									<i class="el-icon-plus text-blue"></i> Ekle
								</el-dropdown-item>
								<el-dropdown-item :command="{type : 'edit', id : item.id}">
									<i class="el-icon-edit text-green"></i> Duzenle
								</el-dropdown-item>
								<el-dropdown-item :command="{type : 'destroy', id : item.id}">
									<i class="el-icon-delete text-red"></i> Sil
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
			<menu-link-nested :items="item.nested" :handleRowActionCommand="handleRowActionCommand" />
		</li>
	</draggable>
</template>
<script>
    import draggable from "vuedraggable";

    export default {
        name: 'menu-link-nested',
        components: {
            draggable
        },
        props: {
            items: {
                required: true,
                type: Array
            },
            handleRowActionCommand : null,
        },
        methods: {

        }
    };
</script>
