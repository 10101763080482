<template>
    <div class="empty">
        <div class="media">
           <icon icon="emoticon-sad-outline" />
        </div>
        <div class="text">
            <div class="title">{{ title }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Empty',
    props: {
        title: {
            default: 'Sonuc bulunamadi!'
        }
    },
};
</script>
