<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    <icon icon="video" />
                    Video / Servis Islemleri
                </div>
            </template>
        </el-card>
        <el-card class="custom-padding">
            <div class="box-list">
                <div class="box-list-item">
                    <div class="name">Uuid</div>
                    <div class="description">{{ form.uuid }}</div>
                </div>
                <div class="box-list-item">
                    <div class="name">Servis Id</div>
                    <div class="description">{{ form.service_id }}</div>
                </div>
                <div class="box-list-item">
                    <div class="name">Password</div>
                    <div class="description">{{ form.service_password }}</div>
                </div>
                <div class="box-list-item">
                    <div class="name">Video Turu</div>
                    <div class="description">{{ form.record_type }}</div>
                </div>
                <div class="box-list-item">
                    <div class="name">Ders Kayıt Durumu</div>
                    <div class="description">{{ form.record_status }}</div>
                </div>
                <div class="box-list-item">
                    <div class="name">Cdn Ders Kayıt Uuid</div>
                    <div class="description">
                        {{ form.cdn_record_id }}
                    </div>
                </div>
                <div class="box-list-item">
                    <div class="name">Cdn Ders Kayıt Url</div>
                    <div class="description">
                        <el-button type="text" class="flat dark blue" icon="el-icon-view" @click="openBlankWindow(form.cdn_record_link)"/>
                    </div>
                </div>
            </div>
        </el-card>

        <VideoPlayer :duration="player.duration" :start="player.startTime" :video-src="form.cdn_record_link" :pins="[]" :auto-play="player.autoPlay"/>
    </div>
</template>

<script>
import VideoPlayer from "@/app/Components/VideoPlayer";

export default {
    name: 'LessonRecord',
    props: ['form'],
    components: {
        VideoPlayer
    },
    data() {
        return {
            pageLoading: false,
            player: {
                startTime: 0,
                autoPlay: false,
                duration: 120,
            },
        }
    },
    methods: {
        //
    }
}
</script>
