<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<el-form-item label="Bagli Oldugu Tanim">
					<el-select v-model="form.parent_id" placeholder="Bagli Oldugu Tanim" clearable filterable>
						<el-option v-for="item in items" :key="item.id" :label="item.title" :value="item.id" />
					</el-select>
				</el-form-item>
				<el-form-item label="Baslik">
					<el-input v-model="form.title" placeholder="Baslik" />
				</el-form-item>
				<el-form-item label="Kisa Baslik">
					<el-input v-model="form.meta.sort_title" placeholder="Kisa Baslik" />
				</el-form-item>
				<div v-if="$route.meta.alias === 'tax-classes'">
					<el-form-item label="Deger">
						<el-input v-model="form.meta.value" placeholder="Deger"/>
					</el-form-item>
				</div>
				<el-form-item label="Siralama">
					<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
				</el-form-item>
				<el-form-item>
					<el-switch v-model="form.is_active" active-text="Aktif" />
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
		},
        data() {
            return {
                pageLoading: false,
                form: {
                    meta : {
                        //
                    }
                },

				items : [],
            }
        },
        methods: {
            getDefinitions() {
                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.apiPoint, {params : {perPage : 1000, view : 'dropdown'}})
                    .then(response => {
                        this.items = response.body.data;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
			},

            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
						this.pageLoading = false;
                        this.form = response.body.data;

						if (!this.lodash.size(this.form.meta)) {
							this.form.meta = {};
						}
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },
        },
        mounted() {
            if (this.$route.params.id) {
                this.show();
            }

            this.getDefinitions();
        },
    }
</script>
