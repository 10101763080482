<template>
    <div>
        <el-row :gutter="24" v-if="process === 'choice'">
            <el-col :span="12">
                <el-button class="modern fluid large" icon="el-icon-plus" style="min-height: 300px; font-size: 24px"
                           @click="process = 'create'">
                    Toplu Ders Ekle
                </el-button>
            </el-col>
            <el-col :span="12">
                <el-button class="modern fluid large green" icon="el-icon-edit"
                           style="min-height: 300px; font-size: 24px" @click="process = 'edit'">
                    Toplu Ders Guncelle
                </el-button>
            </el-col>
        </el-row>

        <LessonManagementCreate v-if="process === 'create'" :processHandle="changeProcess" />
        <LessonManagementEdit v-if="process === 'edit'" :processHandle="changeProcess" />
    </div>
</template>

<script>
import LessonManagementCreate from "./Create";
import LessonManagementEdit from "./Edit";

export default {
    name: 'LessonManagementIndex',
    components: {
        LessonManagementEdit,
        LessonManagementCreate
    },
    data() {
        return {
            process: 'choice',
        }
    },
    methods: {
        changeProcess(value) {
            this.process = value
        }
    }
}
</script>