<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">

				<el-form-item label="Adres Turu">
					<lv-select :options="addressTypes" :container="form" model="address_type" />
				</el-form-item>

				<el-form-item label="Adres Başlığı">
					<el-input v-model="form.title" placeholder="Adres Başlığı" />
				</el-form-item>

				<div v-show="form.address_type === 'company'">
					<el-form-item label="Firma Adi">
						<el-input v-model="form.company_name" placeholder="Firma Adi" />
					</el-form-item>

					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item label="Vergi Numarasi">
								<el-input v-model="form.tax_no" placeholder="Vergi Numarasi" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Vergi Dairesi">
								<el-input v-model="form.tax_center" placeholder="Vergi Dairesi" />
							</el-form-item>
						</el-col>
					</el-row>
				</div>

				<div v-show="form.address_type === 'customer' || !form.address_type">
					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item label="Adı">
								<el-input v-model="form.first_name" placeholder="Adı" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Soyadı">
								<el-input v-model="form.last_name" placeholder="Soyadı" />
							</el-form-item>
						</el-col>
					</el-row>

					<el-form-item label="T.C. No">
						<el-input v-model="form.identification_no" placeholder="T.C. No" v-mask="'###########'" />
					</el-form-item>
				</div>

				<el-form-item label="Telefon">
					<el-input v-model="form.phone" placeholder="Telefon" v-mask="'0 (###) ### ## ##'" />
				</el-form-item>

				<el-form-item label="Ülke">
					<el-select v-model="form.country_zone_id" placeholder="Ülke seçiniz" @change="handleChangeZone('country')" clearable>
						<el-option v-for="item in countries" :key="item.id" :label="item.title" :value="item.id" />
					</el-select>
				</el-form-item>

				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="Şehir">
							<el-select v-model="form.city_zone_id" placeholder="Şehir seçiniz" filterable @change="handleChangeZone('city')" clearable>
								<el-option v-for="item in cities" :key="item.id" :label="item.title" :value="item.id" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="İlçe">
							<el-select v-model="form.town_zone_id" placeholder="İlçe seçiniz" filterable @change="handleChangeZone('town')" clearable>
								<el-option v-for="item in towns" :key="item.id" :label="item.title" :value="item.id" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>

				<el-form-item label="Adres">
					<el-input v-model="form.address_1" placeholder="Adres" />
				</el-form-item>

				<el-form-item label="Adres 2">
					<el-input v-model="form.address_2" placeholder="Adres 2" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_main" active-text="Varsayılan Iletisim Adresi" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_active" active-text="Aktif" />
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import CrudHeader from "../../Components/CrudHeader";

	export default {
		name: 'CrudItem',
		components: {
			CrudHeader
		},
		data() {
			return {
				pageLoading: false,
				form: {
					//
				},

				countries: [],
				cities: [],
				towns: [],
				districts: [],
				neighborhoods: [],

				addressTypes: [
					{
						value: 'customer',
						label: 'Sahis',
					},
					{
						value: 'company',
						label: 'Sirket',
					},
				],
			}
		},

		mounted() {
			if (this.$route.params.id) {
				this.show();
			}
		},
		methods: {

			/**
			 * Show page
			 */
			show() {
				this.pageLoading = true;

				this.$http
					.get(this.createShowPageLink())
					.then(response => {
						this.form = response.body.data;

						this.pageLoading = false;
						this.setZones();
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			setZones() {
				this.getZones('countries', 0);

				if (this.form.country_zone_id) {
					this.getZones('cities', this.form.country_zone_id);
				}

				if (this.form.city_zone_id) {
					this.getZones('towns', this.form.city_zone_id);
				}

				if (this.form.district_zone_id) {
					this.getZones('neighborhoods', this.form.district_zone_id);
				}
			},

			getZones(alias, parentId) {

				this.dialogLoading = true;

				this.$http
					.get('tools/search/zones', {
						params: {
                            parent_id: parentId,
						}
					})
					.then(response => {
						this[alias] = response.body.data;
						this.dialogLoading = false;
					})
					.catch((e) => {
						this.dialogLoading = false;
						return this.responseError(e);
					});
			},

            handleChangeZone(type) {
                switch (type) {

                    case 'country':

                        this.cities = [];
                        this.towns = [];
                        this.districts = [];
                        this.neighborhoods = [];

                        this.form.city_zone_id = null;
                        this.form.town_zone_id = null;
                        this.form.district_zone_id = null;
                        this.form.neighborhood_zone_id = null;

                        this.getZones('cities', this.form.country_zone_id)

                        break;

                    case 'city':

                        this.towns = [];
                        this.districts = [];
                        this.neighborhoods = [];

                        this.form.town_zone_id = null;
                        this.form.district_zone_id = null;
                        this.form.neighborhood_zone_id = null;

                        this.getZones('towns', this.form.city_zone_id)

                        break;

                    case 'town':
                        this.districts = [];
                        this.neighborhoods = [];

                        this.form.district_zone_id = null;
                        this.form.neighborhood_zone_id = null;

                        this.getZones('neighborhoods', this.form.town_zone_id)

                        break;

                    case 'district':
                        this.neighborhoods = [];

                        this.form.neighborhood_zone_id = null;

                        this.getZones('neighborhoods', this.form.town_zone_id)

                        break;

                    default :
                        this.towns = [];
                        this.districts = [];
                        this.neighborhoods = [];

                        this.form.town_zone_id = null;
                        this.form.district_zone_id = null;
                        this.form.neighborhood_zone_id = null;

                        break;
                }
            },
		}
	}
</script>