<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">

				<el-form-item label="Baslik">
					<el-input v-model="form.title" placeholder="Baslik" />
				</el-form-item>

				<el-form-item label="Deger">
					<el-input v-model="form.conversion_rate" placeholder="Deger" />
				</el-form-item>

				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="ISO Kod">
							<el-input v-model="form.iso_code" placeholder="ISO Kod" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="ISO Kod Numeric">
							<el-input v-model="form.iso_code_num" placeholder="ISO Kod Numeric" />
						</el-form-item>
					</el-col>
				</el-row>

				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="Sembol">
							<el-input v-model="form.symbol" placeholder="Sembol" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Format">
							<el-input v-model="form.format" placeholder="Format" />
						</el-form-item>
					</el-col>
				</el-row>

				<el-form-item label="Virgülden Sonra Kaç Basamak">
					<el-input v-model="form.step" placeholder="Virgülden Sonra Kaç Basamak" type="number" />
				</el-form-item>

				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="Ondalık Ayracı">
							<el-input v-model="form.decimal" placeholder="Ondalık Ayracı" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Binler Ayracı">
							<el-input v-model="form.thousand" placeholder="Binler Ayracı" />
						</el-form-item>
					</el-col>
				</el-row>

				<el-form-item label="Siralama">
					<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_active" active-text="Aktif" />
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
		},
        data() {
            return {
                pageLoading: false,
                form: {
                    //
                },
            }
        },
        methods: {

            /**
             * Show page
             */
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },
        },
        mounted() {
            if (this.$route.params.id) {
                this.show();
            }
        },
    }
</script>
