<template>
	<div class="card-custom-padding-box margin-0">
		<div class="card-sub-title">Magaza</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Site Adı">
				<el-input v-model="form.site_name" placeholder="Site Adı" />
			</el-form-item>

			<el-form-item label="Firma Adi">
				<el-input v-model="form.site_real_name" placeholder="Firma Adi" />
			</el-form-item>

			<el-form-item label="Site Sahibi">
				<el-input v-model="form.site_owner" placeholder="Site Sahibi" />
			</el-form-item>

			<el-form-item label="Web Site">
				<el-input v-model="form.site_website" placeholder="Web Site" />
			</el-form-item>

            <el-form-item label="Site Email">
                <el-input v-model="form.site_email" placeholder="Site Email" />
            </el-form-item>

			<el-row :gutter="24">
				<el-col :span="12">
					<el-form-item label="Gsm">
						<el-input v-model="form.site_gsm" placeholder="Gsm" />
					</el-form-item>
				</el-col>
                <el-col :span="12">
                    <el-form-item label="Whatsapp">
                        <el-input v-model="form.whatsapp" placeholder="Whatsapp" />
                    </el-form-item>
                </el-col>
				<el-col :span="12">
					<el-form-item label="Telefon">
						<el-input v-model="form.site_phone" placeholder="Telefon" />
					</el-form-item>
				</el-col>
                <el-col :span="12">
                    <el-form-item label="Fax">
                        <el-input v-model="form.site_fax" placeholder="Fax" />
                    </el-form-item>
                </el-col>
			</el-row>
			<el-form-item label="Adres">
				<el-input v-model="form.site_address" placeholder="Adres" />
			</el-form-item>

			<el-form-item label="Site GeoLocation">
				<el-input v-model="form.site_geolocation" placeholder="Site GeoLocation" />
			</el-form-item>
		</div>
		<div class="card-sub-title">Görünüm Ayarları</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Varsayilan Tema">
				<el-select v-model="form.theme_id" placeholder="Select">
					<el-option v-for="theme in themes" :key="theme.id" :label="theme.title" :value="theme.id" />
				</el-select>
			</el-form-item>
		</div>
		<div class="card-sub-title">Seo</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Meta Title">
				<el-input v-model="form.meta_title" placeholder="Meta Title" maxlength="60" show-word-limit />
			</el-form-item>

			<el-form-item label="Meta Description">
				<el-input v-model="form.meta_description" placeholder="Meta Description" type="textarea" maxlength="160" show-word-limit />
			</el-form-item>

			<el-form-item label="Meta Keywords">
				<el-input v-model="form.meta_keywords" placeholder="Meta Keywords" type="textarea" maxlength="60" show-word-limit />
			</el-form-item>

			<el-form-item label="Meta Owner">
				<el-input v-model="form.meta_author" placeholder="Meta Owner" />
			</el-form-item>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SiteSetting',
		props: ['form', 'themes'],
		data() {
			return {
				//
			}
		},
		mounted() {
			//
		},
		methods: {
			//
		}
	}
</script>
