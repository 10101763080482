<template>
    <el-select v-model="container[model]" :placeholder="placeholder ? placeholder : 'Seciminiz'" filterable clearable :loading="loading" class="user-search" @change="handleChange">
        <el-option class="user-search-item" v-for="item in items" :key="item.id" :label="item.name" :value="item.id">
            <div class="user-search-item-container">
                <div>
                    <pic :src="item.avatar" type="avatar" />
                </div>
                <div>
                    <div class="title">{{ item.name }}</div>
                    <div class="mini">{{ item.email }}</div>
                    <div class="mini">{{ item.phone }}</div>
                </div>
            </div>
        </el-option>
        <template slot="prefix">
            <i class="el-input__icon el-icon-user"></i>
        </template>
    </el-select>
</template>

<script>
export default {
    name: 'Icon',
    props: ['role', 'container', 'model', 'change', 'placeholder'],
    data() {
        return {
            loading: false,
            items : []
        }
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        getUsers() {
            this.loading = true;

            this
                .$http
                .get('crm/users', {
                    params: {
                        perPage: 1000,
                        filters: [
                            {
                                relation: 'role.role',
                                inputs: [
                                    {
                                        name: 'alias',
                                        field: 'alias',
                                        type: 'singleSelect',
                                        value: this.role
                                    }
                                ]
                            },
                        ]
                    }
                })
                .then(response => {
                    this.loading = false;
                    this.items = response.body.rows;
                })
                .catch((e) => {
                    this.loading = false;
                    this.responseError(e);
                });
        },

        handleChange(event) {
            if (!this.change) {
                return false;
            }

            this.change(this.container);
        }
    }
};
</script>

<style lang="scss">
.user-search-item {
    height      : auto;
    line-height : normal;
    padding     : 10px 20px;
    line-height : normal !important;

    .user-search-item-container {
        display     : flex;
        align-items : center;

        img {
            height       : 45px;
            width        : 45px;
            margin-right : 20px;
        }

        .title {
            font-weight : 500;
        }

        .mini {
            font-size : 13px;
        }
    }

}
</style>