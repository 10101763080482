<template>
	<div v-loading="pageLoading">
        <div class="ui message blue">
            Eger girdiginiz sayfa sayisi 1 den fazla ise, tasarimi okuma kitabi olarak gorunur.
        </div>

		<el-row :gutter="24">
            <el-col :span="16">
                <template v-if="form.id">
                    <el-card class="custom-padding">
                        <template slot="header">
                            <div class="crud-header">
                                <div class="title">
                                    Sayfa Bilgileri
                                </div>
                                <div class="actions">
                                    <el-tooltip content="Kaydet" placement="left">
                                        <el-button class="flat dark green" icon="el-icon-check" @click="update" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                        <el-form :label-position="'top'" ref="form" :model="form" :key="form.id">
                            <div class="card-sub-title">Metin</div>
                            <div class="card-custom-padding-box-items">
                                <el-form-item label="Baslik">
                                    <el-input v-model="form.title" placeholder="Baslik" />
                                </el-form-item>

                                <el-form-item label="Icerik" class="is-editor">
                                    <editor :container="form" model="description" :small="true" />
                                </el-form-item>

                                <el-form-item label="Ceviri" class="is-editor">
                                    <editor :container="form" model="translation" :small="true" />
                                </el-form-item>
                            </div>
                            <div class="card-sub-title">Medya</div>
                            <div class="card-custom-padding-box-items">
                                <el-form-item label="Ses Dosyasi">
                                    <fs v-model="form.file.path" :isFile="true" />
                                </el-form-item>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="Görsel" class="margin-bottom-0">
                                            <fs v-model="form.meta.show_cover" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-form>
                    </el-card>
                </template>

                <template v-if="!form.id">
                    <empty title="Sayfa bilgilerini goruntuleye bilmek icin sol taraftan sayfa secimi yapmaniz gerekiyor." />
                </template>
            </el-col>
            <el-col :span="8">
                <el-card class="custom-padding">
                    <template slot="header">
                        <div class="crud-header">
                            <div class="title">
                                Sayfalar
                            </div>
                            <div class="actions">
                                <el-tooltip content="Sayfa Ekle" placement="left">
                                    <el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>

                    <div class="box-list">
                        <template v-for="(page, index) in items">
                            <div class="box-list-item" :key="index">
                                <div class="name fluid">
                                    {{ page.title }}
                                </div>
                                <div class="description">
                                    <el-tooltip content="Duzenle" placement="left">
                                        <el-button class="flat dark green" icon="el-icon-edit" @click="edit(page.id)" />
                                    </el-tooltip>
                                    <el-tooltip content="Sil" placement="left">
                                        <el-button class="flat dark red" icon="el-icon-delete" @click="destroy(page.id)" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog title="Sayfa Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="70%" class="custom-padding">
            <el-form :label-position="'top'" ref="form" :model="form" :key="form.id">
                <el-card class="no-shadow custom-padding">
                    <div class="card-sub-title">Metin</div>
                    <div class="card-custom-padding-box-items">
                        <el-form-item label="Baslik">
                            <el-input v-model="form.title" placeholder="Baslik" />
                        </el-form-item>

                        <el-form-item label="Aciklama" class="is-editor">
                            <editor :container="form" model="description" :small="true" />
                        </el-form-item>

                        <el-form-item label="Ceviri" class="is-editor">
                            <editor :container="form" model="translation" :small="true" />
                        </el-form-item>
                    </div>
                    <div class="card-sub-title">Medya</div>
                    <div class="card-custom-padding-box-items">
                        <el-form-item label="Ses Dosyasi">
                            <fs v-model="form.file.path" :isFile="true" />
                        </el-form-item>

                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="Görsel" class="margin-bottom-0">
                                    <fs v-model="form.meta.show_cover" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-form>
            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store">
                    Kaydet
                </el-button>
            </template>
        </el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'ReadingPage',
        props : ['id'],
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    file : {
                        path : null
                    },
                    meta : {
                        //
                    }
                },

                items: [],
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = this.$route.meta.apiPoint + '/' + this.id + '/pages';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                    .then(response => {
                        this.items = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {
                    file : {
                        path : null
                    },
                    meta : {
                        //
                    }
                };
                this.dialogVisible = true;
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {
                    file : {
                        path : null
                    },
                    meta : {
                        //
                    }
                };
                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.pageLoading = false;
                        this.form = response.body.data;

                        if (!this.lodash.size(this.form.meta)) {
                            this.form.meta = {
                                //
                            };
                        }

                        if (!this.form.file) {
                            this.form.file = {
                                //
                            };
                        }
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();
                                this.form = {
                                    //
                                }

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },
        }
    }
</script>