<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    <icon icon="cast-connected"/>
                    Kullanicilar [{{ elementSize(items) }}]
                </div>
                <div class="actions">
                    <el-tooltip content="Misafir Ogrenci Ekle" placement="top">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="create"/>
                    </el-tooltip>
                    <el-tooltip content="Yenile" placement="top">
                        <el-button class="flat dark purple" icon="el-icon-refresh" @click="index"/>
                    </el-tooltip>
                </div>
            </template>
        </el-card>
        <div class="lesson-users">
            <template v-for="(item, index) in items">
                <div class="lesson-user" :key="index" :class="{'bg-red-opack' : !item.service_id}"
                     v-loading="item.loading">
                    <div class="media">
                        <pic type="avatar" :src="item.avatar"/>
                        <div class="caption" v-if="item.is_host">
                            <el-tooltip content="Ogretmen" align="bottom">
                                <icon icon="account-tie-voice-outline"/>
                            </el-tooltip>
                        </div>
                        <div class="caption blue" v-if="item.type === 'visitor'">
                            <el-tooltip content="Misafir Ogrenci" align="bottom">
                                <icon icon="account-supervisor-circle-outline"/>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="text">
                        <div class="title">{{ item.name }}</div>
                        <div class="description">
                            {{ item.email }}
                        </div>
                    </div>
                    <div class="actions">
                        <el-tooltip content="Servis Kaydi Yaptir" placement="top">
                            <el-button class="flat dark green" icon="el-icon-check" v-if="!item.service_id && lessonServiceId" @click="registrationStore(item)"/>
                        </el-tooltip>

                        <el-tooltip content="Sil" placement="top">
                            <el-button class="flat dark red" icon="el-icon-delete" v-if="item.type === 'visitor'" @click="destroy(item.db_id)"/>
                        </el-tooltip>

                        <el-tooltip content="Duzenle" placement="top">
                            <el-button class="flat dark blue" icon="el-icon-edit" @click="visibleInfo(item)" v-if="!item.is_teacher"/>
                        </el-tooltip>
                    </div>
                </div>
            </template>
        </div>

        <el-dialog title="Misafir Ogrenci Bilgileri" :visible.sync="createDialogVisible" width="40%">
            <el-form :label-position="'top'" ref="createForm" :model="createForm">
                <el-form-item class="margin-bottom-0">
                    <user :container="createForm" model="user_id" placeholder="Ogrenci"/>
                </el-form-item>
            </el-form>
            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" :loading="createLoading"
                           :disabled="createLoading">
                    Kaydet
                </el-button>
            </template>
        </el-dialog>

        <el-dialog title="Ogrenci Bilgileri" :visible.sync="infoDialogVisible" width="60%" class="custom-padding">
            <el-form :label-position="'top'" ref="form" name="form" :model="infoData" :key="infoData.id">
                <el-card class="custom-padding">
                    <div class="card-sub-title first">Servis Bilgileri</div>
                    <div class="card-custom-padding-box-items">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Servis Id">
                                    <el-input v-model="infoData.service_id" placeholder="Servis Id"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Servis UId">
                                    <el-input v-model="infoData.service_uid" placeholder="Servis UId"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Token">
                                    <el-input v-model="infoData.service_token" placeholder="Token"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Sifre">
                                    <el-input v-model="infoData.service_password" placeholder="Sifre"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="Link">
                            <el-input v-model="infoData.service_live_url" placeholder="Link"/>
                        </el-form-item>
                    </div>
                    <div class="card-sub-title">Ogretmen Degerlendirmesi</div>
                    <div class="card-custom-padding-box-items">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Grammer">
                                    <el-input v-model="infoData.rating.grammar" placeholder="grammar" type="number"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Writing">
                                    <el-input v-model="infoData.rating.writing" placeholder="writing" type="number"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Listening">
                                    <el-input v-model="infoData.rating.listening" placeholder="listening"
                                              type="number"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Speaking">
                                    <el-input v-model="infoData.rating.speaking" placeholder="speaking" type="number"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="Vocabulary">
                                    <el-input v-model="infoData.rating.vocabulary" placeholder="vocabulary"
                                              type="number"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="Ogretmen Mesaji">
                                    <el-input v-model="infoData.rating.message" placeholder="Ogretmen Mesaji"
                                              type="textarea" rows="4"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="registrationUpdate" :loading="infoDialogLoading">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'LessonUsers',
    props: ['lessonId', 'lessonServiceId'],
    data() {
        return {
            pageLoading: false,
            items: [],
            form: {
                //
            },

            infoDialogVisible: false,
            infoDialogLoading: false,
            infoData: {
                rating : {
                    //
                }
            },

            createDialogVisible: false,
            createLoading: false,
            createForm: {
                //
            }
        }
    },

    mounted() {
        this.index();
    },
    methods: {
        registrationEndPoint(suffix) {

            const path = 'educations/lessons/' + this.lessonId + '/registrations';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        visitorEndPoint(suffix) {

            const path = 'educations/lessons/' + this.lessonId + '/visitors';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        index() {
            this.pageLoading = true;

            this.$http
                .get(this.registrationEndPoint())
                .then(response => {

                    this.items = response.body.rows;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        create() {
            this.createForm = {
                //
            };
            this.createDialogVisible = true;
        },

        store() {
            this.createLoading = true;

            this.$http
                .post(this.visitorEndPoint(), this.createForm)
                .then(response => {
                    this.createLoading = false;
                    this.createDialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.createLoading = false;
                    return this.responseError(response);
                })
        },

        registrationStore(item) {
            item.loading = true;

            this.$http
                .post(this.registrationEndPoint(), {user_id: item.id})
                .then(response => {
                    item.loading = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    item.loading = false;
                    return this.responseError(response);
                })
        },

        registrationUpdate() {
            this.infoDialogLoading = true;

            this.$http
                .put(this.registrationEndPoint(this.infoData.db_id), this.infoData)
                .then(response => {
                    this.infoDialogLoading = false;
                    this.infoDialogVisible = false;
                    this.index();
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.infoDialogLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    this.$http
                        .delete(this.visitorEndPoint(id))
                        .then(response => {
                            this.index();
                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });
                    return true;

                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        visibleInfo(item) {
            this.infoData = item;
            this.infoDialogVisible = true;
        },

        exportUser() {
            this.pageLoading = true;

            this.$http
                .get(this.registrationEndPoint(), {
                    params: {
                        process: 'export'
                    }
                })
                .then(response => {
                    this.pageLoading = false;
                    this.openBlankWindow(response.body.data.link)
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
