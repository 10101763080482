<template>
    <div>
        <el-form-item label="Meta Title">
            <el-input v-model="container.meta_title" placeholder="Meta Title" maxlength="60" show-word-limit prefix-icon="mdi mdi-subtitles-outline"/>
        </el-form-item>

        <el-form-item label="Link">
            <el-input v-model="container.slug" placeholder="Link" @change="checkSlug">
                <template slot="prepend">{{ 'https://' + $store.state.ui.tenant.domain }}</template>
                <el-tooltip content="Link degerini Meta Title'a gore duzenle" placement="left" slot="append">
                    <el-button icon="el-icon-refresh" style="width: 55px; font-size: 22px; line-height: 55px;" @click="resetSlug"/>
                </el-tooltip>
            </el-input>
        </el-form-item>

        <el-form-item label="Meta Description">
            <el-input v-model="container.meta_description" placeholder="Meta Description" type="textarea" maxlength="160" show-word-limit />
        </el-form-item>

        <el-form-item label="Meta Keywords">
            <el-input v-model="container.meta_keywords" placeholder="Meta Keywords" maxlength="60" show-word-limit prefix-icon="mdi mdi-tag-multiple-outline" />
        </el-form-item>

        <el-form-item class="margin-bottom-0">
            <el-switch v-model="container.meta_no_index" active-text="Arama Motorunda Gözükmesin" />
        </el-form-item>

        <el-form-item>
            <el-switch v-model="container.meta_no_follow" active-text="Linkleri Takip Etme" />
        </el-form-item>

        <el-card class="google-seo-preview is-bordered margin-bottom-0" v-if="container.meta_title" style="padding: 20px">
            <div class="title">{{ container.meta_title }}</div>
            <div class="description">{{ container.meta_description }}</div>
            <div class="link" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/' + container.slug)">{{ appConfig.frontPath }}/{{ container.slug }}</div>
        </el-card>
    </div>
</template>

<script>

require('../utils/Urlify');

export default {
    name: 'Seo',
    props: ['container'],
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        resetSlug() {
            this.container.slug = URLify(this.container.meta_title);
        },

        checkSlug() {
            this.container.slug = URLify(this.container.slug);
        }

    },
};
</script>
