<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<div class="card-sub-title">Genel Bilgiler</div>
				<div class="padding-20">
					<el-row :gutter="24">
						<el-col :span="20">
							<el-form-item label="Kullanici Adi">
								<el-input v-model="form.name" placeholder="Kullanici Adi" />
							</el-form-item>
							<el-form-item label="E-Posta Adresi">
								<el-input v-model="form.email" placeholder="E-Posta Adresi" />
							</el-form-item>
							<el-form-item label="Telefon Numarasi">
								<el-input v-model="form.phone" placeholder="Telefon Numarasi" />
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="Avatar">
								<fs v-model="form.avatar"/>
							</el-form-item>
						</el-col>
					</el-row>
				</div>

				<div class="card-sub-title">Sifre Bilgileri</div>
				<div class="padding-20">
					<el-form-item label="Sifre">
						<el-input v-model="form.password" placeholder="Sifre" />
					</el-form-item>
				</div>

				<div class="card-sub-title">Kisisel Bilgileri</div>
				<div class="padding-20">
					<el-form-item label="Doğum Günü">
						<el-date-picker v-model="form.birthday" format="d/M/yyyy" value-format="yyyy-MM-dd" type="date" placeholder="Tarih Seçiniz" />
					</el-form-item>

					<el-form-item label="Cinsiyet">
						<definitions alias="genders" :container="form" model="gender_definition_id"  />
					</el-form-item>

					<el-form-item label="Egitim Durumu">
						<definitions alias="educations" :container="form" model="education_definition_id"  />
					</el-form-item>

					<el-form-item label="Meslek">
						<definitions alias="jobs" :container="form" model="job_definition_id"  />
					</el-form-item>
				</div>

			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
		},
        data() {
            return {
                pageLoading: false,
                form: {
                    //
                },
            }
        },
        methods: {
			//
        },
        mounted() {
            //
        },
    }
</script>
