<template>
	<div>
		<tinymce v-model="container[model]" :api-key="token" :init="config" />

        <el-dialog width="90%" title="Dosya Yöneticisi" :visible.sync="fileManagerDialogVisible" append-to-body class="file-manager-dialog">
            <template slot="title">
                <div class="head">
                    <div class="title">Dosya Yöneticisi</div>
                    <div class="action">
                        <el-button class="modern green" @click="handleSelected">
                            Dosyayi Sec
                        </el-button>
                    </div>
                </div>
            </template>
            <div class="ui message yellow" style="margin: 0; border-radius: 0">
                Lutfen resim yuklerken, dosya formatini <b>.webp</b> cevirmeyi ve <b>boyutunu kucultmeyi</b> unutmayiniz
            </div>

            <file-manager :settings="fileManagerSettings" />
        </el-dialog>
	</div>
</template>

<script>
	import tinymce from '@tinymce/tinymce-vue'
	export default {
		name: 'Editor',
		props: ['container', 'model', 'small', 'mini', 'large'],
		components: {
			tinymce
		},
		data() {
			return {
				token: 'x90rn1iycrzopt9z5rrsnjbrg0y9rxiz2mh3g2kdu8ili7n2',
				config: {
					verify_html: false,
					entity_encoding: 'raw',
					extended_valid_elements: '*[*]',
					height: 400,
					menubar: false,
					forced_root_block: false,
                    deprecation_warnings: false,
					paste_as_text: true,
					plugins: 'paste preview autolink code image link media table hr lists wordcount textpattern noneditable quickbars',
                    toolbar: 'code | image media | bold italic underline strikethrough link | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent  | numlist bullist | forecolor backcolor | table',
					file_picker_callback: (callback) => {
						this.fileManagerDialogVisible = true;
						this.fileManagerDialogCallback = callback;
					},
                    selector : 'ads',
                    content_style : 'ads{width:320px;height:400px;display:flex;align-items:center;justify-content:center;background:#52576d;border-radius:6px;color:#111;flex-shrink:0;position:relative;margin-left:20px} ads[view="left"] {float : left;margin-right : 20px;}  ads[view="right"] {float : right;margin-left : 20px;} ads[view="full"] {width  : 100%; height : 90px;}'
				},
				fileManagerDialogVisible: false,
				fileManagerDialogCallback: null
			}
		},
		created() {

			this.config.height = 400;

            if (this.large) {
                this.config.height = 600;
            }

			if (this.small) {
				this.config.height = 200;
			}

			if (this.mini) {
				this.config.height = 120;
			}
		},
		methods: {
			handleSelected() {
				let files = this.$store.state.fm.left.selected.files;

				if (!this.lodash.size(files)) {
					return this.$notify
						.error({
							title: 'Upss!',
							message: 'Dosya secimi yapmaniz gerekiyor!',
							position: 'top-right'
						});
				}

				this.fileManagerDialogCallback(this.appConfig.s3Path + '/' + files[0]);
				this.fileManagerDialogVisible = false;
			}
		}
	};
</script>

<style>
	.tox {
		z-index : 9999 !important;
	}

	.tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
		z-index : 99999 !important;
	}

	.file-manager-dialog {
		z-index : 999999 !important;
	}
</style>
