import LayoutConfig from '../config/layout'

import Dashboard from '../app/Pages/Dashboard'

export default {
    path: '/dashboard',
    name: 'api.dashboard.index',
    component: Dashboard,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Dashboard',
    }
}