<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Baslik" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="20">
                        <el-form-item label="Aciklama">
                            <el-input v-model="form.description" type="textarea" placeholder="Aciklama"  />
                        </el-form-item>

                        <el-form-item label="Siralama">
                            <el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <fs v-model="form.file.path"/>
                    </el-col>
                </el-row>

                <el-form-item>
                    <el-switch v-model="form.is_active" active-text="Aktif" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                file : {}
            },
        }
    },
    methods: {

        /**
         * Show page
         */
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },


    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
}
</script>
