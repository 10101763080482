<template>
    <div class="book-directory" v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">
                        Dizin
                    </div>
                    <div class="actions">
                        <el-tooltip content="Kitap Pdf" placement="left">
                            <el-button class="flat dark violet" icon="el-icon-refresh" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/prints/educations/books/' + id)" />
                        </el-tooltip>
                        <el-tooltip content="Unite Ekle" placement="left">
                            <el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
                        </el-tooltip>
                    </div>
                </div>
            </template>
        </el-card>

        <template v-for="(unit, unitIndex) in items">
            <el-card class="custom-padding" :key="unitIndex">
                <template slot="header">
                    <div class="crud-header">
                        <div class="title">
                            {{ unit.title }}
                        </div>
                    </div>
                </template>
                <div class="box-list">
                    <div class="box-list-item box-list-item-title fs-14">
                        <div class="name">
                            {{ unit.publish_at }}
                        </div>
                        <div class="description d-flex flex-shrink justify-content-end">
                            <div class="meta cursor-pointer margin-right-20" @click="handleCreateSection(unit.id)">
                                <icon icon="plus" class="text-blue" /> Bolum Ekle
                            </div>
                            <div class="meta cursor-pointer margin-right-20" @click="edit(unit.id)">
                                <icon icon="book-edit-outline" class="text-green" /> Duzenle
                            </div>
                            <div class="meta cursor-pointer" @click="destroy(unit.id)">
                                <icon icon="trash-can-outline" class="text-red" /> Sil
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-list">
                    <BookDirectorySection :ref="'Section' + unit.id" :id="unit.id" :key="unit.id" :sections="unit.sections" :findExercise="findExercise" />
                </div>
            </el-card>
        </template>

        <el-dialog title="Unite Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="50%">
            <el-form :label-position="'top'" ref="form" :model="form" :key="form.id">
                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Baslik" />
                </el-form-item>

                <el-form-item label="Yayinlama Basligi">
                    <el-input v-model="form.public_title" placeholder="Yayinlama Basligi" />
                </el-form-item>

                <el-form-item label="Yayınlanma Tarihi">
                    <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayınlanma Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" />
                </el-form-item>

                <el-form-item label="Kisa Aciklama" class="is-editor margin-bottom-0">
                    <editor :container="form" model="short_description" :small="true" />
                </el-form-item>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import BookDirectorySection from "@/app/Pages/Education/Book/Section";
export default {
    name: 'BookDirectory',
    props: ['id', 'findExercise'],
    components: {
        BookDirectorySection
        //
    },
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            form: {
                //
            },

            items: [],
        }
    },
    mounted() {
        this.index()
    },
    methods: {
        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = this.$route.meta.apiPoint + '/' + this.id + '/units';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        /**
         * All items
         */
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                .then(response => {

                    this.items = response.body.rows;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         *  Create modal
         */
        create() {
            this.form = {
                //
            };
            this.dialogVisible = true;
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form save
         */
        store() {
            this.pageLoading = true;

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;

                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {
                            this.index();
                            this.responseSuccess(response);
                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        handleCreateSection(unitId) {
            this.$refs['Section' + unitId][0].create();
        }
    }
}
</script>
