<template>
    <div v-loading="pageLoading" class="order-create-page">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">Detay</div>
                </div>
            </template>

            <el-row :gutter="24">
                <el-col :span="8">
                    <div class="basket-customer">
                        <div class="card-sub-title">Musteri Bilgileri</div>
                        <div class="card-custom-padding-box-items">
                            <div class="margin-top-20">
                                <div class="title">Musteri</div>
                                <div class="description">{{ form.user.name }}</div>
                                <div class="email">{{ form.user.email }}</div>
                                <div class="phone">{{ form.user.phone }}</div>
                            </div>
                            <div class="margin-top-20" v-if="form.invoiceAddress">
                                <div class="title">Fatura Adresi</div>
                                <div class="description" >
                                    {{ form.invoiceAddress.title }}
                                </div>
                            </div>
                            <div class="margin-top-20"  v-if="form.deliveryAddress">
                                <div class="title">Teslimat Adresi</div>
                                <div class="description">
                                    {{ form.deliveryAddress.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="16">
                    <div class="card-sub-title">Urun Bilgileri</div>
                    <div>
                        <el-card class="card-flat-list">
                            <div class="card-flat-list-items sm ">
                                <div class="item" v-for="product in form.products" :key="product.id">
                                    <div class="text">
                                        <div class="title">{{ product.title }}</div>
                                        <div class="vertical-flex-items has-dimmer">
                                            <div v-if="product.teacher">{{ product.teacher.name }}</div>
                                            <div>{{ product.reference }}</div>
                                            <div>{{ product.quantity }}x {{ $n(product.total, 'currency') }}</div>
                                            <div>{{ product.special_key }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </div>
                    <div v-if="gifts">
                        <div class="card-sub-title">Hediye Urunler</div>
                        <el-card class="card-flat-list">
                            <div class="card-flat-list-items sm ">
                                <div class="item" v-for="product in gifts" :key="product.id">
                                    <div class="text">
                                        <div class="title">{{ product.title }}</div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                        <div class="basket-end-button text-right padding-right-20 margin-top-20">
                            <el-button class="modern large extract" style="padding: 15px 20px !important" @click="goRoute('/shop/orders/create/' + form.id)">
                                Siparis Olustur
                            </el-button>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>

export default {
    name: 'CrudItem',
    components: {
        //
    },
    data() {
        return {
            pageLoading: false,
            form: {
                user : {
                    //
                },
                invoiceAddress : {
                    //
                },

                delieveryAddress : {
                    //
                },

                products : [],
            },
            gifts : []
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;
                        this.gifts = response.body.gifts;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
        },
    }
}
</script>
