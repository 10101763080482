import LayoutConfig from '../config/layout'
import ViewContainer from '../app/Container/ViewContainer'

import Index from '../app/Pages/Plugins/Index'
import Show from '../app/Pages/Plugins/Show'
import Create from '../app/Pages/Plugins/Create'

export default {
    path: '/plugins',
    component: ViewContainer,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Eklentiler',
    },
    children: [
        {
            path: '',
            component: Index,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Eklenti Yonetimi',
            },
            children: [
                {
                    name: 'api.plugins.index',
                    path: '',
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Eklenti Yonetimi',
                        apiPoint: 'plugins',
                    },
                },
                {
                    name: 'api.plugins.create',
                    path: 'create',
                    component: Create,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Eklenti Yukle',
                        apiPoint: 'plugins',
                    }
                },
                {
                    name: 'api.plugins.edit',
                    path: ':id',
                    component: Show,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Eklenti Bilgileri',
                        apiPoint: 'plugins',
                    },
                },
            ]
        },
    ]
}