// Helpers
import lodash from 'lodash';
import moment from 'moment';
import settings from './config/settings'
import 'prismjs'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-json';

// Vue
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueResource from 'vue-resource'

// 3. party
import ElementUI from 'element-ui';
import ElementUILocale from 'element-ui/lib/locale'
import ElementUILang from 'element-ui/lib/locale/lang/tr-TR'
import VueGoodTablePlugin from 'vue-good-table';
import HighchartsVue from 'highcharts-vue'
import VueScroll from 'vue-scroll'
import VueNestable from 'vue-nestable'
import VuePrismEditor from 'vue-prism-editor';
import FileManager from 'laravel-file-manager';
import Vuelidate from 'vuelidate'

// Directives
import {mask} from 'vue-the-mask'

Vue.directive('mask', mask);

// App
import Provider from './Provider.vue'

// Projects
import store from './store'
import router from './routes/'
import {Languages} from './resources/lang';
import './components'

ElementUILocale.use(ElementUILang);

Vue.use(VueResource);
Vue.use(VueI18n);
Vue.use(ElementUI, {ElementUILocale});
Vue.use(VueGoodTablePlugin);
Vue.use(HighchartsVue);
Vue.use(VueScroll, {debounce: 600});
Vue.use(VueNestable);
Vue.use(Vuelidate);
Vue.use(FileManager, {
	store,
	headers: {
		'Authorization': 'Bearer ' + localStorage.access_token,
		'Tenant': settings.tenantName
	},
	baseUrl: settings.endPoint + '/v1/tools/file-manager/'
});

Vue.component('vue-prism-editor', VuePrismEditor);

Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.prototype.lodash = lodash;
Vue.prototype.moment = moment;

Vue.http.options.root = settings.endPoint;
Vue.http.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
Vue.http.headers.common['Tenant'] = settings.tenantName;
Vue.http.headers.common['Accept'] = 'application/json';

Vue.mixin({
	data: () => {
		return {
			appConfig: {
				basePath: settings.endPoint,
				frontPath: store.state.ui ? store.state.ui.tenant.domain : '',
				platformPath: settings.platform_domain,
				storagePath: 'storage/' + settings.storage_path,
				s3SourcePath : 'https://saturn-storage.s3.eu-central-1.amazonaws.com/' + settings.storage_path,
				s3Path : 'https://cdn.rhpozitif.com/' + settings.storage_path,
				cdnPath : 'https://cdn.rhpozitif.com/',
				predefineColors: [
					'#ff4500',
					'#ff8c00',
					'#ffd700',
					'#90ee90',
					'#00ced1',
					'#1e90ff',
					'#c71585',
					'#ffffff',
					'#5c50b9',
				],
				config: settings
			},
			fileManagerSettings: {
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
					Tenant: settings.tenantName,
				},
				baseUrl: settings.endPoint + '/tools/file-manager/',
				viewType: 'grid'
			},

			approveOptions: [
				{
					value: '0',
					label: 'Pasif'
				},
				{
					value: '1',
					label: 'Aktif'
				}
			],
			approveOptionsInteger: [
				{
					value: 0,
					label: 'Pasif'
				},
				{
					value: 1,
					label: 'Aktif'
				}
			],
			approveOptionsBoolean: [
				{
					value: false,
					label: 'Hayir'
				},
				{
					value: true,
					label: 'Evet'
				}
			],
			approveOptionsString: [
				{
					value: 'no',
					label: 'Hayir'
				},
				{
					value: 'yes',
					label: 'Evet'
				}
			],

			filterSearchTypeProp : [
				{
					value : 'in',
					label : 'İçinde Geçen'
				},
				{
					value : 'notIn',
					label : 'İçinde Geçmeyen'
				}
			],

			datePickerOptions: {
				firstDayOfWeek: 1,
			},
			monthTitles : [
				{
					id : 1,
					title : 'Ocak',
				},
				{
					id : 2,
					title : 'Subat',
				},
				{
					id : 3,
					title : 'Mart',
				},
				{
					id : 4,
					title : 'Nisan',
				},
				{
					id : 5,
					title : 'Mayis',
				},
				{
					id : 6,
					title : 'Haziran',
				},
				{
					id : 7,
					title : 'Temmuz',
				},
				{
					id : 8,
					title : 'Agustos',
				},
				{
					id : 9,
					title : 'Eylul',
				},
				{
					id : 10,
					title : 'Ekim',
				},
				{
					id : 11,
					title : 'Kasim',
				},
				{
					id : 12,
					title : 'Aralik',
				}
			]
		}
	},
	methods: {

		multiIndex(obj, is) {  // obj,['1','2','3'] -> ((obj['1'])['2'])['3']

			if (!obj) {
				return '';
			}

			return is.length ? this.multiIndex(obj[is[0]], is.slice(1)) : obj
		},

		pathIndex(obj, is) {   // obj,'1.2.3' -> multiIndex(obj,['1','2','3'])
			return this.multiIndex(obj, is.split('.'))
		},

		openBlankWindow(link) {
			return window.open(link, '_blank');
		},

		goRoute(link) {
			return this.$router.push(link);
		},

		goLink(link) {
			return window.location.href = link;
		},

		goIndexPage() {
			if (this.$route.meta.uiPoint) {
				return this.goRoute(this.$route.meta.uiPoint)
			}

			return this.goRoute('/' + this.generateApiPointWithAlias(this.$route.meta.apiPoint));
		},

		goShowPage(id) {
			if (this.$route.meta.uiPoint) {
				return this.goRoute(this.$route.meta.uiPoint + '/' + id)
			}

			return this.goRoute('/' + this.generateApiPointWithAlias(this.$route.meta.apiPoint) + '/' + id);
		},

		goCreatePage() {

			if (this.$route.meta.uiPoint) {
				return this.goRoute(this.$route.meta.uiPoint + '/create')
			}

			return this.goRoute('/' + this.generateApiPointWithAlias(this.$route.meta.apiPoint) + '/create');
		},

		createShowPageLink() {
			return this.generateApiPointWithAlias(this.$route.meta.apiPoint) + '/' + this.$route.params.id
		},

		cardCrudTitle() {
			let names = this.$route.name.split('.');
			let method = names[this.lodash.size(names) - 1];

			if (method === 'create') {
				return 'Olustur';
			}

			if (method === 'edit') {
				return 'Duzenle';
			}

			return this.$route.meta.title;
		},

		generateApiPointWithAlias(url) {
			if (!this.$route.params.alias) {
				return url;
			}

			return url + '/' + this.$route.params.alias;
		},

		elementSize(element) {
			return this.lodash.size(element)
		},

		responseSuccess(response) {
			this.$notify.success({
				title: 'Başarılı',
				message: response.body.success,
				position: 'top-right'
			});
		},

		responseError(response) {
			let message = null;

			if (!response.body) {
				return null;
			}

			if (response.status === 422) {
				message = response.body.message;
			}

			if (response.status === 500) {
				message = 'Onemli bir hata olustu, Lütfen teknik ekip ile iletişime geçiniz.';
			}

			if (this.lodash.size(response.body.errors)) {

				let notify = this.$notify;

				this.lodash.forEach(response.body.errors, (error) => {
					notify.error({
						title: 'Upss!',
						message: error[0],
						position: 'top-right'
					});
				});

				return;
			}

			if (response.body.error) {
				message = response.body.error;
			}

			if (message) {
				this.$notify.error({
					title: 'Upss!',
					message: message,
					position: 'top-right'
				});
			}
		},

		hasPermission(name) {

			return name;

			/*
			if (!store.state.account) {
				return false;
			}

			if (!store.state.account.permission[name]) {
				return false;
			}

			return store.state.account.permission[name];
			*/
		},

		findProductType(type) {
			switch (type) {
				case 'weekend':
					return 'Hafta Sonu';
				case 'weekdays':
					return 'Hafta Ici';
				case 'night':
					return 'Gece';
				case 'day':
					return 'Gunduz';
				case 'live_and_record':
					return 'Canli ve Kayittan';
				case 'live':
					return 'Canli';
				case 'record':
					return 'Kayittan';
				default :
					return type;
			}
		},

		findProductMainType(type) {
			switch (type) {
				case 'digital':
					return 'Dijital';
				case 'physical':
					return 'Fiziksel';
				case 'group':
					return 'Grup';
				case 'private':
					return 'Ozel Ders';
                case 'subscription':
                    return 'Abonelik Paketi';
				default :
					return type;
			}
		},

		findProductIcon(type) {
			switch (type) {
				case 'group':
					return 'message-video';
				case 'package':
					return 'archive-outline';
				case 'physical':
					return 'bookshelf';
				case 'digital':
					return 'gesture-tap-button';
                case 'subscription':
                    return 'youtube-subscription';
				default :
					return type;
			}
		},

		formatSecondTime(time) {
			var sec_num = parseInt(time, 10); // don't forget the second param
			var hours = Math.floor(sec_num / 3600);
			var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
			var seconds = sec_num - (hours * 3600) - (minutes * 60);

			if (hours < 10) {
				hours = "0" + hours;
			}
			if (minutes < 10) {
				minutes = "0" + minutes;
			}
			if (seconds < 10) {
				seconds = "0" + seconds;
			}

			if (!parseInt(hours)) {
				return minutes + ':' + seconds;
			}

			return hours + ' saat, ' + minutes + ' dakika';
		},

		initMathJax(elements) {
			this.lodash.forEach(elements, (math, index) => {
                try {
                    let value = elements[index].outerHTML.trim()

                    elements[index].outerHTML = window.MathJax.mathml2chtml(value).outerHTML
                }
                catch(err) {
                    console.log(err)
                }
			});

			window.MathJax.startup.document.clear();
			window.MathJax.startup.document.updateDocument();
		},

        textSearch(q, txt) {
            let searchQ = URLify(q).replaceAll('-', ' ')
            let content = URLify(txt).replaceAll('-', ' ')

            return content.indexOf(searchQ) > -1;
        },

        cdnPath(path) {
            return this.appConfig.s3Path + '/' + path
        }
	}
});

const numberFormats = {
	'en-Us': {
		currency: {
			style: 'currency', currency: 'TRY', symbol: '₺'
		}
	},
	'tr-TR': {
		currency: {
			style: 'currency', currency: 'TRY', symbol: '₺'
		}
	}
};

const dateTimeFormats = {
	'tr-TR': {
		short: {
			year: 'numeric', month: 'short', day: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric'
		}
	}
};

new Vue({
	store,
	router,
	i18n: new VueI18n({
		locale: 'tr-TR',
		silentTranslationWarn: true,
		messages: Languages,
		dateTimeFormats,
		numberFormats
	}),
	render: h => h(Provider),
}).$mount('#app');
