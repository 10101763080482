<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list">
            <template slot="header">
                <div class="title">
                    Urunler
                </div>
                <div class="actions">
                    <el-tooltip content="Ekle" placement="left">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
                    </el-tooltip>
                </div>
            </template>
            <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                <el-table :data="response.rows" width="100%">
                    <el-table-column label="Urun Tipi" prop="type" width="100" align="center">
                        <template slot-scope="props">
                            <div @click="edit(props.row.id)" class="cursor-pointer">
                                {{ findProductMainType(props.row.type) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Urun Sahibi">
                        <template slot-scope="props">
                            <div class="d-flex align-items-center cursor-pointer" v-if="props.row.teacher" @click="edit(props.row.id)">
                                <div class="avatar margin-right-20">
                                    <pic :src="props.row.teacher.avatar" type="avatar" class="avatar" />
                                </div>
                                <div class="text">
                                    <div class="title fs-16 fw=500 font-weight-500">{{ props.row.teacher.name }}</div>
                                    <div>{{ props.row.teacher.email }}</div>
                                    <div>{{ props.row.reference }}</div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center text-red"
                                 v-if="!props.row.teacher && props.row.type === 'group' && props.row.type === 'private'">
                                Ogretmen Secimi Yapilmamis!
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Zaman Kisitlamasi" width="300">
                        <template slot-scope="props">
                            <div>
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Başlangıç Tarihi</div>
                                    <div>{{ props.row.start_at }}</div>
                                </div>
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Bitiş Tarihi</div>
                                    <div>{{ props.row.end_at }}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Varyasyon" width="240">
                        <template slot-scope="props">
                            <div v-if="props.row.type !== 'group'">
                                -
                            </div>
                            <div v-if="props.row.type === 'group'">
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Variant</div>
                                    <div>{{ findProductType(props.row.variant_type) }}</div>
                                </div>
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Hafta</div>
                                    <div>{{ findProductType(props.row.week_type) }}</div>
                                </div>
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Gün</div>
                                    <div>{{ findProductType(props.row.day_type) }}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Stok" align="center" width="140">
                        <template slot-scope="props">
                            <div v-if="props.row.type === 'private'">
                                {{ props.row.quantity }} Saat
                            </div>
                            <div v-if="props.row.type === 'group'">
                                {{ props.row.current_quantity }} kisi / {{ props.row.quantity }} kisi
                            </div>
                            <div v-if="props.row.type === 'physical' || props.row.type === 'digital'">
                                {{ props.row.quantity - props.row.current_quantity }} adet
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Fiyat" align="right" width="100">
                        <template slot-scope="props">
                            <div class="flex-column" v-if="props.row.discount">
                                <div class="text-red">
                                    <del><b>{{ $n(props.row.discount.price, 'currency') }}</b></del>
                                </div>
                                <div class="text-green">
                                    <b>{{ $n(props.row.price, 'currency') }}</b>
                                </div>
                            </div>
                            <div v-if="!props.row.discount">
                                <b>{{ $n(props.row.price, 'currency') }}</b>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="right" class="actions" width="60">
                        <template slot-scope="props">
                            <el-button type="text" class="flat dark red" icon="el-icon-delete" @click="destroy(props.row.id)" />
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>

        <div class="text-center margin-top-20" v-if="response.pagination.total > response.pagination.per_page">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="response.pagination.total"
                :page-size="response.pagination.per_page"
                :current-page.sync="response.pagination.current_page"
                @current-change="handleChangePage"
            />
        </div>

        <el-dialog title="Urun Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" class="dialog-with-page body-overflow custom-padding">
            <el-form :label-position="'top'" ref="form" name="form" :model="form" :key="form.id">
                <el-card shadow="never" class="no-shadow custom-padding">
                    <div class="el-card__tabs custom-padding">
                        <el-tabs class="el-card--tabs fluid" :tab-position="'top'">
                            <el-tab-pane label="Genel Bilgiler">
                                <div class="card-sub-title">Genel</div>
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Urun Tipi">
                                        <lv-select :container="form" model="type" :options="types" />
                                    </el-form-item>

                                    <el-form-item label="Urun Kodu">
                                        <el-input v-model="form.reference" placeholder="Urun Kodu" />
                                    </el-form-item>

                                    <el-form-item label="Marka">
                                        <el-select v-model="form.brand_id" placeholder="Marka" clearable filterable>
                                            <el-option v-for="item in brands" :key="item.id" :label="item.title" :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <template v-if="form.type === 'package'">
                                    <div class="card-sub-title">Egitim Bilgileri</div>
                                    <div class="card-custom-padding-box-items">
                                        <el-row :gutter="24">
                                            <el-col :span="12">
                                                <el-form-item label="Egitim Baslangic Tarihi">
                                                    <el-date-picker v-model="form.meta.education_start_at" type="datetime" placeholder="Egitim Baslangic Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="Egitim Bitis Tarihi">
                                                    <el-date-picker v-model="form.meta.education_end_at" type="datetime" placeholder="Egitim Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </template>

                                <div class="card-custom-padding-box-items" v-if="form.type !== 'digital' || form.type !== 'event'">
                                    <el-form-item label="Egitmen" >
                                        <user :container="form" model="user_id" placeholder="Egitmen" :key="form.id" />
                                    </el-form-item>
                                </div>

                                <template v-if="form.type === 'group' || form.type === 'private'">
                                    <div class="card-sub-title">Egitim Bilgileri</div>
                                    <div class="card-custom-padding-box-items">

                                        <el-form-item label="Ogretmen">
                                            <user :container="form" model="user_id" placeholder="Ogretmen" :key="form.id" />
                                        </el-form-item>

                                        <el-form-item label="Varyasyon">
                                            <lv-select :container="form" model="variant_type" :options="variantTypes" />
                                        </el-form-item>

                                        <el-row :gutter="24">
                                            <el-col :span="12">
                                                <el-form-item label="Egitim Baslangic Tarihi">
                                                    <el-date-picker v-model="form.meta.education_start_at" type="datetime" placeholder="Egitim Baslangic Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="Egitim Bitis Tarihi">
                                                    <el-date-picker v-model="form.meta.education_end_at" type="datetime" placeholder="Egitim Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>

                                        <el-form-item label="Kullanim Suresi [Satin Almadan Itibaren Musterinin Bu Urune Erise Bilecegi Sure]">
                                            <el-input v-model="form.meta.lesson_usage_time" placeholder="Kullanim Suresi" type="number" prefix-icon="el-icon-time">
                                                <template slot="append">ay</template>
                                            </el-input>
                                        </el-form-item>

                                        <el-row :gutter="24">
                                            <el-col :span="12">
                                                <el-form-item label="Toplam Ders Adeti">
                                                    <el-input v-model="form.meta.lesson_count" placeholder="Toplam Ders Adeti" type="number" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="Toplam Ders Saati">
                                                    <el-input v-model="form.meta.lesson_time_count" placeholder="Toplam Ders Saati" type="number" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>

                                        <el-form-item label="Gunler">
                                            <definitions alias="days" model="days" :container="form.meta" :multiple="true" />
                                        </el-form-item>

                                        <el-form-item label="Gün Bilgisi">
                                            <el-input v-model="form.meta.day_text" placeholder="Gün Bilgisi" />
                                        </el-form-item>

                                        <el-form-item label="Hafta Turu">
                                            <lv-select :container="form" model="week_type" :options="weekTypes" />
                                        </el-form-item>

                                        <el-form-item label="Gun Turu">
                                            <lv-select :container="form" model="day_type" :options="dayTypes" />
                                        </el-form-item>
                                    </div>
                                </template>
                                <div class="card-sub-title">Diger</div>
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Siralama">
                                        <el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
                                    </el-form-item>
                                    <el-form-item class="m-0">
                                        <el-switch v-model="form.is_active" active-text="Aktif" />
                                    </el-form-item>
                                    <el-form-item class="m-0">
                                        <el-switch v-model="form.is_main" active-text="Ana Urun" />
                                    </el-form-item>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Fiyat Bilgileri">
                                <div class="card-sub-title">Satis Tarihleri</div>
                                <div class="card-custom-padding-box-items">
                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item label="Baslangic Tarihi">
                                                <el-date-picker v-model="form.start_at" type="datetime" placeholder="Baslangic Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Bitis Tarihi">
                                                <el-date-picker v-model="form.end_at" type="datetime" placeholder="Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="card-sub-title">Fiyat Bilgileri</div>
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Vergi Orani">
                                        <definitions :container="form" model="tax_class_definition_id" alias="tax-classes" />
                                    </el-form-item>

                                    <el-form-item label="Urun Fiyati" v-if="form.type !== 'package'">
                                        <el-input v-model="form.price" placeholder="Urun Fiyati" />
                                    </el-form-item>

                                    <template v-if="form.type === 'group' || form.type === 'private'">
                                        <el-form-item label="Ders Birim Fiyati">
                                            <el-input v-model="form.lesson_price" placeholder="Ders Birim Fiyati" />
                                        </el-form-item>
                                    </template>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Indirim Bilgileri">
                                <div class="card-sub-title">
                                    <div>Indirim Bilgileri</div>
                                    <div>
                                        <el-button icon="el-icon-plus" class="flat dark blue" @click="createDiscount" />
                                    </div>
                                </div>

                                <el-tabs v-model="discountTab" class="el-card--tabs fluid" :tab-position="'top'" closable @tab-remove="destroyDiscount">
                                    <el-tab-pane :label="'Indirim ' + (key + 1)" v-for="(discount, key) in form.discounts" :key="'key-' + key" :name="'' + key">
                                        <div class="card-custom-padding-box-items">

                                            <el-row :gutter="24">
                                                <el-col :span="12">
                                                    <el-form-item label="Indirim Baslangic Tarihi">
                                                        <el-date-picker v-model="discount.start_at" type="date" placeholder="Indirim Baslangic Tarihi" value-format="yyyy-MM-dd" />
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="12">
                                                    <el-form-item label="Indirim Bitis Tarihi">
                                                        <el-date-picker v-model="discount.end_at" type="date" placeholder="Indirim Bitis Tarihi" value-format="yyyy-MM-dd" />
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>

                                            <el-form-item label="Adet">
                                                <el-input v-model="discount.quantity" placeholder="Adet" type="number" />
                                            </el-form-item>

                                            <el-form-item label="Fiyat" v-if="form.type !== 'package'">
                                                <el-input v-model="discount.price" placeholder="Fiyat" />
                                            </el-form-item>

                                            <el-form-item label="Oran">
                                                <el-input v-model="discount.percent" placeholder="Indirim Orani" />
                                            </el-form-item>

                                            <el-form-item label="Indirim Aciklamasi" v-if="form.type !== 'package'">
                                                <el-input v-model="discount.description" placeholder="Indirim Aciklamasi" type="textarea" rows="2" />
                                            </el-form-item>

                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </el-tab-pane>
                            <el-tab-pane label="Kargo">
                                <div class="card-sub-title">Kargo / Stok Bilgileri</div>
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Kargolu Urun">
                                        <lv-select :container="form" model="is_shipping"
                                                   :options="approveOptionsBoolean" />
                                    </el-form-item>

                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item :label="findStokQuantityTitle()">
                                                <el-input v-model="form.quantity" :placeholder="findStokQuantityTitle()"
                                                          type="number" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Guncel Durum">
                                                <el-input v-model="form.current_quantity" placeholder="Guncel Durum"
                                                          type="number" disabled />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-form-item label="Stokta Yoksa Durumu">
                                        <statuses :container="form" model="stock_status_id" alias="stocks" />
                                    </el-form-item>

                                    <el-row :gutter="24" v-if="form.type === 'physical'">
                                        <el-col :span="12">
                                            <el-form-item label="Agirlik">
                                                <el-input v-model="form.weight" placeholder="Agirlik" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Agirlik Birimi">
                                                <definitions alias="weight-classes" model="weight_class_definition_id"
                                                             :container="form" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-row :gutter="24">
                                                <el-col :span="8">
                                                    <el-form-item label="Uzunluk">
                                                        <el-input v-model="form.length" placeholder="Uzunluk" />
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="Genislik">
                                                        <el-input v-model="form.width" placeholder="Genislik" />
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="Yukseklik">
                                                        <el-input v-model="form.height" placeholder="Yukseklik" />
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Uzunluk Birimi">
                                                <definitions label="Uzunluk Birimi" alias="length-classes"
                                                             model="length_class_definition_id" :container="form" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Video" v-if="form.type === 'private' || form.type === 'group'">
                                <div class="card-sub-title">Video</div>
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Baslik">
                                        <el-input v-model="form.meta.video_title" placeholder="Baslik" />
                                    </el-form-item>

                                    <el-row :gutter="24">
                                        <el-col :span="6">
                                            <el-form-item label="Resim">
                                                <fs v-model="form.meta.video_img" />
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="18">
                                            <el-form-item label="Sure">
                                                <el-input v-model="form.meta.video_time" placeholder="Sure" />
                                            </el-form-item>

                                            <el-form-item label="Kaynak">
                                                <el-input v-model="form.meta.video_source" placeholder="Kaynak" />
                                            </el-form-item>

                                            <el-form-item label="Aciklama">
                                                <el-input v-model="form.meta.video_description" type="textarea" placeholder="Aciklama" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Etkinlik / Webinar" v-if="form.type === 'event'">
                                <div class="card-custom-padding-box-items">
                                    <el-form-item label="Konusmaci">
                                        <user :container="form" model="user_id" placeholder="Konusmaci" :key="form.id" />
                                    </el-form-item>

                                    <el-form-item label="Konusmacilar">
                                        <el-select v-model="form.meta.speakers" placeholder="Konusmacilar" clearable filterable multiple>
                                            <el-option v-for="item in contents" :key="item.id" :label="item.title" :value="item.id" />
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="Konferans Tarihi">
                                        <el-date-picker v-model="form.meta.event_start_at" type="datetime" placeholder="Konferans Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                    </el-form-item>

                                    <el-form-item label="Ders">
                                        <education-content-remote-select alias="lessons" :container="form.meta" model="event_lesson_id" />
                                    </el-form-item>

                                    <el-form-item label="Canli Yayin Linki">
                                        <el-input v-model="form.meta.live_source" placeholder="Canli Yayin Linki" />
                                    </el-form-item>

                                    <el-form-item label="Dokuman Dosyasi">
                                        <fs v-model="form.meta.document_file" :isFile="true" />
                                    </el-form-item>

                                    <el-row :gutter="24">
                                        <el-col :span="6">
                                            <el-form-item label="Dokuman Kapak Resmi">
                                                <fs v-model="form.meta.document_image" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-card>
            </el-form>
            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">Kaydet</el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">Guncelle</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Product',
    props: ['id'],
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            search: '',
            page: 1,
            response: {
                pagination: {
                    //
                },
                rows: []
            },
            form: {
                type: 'physical',
                discount: {
                    //
                },
                meta: {
                    //
                },
                discounts: []
            },
            filterForm: {
                //
            },
            users: [],
            brands: [],
            types: [
                {
                    value: 'physical',
                    label: 'Fiziksel',
                },
                {
                    value: 'digital',
                    label: 'Dijital',
                },
                {
                    value: 'package',
                    label: 'Calisma Paketi',
                },
                {
                    value: 'group',
                    label: 'Grup',
                },
                {
                    value: 'event',
                    label: 'Etkinlik / Webinar',
                }
            ],
            weekTypes: [
                {
                    value: 'weekend',
                    label: 'Hafta Sonu',
                },
                {
                    value: 'weekdays',
                    label: 'Hafta Ici',
                }
            ],
            dayTypes: [
                {
                    value: 'night',
                    label: 'Gece',
                },
                {
                    value: 'day',
                    label: 'Gunduz',
                }
            ],
            variantTypes: [
                {
                    value: 'live_and_record',
                    label: 'Canli ve Kayittan',
                },
                {
                    value: 'live',
                    label: 'Canli',
                },
                {
                    value: 'record',
                    label: 'Kayittan',
                }
            ],

            discountTab: null,

            contents : [],
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        getBrands() {
            this.pageLoading = true;

            this.$http
                .get('catalog/brands', {params: {perPage: 1000}})
                .then(response => {
                    this.brands = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getUsers() {
            this.pageLoading = true;

            this.$http
                .get('crm/roles/teacher/users', {params: {perPage: 1000}})
                .then(response => {
                    this.users = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getContents() {
            this.pageLoading = true;

            this.$http
                .get('cms/teachers/contents', {params: {perPage: 1000, view: 'dropdown'}})
                .then(response => {
                    this.contents = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        endPoint(suffix) {

            const path = 'catalog/products';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {
                    params: {
                        page: this.page,
                        sorts: {
                            created_at: 'desc'
                        },
                        filters: [
                            {
                                relation: null,
                                add_with: 'true',
                                inputs: [
                                    {
                                        name: 'content_id',
                                        field: 'content_id',
                                        type: 'singleSelect',
                                        value: this.id
                                    }
                                ]
                            },
                        ],
                    }
                })
                .then(response => {
                    this.response = response.body;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        create() {

            this.initRelationItems();

            this.form = {
                type: 'physical',
                discount: {
                    //
                },
                meta: {
                    //
                },

                discounts: []
            };

            this.dialogVisible = true;
        },

        edit(id) {

            this.initRelationItems();

            this.form = {
                type: 'physical',
                discount: {
                    //
                },
                meta: {
                    //
                },

                discounts: []
            };

            this.dialogVisible = true;

            this.show(id);
        },

        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    this.form.content = null;
                    this.form.length_definition = null;
                    this.form.lessons = null;
                    this.form.books = null;
                    this.form.stock_status = null;
                    this.form.tax_definition = null;
                    this.form.teacher = null;
                    this.form.weight_definition = null;

                    if (!this.lodash.size(this.form.discount)) {
                        this.form.discount = {};
                    }

                    if (!this.lodash.size(this.form.discounts)) {
                        this.form.discounts = [];
                    }

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            //
                        };
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        store() {
            this.pageLoading = true;
            this.form.content_id = this.id;

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        update() {
            this.pageLoading = true;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!' + response
                    });

                    return false;
                });
        },

        handleRowActionCommand(command) {
            if (command.type === 'destroy') {
                return this.destroy(command.id);
            }

            this.edit(command.id);
        },

        initRelationItems() {

            if (!this.lodash.size(this.brands)) {
                this.getBrands();
            }

            if (!this.lodash.size(this.users)) {
                this.getUsers();
            }

            if (!this.lodash.size(this.contents)) {
                this.getContents();
            }
        },

        findStokQuantityTitle() {
            if (this.form.type === 'group') {
                return 'Kontenjan';
            }

            if (this.form.type === 'private') {
                return 'Adet';
            }

            return 'Adet';
        },

        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        createDiscount() {
            this.form.discounts.push({
                start_at : null,
                end_at : null,
                quantity : null,
                price : null,
                percent : null,
                description : null,
            })
        },

        destroyDiscount(key) {

            this
                .$confirm('Indirim ' + (parseInt(key) + 1) + ' kalici olarak silinecek!', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    this.form.discounts.splice(key, 1);
                    return true;
                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        }
    },
}
</script>
