<template>
	<div v-loading="pageLoading">
        <div class="page-filters-disable">
            <el-card class="no-shadow">
                <el-form :label-position="'top'" ref="filter">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item>
                                <el-input placeholder="metne göre arama yap" suffix-icon="el-icon-search" v-model="searchTitle">
                                    <el-select v-model="searchTitleType" slot="append" placeholder="Arama Turu" class="select-append">
                                        <el-option label="Soru Icerigi" value="question_content" />
                                        <el-option label="Soru Koku" value="question" />
                                        <el-option label="Notlar" value="note" />
                                    </el-select>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item>
                                        <el-date-picker v-model="filterDate.startAt" type="date" placeholder="Başlangıç Tarihini Seçiniz" value-format="yyyy-MM-dd" :picker-options="datePickerOptions"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item>
                                        <el-date-picker v-model="filterDate.endAt" type="date" placeholder="Bitiş Tarihini Seçiniz" value-format="yyyy-MM-dd" :picker-options="datePickerOptions"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item>
                                        <ti-select :options="video_filter_options" :container="filterAccessVideo" model="is_access_video_solve" title="title" placeholder="Videolu Soru Çözüm" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>

                    <el-row :gutter="24">
                        <el-col :span="20">
                            <el-form-item>
                                <definitions alias="questions" :container="filterTag" model="tag_requests" :multiple="true" placeholder="Etiketler" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-select v-model="filterTag.tag_value" placeholder="Arama Türü">
                                    <el-option :label="option.label" :value="option.value" v-for="(option, index) in filterSearchTypeProp" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item>
                        <el-cascader placeholder="Konu Etiketleri" v-model="filterTopics" :options="topics" :props="{multiple: true}" clearable filterable />
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <ti-select :options="branches" :container="filter" model="archive_branch_id" title="title_ui" placeholder="Branş" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <ti-select :options="types" :container="filter" model="question_type" placeholder="Soru Turu" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <ti-select :options="views" :container="filter" model="view_type" placeholder="Soru Şekli" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <ti-select :options="owner_types" :container="filter" model="owner_type" placeholder="Kaynak Türü" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div class="margin-top-20" v-if="filter.owner_type">
                        <el-form-item class="margin-bottom-0">
                            <template v-if="filter.owner_type === 'examination'">
                                <ti-select :options="examinations" :container="filter" model="archive_examination_id" title="title_ui" placeholder="Sınavlar" />
                            </template>
                            <template
                                v-if="filter.owner_type === 'owner'">
                                <user-with-role role="teacher" :container="filter" model="owner_id" placeholder="Yazar" />
                            </template>
                        </el-form-item>
                    </div>
                </el-form>

            </el-card>

            <div style="padding: 20px">
                <el-button class="modern secondary large fluid" @click="handleSearch" icon="el-icon-search">
                    Filtrele
                </el-button>
            </div>
        </div>

        <el-card class="question-content-rows-card">
            <div class="question-content-rows">
                <el-card class="question-content-rows-card" >
                    <template v-for="(row, index) in rows">
                        <div class="question-preview" :key="index">
                            <div class="question-content" v-if="row.question_type === 'connected'" v-html="row.text"></div>
                            <div class="questions">
                                <div class="question" v-for="(question, questionIndex) in row.questions" :key="question.id" :class="{active : checkInTest(question.id)}">
                                    <div class="question-control">
                                        <div class="control">
                                            <el-tooltip content="Duzenle" placement="bottom">
                                                <el-button @click="openBlankWindow('/#/archives/question-contents/' + row.id)" class="modern default icon" icon="el-icon-edit" />
                                            </el-tooltip>
                                        </div>
                                        <div class="control" v-if="!checkInTest(question.id)">
                                            <el-tooltip content="Test'e Ekle" placement="bottom">
                                                <el-button @click="handleClickStore(question.id)" class="modern green icon" icon="el-icon-check" />
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div class="question-wrap">
                                        <div class="prefix">
                                            {{ questionIndex + 1 + index }}.
                                        </div>
                                        <div class="content">
                                            <div v-html="question.description"></div>

                                            <div class="ui message blue" v-if="question.meta.solved_description">
                                                <div v-html="question.meta.solved_description"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="options">
                                        <template v-for="(option, optionIndex) in question.options">
                                            <div class="option"  :key="option.id" :class="{active : option.is_correct}">
                                                <div class="prefix" :class="{active : option.is_correct}">
                                                    <icon :icon="'letter-' + question.option_view_type + '-' + (optionIndex + 1)" />
                                                </div>
                                                <div class="content" v-html="option.description"></div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-card>
            </div>
        </el-card>

        <div class="text-center margin-top-20"
             v-if="pagination && pagination.total > pagination.per_page">
            <el-pagination
                large
                background
                layout="prev, pager, next"
                :total="pagination.total"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page"
                @current-change="handleChangePage"
            />
        </div>

        <div style="height: 40px"></div>

	</div>
</template>

<script>
    export default {
        name: 'FilterQuestionTest',
        props : ['store', 'questions'],
        data() {
            return {
                pageLoading: false,
                filter: {
                    //
                },
                filterDate : {
                    startAt : null,
                    endAt : null,
                },
                filterTopics : [],
                searchTitle: null,
                searchTitleType: 'question',
                filterTag : {
                    tag_value : 'in',
                    tag_requests : [],
                },
                filterAccessVideo :{},

                branches: [],
                examinations: [],
                types: [
                    {
                        id: 'singular',
                        title: 'Tekil',
                    },
                    {
                        id: 'connected',
                        title: 'Bağlı',
                    }
                ],
                views: [
                    {
                        id: 'multiple_choice',
                        title: 'Çoktan Seçmeli',
                    },
                    {
                        id: 'description',
                        title: 'Seceneksiz Soru',
                    },
                    {
                        id: 'fill_in_the_blank',
                        title: 'Bosluk Doldurma',
                    }
                ],
                owner_types: [
                    {
                        id: 'examination',
                        title: 'Gerçek Sınav',
                    },
                    {
                        id: 'owner',
                        title: 'Orjinal',
                    }
                ],
                video_filter_options: [
                    {
                        id: 'yes',
                        title: 'Video Çözümü Yapılabilir',
                    },
                    {
                        id: 'no',
                        title: 'Video Çözümü Yapılamaz',
                    }
                ],
                topics : [],
                rows: [],
                pagination: null,
                page: 1,
                perPage: 10,
            }
        },
        mounted() {
            this.getBranches();
            this.getExaminations();
            this.getTopics();
        },
        methods: {
            handleClickStore(id) {
                return this.store(id)
            },

            getTopics() {
                this.pageLoading = true;

                this.$http
                    .get('archives/topics', {
                        params: {
                            perPage: 10000,
                            view: 'cascader',
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.topics = response.body.data;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getBranches() {
                this.pageLoading = true;

                this.$http
                    .get('archives/branches', {params: {perPage: 10000}})
                    .then(response => {
                        this.pageLoading = false;
                        this.branches = response.body.rows;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getExaminations() {
                this.pageLoading = true;

                this.$http
                    .get('archives/examinations', {params: {perPage: 10000}})
                    .then(response => {
                        this.pageLoading = false;
                        this.examinations = response.body.rows;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            handleChangePage(value) {
                this.page = value;
                this.index();
            },

            handleSearch() {
                this.page = 1;
                this.index();
            },

            index() {
                this.pageLoading = true;

                this.$http
                    .get('archives/question-contents', {
                        params: {
                            page: this.page,
                            perPage: this.perPage,
                            sort: {
                                created_at: 'desc'
                            },
                            columnFilters: this.filter,
                            topics: this.filterTopics,
                            title: this.searchTitle,
                            title_type: this.searchTitleType,
                            owner_type : this.filter.owner_type,
                            owner_id : this.filter.owner_id ? this.filter.owner_id : null,
                            start_at : this.filterDate.startAt,
                            end_at : this.filterDate.endAt,
                            tag_requests : this.filterTag.tag_requests,
                            tag_value : this.filterTag.tag_value,
                            is_access_video_solve : this.filterAccessVideo.is_access_video_solve,
                        }
                    })
                    .then(response => {
                        this.rows = response.body.rows;
                        this.pagination = response.body.pagination;

                        setTimeout(() => {
                            this.initMathJax(window.document.getElementsByTagName('math'));
                            this.pageLoading = false;
                        }, 500)
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            checkInTest(id) {

                let status = false;

                this.lodash.forEach(this.questions, (question) => {
                    if (question.id === id) {
                        status = true;
                    }
                })

                return status;
            }
        }
    }
</script>
