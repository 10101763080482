<template>
    <div v-loading="pageLoading">
        <app-tab :items="appTabs" :active="activeTab" :update="handleChangeTab" v-if="form.id" class="margin-bottom-40" />

        <el-card class="custom-padding" v-show="activeTab === 'forums'">
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-sub-title">Metin</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Baslik">
                        <el-input v-model="form.title" placeholder="Baslik" />
                    </el-form-item>

                    <el-form-item label="Aciklama" class="is-editor">
                        <editor :container="form" model="description" :small="true" />
                    </el-form-item>
                </div>

                <div class="card-sub-title">Baglantilar</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Urunler">
                        <product-transfer :container="form" model="product_requests" :types="['group', 'subscription']" />
                    </el-form-item>
                </div>
            </el-form>
        </el-card>

        <ForumComments :id="form.id" v-if="form.id" v-show="activeTab === 'comments'" />
    </div>
</template>

<script>
import CrudHeader from "../../../Components/CrudHeader";
import ForumComments from "@/app/Pages/Education/Forum/Comments";

export default {
    name: 'CrudItem',
    components: {
        ForumComments,
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                product_requests: [],
            },

            activeTab: 'forums',
            appTabs: [
                {
                    key: 'forums',
                    title: 'Tartisma Bilgileri'
                },
                {
                    key: 'comments',
                    title: 'Katilimcilar'
                }
            ],
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        handleChangeTab(event) {
            this.activeTab = event
        },

        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>
