<template>
    <div class="order-create-page">
        <ProductType v-if="shop.create.step === 'product_type'" />
        <ProductList v-if="shop.create.step === 'product_list'" />
        <Customer v-if="shop.create.step === 'customer'" />
        <Summary v-if="shop.create.step === 'basket'" />
    </div>
</template>

<script>
import {mapState} from 'vuex';
import ProductType from "./Create/ProductType";
import ProductList from "./Create/ProductList";
import Customer from "./Create/Customer";
import Summary from "./Create/Summary";

export default {
    name: 'OrderCreate',
    components: {
        Summary,
        Customer,
        ProductList,
        ProductType
    },
    computed: mapState(['shop']),
    data() {
        return {
            //
        }
    },
    mounted() {
        this.$store.commit('setShopCreateChangeStep', 'product_type');
    },
    methods: {
        //
    }
}
</script>