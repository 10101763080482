<template>
	<div class="logo">
		<router-link to="/dashboard">
			<img src="@/resources/img/sidebar-logo.svg" alt="logo" style="filter: brightness(0) invert(1);">
		</router-link>
	</div>
</template>

<script>
    export default {
        name: 'Logo',
    }
</script>