<template>
    <el-form :label-position="'top'" ref="form" :model="form">
        <el-form-item label="Urunler" :class="{ 'is-error': submitted && $v.form.products.$error }">
            <product-dropdown :container="form" model="products" :multiple="true" />
        </el-form-item>

        <el-form-item label="Ogretmenler">
            <user :container="form" model="teachers" :multiple="true" placeholder="Ogretmenler" />
        </el-form-item>

        <el-form-item label="Mesajiniz" :class="{ 'is-error': submitted && $v.form.message.$error }">
            <el-input v-model="form.message" placeholder="Mesajiniz" type="textarea" rows="6" />
        </el-form-item>

        <el-button class="modern green large fluid" @click="store">
            Gonder
        </el-button>
    </el-form>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
    name: 'Mail',
    props: ['send', 'products'],
    data() {
        return {
            submitted: false,
            form: {
                products : [],
                teachers : []
            },
            sendType : [
                {
                    label : 'Ticari',
                    value : 'merchant',
                },
                {
                    label : 'Normal',
                    value : 'basic',
                }
            ]
        }
    },
    validations: {
        form: {
            products: {required},
            message: {required},
        }
    },
    methods: {
        store() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.send(this.form)
        }
    }
}
</script>
