<template>
	<div class="list-content">
		<div class="cards" v-infinite-scroll="index" v-loading="pageLoading">
			<draggable class="draggable" :data-stage="stageId" :options="{handle : '.card', animation : 0, group : 'people', ghostClass : 'ghost'}" :sort="true" @end="endDraggable">
				<div class="card" :data-card="card.id" v-for="card in cards" :key="card.id" @click="goCardDetail(card)">
					<div class="card-detail">
						<div class="tags" v-if="elementSize(card.labels)">
							<el-tooltip effect="dark" :content="label.label.title" placement="bottom"
										v-for="label in card.labels" :key="label.id">
								<div class="tag" :style="{'background-color': label.label.bg_color}"></div>
							</el-tooltip>
						</div>
						<div class="title">
							{{ card.title }}
						</div>
						<div class="assignees" v-if="elementSize(card.assignees)">
							<div class="assignee" v-for="assignee in card.assignees" :key="assignee.id">
								<el-tooltip effect="dark" :content="assignee.assignee.name" placement="bottom">
									<pic :src="assignee.assignee.avatar" type="avatar" />
								</el-tooltip>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<div class="left">
							<div class="date">
								<icon icon="clock-outline" />
								{{ card.humans_date }}
							</div>
						</div>
						<div class="right">
							<el-tooltip effect="dark" content="Dosya Sayisi" placement="bottom">
								<div class="item">
									<icon icon="paperclip" />
									{{ card.file_count }}
								</div>
							</el-tooltip>
							<el-tooltip effect="dark" content="Checklist Sayisi" placement="bottom">
								<div class="item">
									<icon icon="comment-text-outline" />
									{{ card.checklist_count }}
								</div>
							</el-tooltip>
						</div>
					</div>
				</div>
			</draggable>
		</div>

		<div class="list-footer" v-loading="createLoading">
			<div class="create" v-if="createVisible">
				<div class="input">
					<el-input placeholder="Baslik" v-model="form.title" />
				</div>
				<div class="actions">
					<el-button class="modern icon" @click="store">Kaydet</el-button>
					<el-button class="modern red icon" icon="el-icon-delete" @click="createVisible = false" />
				</div>
			</div>
			<div class="title" @click="create" v-if="!createVisible">
				<icon icon="plus-circle" class="fs-18" />
				Yeni Kart Ekle
			</div>
		</div>
	</div>
</template>

<script>
	import draggable from 'vuedraggable'

	export default {
		name: 'Cards',
		components: {
			draggable
		},
		props: ['stageId'],
		data() {
			return {
				page: 0,
				pageLoading: false,
				cards: [],
				createVisible: false,
				createLoading: false,
				form: {
					//
				}
			}
		},
		mounted() {
			this.index();
		},
		methods: {
			goCardDetail(card) {
				return this.goRoute('/boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.stageId + '/cards/' + card.id);
			},

			index() {

                if (this.$route.name === 'api.boards.pipelines.stages.cards.show') {
                    return false;
                }

				this.pageLoading = true;
				this.page++;

				let request = {
					page: this.page,
					sorts: {
						created_at: 'desc'
					},
					filters: [
						{
							relation: '',
							inputs: [
								{
									name: 'is_archive',
									field: 'is_archive',
									type: 'singleSelect',
									value: 0,
								},
								{
									name: 'board_pipeline_stage_id',
									field: 'board_pipeline_stage_id',
									type: 'singleSelect',
									value: this.stageId,
								}
							]
						}
					]
				}

				this.$http
					.get('boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.stageId + '/cards', {params: request})
					.then(response => {
						this.pageLoading = false;
						this.cards = this.cards.concat(response.body.rows);
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			create() {
				this.form = {
					//
				}

				this.createLoading = false;
				this.createVisible = true;
			},

			store() {
				this.createLoading = true;

				this.form.board_pipeline_stage_id = this.stageId;

				this.$http
					.post('boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.stageId + '/cards', this.form)
					.then(response => {
						this.createLoading = false;
						this.createVisible = false;

						this.cards = [response.body.data].concat(this.cards);
					})
					.catch(response => {
						this.createLoading = false;
						return this.responseError(response);
					})
			},

			endDraggable(event) {
				let cardId = parseInt(event.item.attributes['data-card'].value);

				let form = {
					process: 'draggable',
					stage_id: parseInt(event.to.attributes['data-stage'].value),
					card_id: cardId,
					sort: event.newIndex,
				}

				this.$http
					.put('boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.stageId + '/cards/' + cardId, form)
					.then(response => {
						//
					})
					.catch(response => {
						return this.responseError(response);
					})
			}
		}
	}
</script>
