<template>
    <div class="lesson-page">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    {{ $route.meta.title }}
                </div>
                <div class="actions">
                    <el-tooltip content="Filtrele" placement="bottom">
                        <el-button class="flat dark secondary" icon="el-icon-search" @click="handleSearch" />
                    </el-tooltip>
                    <el-tooltip content="Yeni Ders Olustur" placement="bottom">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="goRoute('/educations/lessons/lessons/create')" />
                    </el-tooltip>
                </div>
            </template>
            <div class="el-card__filters">
                <el-form :label-position="'top'" ref="filter" :model="filter" @submit.native.prevent="handleSearch">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item>
                                <el-input placeholder="ders adi..." suffix-icon="el-icon-search" v-model="filter.name" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item>
                                <el-select v-model="filter.plugin_id" placeholder="Servisler" clearable filterable>
                                    <el-option v-for="plugin in plugins" :key="plugin.id" :label="plugin.name" :value="plugin.id" v-if="plugin.sub_alias === 'meetings'">
                                        <div class="el-option__media">
                                            <div class="media">
                                                <img :src="'https://' + $store.state.ui.tenant.domain + plugin.preview" alt="preview">
                                            </div>
                                            <div class="text">
                                                <div class="title">{{ plugin.name }}</div>
                                                <div class="description">{{ plugin.title }}</div>
                                            </div>
                                        </div>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-date-picker v-model="filter.start_at" type="datetime" placeholder="Baslangic Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-date-picker v-model="filter.end_at" type="datetime" placeholder="Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-select v-model="filter.sort_type" placeholder="Siralama Turu" filterable>
                                    <el-option v-for="date in dates" :key="date.id" :label="date.title" :value="date.id"/>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-select v-model="filter.sort_value" placeholder="Siralama" filterable>
                                    <el-option v-for="sort in sorts" :key="sort.id" :label="sort.title" :value="sort.id"/>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item class="margin-bottom-0">
                                <product-dropdown :container="filterForm" model="requestProducts" :types="['group', 'subscription']" :multiple="true"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button native-type="submit" style="display: none"></el-button>
                </el-form>
            </div>
        </el-card>

        <div v-loading="pageLoading">
            <el-row :gutter="24">
                <template v-for="(row, index) in rows">
                    <el-col :span="12" :key="index">
                        <el-card class="lesson-row" v-loading="row.action">
                            <div class="lesson-wrp lesson-video">
                                <div class="content">
                                    <div class="figure" v-if="!row.cdn_record_link">
                                        Ders Kaydı İnidiriliyor...
                                    </div>
                                    <div class="figure" v-if="row.cdn_record_link" :key="row.id">
                                        <VideoPlayer :key="row.id" :duration="player.duration" :start="player.startTime" :videoSrc="row.cdn_record_link" :pins="[]" :auto-play="player.autoPlay"/>
                                    </div>
                                    <div class="text">
                                        <div class="title">
                                            {{ row.name }}
                                        </div>
                                        <div class="dates">
                                            <div class="date">
                                                <icon icon="timer-sand" /> {{ row.duration }} dk.
                                            </div>
                                            <div class="date">
                                                <icon icon="clock-outline" /> {{ row.start_at }} - {{ row.end_at }}
                                            </div>
                                            <div class="date">
                                                <icon icon="calendar-range" /> {{ row.day }}
                                            </div>
                                            <div class="date">
                                                <icon icon="robot-outline" /> {{ row.service }}
                                            </div>
                                            <div class="date">
                                                <icon icon="record-rec" /> {{ row.record_status }}
                                            </div>
                                        </div>
                                        <div class="product-metas metas">
                                            <template v-for="(product, productIndex) in row.products">
                                                <div class="meta" :key="productIndex">
                                                    <icon icon="package-up" /> <span v-html="product"></span>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="buttons">
                                            <router-link class="btn fluid" :to="'/educations/lessons/lessons/' + row.id" >
                                                <icon icon="square-edit-outline" />
                                                Düzenle
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </template>
            </el-row>
        </div>

        <div class="pagination" v-if="pagination && pagination.total > pagination.per_page">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="pagination.total"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page"
                @current-change="handleChangePage"
            />
        </div>
    </div>
</template>

<script>
import VideoPlayer from "@/app/Components/VideoPlayer.vue";

export default {
    name: 'LessonIndex',
    components: {VideoPlayer},
    data() {
        return {
            pageLoading: false,
            filter: {
                sort_type : 'created_at',
                sort_value : 'desc'
            },
            filterForm: {
                requestProducts: [],
            },

            plugins: [],

            rows: [],
            pagination: null,
            page: 1,
            perPage: 10,
            dates : [
                {
                    id : 'start_at',
                    title : 'Baslangic Tarihine Gore'
                },
                {
                    id : 'end_at',
                    title : 'Bitis Tarihine Gore'
                },
                {
                    id : 'created_at',
                    title : 'Olusturulma Tarihine Gore'
                },
                {
                    id : 'updated_at',
                    title : 'Guncellenme Tarihine Gore'
                }
            ],
            sorts : [
                {
                    id : 'asc',
                    title : 'Kucukten Buyuge'
                },
                {
                    id : 'desc',
                    title : 'Buyukten Kucuge'
                }
            ],
            player: {
                startTime: 0,
                autoPlay: false,
                duration: 120,
            },
        }
    },
    mounted() {
        this.getPlugins();
        this.index();
    },
    methods: {
        getPlugins() {
            this.pageLoading = true;

            this.$http
                .get('plugins')
                .then(response => {
                    this.plugins = response.body.data.services.items;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleChangePage(value) {
            this.page = value;
            this.index();
        },

        handleSearch() {
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            let filters = [];

            if (this.elementSize(this.filterForm.requestProducts)) {
                filters.push({
                    relation: 'products',
                    add_with: null,
                    inputs: [
                        {
                            name: 'product_id',
                            field: 'product_id',
                            type: 'multiSelect',
                            value: this.filterForm.requestProducts
                        }
                    ]
                })
            }

            this.$http
                .get(this.$route.meta.apiPoint, {
                    params: {
                        page: this.page,
                        perPage: this.perPage,
                        filter : this.filter,
                        columnFilters: {
                            name : this.filter.name,
                            plugin_id : this.filter.plugin_id,
                        },
                        filters: filters,
                    }
                })
                .then(response => {
                    this.rows = response.body.rows;
                    this.pagination = response.body.pagination;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        download(row) {


            this
                .$confirm('Bu ders ile ilgili daha önceden oluşturulan video kayıtları silinecek onaylıyor musunuz', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    row.action = true;

                    let form = {
                        process : 'reRecord',
                        service_id : row.service_id,
                    }

                    this.$http
                        .put(this.$route.meta.apiPoint + '/' + row.id, form)
                        .then(response => {
                            row.action = false;
                            row.record_status = response.record_status;
                            row.record_url = response.record_status;

                            return this.responseSuccess(response);
                        })
                        .catch(response => {
                            row.action = false;
                            return this.responseError(response);
                        })

                    return true;

                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        }
    }
}
</script>
