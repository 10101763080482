<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<div class="title">
					Linkler
				</div>
				<div class="actions">
					<el-tooltip content="Siralama Islemini Kaydet" placement="left">
						<el-button class="flat dark teal" icon="el-icon-check" @click="updateSort" />
					</el-tooltip>
					<el-tooltip content="Ekle" placement="left">
						<el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
					</el-tooltip>
				</div>
			</template>
			<div style="min-height: 500px">
				<menu-link-nested :items="items" :handleRowActionCommand="handleRowActionCommand" />
			</div>
		</el-card>

		<el-dialog title="Link" :visible.sync="dialogVisible" v-loading="dialogLoading">
			<el-form :label-position="'top'" ref="form" :model="form">

				<el-form-item label="Gorunum Turu">
					<el-select v-model="form.view_type" filterable allow-create default-first-option
							   placeholder="Gorunum Turu">
						<el-option v-for="item in layouts" :key="item.id" :label="item.label"
								   :value="item.id" />
					</el-select>
				</el-form-item>

				<el-form-item label="Baslik">
					<el-input v-model="form.title" placeholder="Baslik" />
				</el-form-item>

				<el-form-item label="Url">
					<el-input v-model="form.url" placeholder="Url" />
				</el-form-item>

				<el-form-item label="Siralama">
					<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_active" active-text="Aktif" />
				</el-form-item>

				<el-collapse accordion>
					<el-collapse-item title="Link Ozellikleri" name="1">

						<el-form-item label="Html Class">
							<el-input v-model="form.html_class" placeholder="Html Class" />
						</el-form-item>

						<el-form-item label="Ikon">
							<el-input v-model="form.icon" placeholder="Ikon" />
						</el-form-item>

						<el-form-item label="Rota Adi">
							<el-input v-model="form.route_name" placeholder="Rota Adi" />
						</el-form-item>

						<el-form-item label="Aciklama">
							<el-input type="textarea" rows="4" placeholder="Aciklama" v-model="form.description" />
						</el-form-item>

						<el-form-item label="Oncesi">
							<el-input type="textarea" rows="4" placeholder="Oncesi"
									  v-model="form.before" />
						</el-form-item>

						<el-form-item label="Sonrasi">
							<el-input type="textarea" rows="4" placeholder="Sonrasi" v-model="form.after" />
						</el-form-item>
					</el-collapse-item>
				</el-collapse>

			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
					Kaydet
				</el-button>
				<el-button class="fluid flat dark green large" @click="update" v-if="form.id">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    import MenuLinkNested from "../../Components/MenuLinkNested";

    export default {
        name: 'MenuLink',
        components: {
            MenuLinkNested,
        },
        props: ['menuId'],
        data() {
            return {
                pageLoading: false,
                dialogLoading: false,
                dialogVisible: false,
                form: {
                    //
                },

                items: [],

                layouts: [
                    {
                        id: 'basic',
                        label: 'Varsayilan'
                    },
                    {
                        id: 'onlyLinks',
                        label: 'Sadece Linkleri Goster'
                    },
					{
						id: 'mega',
						label: 'Mega Menu'
					}
                ],

				sortItems : [],
            }
        },
        methods: {

            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = 'system/menus/' + this.menuId + '/links';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint())
                    .then(response => {

                        this.items = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create(parentId) {
                this.form = {
                    parent_id : parseInt(parentId) ? parentId : 0,
				};

                this.dialogVisible = true;
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.dialogLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        this.dialogLoading = false;
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {

                this.dialogLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.dialogLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.dialogLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this.pageLoading = true;

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.pageLoading = false;

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                                this.pageLoading = false;
                            });

                        return true;

                    })
                    .catch((response) => {

                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        this.pageLoading = false;

                        return false;
                    });
            },

            /**
             * Item dropdown actions
             *
             * @param command
             */
            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroy(command.id);
                }

                if (command.type === 'create') {
                    return this.create(command.id);
                }

                this.edit(command.id);
            },

            /**
             *
             */
            updateSort() {
                this.sortItems = [];

                this.generateRequestSortItems(this.items);

                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(this.form.id), {
                        items : this.sortItems,
						view : 'sort'
					})
                    .then(response => {
                        this.pageLoading = false;

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

			generateRequestSortItems(items, parentId) {

                if (!parentId) {
                    parentId = 0;
				}

                this.lodash.forEach(items, (item, key) => {
                    this.sortItems.push({
                        id: item.id,
                        parent_id: parentId,
                        sort : key
                    });

					if (this.lodash.size(item.nested)) {
                        this.generateRequestSortItems(item.nested, item.id)
					}
                });
			}
        },
        mounted() {
            this.index();
        },
    }
</script>
