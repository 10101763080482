<template>
	<el-select v-model="container[model]" :placeholder="placeholder ? placeholder : 'Seçiminiz'" :multiple="multiple" clearable filterable @change="handleSelected">
		<el-option v-for="item in sortItems(options)" :key="id ? item[id] : item.id" :label="title ? item[title] : item.title" :value="id ? item[id] : item.id" />
        <template slot="prefix">
            <i class="el-input__icon el-icon-s-operation"></i>
        </template>
	</el-select>
</template>

<script>
	export default {
		name: 'TitleIdSelect',
		props: ['container', 'model', 'label', 'multiple', 'options', 'change', 'placeholder', 'title', 'id'],
		methods: {
			handleSelected(e) {
				if (!this.change) {
					return;
				}

				this.change(e)
			},

			sortItems(items) {
				return this.lodash.sortBy(items, [(o) => {
					if (this.title) {
						return o[this.title];
					}

					return o.title
				}])
			},
		},
	};
</script>
