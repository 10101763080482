<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<el-form-item :label="alias.title" v-if="alias">
					<el-select
							v-model="form.parent_id"
							value-key="id"
							filterable
							remote
							reserve-keyword
							:placeholder="alias.title"
							:remote-method="search"
							:loading="searchLoading">
						<el-option v-for="item in zones" :key="item.id" :label="item.title" :value="item.id" />
					</el-select>
				</el-form-item>

				<el-form-item label="Baslik">
					<el-input v-model="form.title" placeholder="Baslik" />
				</el-form-item>

				<el-form-item label="Siralama">
					<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_active" active-text="Aktif" />
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
        },
        data() {
            return {
                pageLoading: false,
                searchLoading: false,
                form: {
                    alias: this.$route.meta.alias
                },

                alias: null,
                aliases: {
                    countries: {
                        alias: 'countries',
                        title: 'Ulke',
                    },
                    cities: {
                        alias: 'cities',
                        title: 'Sehir',
                    },
                    towns: {
                        alias: 'towns',
                        title: 'Ilce',
                    },
                    districts: {
                        alias: 'districts',
                        title: 'Semt',
                    },
                    neighborhoods: {
                        alias: 'neighborhoods',
                        title: 'Mahalle',
                    },
                },
                zones: [],
            }
        },
        methods: {

            /**
             * Show page
             */
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;

                        if (this.form.parent) {
                            this.zones.push(this.form.parent)
						}
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            search(query) {

                this.searchLoading = true;

                this.$http
                    .get('tools/search/zones?q=' + query + '&alias=' + this.alias.alias)
                    .then(response => {
                        this.searchLoading = false;
                        this.zones = response.body.data;
                    })
                    .catch(() => {
                        this.searchLoading = false;
                    });
            },

            findAlias() {
                switch (this.form.alias) {
                    case 'cities':
                        return this.aliases.countries;
                    case 'towns':
                        return this.aliases.cities;
                    case 'districts':
                        return this.aliases.towns;
                    case 'neighborhoods':
                        return this.aliases.districts;
                }
            }
        },
        mounted() {
            if (this.$route.params.id) {
                this.show();
            }

            this.alias = this.findAlias();
        },
    }
</script>
