<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    {{ this.$route.meta.title }}
                </div>
                <div class="actions">
                    <el-tooltip content="Yenile" placement="left">
                        <el-button class="flat dark purple" icon="el-icon-refresh" @click="refreshPage"/>
                    </el-tooltip>
                    <el-tooltip content="Ekle" placement="left">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="goRoute($route.fullPath + '/create')" v-if="!$route.meta.createDisable" />
                    </el-tooltip>
                </div>
            </template>

            <Datatable ref="DataTable" :apiPoint="$route.meta.apiPoint" :key="$route.fullPath" />
        </el-card>
    </div>
</template>

<script>
import Datatable from "../Components/Datatable";
import CrudHeader from "../Components/CrudHeader";

export default {
    name: 'DatatableContainer',
    components: {CrudHeader, Datatable},
    data() {
        return {
            pageLoading: true,
        }
    },
    mounted() {
        this.pageLoading = false;
    },
    methods : {
        refreshPage() {
            this.$refs.DataTable.loadItems();
        }
    }
}
</script>