<template>
    <el-drawer title="İşlem Kayıtları" :visible.sync="visible">
        <div v-loading="pageLoading">
            <el-card class="card-flat-list">
                <div class="card-flat-list-items sm">
                    <template v-for="(item, index) in items">
                        <div class="item log-item" :key="index">
                            <div class="text">
                                <div class="description">
                                    <span class="user">#{{ item.user }}</span> tarafından

                                    <template v-if="item.message">
                                        <span v-html="item.message"></span>
                                    </template>

                                    <template v-if="!item.message">
                                        <span>{{ $t('logs.actions.' + item.action_type) }}</span>
                                    </template>
                                </div>
                                <div class="date">
                                    <i class="el-icon-date"></i> {{ item.date }}
                                </div>
                            </div>
                        </div>
                    </template>

                </div>
            </el-card>
        </div>
    </el-drawer>
</template>

<script>
export default {
    name: 'ProcessLog',
    props: ['id', 'controller'],
    data() {
        return {
            pageLoading: false,
            visible: false,
            items: [],
        }
    },
    methods: {
        index() {
            this.pageLoading = true;

            this.$http
                .get('tools/logs/processes', {
                    params : {
                        sorts : {created_at : 'desc'},
                        filters: [
                            {
                                relation: null,
                                inputs: [
                                    {
                                        name: 'logable_type',
                                        field: 'logable_type',
                                        type: 'singleSelect',
                                        value: this.controller,
                                    },
                                    {
                                        name: 'logable_id',
                                        field: 'logable_id',
                                        type: 'singleSelect',
                                        value: this.id,
                                    }
                                ]
                            },
                        ],
                    }})
                .then(response => {
                    this.items = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleVisible() {
            this.visible = true;
            this.index();
        }
    }
}
</script>
