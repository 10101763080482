<template>
    <div>
        <div class="page-tab-switch">
            <template v-for="(item, index) in items">
                <router-link :to="item.link" :key="index">
                    {{ item.title }}
                </router-link>
            </template>
        </div>

        <div class="margin-top-20">
            <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Lesson',
    components: {
        //
    },
    data() {
        return {
            items: [
                {
                    key: 'lessons',
                    title: 'Dersler',
                    link: '/educations/lessons/lessons',
                },
                {
                    key: 'try',
                    title: 'Deneme Dersi Olustur',
                    link: '/educations/lessons/try',
                },
                {
                    key: 'lesson-management',
                    title: 'Toplu Ders Islemleri',
                    link: '/educations/lessons/lesson-management',
                },
                {
                    key: 'lesson-records',
                    title: 'Ders Kayıtları',
                    link: '/educations/lessons/lesson-records',
                },
            ],
        }
    }
}
</script>
