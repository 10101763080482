<template>
    <div class="product-dropdown" v-loading="pageLoading">
        <el-select v-model="container[model]" placeholder="Urunler" :multiple="multiple" filterable clearable :filter-method="filterMethod">
            <el-option v-for="item in filterItems" :key="item.id" :label="item.title" :value="item.id">
                {{ item.ui_title }}
            </el-option>
            <template slot="prefix">
                <icon icon="package-up" class="el-input__icon"/>
            </template>
        </el-select>
    </div>
</template>

<script>
export default {
    name: 'ProductDropdown',
    props: ['container', 'model', 'types', 'options', 'multiple'],
    data() {
        return {
            items: [],
            filterItems : [],
            pageLoading: false,
        };
    },
    mounted() {
        this.index();
    },
    methods: {
        filterMethod(query) {

            if (this.lodash.size(query) < 5) {
                return;
            }

            this.filterItems = [];

            this.items.forEach((item) => {
                if (this.textSearch(query, item.ui_title)) {
                    this.filterItems.push(item)
                }
            })
        },

        index() {

            if (this.options) {
                return this.items = this.options;
            }

            if (this.$store.getters.products) {
                this.items = this.$store.getters.products;
                this.filterItems = this.$store.getters.products;

                return;
            }

            this.pageLoading = true;

            this.$http
                .get('tools/search/products', {params: {types: this.types}})
                .then(response => {
                    this.pageLoading = false;
                    this.items = response.body.data;
                    this.filterItems = response.body.data;

                    this.$store.commit('setProducts', response.body.data);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    },
};
</script>
