import LayoutConfig from '../config/layout'
import Container from '../app/Container/Main'

import Profile from '../app/Pages/Account/Profile'

export default {
    path: '/account',
    name: 'api.account.index',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Hesabim',
    },
    children: [
        {
            name: 'api.account.profile.index',
            path: 'profile',
            component: Profile,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Profil Bilgilerim',
            },
        }
    ]
}