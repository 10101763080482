<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">
                        Alistirmalar
                    </div>
                    <div class="actions">
                        <el-tooltip content="Alistirma Ekle" placement="left">
                            <el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
                        </el-tooltip>
                    </div>
                </div>
            </template>

            <template v-for="(exercise, index) in items">
                <div :key="index">
                    <div class="card-sub-title">
                        <div class="title">
                            {{ exercise.title }}
                        </div>
                        <div class="actions">
                            <el-tooltip content="Duzenle" placement="left">
                                <el-button class="flat dark green" icon="el-icon-edit" @click="edit(exercise.id)" />
                            </el-tooltip>
                            <el-tooltip content="Sil" placement="left">
                                <el-button class="flat dark red" icon="el-icon-delete" @click="destroy(exercise.id)" />
                            </el-tooltip>
                        </div>
                    </div>

                    <BookExerciseContent :id="exercise.id" class="margin-bottom-20" />
                </div>
            </template>
        </el-card>

        <el-dialog title="Alistirma Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="50%">
            <el-form :label-position="'top'" ref="form" :model="form" :key="form.id">
                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Baslik" />
                </el-form-item>

                <el-form-item label="Yayinlama Basligi">
                    <el-input v-model="form.public_title" placeholder="Yayinlama Basligi" />
                </el-form-item>

                <el-form-item label="Kisa Aciklama" class="is-editor margin-bottom-0">
                    <editor :container="form" model="short_description" :small="true" />
                </el-form-item>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import BookExerciseContent from "@/app/Pages/Education/Book/Content";
export default {
    name: 'BookExercise',
    components: {BookExerciseContent},
    props: ['id'],
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            form: {
                //
            },

            items: [],
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = this.$route.meta.apiPoint + '/' + this.id + '/exercises';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        /**
         * All items
         */
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                .then(response => {

                    this.items = response.body.rows;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         *  Create modal
         */
        create() {
            this.form = {
                //
            };
            this.dialogVisible = true;
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form save
         */
        store() {
            this.pageLoading = true;

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;

                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

    }
}
</script>