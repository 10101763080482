<template>
    <div class="catalog-page-detail-content-tab">
        <Tabs :id="form.id" path="cms/products/contents" />
    </div>
</template>

<script>
import Tabs from "@/app/Pages/Cms/Contents/Tabs";

export default {
    name: 'ProductTabs',
    props: ['tab', 'form'],
    components: {
        Tabs
    },
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>