<template>
	<div>
		<tinymce v-model="container[model]" :api-key="token" :init="config" />

		<el-dialog width="90%" title="Dosya Yöneticisi" :visible.sync="fileManagerDialogVisible" append-to-body class="file-manager-dialog">
            <template slot="title">
                <div class="head">
                    <div class="title">Dosya Yöneticisi</div>
                    <div class="action">
                        <el-button class="modern green" @click="handleSelected">
                            Dosyayi Sec
                        </el-button>
                    </div>
                </div>
            </template>

            <div class="ui message yellow" style="margin: 0; border-radius: 0">
                Lutfen resim yuklerken, dosya formatini <b>.webp</b> cevirmeyi ve <b>boyutunu kucultmeyi</b> unutmayiniz
            </div>

			<file-manager :settings="fileManagerSettings" />
		</el-dialog>
	</div>
</template>

<script>
	import tinymce from '@tinymce/tinymce-vue'

	export default {
		name: 'Editor',
		props: ['container', 'model', 'small', 'mini'],
		components: {
			tinymce
		},
		data() {
			return {
				token: 'x90rn1iycrzopt9z5rrsnjbrg0y9rxiz2mh3g2kdu8ili7n2',
				config: {
                    inline: true,
					verify_html: false,
					entity_encoding: 'raw',
					extended_valid_elements: '*[*]',
					height: 120,
					menubar: false,
					forced_root_block: '',
					paste_as_text: true,
                    deprecation_warnings: false,
                    powerpaste_word_import: 'clean',
                    powerpaste_html_import: 'clean',
					plugins: 'paste importcss tinydrive searchreplace autolink autosave code save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
					toolbar: 'code | image media | bold italic underline | fontsizeselect |  numlist bullist | table ',
					file_picker_callback: (callback) => {
						this.fileManagerDialogVisible = true;
						this.fileManagerDialogCallback = callback;
					}
				},
				fileManagerDialogVisible: false,
				fileManagerDialogCallback: null
			}
		},
		created() {
			this.config.height = 400;

			if (this.small) {
				this.config.height = 200;
			}

			if (this.mini) {
				this.config.height = 120;
			}
		},
		methods: {
			handleSelected() {
				let files = this.$store.state.fm.left.selected.files;

				if (!this.lodash.size(files)) {
					return this.$notify
						.error({
							title: 'Upss!',
							message: 'Dosya secimi yapmaniz gerekiyor!',
							position: 'top-right'
						});
				}

                this.fileManagerDialogCallback(this.appConfig.s3Path + '/' + files[0]);
				this.fileManagerDialogVisible = false;
			}
		}
	};
</script>

<style>
	.tox {
		z-index : 9999 !important;
	}

	.tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
		z-index : 99999 !important;
	}

	.file-manager-dialog {
		z-index : 999999 !important;
	}
</style>
