<template>
	<div>
		<div class="page-header">
			<div class="content">
				<div class="header">
					{{ $route.meta.title }}
				</div>
			</div>

			<Breadcrumb :key="$route.name" />

		</div>
		<div class="page-content" :key="$route.name">
			<router-view :key="$route.fullPath" />
		</div>
	</div>
</template>

<script>

    import Breadcrumb from "../Components/Breadcrumb";

    export default {
        name: 'PageContainer',
        components: {Breadcrumb},
    }
</script>