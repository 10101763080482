<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list">
			<template slot="header">
				<div class="title">
                    <icon icon="feature-search-outline" />
					Ozellikler
				</div>
				<div class="actions">
					<el-tooltip content="Ekle" placement="left">
						<el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
					</el-tooltip>
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                <empty v-if="!elementSize(items)" />
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text display-flex align-items-center">
						<div>
							<div class="title">{{ item.feature.title }}</div>
						</div>
					</div>
					<div class="actions" style="right: 20px">
						<el-dropdown @command="handleRowActionCommand">
							<el-button type="text" class="flat dark grey" icon="el-icon-arrow-down" />
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{type : 'edit', id : item.id}">
									<i class="el-icon-edit text-green"></i> Duzenle
								</el-dropdown-item>
								<el-dropdown-item :command="{type : 'destroy', id : item.id}">
									<i class="el-icon-delete text-red"></i> Sil
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog title="Ozellik Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" class="dialog-with-page custom-padding" append-to-body>
			<el-form :label-position="'top'" ref="form" :model="form">
				<el-card shadow="never" class="no-shadow custom-padding card-flat-list">
					<div class="card-custom-padding-box-items">
						<el-form-item label="Ozellikler">
							<el-select v-model="form.feature_id" @change="handleChange" placeholder="Ozellikler">
								<el-option v-for="(item, key) in features" :key="'features-' + key" :label="item.title" :value="item.id" />
							</el-select>
						</el-form-item>
						<el-form-item label="Siralama">
							<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
						</el-form-item>
					</div>

					<div v-if="form.feature_id" :key="form.feature_id">
						<div class="card-sub-title d-flex align-items-center justify-content-between">
							<div>Adimlar</div>
							<div>
								<el-button icon="el-icon-plus" class="flat dark blue" @click="createStepItem" />
							</div>
						</div>
						<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
							<div class="item" v-for="(step, stepKey) in form.items" :key="stepKey">
								<div class="text">
									<el-row :gutter="24">
										<el-col :span="20">
											<el-form-item>
												<el-select v-model="step.feature_item_id" value-key="id" placeholder="Adimlar">
													<el-option v-for="(item, index) in featureItems" :key="index" :label="item.title" :value="item.id" />
												</el-select>
											</el-form-item>
										</el-col>
										<el-col :span="4">
											<el-form-item>
												<el-input v-model="step.sort" placeholder="Siralama" type="number" />
											</el-form-item>
										</el-col>
									</el-row>
									<el-form-item>
										<el-input v-model="step.description" type="textarea" placeholder="Aciklama" />
									</el-form-item>
								</div>
								<div class="actions" style="right: 20px;">
									<el-button class="flat dark red" icon="el-icon-delete" @click="destroyStepItem(stepKey)">
										Sil
									</el-button>
								</div>
							</div>
						</div>
					</div>
				</el-card>
			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
					Kaydet
				</el-button>
				<el-button class="fluid flat dark green large" @click="update" v-if="form.id">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'Features',
        props: ['id', 'path'],
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    items: []
                },

                items: [],
                features: [],
				featureItems : []

            }
        },
        methods: {

            getFeatures() {
                this.pageLoading = true;

                this.$http
                    .get('cms/features', {params: {perPage: 1000}})
                    .then(response => {

                        this.features = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getFeatureItems(id) {
                this.pageLoading = true;

                this.$http
                    .get('cms/features/' + id + '/items', {params: {perPage: 1000}})
                    .then(response => {
                        this.featureItems = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = (this.path ? this.path : this.$route.meta.apiPoint) + '/' + this.id + '/features';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {
                        params: {
                            perPage: 1000,
                            sorts: {sort: 'asc'},
                            with: ['feature', 'items.item']
                        }
                    })
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {
                    items : []
                };
                this.dialogVisible = true;
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {

                        this.form = response.body.data;

                        this.getFeatureItems(this.form.feature_id)

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            /**
             * Item dropdown actions
             *
             * @param command
             */
            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroy(command.id);
                }

                this.edit(command.id);
            },

            handleChange(event) {

                this.form.items = [];

                this.getFeatureItems(event)
            },

            createStepItem() {

                this.form.items.push({
                    feature_item_id: null,
                    description: null,
                    sort: 100,
                });
    },

            destroyStepItem(key) {
                this.form.items.splice(key, 1);
            },
        },
        mounted() {
            this.index();
            this.getFeatures();
        },
    }
</script>
