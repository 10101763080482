<template>
    <div class="lesson-page">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    {{ $route.meta.title }}
                </div>
                <div class="actions">
                    <el-tooltip content="Filtrele" placement="bottom">
                        <el-button class="flat dark secondary" icon="el-icon-search" @click="handleSearch"/>
                    </el-tooltip>
                    <el-tooltip content="Yeni Ders Olustur" placement="bottom">
                        <el-button class="flat dark blue" icon="el-icon-plus"
                                   @click="goRoute('/educations/lessons/lessons/create')"/>
                    </el-tooltip>
                </div>
            </template>
            <div class="el-card__filters">
                <el-form :label-position="'top'" ref="filter" :model="filter" @submit.native.prevent="handleSearch">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item>
                                <el-input placeholder="ders adi..." suffix-icon="el-icon-search" v-model="filter.name"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-select v-model="filter.plugin_id" placeholder="Servisler" clearable filterable>
                                    <el-option v-for="plugin in plugins" :key="plugin.id" :label="plugin.name"
                                               :value="plugin.id" v-if="plugin.sub_alias === 'meetings'">
                                        <div class="el-option__media">
                                            <div class="media">
                                                <img :src="'https://' + $store.state.ui.tenant.domain + plugin.preview" alt="preview">
                                            </div>
                                            <div class="text">
                                                <div class="title">{{ plugin.name }}</div>
                                                <div class="description">{{ plugin.title }}</div>
                                            </div>
                                        </div>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <statuses alias="lessons" :container="filter" model="feedback_status_id" placeholder="Ders Durumu" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-date-picker v-model="filter.start_at" type="datetime" placeholder="Baslangic Tarihi"
                                                value-format="yyyy-MM-dd HH:mm:ss"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-date-picker v-model="filter.end_at" type="datetime" placeholder="Bitis Tarihi"
                                                value-format="yyyy-MM-dd HH:mm:ss"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-select v-model="filter.sort_type" placeholder="Siralama Turu" filterable>
                                    <el-option v-for="date in dates" :key="date.id" :label="date.title"
                                               :value="date.id"/>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-select v-model="filter.sort_value" placeholder="Siralama" filterable>
                                    <el-option v-for="sort in sorts" :key="sort.id" :label="sort.title"
                                               :value="sort.id"/>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item class="margin-bottom-0">
                                <product-dropdown :container="filterForm" model="requestProducts"
                                                  :types="['group', 'subscription']" :multiple="true"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button native-type="submit" style="display: none"></el-button>
                </el-form>
            </div>
        </el-card>

        <div class="text-right margin-bottom-20">
            <el-button class="flat dark red" icon="el-icon-trash" @click="multipleDestroy">
                Secili Olanlari Sil
            </el-button>
        </div>

        <div v-loading="pageLoading">
            <template v-for="(row, index) in rows">
                <el-card :key="index" class="lesson-row">
                    <div class="lesson-wrp">
                        <div class="content">
                            <el-tooltip content="Sec" placement="top">
                                <div class="media cursor-pointer" @click="handleSelectedDeleteItem(row)"
                                     :class="{delete : row.action}">
                                    <icon icon="play-box-outline"/>
                                </div>
                            </el-tooltip>
                            <router-link class="text" :to="'/educations/lessons/lessons/' + row.id">
                                <div class="title">{{ row.name }}</div>
                                <div class="dates">
                                    <div class="date">
                                        <icon icon="timer-sand"/>
                                        {{ row.duration }} dk.
                                    </div>
                                    <div class="date">
                                        <icon icon="clock-outline"/>
                                        {{ row.start_at }} - {{ row.end_at }}
                                    </div>
                                    <div class="date">
                                        <icon icon="calendar-range"/>
                                        {{ row.day }}
                                    </div>
                                    <div class="date">
                                        <icon icon="robot-outline"/>
                                        {{ row.service }}
                                    </div>
                                    <div class="date" v-if="row.feedback_status.title" :style="{color : row.feedback_status.bg_color}">
                                        <icon icon="compass-outline"/>
                                        {{ row.feedback_status.title }}
                                    </div>
                                </div>
                                <div class="product-metas metas">
                                    <template v-for="(product, productIndex) in row.products">
                                        <div class="meta" :key="productIndex">
                                            <icon icon="package-up"/>
                                            <span v-html="product"></span>
                                        </div>
                                    </template>
                                </div>
                            </router-link>
                        </div>
                        <div class="actions">
                            <el-tooltip content="Sil" placement="left">
                                <el-button type="text" class="flat dark red large" icon="el-icon-delete"
                                           @click="destroy(row.id)"/>
                            </el-tooltip>
                        </div>
                    </div>
                </el-card>
            </template>
        </div>

        <div class="pagination" v-if="pagination && pagination.total > pagination.per_page">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="pagination.total"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page"
                @current-change="handleChangePage"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LessonIndex',
    components: {
        //
    },
    data() {
        return {
            pageLoading: false,
            filter: {
                sort_type: 'created_at',
                sort_value: 'desc'
            },

            filterForm: {
                requestProducts: [],
            },

            plugins: [],
            rows: [],
            pagination: null,
            page: 1,
            perPage: 10,
            dates: [
                {
                    id: 'start_at',
                    title: 'Baslangic Tarihine Gore'
                },
                {
                    id: 'end_at',
                    title: 'Bitis Tarihine Gore'
                },
                {
                    id: 'created_at',
                    title: 'Olusturulma Tarihine Gore'
                },
                {
                    id: 'updated_at',
                    title: 'Guncellenme Tarihine Gore'
                }
            ],
            sorts: [
                {
                    id: 'asc',
                    title: 'Kucukten Buyuge'
                },
                {
                    id: 'desc',
                    title: 'Buyukten Kucuge'
                }
            ],
        }
    },
    mounted() {
        this.getPlugins();
        this.index();
    },
    methods: {
        getPlugins() {
            this.pageLoading = true;

            this.$http
                .get('plugins')
                .then(response => {
                    this.plugins = response.body.data.services.items;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleChangePage(value) {
            this.page = value;
            this.index();
        },

        handleSearch() {
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            let filters = [];

            if (this.elementSize(this.filterForm.requestProducts)) {
                filters.push({
                    relation: 'products',
                    add_with: null,
                    inputs: [
                        {
                            name: 'product_id',
                            field: 'product_id',
                            type: 'multiSelect',
                            value: this.filterForm.requestProducts
                        }
                    ]
                })
            }

            this.$http
                .get(this.$route.meta.apiPoint, {
                    params: {
                        page: this.page,
                        perPage: this.perPage,
                        filter: this.filter,
                        columnFilters: {
                            name: this.filter.name,
                            plugin_id: this.filter.plugin_id,
                            feedback_status_id: this.filter.feedback_status_id,
                        },
                        filters: filters,
                    }
                })
                .then(response => {
                    this.rows = response.body.rows;
                    this.pagination = response.body.pagination;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.$route.meta.apiPoint + '/' + id)
                        .then(response => {
                            this.index();
                            this.responseSuccess(response);
                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        multipleDestroy() {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    let ids = [];

                    this.lodash.forEach(this.rows, (row) => {
                        if (row.action) {
                            ids.push(row.id);
                        }
                    });

                    this.$http
                        .delete(this.generateApiPointWithAlias(this.$route.meta.apiPoint) + '/9999999', {
                            params: {
                                ids: ids,
                                process: 'multiple'
                            }
                        })
                        .then(response => {
                            this.responseSuccess(response);
                            this.loadItems();
                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        handleSelectedDeleteItem(row) {
            row.action = !row.action;
        }
    }
}
</script>
