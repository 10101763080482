<template>
	<div class="pipeline-page" v-loading="pageLoading">
		<div v-if="response" :key="response.pipeline ? response.pipeline.id : null">

            <div class="headv2">
                <div class="left">
                    <div class="back" @click="goRoute('/boards')">
                        <i class="el-icon-back"></i>
                    </div>
                    <div class="media" :style="{background : response.pipeline ? response.pipeline.color : null}">
                        <icon icon="chart-box-outline" />
                    </div>
                    <div class="text">
                        <div class="title" v-if="response.pipeline">
                            <el-select v-model="activeBoardPipelineId" @change="handleChangePipeline">
                                <el-option
                                    v-for="boardPipeline in response.board.pipelines"
                                    :key="boardPipeline.id"
                                    :label="boardPipeline.title"
                                    :value="boardPipeline.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="sub-title">{{ response.board.title }}</div>
                    </div>
                </div>
                <div class="right">
                    <el-popover placement="bottom-end" width="320" trigger="click">
                        <el-button icon="icon mdi mdi-dots-vertical" class="flat dark large grey icon" slot="reference" />
                        <div class="pipeline-list-popover">
                            <div class="item" @click="create">
                                <icon icon="text-box-plus-outline" />
                                Yeni Islem Hatti Ekle
                            </div>
                            <div class="item" v-if="response.pipeline" @click="edit">
                                <icon icon="file-document-edit-outline" />
                                Islem Hattini Duzenle
                            </div>
                            <div class="item" v-if="response.pipeline" @click="destroy">
                                <icon icon="trash-can-outline" />
                                Islem Hattini Sil
                            </div>
                            <div class="item" @click="boardDialogVisible = true">
                                <icon icon="square-edit-outline" />
                                Gorevi Duzenle
                            </div>
                            <div class="item" @click="exportPipeline">
                                <icon icon="square-edit-outline" />
                                Excel Ciktisi Al
                            </div>
                        </div>
                    </el-popover>
                </div>
            </div>

			<div class="stages" v-if="response.pipeline">
				<Stage :pipelineId="response.pipeline.id" />
			</div>

			<router-view :key="$route.fullPath" />

			<el-dialog title="Gorev Islemleri" :visible.sync="boardDialogVisible" v-loading="dialogLoading">
				<el-form :label-position="'top'" ref="board" :model="response.board">
					<el-form-item label="Baslik">
						<el-input v-model="response.board.title" placeholder="Baslik" />
					</el-form-item>
				</el-form>

				<template slot="footer">
					<el-button class="modern red large fluid" icon="el-icon-delete" @click="boardDestroy">
						Sil
					</el-button>
					<el-button class="modern green large fluid" icon="el-icon-edit" @click="boardUpdate">
						Guncelle
					</el-button>
				</template>
			</el-dialog>

			<el-dialog title="Islem Hatti" :visible.sync="dialogVisible" v-loading="dialogLoading">
				<el-form :label-position="'top'" ref="board" :model="form">
					<el-form-item label="Baslik">
						<el-input v-model="form.title" placeholder="Baslik" />
					</el-form-item>

					<el-form-item label="Renk">
						<el-select v-model="form.color" placeholder="Renk">
							<el-option v-for="color in appConfig.config.colors" :key="color.value" :label="color.label"
									   :value="color.value" class="color-option" :style="{background : color.value}" />
						</el-select>
					</el-form-item>

					<el-form-item>
						<el-switch v-model="form.is_main" active-text="Acilis islem hatti olarak sec." />
					</el-form-item>
				</el-form>

				<template slot="footer">
					<el-button class="modern green large fluid" icon="el-icon-edit" @click="update" v-if="form.id">
						Guncelle
					</el-button>
					<el-button class="modern green large fluid" icon="el-icon-check" @click="store" v-if="!form.id">
						Kaydet
					</el-button>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import Stage from "./Stage";
	export default {
		name: 'Pipeline',
		components: {Stage},
		data() {
			return {
				pageLoading: false,
				response: null,
				dialogVisible: false,
				dialogLoading: false,
				form: {
					//
				},

				boardDialogVisible : false,

                activeBoardPipelineId : null,
			}
		},
		mounted() {
			this.show(this.$route.params.pipeId);
		},
		methods: {
			endPoint(key) {
				if (!key) {
					return 'boards/' + this.$route.params.id + '/pipelines'
				}

				return 'boards/' + this.$route.params.id + '/pipelines/' + key
			},

			show(id) {
				this.pageLoading = true;

				this.$http
					.get(this.endPoint(id))
					.then(response => {
						this.response = response.body.data;

						this.activeBoardPipelineId = this.response.pipeline.id

						this.pageLoading = false;
					})
					.catch(response => {
						this.response = null;
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			create() {
				this.form = {
					//
				};

				this.dialogLoading = false;
				this.dialogVisible = true;
			},

			edit() {
				this.form = this.response.pipeline;
				this.dialogLoading = false;
				this.dialogVisible = true;
			},

			store() {
				this.dialogLoading = true;

				this.$http
					.post(this.endPoint(), this.form)
					.then(response => {
						this.dialogLoading = false;
						this.dialogVisible = false;

						this.goRoute('/' + this.endPoint(response.body.data.id));
					})
					.catch(response => {
						this.dialogLoading = false;
						return this.responseError(response);
					})
			},

			update() {
				this.dialogLoading = true;

				this.$http
					.put(this.endPoint(this.$route.params.pipeId), this.form)
					.then(response => {
						this.dialogLoading = false;
						this.dialogVisible = false;
						this.form = {
							//
						};

						this.response.board.pipelines[response.body.data.id] = response.body.data;
					})
					.catch(response => {
						this.dialogLoading = false;
						return this.responseError(response);
					})
			},

			destroy() {
				this.pageLoading = true;

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {

						this.$http
							.delete(this.endPoint(this.$route.params.pipeId))
							.then(response => {
								this.pageLoading = false;
								this.responseSuccess(response);

								this.response.board.pipelines[this.response.pipeline.id] = null;
								this.response.pipeline = null;
							})
							.catch(response => {
								this.responseError(response);
								this.pageLoading = false;
							});

						return true;

					})
					.catch((response) => {

						this.pageLoading = false;
						this.dialogLoading = false;

						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},

			boardUpdate() {
				this.dialogLoading = true;

				this.$http
					.put('boards/' + this.$route.params.id, {title: this.response.board.title})
					.then(response => {
						this.dialogLoading = false;
						this.boardDialogVisible = false;
					})
					.catch(response => {
						this.dialogLoading = false;
						return this.responseError(response);
					})
			},

			boardDestroy() {
				this.dialogLoading = true;

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {

						this.$http
							.delete('boards/' + this.$route.params.id)
							.then(response => {
								this.responseSuccess(response);
								this.goRoute('/boards')
							})
							.catch(response => {
								this.responseError(response);
								this.dialogLoading = false;
							});

						return true;

					})
					.catch((response) => {

						this.dialogLoading = false;

						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},

            handleChangePipeline(id) {
			    return this.goRoute('/' + this.endPoint(id));
            },

            exportPipeline() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), {id : this.response.pipeline.id, process : 'export'})
                    .then(response => {
                        this.pageLoading = false;
                        this.openBlankWindow(response.body.data.link);
                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            }
		}
	}
</script>
