<template>
    <el-row :gutter="24" style="width: 100%;">
        <el-col :span="4">
            <el-form-item :label="item.label">
                <fs v-model="container[item.name]" />
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: 'Image',
    props : ['form', 'container', 'item'],
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>