<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" :store="handleClickStore"/>
            </template>

            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-sub-title">Ogrenci Bilgileri</div>
                <div class="card-custom-padding-box-items">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Adı" :class="{ 'is-error': submitted && $v.form.first_name.$error }">
                                <el-input v-model="form.first_name" placeholder="Adı" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Soyadı" :class="{ 'is-error': submitted && $v.form.last_name.$error }">
                                <el-input v-model="form.last_name" placeholder="Soyadı" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="E-Posta Adresi" :class="{ 'is-error': submitted && $v.form.email.$error }">
                        <el-input v-model="form.email" placeholder="E-Posta Adresi" />
                    </el-form-item>
                </div>
                <div class="card-sub-title" :key="form.start_at">
                    Ders Bilgileri <span v-if="form.start_at">[{{ form.start_at }} - {{ form.end_at }}]</span>
                </div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Servis" :class="{ 'is-error': submitted && $v.form.plugin_id.$error }">
                        <lv-select :container="form" model="plugin_id" :options="plugins"/>
                    </el-form-item>

                    <el-form-item label="Ogretmen" :class="{ 'is-error': submitted && $v.form.teacher_id.$error }">
                        <user :container="form" model="teacher_id" placeholder="Ogretmen" />
                    </el-form-item>

                    <el-form-item label="Ders Süresi (Dk)" :class="{ 'is-error': submitted && $v.form.duration.$error }">
                        <el-input type="number" placeholder="Süre DK" v-model="form.duration"/>
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Saat Araligi" :class="{ 'is-error': submitted && $v.form.start_at.$error }">
                                <el-time-picker
                                    value-format="HH:mm:ss"
                                    is-range
                                    v-model="form.times"
                                    range-separator="-"
                                    start-placeholder="Baslangic Saati"
                                    end-placeholder="Bitis Saati" @change="handleChangeDate">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Tarih" :class="{ 'is-error': submitted && $v.form.end_at.$error }">
                                <el-date-picker v-model="form.date" type="date" placeholder="Tarih" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" @change="handleChangeDate"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import CrudHeader from "@/app/Components/CrudHeader";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'LessonCreate',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            submitted: false,
            plugins: [],
            form: {
                process: 'try',
                // Student
                email : '@minidil.com',

                // Lesson
                start_at : null,
                end_at : null,
            }
        }
    },
    validations: {
        form: {
            first_name: {required},
            last_name: {required},
            email: {required},
            start_at: {required},
            end_at: {required},
            duration: {required},
            teacher_id: {required},
            plugin_id: {required},
        }
    },
    mounted() {
        this.getPlugins()
    },
    methods: {
        getPlugins() {
            this.pageLoading = true;

            this.$http
                .get('tools/search/plugins', {params: {alias: 'services', sub_alias: 'meetings'}})
                .then(response => {
                    this.plugins = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleChangeDate() {
            if (this.elementSize(this.form.times) && this.form.date) {
                this.form.start_at = this.form.date + ' ' + this.form.times[0];
                this.form.end_at = this.form.date + ' ' + this.form.times[1];
            } else {
                this.form.start_at = null;
                this.form.end_at = null;
            }
        },

        handleClickStore() {
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            this.pageLoading = true;

            this.$http
                .post(this.generateApiPointWithAlias(this.$route.meta.apiPoint), this.form)
                .then(response => {
                    this.pageLoading = false;
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
