<template>
	<div v-loading="pageLoading" :key="listRefreshKey">
		<el-card class="custom-padding" v-for="item in items" :key="item.id">
			<template slot="header">
				<div class="title">{{ item.title }}</div>
				<div class="actions">
					<el-tooltip content="Ekle" placement="bottom">
						<el-button class="flat dark secondary" icon="el-icon-plus" @click="handleCreateItem(item)" />
					</el-tooltip>
					<el-tooltip content="Duzenle" placement="bottom">
						<el-button class="flat dark blue" icon="el-icon-edit" @click="edit(item)" />
					</el-tooltip>
					<el-tooltip content="Sil" placement="bottom">
						<el-button class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)" />
					</el-tooltip>
				</div>
			</template>
			<div class="ui message" style="border: none; box-shadow: none">
				{{ item.description }}
			</div>
			<CardChecklistItem :ref="'ChecklistItem-' + item.id" :checklistId="item.id" :cardId="cardId" />
		</el-card>

		<el-dialog title="Checklist Bilgileri" :visible.sync="dialogVisible" v-loading="dialogLoading" append-to-body>
			<el-form :label-position="'top'" ref="checklistform" :model="form">
				<el-form-item label="Baslik">
					<el-input v-model="form.title" placeholder="Baslik" />
				</el-form-item>

				<el-form-item label="Aciklama">
					<el-input v-model="form.description" placeholder="Aciklama" type="textarea" rows="4" />
				</el-form-item>

				<el-form-item label="Siralama">
					<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
				</el-form-item>
			</el-form>
			<template slot="footer">
				<el-button class="modern green large fluid" icon="el-icon-check" @click="store" v-if="!form.id">
					Kaydet
				</el-button>
				<el-button class="modern green large fluid" icon="el-icon-check" @click="update" v-if="form.id">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import CardChecklistItem from "./ChecklistItem";

	export default {
		name: 'CardChecklist',
		components: {CardChecklistItem},
		props: ['cardId'],
		data() {
			return {
				pageLoading: false,
				dialogLoading: false,
				dialogVisible: false,
				form: {
					//
				},

				items: [],
                listRefreshKey : null,
			}
		},
		mounted() {
			this.index();
		},
		methods: {
			endPoint(key) {
				if (!key) {
					return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.$route.params.stageId + '/cards/' + this.cardId + '/checklists'
				}

				return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.$route.params.stageId + '/cards/' + this.cardId + '/checklists/' + key
			},

			index() {
				this.pageLoading = true;

				this.$http
					.get(this.endPoint(), {params: {sorts: {sort: 'asc'}}})
					.then(response => {
						this.items = response.body.rows;
                        this.listRefreshKey = Math.random();
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			create() {
				this.dialogVisible = true;
				this.form = {}
			},

			edit(item) {
				this.dialogVisible = true;
				this.form = item
			},

			store() {
				this.dialogLoading = true;

				this.$http
					.post(this.endPoint(), this.form)
					.then(response => {
						this.dialogLoading = false;
						this.dialogVisible = false;
						this.index();
					})
					.catch(response => {
						this.dialogLoading = false;
						return this.responseError(response);
					})
			},

			update() {
				this.dialogLoading = true;

				this.$http
					.put(this.endPoint(this.form.id), this.form)
					.then(response => {
						this.dialogLoading = false;
						this.dialogVisible = false;
					})
					.catch(response => {
						this.dialogLoading = false;
						return this.responseError(response);
					})
			},

			destroy(id) {
				this.pageLoading = true;

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.delete(this.endPoint(id))
							.then(response => {
								this.pageLoading = false;
								this.responseSuccess(response);

								this.index();
							})
							.catch(response => {
								this.responseError(response);
								this.pageLoading = false;
							});

						return true;
					})
					.catch((response) => {

						this.pageLoading = false;

						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},

			handleCreateItem(item) {
				this.$refs['ChecklistItem-' + item.id][0].create()
			}
		}
	}
</script>
