<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<div v-if="!$route.params.id" class="padding-20">
					<div class="alert alert-warning">
						Eklediginiz rotalara mutlaka isim eklemeniz gerekiyor. Eklediginiz rotalar hangi alan ait ise
						onu belirtmeniz gerekiyor.
						Yetkilendirme ve diger ozellikler bu isme bagli olarak calismakta. <br>
						Ornegin; <br>
						<b>api.home.index icin:</b> api.home yazmaniz gerekiyor <br>
						<b>web.home.index icin:</b> api.home yazmaniz gerekiyor
					</div>

					<el-form-item label="Rota Adi">
						<el-input v-model="form.name" placeholder="Rota Adi" />
					</el-form-item>
				</div>
				<div v-if="$route.params.id">
					<div class="card-sub-title">{{ form.name }}</div>

					<div class="padding-20">
						<table class="table">
							<thead>
							<tr>
								<th width="150">Method</th>
								<th>Name</th>
								<th>Type</th>
								<th width="1">IsIndex</th>
								<th width="1">
									<el-button class="flat dark blue" icon="el-icon-plus" @click="handleNewRow" />
								</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(request, key) in form.requests" :key="key">
								<td>
									<el-form-item style="margin: 0">
										<el-input v-model="request.method" placeholder="Method" />
									</el-form-item>
								</td>
								<td>
									<el-form-item style="margin: 0">
										<el-input v-model="request.name" placeholder="Name" />
									</el-form-item>
								</td>
								<td>
									<el-form-item style="margin: 0">
										<el-input v-model="request.type" placeholder="Type" />
									</el-form-item>
								</td>
								<td class="text-center">
									<el-form-item style="margin: 0">
										<el-switch v-model="request.is_index" />
									</el-form-item>
								</td>
								<td>
									<el-button class="flat dark red" icon="el-icon-delete"
											   @click="handleDeleteRow(key)" />
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
        },
        data() {
            return {
                pageLoading: false,
                form: {
                    //
                },
            }
        },
        methods: {

            /**
             * Show page
             */
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            handleNewRow() {
                this.form.requests.push({})
            },

            handleDeleteRow(key) {
                this.form.requests.splice(key, 1);
            }
        },
        mounted() {
            if (this.$route.params.id) {
                this.show();
            }
        },
    }
</script>