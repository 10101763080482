<style lang="scss">
.el-rate__icon {
    font-size : 38px;
}
</style>

<template>
    <el-form :label-position="'top'" ref="form" :model="form" v-loading="pageLoading">

        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>

            <el-form-item label="Etiketler" class="margin-bottom-0">
                <definition-box alias="feedback-tags" :container="form" model="tag_requests" placeholder="Etiketler" :multiple="true" />
            </el-form-item>
        </el-card>
        <el-row :gutter="24">
            <el-col :span="12">
                <el-card class="custom-padding">
                    <div class="card-sub-title">Mesaj</div>
                    <div class="card-custom-padding-box-items">
                        <el-form-item>
                            <el-rate v-model="form.rating" :colors="['#99a9bf', '#f7ba2a', '#ff9900']"></el-rate>
                        </el-form-item>

                        <div style="height: 25px"></div>

                        <div v-if="form.content">
                            <el-card>
                                <a :href="'/catalog/contents/'+form.content.alias+'/' + form.content.id" target="_blank">
                                    {{ form.content.title }}
                                </a>
                            </el-card>
                        </div>

                        <el-form-item label="Konu">
                            <el-select v-model="form.subject" placeholder="Seciminiz" filterable clearable>
                                <el-option v-for="item in subjects" :key="item.value" :label="item.label" :value="item.value" />
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Baslik">
                            <el-input v-model="form.title" placeholder="Baslik" />
                        </el-form-item>

                        <el-form-item label="Aciklama">
                            <el-input type="textarea" placeholder="Aciklama" v-model="form.description" rows="4" />
                        </el-form-item>

                        <el-form-item label="Video Kaynağı">
                            <fs v-model="form.video_source" :isFile="true" />
                        </el-form-item>

                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item label="Resim">
                                    <fs v-model="form.file.path" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="custom-padding">
                    <div class="card-sub-title">Musteri</div>
                    <div class="card-custom-padding-box-items">
                        <el-form-item label="Şehir">
                            <el-select v-model="form.city_zone_id" placeholder="Şehir seçiniz" filterable clearable>
                                <el-option v-for="item in cities" :key="item.id" :label="item.title" :value="item.id" />
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Ad">
                            <el-input v-model="form.first_name" placeholder="Ad" />
                        </el-form-item>

                        <el-form-item label="Soyad">
                            <el-input v-model="form.last_name" placeholder="Soyad" />
                        </el-form-item>

                        <el-form-item label="E-Posta">
                            <el-input v-model="form.email" placeholder="E-Posta" />
                        </el-form-item>

                        <el-form-item label="Telefon">
                            <el-input v-model="form.phone" placeholder="Telefon" />
                        </el-form-item>

                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item class="margin-bottom-0">
                                    <el-switch v-model="form.is_active" active-text="Onayla" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item class="margin-bottom-0">
                                    <el-switch v-model="form.is_featured" active-text="One Cikar" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item class="margin-bottom-0">
                                    <el-switch v-model="form.is_notify" active-text="Musteriyi Bilgilendir" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item class="margin-bottom-0">
                            <el-switch v-model="form.has_access_web_site" active-text="Web Sitesinde Yayinlanmasina Izin Veriyorum" />
                        </el-form-item>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                file : {
                    //
                },
                tag_requests : []
            },
            subjects: [
                {
                    value: 'web',
                    label: 'Web Sitesi Hakkinda'
                },
                {
                    value: 'platform',
                    label: 'Platform Hakkinda'
                },
                {
                    value: 'teacher',
                    label: 'Egitmen Hakkinda'
                },
                {
                    value: 'product',
                    label: 'Urun Hakkinda'
                }
            ],
            cities: [],
        }
    },
    mounted() {
        this.getZones('cities', this.$store.state.ui.settings.site_country_id);

        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;
                    this.pageLoading = false;

                    if (!this.form.file) {
                        this.form.file = {

                        }
                    }
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getZones(alias, parentId) {

            this.pageLoading = true;

            this.$http
                .get('system/' + alias + '/zones', {
                    params: {
                        perPage: 1000,
                        filters: [
                            {
                                relation: null,
                                inputs: [
                                    {
                                        name: 'parent_id',
                                        field: 'parent_id',
                                        type: 'singleSelect',
                                        value: parentId
                                    }
                                ]
                            },
                        ]

                    }
                })
                .then(response => {
                    this[alias] = response.body.rows;
                    this.pageLoading = false;
                })
                .catch((e) => {
                    this.pageLoading = false;
                    return this.responseError(e);
                });
        },
    },
}
</script>
