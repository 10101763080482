<template>
    <div class="page-catalog" style="padding-bottom: 100px">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    {{ $route.meta.title }}
                </div>
                <div class="actions">
                    <el-tooltip content="Olustur" placement="left">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="store" />
                    </el-tooltip>
                </div>
            </template>
            <div class="el-card__filters">
                <el-form :label-position="'top'" ref="filter" :model="filter" @submit.native.prevent="handleSearch">
                    <el-form-item class="margin-bottom-0">
                        <el-input placeholder="urun adi..." prefix-icon="mdi mdi-dolly" v-model="filter.title" />
                    </el-form-item>
                    <el-button native-type="submit" style="display: none"></el-button>
                </el-form>
            </div>
        </el-card>

        <div v-loading="pageLoading">
            <template v-for="(row, index) in rows">
                <el-card :key="index" class="product-row">
                    <div class="product-row-wrp">
                        <router-link :to="$route.fullPath + '/' + row.id" class="content">
                            <div class="media">
                                <icon :icon="findProductIcon()" />
                            </div>
                            <div class="text">
                                <div class="title">{{ row.title }}</div>
                                <div class="metas">
                                    <div class="meta">
                                        <icon icon="calendar-outline" /> {{ row.publish_at}}
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <div class="actions">
                            <el-tooltip content="Sil" placement="left">
                                <el-button type="text" class="flat dark red large" icon="el-icon-delete" @click="destroy(row.id)" />
                            </el-tooltip>
                        </div>
                    </div>
                </el-card>
            </template>
        </div>

        <div class="pagination" v-if="pagination && pagination.total > pagination.per_page">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="pagination.total"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page"
                @current-change="handleChangePage"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductIndex',
    data() {
        return {
            pageLoading: false,
            filter: {
                //
            },

            page: 1,
            perPage: 10,
            pagination: null,
            rows: [],
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        handleChangePage(value) {
            this.page = value;
            this.index();
        },

        handleSearch() {
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {
                    params: {
                        page: this.page,
                        perPage: this.perPage,
                        type: this.$route.meta.type,
                        title: this.filter.title,
                    }
                })
                .then(response => {
                    this.rows = response.body.data;
                    this.pagination = response.body.pagination;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        store() {
            this.pageLoading = true;

            let form = {
                type : this.$route.meta.type
            }

            this.$http
                .post(this.generateApiPointWithAlias(this.$route.meta.apiPoint), form)
                .then(response => {
                    return this.goRoute(this.$route.fullPath + '/' + response.body.data.id)
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.$route.meta.apiPoint + '/' + id)
                        .then(response => {
                            this.index();
                            this.responseSuccess(response);
                        })
                        .catch(response => {
                            this.responseError(response);
                        });
                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        findProductIcon() {
            switch (this.$route.meta.type) {
                case 'group':
                    return 'message-video';
                case 'package':
                    return 'archive-outline';
                case 'physical':
                    return 'bookshelf';
                case 'digital':
                    return 'gesture-tap-button';
                case 'event':
                    return 'party-popper';
                case 'subscription':
                    return 'youtube-subscription';
                default :
                    return this.$route.meta.type;
            }
        },
    }
}
</script>
