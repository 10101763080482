let tenantName = localStorage.getItem('tenant')

export default {
    endPoint: process.env.NODE_ENV == 'development' ? 'https://tisuite.test/api/v1' : 'https://tisuite.co/api/v1',
	tenantName: tenantName,
    storage_path: tenantName,
    domain: null,
	platform_domain: null,
	colors: [
		{
			value: '#545c64',
			label: 'Varsayilan'
		},
		{
			value: '#db2828',
			label: 'Red'
		},
		{
			value: '#f2711c',
			label: 'Orange'
		},
		{
			value: '#fbbd08',
			label: 'Yellow'
		},
		{
			value: '#b5cc18',
			label: 'Olive'
		},
		{
			value: '#21ba45',
			label: 'Green'
		},
		{
			value: '#00b5ad',
			label: 'Teal'
		},
		{
			value: '#2185d0',
			label: 'Blue'
		},
		{
			value: '#2a41e8',
			label: 'Navy'
		},
		{
			value: '#6435c9',
			label: 'Violet'
		},
		{
			value: '#a333c8',
			label: 'Purple'
		},
		{
			value: '#e03997',
			label: 'Pink'
		},
	],
	alias: {
		definitions: [
			{
				key: 'years',
				title: 'Yil'
			},
			{
				key: 'days',
				title: 'Gun'
			},
			{
				key: 'tax-classes',
				title: 'Vergi Sinifi'
			},
			{
				key: 'length-classes',
				title: 'Uzunluk Sinifi'
			},
			{
				key: 'weight-classes',
				title: 'Uzunluk Sinifi'
			},
			{
				key: 'genders',
				title: 'Cinsiyet'
			},
			{
				key: 'educations',
				title: 'Egitim'
			},
			{
				key: 'jobs',
				title: 'Meslek'
			},
			{
				key: 'feedback_subjects',
				title: 'Geri Bildirim Konulari'
			},
			{
				key: 'event_types',
				title: 'Etkinlik Turu'
			},
			{
				key: 'periods',
				title: 'Donemler'
			},
			{
				key: 'seasons',
				title: 'Sezonlar'
			},
		],
		statuses: [
			{
				key: 'stocks',
				title: 'Stok'
			},
			{
				key: 'orders',
				title: 'Siparis'
			},
			{
				key: 'order-returns',
				title: 'Iade'
			},
			{
				key: 'shippings',
				title: 'Kargo'
			},
			{
				key: 'badges',
				title: 'Rozet'
			},
			{
				key: 'skills',
				title: 'Yetenekler'
			},
			{
				key: 'levels',
				title: 'Seviyeler'
			},
			{
				key: 'cards',
				title: 'Kart Etiketleri'
			}
		],
		zones: [
			{
				key: 'countries',
				title: 'Ulke'
			},
			{
				key: 'cities',
				title: 'Sehir'
			},
			{
				key: 'towns',
				title: 'Ilce'
			},
			{
				key: 'districts',
				title: 'Semt'
			},
			{
				key: 'neighborhoods',
				title: 'Mahalle'
			},
		],
		cms: [
			{
				key: 'pages',
				title: 'Sayfa'
			},
			{
				key: 'posts',
				title: 'Blog'
			},
			{
				key: 'faqs',
				title: 'Sikca Sorulan Sorular'
			},
			{
				key: 'references',
				title: 'Referanslar'
			},
			{
				key: 'campaigns',
				title: 'Kampanyalar'
			},
			{
				key: 'branches',
				title: 'Subeler'
			},
			{
				key: 'careers',
				title: 'Kariyer'
			},
			{
				key: 'exam_promos',
				title: 'Sinav Tanitim'
			},
			{
				key: 'city_promos',
				title: 'Sehir Tanitim'
			},
			{
				key: 'forums',
				title: 'Forumlar'
			},
            {
                key: 'encyclopedia',
                title: 'Ansiklopedi'
            },
		],
		catalog: [
			{
				key: 'products',
				title: 'Urun'
			},
			{
				key: 'teachers',
				title: 'Egitmen'
			},
			{
				key: 'materials',
				title: 'Ucretsiz Kaynak'
			},
            {
                key: 'product-landing-pages',
                title: 'Urun Tanitim Sayfalari'
            },
		]
	}
}
