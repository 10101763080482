<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<el-form-item label="Baslik">
					<el-input v-model="form.title" placeholder="Baslik" />
				</el-form-item>

				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="Ikon">
							<el-input v-model="form.icon" placeholder="Ikon" />
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="Arka Plan Rengi">
							<el-color-picker v-model="form.bg_color" show-alpha :predefine="appConfig.predefineColors"/>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="Yazi Rengi">
							<el-color-picker v-model="form.text_color" show-alpha :predefine="appConfig.predefineColors"/>
						</el-form-item>
					</el-col>
				</el-row>

				<el-form-item label="Siralama">
					<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_active" active-text="Aktif" />
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
		},
        data() {
            return {
                pageLoading: false,
                form: {
                    meta : {
                    	//
					}
                },
            }
        },
        methods: {

            /**
             * Show page
             */
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
						this.pageLoading = false;
                        this.form = response.body.data;

						if (!this.lodash.size(this.form.meta)) {
							this.form.meta = {};
						}
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },
        },
        mounted() {
            if (this.$route.params.id) {
                this.show();
            }
        },
    }
</script>
