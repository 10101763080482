import LayoutConfig from '../config/layout'
import Container from '../app/Container/Main'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Datatable'

import Banner from '../app/Pages/Marketing/Banner'
import BulkMessage from '../app/Pages/Marketing/BulkMessage/Index'
import Campaign from '../app/Pages/Marketing/Campaign'
import Coupon from '../app/Pages/Marketing/Coupon'
import AccessCode from '../app/Pages/Marketing/AccessCode'
import Gift from '../app/Pages/Marketing/Gift'
import Price from '../app/Pages/Marketing/Price'
import Newsletter from '../app/Pages/Marketing/Newsletter'
import PlatformNotification from '../app/Pages/Marketing/PlatformNotification'
import Draft from '../app/Pages/Marketing/Draft'

export default {
    path: '/marketing',
    name: 'api.marketing.index',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Pazarlama',
    },
    children: [
        {
            path: 'campaigns',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Kampanyalar',
                breadcrumb: 'api.marketing.campaigns.index',
            },
            children: [
                {
                    name: 'api.marketing.campaigns.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Kampanyalar',
                        apiPoint: 'marketing/campaigns',
                    },
                },
                {
                    name: 'api.marketing.campaigns.create',
                    path: 'create',
                    component: Campaign,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Kampanya Olustur',
                        apiPoint: 'marketing/campaigns',
                    },
                },
                {
                    name: 'api.marketing.campaigns.edit',
                    path: ':id',
                    component: Campaign,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Kampanya Bilgileri',
                        apiPoint: 'marketing/campaigns',
                    },
                }
            ]
        },
        {
            path: 'gifts',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Hediyeler',
                breadcrumb: 'api.marketing.gifts.index',
            },
            children: [
                {
                    name: 'api.marketing.gifts.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Hediyeler',
                        apiPoint: 'marketing/gifts',
                    },
                },
                {
                    name: 'api.marketing.gifts.create',
                    path: 'create',
                    component: Gift,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Hediye Olustur',
                        apiPoint: 'marketing/gifts',
                    },
                },
                {
                    name: 'api.marketing.gifts.edit',
                    path: ':id',
                    component: Gift,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Hediye Bilgileri',
                        apiPoint: 'marketing/gifts',
                    },
                }
            ]
        },
        {
            path: 'banners',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Reklamlar',
                breadcrumb: 'api.marketing.banners.index',
            },
            children: [
                {
                    name: 'api.marketing.banners.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Reklamlar',
                        apiPoint: 'marketing/banners',
                    },
                },
                {
                    name: 'api.marketing.banners.create',
                    path: 'create',
                    component: Banner,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Reklam Olustur',
                        apiPoint: 'marketing/banners',
                    },
                },
                {
                    name: 'api.marketing.banners.edit',
                    path: ':id',
                    component: Banner,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Reklam Bilgileri',
                        apiPoint: 'marketing/banners',
                    },
                }
            ]
        },
        {
            path: 'coupons',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Kupon Kodlari',
                breadcrumb: 'api.marketing.coupons.index',
            },
            children: [
                {
                    name: 'api.marketing.coupons.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Kupon Kodlari',
                        apiPoint: 'marketing/coupons',
                    },
                },
                {
                    name: 'api.marketing.coupons.create',
                    path: 'create',
                    component: Coupon,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Kupon Kodu Olustur',
                        apiPoint: 'marketing/coupons',
                    },
                },
                {
                    name: 'api.marketing.coupons.edit',
                    path: ':id',
                    component: Coupon,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Kupon Kodu Bilgileri',
                        apiPoint: 'marketing/coupons',
                    },
                }
            ]
        },
        {
            path: 'access-codes',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Erisim Kodlari',
                breadcrumb: 'api.marketing.access_codes.index',
            },
            children: [
                {
                    name: 'api.marketing.access_codes.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Erisim Kodlari',
                        apiPoint: 'marketing/access-codes',
                    },
                },
                {
                    name: 'api.marketing.access_codes.create',
                    path: 'create',
                    component: AccessCode,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Erisim Kodu Olustur',
                        apiPoint: 'marketing/access-codes',
                    },
                },
                {
                    name: 'api.marketing.access_codes.edit',
                    path: ':id',
                    component: AccessCode,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Kupon Kodu Bilgileri',
                        apiPoint: 'marketing/access-codes',
                    },
                }
            ]
        },
        {
            path: 'prices',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Fiyat Guncellemeleri',
                breadcrumb: 'api.marketing.prices.index',
            },
            children: [
                {
                    name: 'api.marketing.prices.index',
                    path: '',
                    component: Price,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Fiyat Guncellemeleri',
                        apiPoint: 'marketing/prices',
                    },
                }
            ]
        },
        {
            path: 'bulk-messages',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Toplu Mesaj',
                breadcrumb: 'api.marketing.bulk_messages.index',
            },
            children: [
                {
                    name: 'api.marketing.bulk_messages.index',
                    path: '',
                    component: BulkMessage,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Toplu Mesaj Gonderimi',
                        apiPoint: 'marketing/bulk-messages',
                    },
                }
            ]
        },
        {
            path: 'platform-notifications',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Platform Bildirimleri',
                breadcrumb: 'api.marketing.platform_notifications.index',
            },
            children: [
                {
                    name: 'api.marketing.platform_notifications.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Platform Bildirimleri',
                        apiPoint: 'marketing/platform-notifications',
                    },
                },
                {
                    name: 'api.marketing.platform_notifications.create',
                    path: 'create',
                    component: PlatformNotification,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Platforn Bildirimi Olustur',
                        apiPoint: 'marketing/platform-notifications',
                    },
                },
                {
                    name: 'api.marketing.platform_notifications.edit',
                    path: ':id',
                    component: PlatformNotification,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Platform Bildirimi Bilgileri',
                        apiPoint: 'marketing/platform-notifications',
                    },
                }
            ]
        },
        {
            path: 'drafts',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Taslaklar',
                breadcrumb: 'api.marketing.drafts.index',
            },
            children: [
                {
                    name: 'api.marketing.drafts.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Taslaklar',
                        apiPoint: 'marketing/drafts',
                    },
                },
                {
                    name: 'api.marketing.drafts.create',
                    path: 'create',
                    component: Draft,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Taslak Olustur',
                        apiPoint: 'marketing/drafts',
                    },
                },
                {
                    name: 'api.marketing.drafts.edit',
                    path: ':id',
                    component: Draft,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Taslak Bilgileri',
                        apiPoint: 'marketing/drafts',
                    },
                }
            ]
        },
        {
            path: 'newsletters',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Abonelikler',
                breadcrumb: 'api.marketing.newsletters.index',
            },
            children: [
                {
                    name: 'api.marketing.newsletters.index',
                    path: '',
                    component: Newsletter,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Abonelikler',
                        apiPoint: 'marketing/newsletters',
                    },
                }
            ]
        }
    ]
}
