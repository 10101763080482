<template>
    <div v-loading="pageLoading">

        <div v-if="form.id">

            <Note :path="$route.fullPath" class="margin-bottom-20" />

            <app-tab :items="appTabs" active="default" :update="handleChangeTab" />

            <div class="margin-top-20">
                <el-card class="custom-padding" v-show="activeAppTab === 'default'">
                    <template slot="header">
                        <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
                    </template>
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <div class="card-sub-title">Genel</div>
                        <div class="card-custom-padding-box-items">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="Baslik">
                                        <el-input v-model="form.title" placeholder="Baslik" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Yayinlanma Basligi">
                                        <el-input v-model="form.public_title" placeholder="Yayinlanma Basligi" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-form-item label="Yayınlanma Tarihi">
                                <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayınlanma Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                            </el-form-item>

                            <el-form-item label="Aciklama" class="is-editor">
                                <editor :container="form" model="description" :small="true" />
                            </el-form-item>
                        </div>
                        <div class="card-sub-title">Ozellikler</div>
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Test Turu">
                                <ti-select :options="types" :container="form" model="type" placeholder="Test Turu" />
                            </el-form-item>

                            <el-form-item label="Soru Sayisi" v-if="form.type !== 'leveling_exam'">
                                <el-input type="number" placeholder="Soru Sayisi" v-model="form.question_count"/>
                            </el-form-item>

                            <el-row :gutter="24" v-if="form.type === 'leveling_exam'">
                                <el-col :span="8">
                                    <el-form-item label="Kullanici">
                                        <user :container="form" model="user_id" placeholder="Kullanici" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Branş">
                                        <ti-select :options="branches" :container="form" model="archive_branch_id" title="title_ui" placeholder="Branş" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Seviye">
                                        <ti-select :options="audiences" :container="form" model="archive_audience_id" title="label" id="value" placeholder="Seviye" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row :gutter="24" v-if="form.type !== 'test'">
                                <el-col :span="form.type === 'exam' ? 24 : 12">
                                    <el-form-item label="Sinav Süresi (Dk)">
                                        <el-input type="number" placeholder="Süre DK" v-model="form.duration"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="form.type === 'global_exam'">
                                    <el-form-item label="Sinav Tarihi">
                                        <el-date-picker v-model="form.start_at" type="datetime" placeholder="Sinav Tarihi" value-format="yyyy-MM-dd HH:mm:ss" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <div v-if="form.type === 'global_exam'">
                                <el-form-item label="Sınavdan kaç gün önce Yaklaşan Sınavlar bölümüne alınacak?">
                                    <el-input type="number" placeholder="Sınavdan kaç gün önce Yaklaşan Sınavlar bölümüne alınacak?" v-model="form.meta.remaining_day"/>
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item class="margin-bottom-0">
                                            <lv-select :container="form" model="plugin_id" :options="plugins" placeholder="Servis" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-button class="modern large purple fluid margin-bottom-20" icon="mdi mdi-firebase" @click="reportFirebase">
                                            Sinav Raporu Olustur
                                        </el-button>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-button class="modern large red fluid margin-bottom-20" icon="mdi mdi-firebase" @click="publishFirebase">
                                            Sinav Bilgilerini Guncelle
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                        <div class="card-sub-title">Medya</div>
                        <div class="card-custom-padding-box-items">
                            <el-row :gutter="24">
                                <el-col :span="4">
                                    <el-form-item label="Listeleme Sayfasi Resmi" class="margin-bottom-0">
                                        <fs v-model="form.meta.index_cover" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Detay Sayfasi Resmi" class="margin-bottom-0">
                                        <fs v-model="form.meta.show_cover" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Pdf On Kapak Resmi" class="margin-bottom-0">
                                        <fs v-model="form.meta.page_front_cover" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Pdf Arka Kapak Resmi" class="margin-bottom-0">
                                        <fs v-model="form.meta.page_back_cover" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="Pdf Filigran" class="margin-bottom-0">
                                        <fs v-model="form.meta.page_watermark" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="card-sub-title">
                            <div class="title">Dosyalar</div>
                        </div>
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Test Pdf">
                                <fs v-model="form.meta.pdf_path" :isFile="true" />
                            </el-form-item>
                            <el-form-item label="Test Sorulari Pdf">
                                <fs v-model="form.meta.question_pdf_path" :isFile="true" />
                            </el-form-item>
                        </div>
                        <div class="card-sub-title">
                            <div class="title">Baglantilar</div>
                        </div>
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Etiketler">
                                <definition-box alias="tests" :container="form" model="tag_requests" :multiple="true" placeholder="Etiketler" />
                            </el-form-item>

                            <el-form-item label="Urunler">
                                <product-transfer :container="form" model="product_requests" :types="['group', 'subscription']" />
                            </el-form-item>
                        </div>
                    </el-form>
                </el-card>

                <div v-show="activeAppTab === 'questions'">
                    <TestQuestion :id="form.id" />
                </div>
                <div v-show="activeAppTab === 'instructions'">
                    <TestInstruction :id="form.id" />
                </div>

                <div v-show="activeAppTab === 'users'">
                    <TestUser :report="form.report" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CrudHeader from "../../../Components/CrudHeader";
import TestQuestion from "@/app/Pages/Education/Test/Question";
import TestInstruction from "@/app/Pages/Education/Test/Instruction";
import Note from "@/app/Components/Note";
import TestUser from "@/app/Pages/Education/Test/User";

export default {
    name: 'TestEdit',
    components: {
        TestUser,
        Note,
        TestInstruction,
        TestQuestion,
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                tag_requests: [],
                product_requests: [],
                meta: {
                    //
                },
            },

            activeAppTab : 'default',
            appTabs : [
                {
                    key : 'default',
                    title : 'Test Bilgileri'
                },
                {
                    key : 'questions',
                    title : 'Sorular'
                },
                {
                    key : 'instructions',
                    title : 'Yonergeler'
                },
                {
                    key : 'users',
                    title : 'Katilimcilar'
                }
            ],
            types : [
                {
                    id : 'test',
                    title : 'Test'
                },
                {
                    id : 'exam',
                    title : 'Deneme'
                },
                {
                    id : 'global_exam',
                    title : 'Turkiye Geneli Deneme'
                },
                {
                    id : 'leveling_exam',
                    title : 'Seviye Tespit Sinavi'
                }
            ],

            plugins: [],
            branches: [],
            audiences: [],
        }
    },
    mounted() {
        this.getPlugins();
        this.getBranches();
        this.getAudiences();
        this.show();
    },
    methods : {

        getPlugins() {
            this.pageLoading = true;

            this.$http
                .get('tools/search/plugins', {params: {alias: 'services', sub_alias: 'nosql'}})
                .then(response => {
                    this.plugins = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getBranches() {
            this.pageLoading = true;

            this.$http
                .get('archives/branches', {params: {perPage: 10000}})
                .then(response => {
                    this.pageLoading = false;
                    this.branches = response.body.rows;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getAudiences() {
            this.pageLoading = true;

            this.$http
                .get('archives/audiences', {params: {perPage: 10000, view: 'cascader'}})
                .then(response => {
                    this.pageLoading = false;
                    this.audiences = response.body.data;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleChangeTab(value) {
            this.activeAppTab = value;
        },

        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            //
                        };
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        publishFirebase() {
            this.pageLoading = true;

            this.$http
                .post(this.$route.meta.apiPoint, {
                    process : 'publish',
                    id : this.form.id
                })
                .then(response => {
                    this.pageLoading = false;

                    this.$notify.success({
                        title: 'Başarılı',
                        message: 'Test bilgileriniz basarili bir sekilde firebase veri tabanina yuklendi.',
                        position: 'top-right'
                    });
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        reportFirebase() {

            if (!this.form.uuid) {
                return;
            }

            this.pageLoading = true;

            this.$http
                .post(this.$route.meta.apiPoint, {
                    process : 'report',
                    id : this.form.id
                })
                .then(response => {
                    this.pageLoading = false;

                    this.$notify.success({
                        title: 'Başarılı',
                        message: 'Test raporunuz basarili bir sekilde firebase veri tabanina yuklendi.',
                        position: 'top-right'
                    });
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
