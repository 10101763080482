<template>
    <el-select v-model="container[model]" :placeholder="placeholder">
        <template v-for="(plugin, index) in items">
            <el-option :label="plugin.name" :value="plugin.id" :key="index" v-if="visible(plugin)">
                <div class="el-option__media">
                    <div class="media">
                        <img :src="'https://' + $store.state.ui.tenant.domain + plugin.preview" alt="preview">
                    </div>
                    <div class="text">
                        <div class="title">{{ plugin.name }} {{ sub }}</div>
                        <div class="description">{{ plugin.title }}</div>
                    </div>
                </div>
            </el-option>
        </template>
    </el-select>
</template>

<script>
export default {
    name: 'PluginDropdown',
    props: {
        alias: {
            type: String,
            default: 'payments'
        },
        sub: {
            type: String,
            default : null
        },
        container: {
            type: Object,
        },
        model: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Seçiminiz'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        change: {
            type: Function,
        },
        options: {
            type: Array,
        },
    },

    data() {
        return {
            items : []
        }
    },
    watch: {
        options: function (val) {
           this.items = val;
        },
    },
    mounted() {
        if (!this.elementSize(this.options)) {
            this.getPlugins();
        } else {
            this.items = this.options;
        }
    },
    methods : {
        visible(plugin) {
            if (!this.sub) {
                return true;
            }

            if (this.sub === plugin.sub_alias) {
                return true;
            }

            return false;
        },
        getPlugins() {
            this.items = [];
        }
    }
};
</script>
