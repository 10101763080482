<style lang="scss" rel="stylesheet/scss">
@import "./resources/scss/App";
</style>

<template>
    <div class="wrapper">
        <transition name="fade">
            <div class="preloader" v-if="preloader">
                <div class="wrap">
                    <div class="loader">
                        <img src="@/resources/img/preloader.svg" alt="">
                    </div>
                    <div class="text">
                        sistem bilesenleri yukleniyor...
                    </div>
                </div>
            </div>
        </transition>

        <Header v-if="!preloader && $route.meta.layout.header" />
        <Main v-if="!preloader && $route.meta.layout.main" />
        <Footer v-if="!preloader && $route.meta.layout.footer" />
    </div>
</template>

<script>
import Header from "./app/Layouts/Header";
import Main from "./app/Layouts/Main";
import Footer from "./app/Layouts/Footer";

export default {
    name: 'Provider',
    components: {
        Footer,
        Header,
        Main
    },
    data() {
        return {};
    },
    computed: {
        preloader() {
            return this.$store.getters.preloader
        }
    },
    mounted() {
        if (this.$route.name !== 'api.auth.login.store') {
            this.initUi();
        }
    },
    methods: {
        initUi() {
            this.$store.commit('setPreloader', true)

            this.$http
                .get('ui')
                .then(response => {
                    this.$store.commit('setUi', response.body.data);

                    this.initAccount();
                })
                .catch(error => {
                    localStorage.removeItem('access_token');

                    return this.goRoute('/')
                })
        },

        initAccount() {
            this.$http
                .get('account', {
                    params: {
                        withs: [
                            'role.role'
                        ]
                    }
                })
                .then(response => {
                    this.$store.commit('setAccount', response.body);

                    this.initIcons();
                })
        },

        initIcons() {
            this.$http
                .get('/storage/icons.json')
                .then(response => {

                    this.$store.commit('setIcons', response.body);

                    this.$store.commit('setPreloader', false)
                })
        }
    }
}
</script>
