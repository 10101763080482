<template>
    <el-form-item :label="item.label">
        <vue-prism-editor v-model="container[item.name]" language="js" :lineNumbers="true" />
    </el-form-item>
</template>

<script>
export default {
    name: 'CodeInput',
    props : ['form', 'container', 'item'],
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
