<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" :disable="true" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">

                <div class="card-custom-padding-box-items">
                    <el-form-item label="Semalar">
                        <el-select v-model="form.schema" placeholder="Seciminiz" clearable filterable @change="handleChange">
                            <el-option v-for="item in schemas" :key="item.key" :label="item.title" :value="item.key" />
                        </el-select>
                    </el-form-item>
                </div>

                <div v-if="schema">
                    <div class="card-custom-padding-box-items">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Başlangıç Tarihi">
                                    <el-date-picker v-model="form.start_at" type="datetime" placeholder="Başlangıç Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="datePickerOptions"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Bitiş Tarihi">
                                    <el-date-picker v-model="form.end_at" type="datetime" placeholder="Bitiş Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="datePickerOptions"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item label="Icerik Turu" v-if="schema.key === 'contents'" >
                            <ti-select :options="appConfig.config.alias.cms" :container="form" model="alias" id="key" placeholder="Icerik Turu" />
                        </el-form-item>

                        <el-form-item label="Urunler">
                            <product-dropdown :container="form" model="products" :multiple="true" />
                        </el-form-item>
                    </div>

                    <div class="card-sub-title">Cikti Bilgileri</div>
                    <div class="card-custom-padding-box-items">
                        <el-form-item>
                            <el-transfer :titles="['Kaynak', 'Ciktida Olusacak Alanlar']" v-model="form.columns" :data="schema.columns" :key="schema.key" />
                        </el-form-item>
                    </div>

                    <div class="card-custom-padding-box-items">
                        <el-button class="fluid modern large" icon="el-icon-download" @click="download" :disabled="formLoading" :loading="formLoading">
                            Kosula Bagli Excel Ciktisi Olustur
                        </el-button>
                    </div>
                </div>
			</el-form>
		</el-card>
	</div>
</template>

<style>
.el-transfer-panel__list, .el-transfer-panel__body {
    height: 400px;
}
</style>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'Export',
        components: {
            CrudHeader
		},
        data() {
            return {
                pageLoading: false,
                formLoading : false,
                form: {
                    schema : null,
                    columns : [],
                    products : [],
                },

                schemas : [],
                schema : null,
            }
        },
        mounted() {
            this.index();
        },
        methods: {

            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.apiPoint)
                    .then(response => {
                        this.schemas = response.body.data.schemas;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            download() {
                this.formLoading = true;

                this.$http
                    .post(this.generateApiPointWithAlias(this.$route.meta.apiPoint), this.form)
                    .then(response => {
                        this.formLoading = false;

                        this.openBlankWindow(response.body.data.link);

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.formLoading = false;
                        return this.responseError(response);
                    })
            },

            handleChange (value) {

                if (!value) {
                    return this.schema = null;
                }

                this.schema = this.schemas[value];
            }
        }

    }
</script>
