<template>
    <el-form-item :label="item.label">
        <fs v-model="container[item.name]" :isFile="true" />
    </el-form-item>
</template>

<script>
export default {
    name: 'File',
    props : ['form', 'container', 'item'],
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>