<template>
	<div>
		<div class="card-sub-title d-flex justify-content-between align-items-center">
			<div>Sosyal Medya Hesaplari</div>
			<div>
				<el-tooltip content="Ekle" placement="right">
					<el-button class="flat dark blue" icon="el-icon-plus"/>
				</el-tooltip>
			</div>
		</div>
		<div class="card-custom-padding-box-items" style="padding-bottom: 20px">
			yapim asamasinda
		</div>
	</div>
</template>

<script>
    export default {
        name: 'SocialMetas',
        props: ['container', 'model'],
        data() {
            return {
                //
            }
        },
        mounted() {

        }
    };
</script>
