4<template>
    <div class="question-page padding-bottom-100">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    {{ $route.meta.title }}
                </div>
                <div class="actions">
                    <el-dropdown @command="handleChangeTab">
                        <span class="el-dropdown-link">
                            {{ activeTab.title }}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <template v-for="(tab, index) in appTabs">
                                <el-dropdown-item :command="tab" :key="index">
                                    {{ tab.title }}
                                </el-dropdown-item>
                            </template>
                        </el-dropdown-menu>
                    </el-dropdown>

                    <el-tooltip content="Filtrele" placement="bottom">
                        <el-button class="flat dark secondary margin-left-15" icon="el-icon-search" @click="handleSearch" />
                    </el-tooltip>

                    <el-tooltip content="Rapor" placement="bottom">
                        <el-button class="flat dark violet" icon="el-icon-pie-chart"  @click="handleVisibleReport"/>
                    </el-tooltip>

                    <el-tooltip content="Yeni Soru Olustur" placement="bottom">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="goRoute('/archives/question-contents/create')" />
                    </el-tooltip>
                </div>
            </template>
            <div class="el-card__filters">
                <el-form :label-position="'top'" ref="filter" :model="filter" @submit.native.prevent="handleSearch">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item>
                                <el-input placeholder="metne göre arama yap" suffix-icon="el-icon-search" v-model="searchTitle">
                                    <el-select v-model="searchTitleType" slot="append" placeholder="Arama Turu" class="select-append">
                                        <el-option label="Soru Icerigi" value="question_content" />
                                        <el-option label="Soru Koku" value="question" />
                                        <el-option label="Notlar" value="note" />
                                    </el-select>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item>
                                        <el-date-picker v-model="filterDate.startAt" type="date" placeholder="Başlangıç Tarihini Seçiniz" value-format="yyyy-MM-dd" :picker-options="datePickerOptions"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item>
                                        <el-date-picker v-model="filterDate.endAt" type="date" placeholder="Bitiş Tarihini Seçiniz" value-format="yyyy-MM-dd" :picker-options="datePickerOptions"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item>
                                        <ti-select :options="video_filter_options" :container="filterAccessVideo" model="is_access_video_solve" title="title" placeholder="Videolu Soru Çözüm" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>

                    <el-row :gutter="24">
                        <el-col :span="20">
                            <el-form-item>
                                <definitions alias="questions" :container="filterTag" model="tag_requests" :multiple="true" placeholder="Etiketler" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-select v-model="filterTag.tag_value" placeholder="Arama Türü">
                                    <el-option :label="option.label" :value="option.value" v-for="(option, index) in filterSearchTypeProp" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item>
                        <el-cascader placeholder="Konu Etiketleri" v-model="filterTopics" :options="topics" :props="{multiple: true}" clearable filterable />
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <ti-select :options="branches" :container="filter" model="archive_branch_id" title="title_ui" placeholder="Branş" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <ti-select :options="types" :container="filter" model="question_type" placeholder="Soru Turu" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <ti-select :options="owner_types" :container="filter" model="owner_type" placeholder="Kaynak Türü" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0">
                                <template v-if="filter.owner_type === 'examination'">
                                    <ti-select :options="examinations" :container="filter" model="archive_examination_id" title="title_ui" placeholder="Sınavlar" />
                                </template>
                                <template v-if="filter.owner_type === 'owner'">
                                    <user-with-role role="teacher" :container="filter" model="owner_id" placeholder="Yazar" />
                                </template>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-button native-type="submit" style="display: none" />
                </el-form>
            </div>
        </el-card>
        <div v-loading="pageLoading" class="margin-top-20">
            <el-card class="question-content-rows-card" v-if="activeTab.key === 'questions'">
                <div class="question-content-rows">
                    <template v-for="(row, index) in rows">
                        <div class="question-content-row" :key="index">
                            <div class="content">
                                <div class="metas">
                                    <div class="meta point" :style="{'background-color': row.branch_color }">
                                        {{ row.branch }}
                                    </div>
                                    <div class="meta">{{ $t('quesion_content_type_' + row.question_type) }}</div>
                                    <div class="meta">{{ $t('quesion_content_view_' + row.view_type) }}</div>
                                    <div class="meta">
                                        <icon icon="calendar-range" />
                                        {{ row.created_at }}
                                    </div>
                                </div>
                                <div class="text" v-html="row.text"></div>
                            </div>
                            <div class="actions">
                                <router-link class="margin-right-10" :to="'/archives/question-contents/' + row.id">
                                    <el-button class="modern teal extract icon" icon="el-icon-edit" />
                                </router-link>

                                <el-button @click="destroy(row.id)" class="modern red extract icon" icon="el-icon-delete" />
                            </div>
                        </div>
                    </template>
                </div>
            </el-card>
            <el-card class="question-content-rows-card" v-if="activeTab.key === 'question-control'">
                <template v-for="(row, index) in rows">
                    <div class="question-preview" :key="index">
                        <div class="question-content" v-if="row.question_type === 'connected'" v-html="row.text"></div>
                        <div class="questions">
                            <div class="question" v-for="(question, questionIndex) in row.questions" :key="question.id">
                                <div class="question-control">
                                    <div class="control action" :class="{active : question.control_1}"
                                         @click="updateQuestion(row.id, question, 'control_1')">
                                        <el-tooltip content="Genel Kontrol" placement="bottom">
                                            <icon icon="check" />
                                        </el-tooltip>
                                    </div>
                                    <div class="control action" :class="{active : question.control_2}"
                                         @click="updateQuestion(row.id, question, 'control_2')">
                                        <el-tooltip content="Şıklar ve Cevapların Kontrolü" placement="bottom">
                                            <icon icon="check" />
                                        </el-tooltip>
                                    </div>
                                    <div class="control action" :class="{active : question.control_3}"
                                         @click="updateQuestion(row.id, question, 'control_3')">
                                        <el-tooltip content="Yazım-İmla-Noktalama" placement="bottom">
                                            <icon icon="check" />
                                        </el-tooltip>
                                    </div>
                                    <div class="control action" :class="{active : question.control_4}"
                                         @click="updateQuestion(row.id, question, 'control_4')">
                                        <el-tooltip content="Şekilsel Kontrol" placement="bottom">
                                            <icon icon="check" />
                                        </el-tooltip>
                                    </div>
                                    <div class="control action" :class="{active : question.control_5}"
                                         @click="updateQuestion(row.id, question, 'control_5')">
                                        <el-tooltip content="Sorunun Cozumu" placement="bottom">
                                            <icon icon="check" />
                                        </el-tooltip>
                                    </div>
                                    <div class="control">
                                        <el-tooltip content="Duzenle" placement="bottom">
                                            <router-link class="text" :to="'/archives/question-contents/' + row.id" >
                                                <el-button class="modern default icon" icon="el-icon-edit" />
                                            </router-link>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="question-wrap">
                                    <div class="prefix">
                                        {{ questionIndex + 1 + index }}.
                                    </div>
                                    <div class="content">
                                        <div v-html="question.description"></div>

                                        <div class="ui message blue" v-if="question.meta.solved_description">
                                            <div v-html="question.meta.solved_description"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="options">
                                    <template v-for="(option, optionIndex) in question.options">
                                        <div class="option" :key="option.id" :class="{active : option.is_correct}">
                                            <div class="prefix" :class="{active : option.is_correct}">
                                                <icon
                                                    :icon="'letter-' + question.option_view_type + '-' + (optionIndex + 1)" />
                                            </div>
                                            <div class="content" v-html="option.description"></div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-card>
            <el-card class="question-content-rows-card" v-if="activeTab.key === 'question-owner'">
                <div class="question-content-rows">
                    <template v-for="(row, index) in rows">
                        <div class="question-content-row" :key="index" v-if="!row.owner_id">
                            <div class="content">
                                <div class="metas">
                                    <div class="meta point" :style="{'background-color': row.branch_color }">
                                        {{ row.branch }}
                                    </div>
                                    <div class="meta">{{ $t('quesion_content_type_' + row.question_type) }}</div>
                                    <div class="meta">{{ $t('quesion_content_view_' + row.view_type) }}</div>
                                    <div class="meta">
                                        <icon icon="calendar-range" />
                                        {{ row.created_at }}
                                    </div>
                                </div>
                                <div class="question-preview">
                                    <div class="question-content" v-if="row.question_type === 'connected'" v-html="row.text"></div>
                                    <div class="questions">
                                        <div class="question" v-for="(question, questionIndex) in row.questions" :key="question.id">
                                            <div class="question-wrap">
                                                <div class="prefix">
                                                    {{ questionIndex + 1 + index }}.
                                                </div>
                                                <div class="content">
                                                    <div v-html="question.description"></div>

                                                    <div class="ui message blue" v-if="question.meta.solved_description">
                                                        <div v-html="question.meta.solved_description"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="options">
                                                <template v-for="(option, optionIndex) in question.options">
                                                    <div class="option" :key="option.id" :class="{active : option.is_correct}">
                                                        <div class="prefix" :class="{active : option.is_correct}">
                                                            <icon
                                                                :icon="'letter-' + question.option_view_type + '-' + (optionIndex + 1)" />
                                                        </div>
                                                        <div class="content" v-html="option.description"></div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="actions">
                                <el-form>
                                    <el-form-item class="margin-bottom-0">
                                        <user-with-role role="teacher" :container="row" model="owner_id" placeholder="Yazar" :change="handleChangeOwner" />
                                    </el-form-item>
                                </el-form>

                                <router-link class="margin-right-10 margin-left-10" :to="'/archives/question-contents/' + row.id">
                                    <el-button class="modern teal extract icon" icon="el-icon-edit" />
                                </router-link>

                                <el-button @click="destroy(row.id)" class="modern red extract icon" icon="el-icon-delete" />
                            </div>
                        </div>
                    </template>
                </div>
            </el-card>
            <el-card class="question-content-rows-card" v-if="activeTab.key === 'question-record'">
                <div class="question-content-rows">
                    <template v-for="(row, index) in rows">
                        <div class="question-content-row" :key="index">
                            <div class="content fluid">
                                <div class="top-bar fluid display-flex" style="align-items: center; justify-content: space-between">
                                    <div class="metas">
                                        <div class="meta point" :style="{'background-color': row.branch_color }">
                                            {{ row.branch }}
                                        </div>
                                        <div class="meta">{{ $t('quesion_content_type_' + row.question_type) }}</div>
                                        <div class="meta">{{ $t('quesion_content_view_' + row.view_type) }}</div>
                                        <div class="meta">
                                            <icon icon="calendar-range" />
                                            {{ row.created_at }}
                                        </div>
                                    </div>
                                    <div class="actions">
                                        <router-link class="margin-right-10 margin-left-10" :to="'/archives/question-contents/' + row.id">
                                            <el-button class="modern teal extract icon" icon="el-icon-edit" />
                                        </router-link>
                                        <el-button @click="destroy(row.id)" class="modern red extract icon" icon="el-icon-delete" />
                                    </div>
                                </div>

                                <div class="question-preview">
                                    <div class="question-content" v-if="row.question_type === 'connected'" v-html="row.text"></div>
                                    <div class="questions">
                                        <div class="question" v-for="(question, questionIndex) in row.questions" :key="question.id">
                                            <div class="question-wrap">
                                                <div class="prefix">
                                                    {{ questionIndex + 1 + index }}.
                                                </div>
                                                <div class="content">
                                                    <div v-html="question.description"></div>
                                                    <div class="ui message blue" v-if="question.meta.solved_description">
                                                        <div v-html="question.meta.solved_description"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style="margin: 30px 0">
                                            <div>
                                                <el-row :gutter="24">
                                                    <el-col :span="12">
                                                        <div class="video-solve">
                                                            <video controls width="100%" height="290" :src="appConfig.s3SourcePath + '/' + question.video_solve + '?v=' + (Math.random())"></video>
                                                        </div>
                                                    </el-col>
                                                    <el-col :span="12">
                                                        <el-card class="custom-padding card-flat-list">
                                                            <div class="card-flat-list-items sm scrool-height-290">
                                                                <div class="item" v-for="(log, logIndex) in question.logs" :key="logIndex">
                                                                    <div class="text">
                                                                        <div class="title">{{ log.created_at }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </el-card>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </el-card>
        </div>

        <div class="pagination" v-if="pagination && pagination.total > pagination.per_page">
            <el-pagination
                large
                background
                layout="total, prev, pager, next, jumper"
                :total="pagination.total"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page"
                @current-change="handleChangePage"
            />
        </div>

        <el-dialog title="Rapor" :visible.sync="dialogVisible" width="80%" class="custom-padding">
            <div style="min-height: 100px" v-loading="dialogLoading">

               <div style="padding: 20px 20px 0 20px">
                   <el-row :gutter="24">
                       <el-col :span="12">
                           <el-card class="stat-card">
                               <div class="text">
                                   <div class="number">{{ report.question_count }}</div>
                                   <div class="title">Soru Sayisi</div>
                               </div>
                               <div class="media bg-teal-opack text-teal">
                                   <icon icon="calculator" />
                               </div>
                           </el-card>
                       </el-col>
                       <el-col :span="12">
                           <el-card class="stat-card">
                               <div class="text">
                                   <div class="number">{{ report.teacher_count }}</div>
                                   <div class="title">Ogretmen Sayisi</div>
                               </div>
                               <div class="media bg-purple-opack text-purple">
                                   <icon icon="account-tie-outline" />
                               </div>
                           </el-card>
                       </el-col>
                   </el-row>
               </div>

                <el-card class="custom-padding">
                    <div class="el-card__tabs custom-padding">
                        <el-tabs class="el-card--tabs fluid" :tab-position="'top'">
                            <el-tab-pane label="Konulara Gore Soru Dagilimlari">
                                <div class="box-list">
                                    <template v-for="(item, index) in report.topics">
                                        <div class="box-list-item" :key="index">
                                            <div class="name" style="width: 100%">{{ item.title }}</div>
                                            <div class="description" style="width: 60px">{{ item.count }}</div>
                                        </div>
                                    </template>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Ogretmenlere Gore Soru Dagilimlari">
                                <div class="box-list">
                                    <template v-for="(item, index) in report.teachers">
                                        <div class="box-list-item" :key="index">
                                            <div class="name" style="width: 100%">{{ item.title }}</div>
                                            <div class="description" style="width: 100px; display: flex; align-items: center; justify-content: flex-end">
                                                <div>
                                                    {{ item.count }}
                                                </div>
                                                <div class="margin-left-20">
                                                    <el-button class="flat dark secondary" icon="el-icon-printer" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/prints/educations/tests/1?page=1&teacher=' + item.id)" />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>

                </el-card>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: 'QuestionContentIndex',
    components: {
        //
    },
    data() {
        return {
            pageLoading: false,
            filter: {
                //
            },
            filterDate : {
                startAt : null,
                endAt : null,
            },
            filterTopics : [],
            searchTitle: null,
            searchTitleType: 'question',
            filterTag : {
                tag_value : 'in',
                tag_requests : [],
            },
            filterAccessVideo :{},
            branches: [],
            examinations: [],
            types: [
                {
                    id: 'singular',
                    title: 'Tekil',
                },
                {
                    id: 'connected',
                    title: 'Bağlı',
                }
            ],
            views: [
                {
                    id: 'multiple_choice',
                    title: 'Çoktan Seçmeli',
                },
                {
                    id: 'listening_multiple_choice',
                    title: 'Dinlemeli Çoktan Seçmeli',
                },
                {
                    id: 'blank_multiple_choice',
                    title: 'Boşluk Doldurma',
                }
            ],
            owner_types: [
                {
                    id: 'examination',
                    title: 'Gerçek Sınav',
                },
                {
                    id: 'owner',
                    title: 'Orjinal',
                }
            ],
            video_filter_options: [
                {
                    id: 'yes',
                    title: 'Video Çözümü Yapılabilir',
                },
                {
                    id: 'no',
                    title: 'Video Çözümü Yapılamaz',
                }
            ],


            topics : [],

            rows: [],
            pagination: null,
            page: 1,
            perPage: 10,

            activeTab: {
                key: 'questions',
                title: 'Sorular',
            },
            appTabs: [
                {
                    key: 'questions',
                    title: 'Sorular',
                },
                {
                    key: 'question-owner',
                    title: 'Ogretmen Kontrolu',
                },
                {
                    key: 'question-record',
                    title: 'Videolu Çözüm',
                }
            ],

            dialogVisible : false,
            dialogLoading : false,
            report : {
                topics : [],
                teachers : [],
            }
        }
    },
    computed: mapState(['account', 'ui']),
    mounted() {
        this.getBranches();
        this.getExaminations();
        this.getTopics();
        this.index();
    },
    methods: {
        handleChangeTab(event) {
            this.activeTab = event;
        },

        getTopics() {
            this.pageLoading = true;

            this.$http
                .get('archives/topics', {
                    params: {
                        perPage: 10000,
                        view: 'cascader',
                    }
                })
                .then(response => {
                    this.pageLoading = false;
                    this.topics = response.body.data;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getBranches() {
            this.pageLoading = true;

            this.$http
                .get('archives/branches', {params: {perPage: 10000}})
                .then(response => {
                    this.pageLoading = false;
                    this.branches = response.body.rows;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getExaminations() {
            this.pageLoading = true;

            this.$http
                .get('archives/examinations', {params: {perPage: 10000}})
                .then(response => {
                    this.pageLoading = false;
                    this.examinations = response.body.rows;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleChangePage(value) {
            this.page = value;
            this.index();
        },

        handleSearch() {
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {
                    params: {
                        page: this.page,
                        perPage: this.perPage,
                        sort: {
                            created_at: 'desc'
                        },
                        columnFilters: this.filter,
                        topics: this.filterTopics,
                        title: this.searchTitle,
                        title_type: this.searchTitleType,
                        owner_type : this.filter.owner_type,
                        owner_id : this.filter.owner_id ? this.filter.owner_id : null,
                        start_at : this.filterDate.startAt,
                        end_at : this.filterDate.endAt,
                        tag_requests : this.filterTag.tag_requests,
                        tag_value : this.filterTag.tag_value,
                        active_tab : this.activeTab.key,
                        is_access_video_solve : this.filterAccessVideo.is_access_video_solve,
                    }
                })
                .then(response => {
                    this.rows = response.body.rows;
                    this.pagination = response.body.pagination;

                    setTimeout(() => {
                        this.initMathJax(window.document.getElementsByTagName('math'));
                        this.pageLoading = false;
                    }, 500)
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    this.$http
                        .delete(this.$route.meta.apiPoint + '/' + id)
                        .then(response => {
                            this.index();
                            this.responseSuccess(response);
                        })
                        .catch(response => {
                            this.responseError(response);
                        });

                    return true;
                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        /**
         * Form update
         */
        updateQuestion(id, question, key) {
            this.pageLoading = true;

            question[key] = !question[key];

            this.$http
                .put(this.$route.meta.apiPoint + '/' + id + '/questions/' + question.id, question)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleChangeOwner(questionContent) {
            this.pageLoading = true;

            this.$http
                .put(this.$route.meta.apiPoint + '/' + questionContent.id, {
                    id : questionContent.id,
                    owner_type : 'owner',
                    owner_id : questionContent.owner_id,
                    process : 'owner',
                })
                .then(() => {
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleVisibleReport() {
            this.dialogVisible = true;
            this.dialogLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {
                    params: {
                       process : 'report'
                    }
                })
                .then(response => {
                    this.report = response.body.data;
                    this.dialogLoading = false;
                })
                .catch(response => {
                    this.dialogLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
