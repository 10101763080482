import LayoutConfig from '../config/layout'
import ViewContainer from '../app/Container/ViewContainer'

import Index from '../app/Pages/Themes/Index'
import Show from '../app/Pages/Themes/Show'
import Create from '../app/Pages/Themes/Create'

export default {
    path: '/themes',
    component: ViewContainer,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Temalar',
    },
    children: [
        {
            path: '',
            component: Index,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Tema Yonetimi',
            },
            children: [
                {
                    name: 'api.themes.index',
                    path: '',
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Tema Yonetimi',
                        apiPoint: 'themes',
                    },
                },
                {
                    name: 'api.themes.create',
                    path: 'create',
                    component: Create,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Tema Yukle',
                        apiPoint: 'themes',
                    }
                },
                {
                    name: 'api.themes.edit',
                    path: ':id',
                    component: Show,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Tema Bilgileri',
                        apiPoint: 'themes',
                    },
                },
            ]
        },
    ]
}