<template>
	<div v-loading="pageLoading">
		<el-form :label-position="'top'" ref="form" :model="form">
			<el-card class="custom-padding">
				<template slot="header">
					<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
				</template>
				<div class="card-sub-title">Genel</div>
				<div class="card-custom-padding-box-items">
					<el-form-item label="Baslik">
						<el-input v-model="form.title" placeholder="Baslik" />
					</el-form-item>

					<el-form-item label="Aciklama">
						<el-input v-model="form.description" type="textarea" placeholder="Aciklama" />
					</el-form-item>

					<el-form-item label="Siralama">
						<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
					</el-form-item>
				</div>

				<div class="card-sub-title">Fiyat</div>
				<div class="card-custom-padding-box-items">
					<el-form-item label="İndirim Orani">
						<el-row :gutter="24">
							<el-col :span="4">
								<el-select v-model="form.discount_type" value-key="value" placeholder="Oran Tipi"
										   clearable>
									<el-option v-for="item in discountTypes" :key="item.value" :label="item.label"
											   :value="item.value"></el-option>
								</el-select>
							</el-col>
							<el-col :span="20">
								<el-input v-model="form.discount" placeholder="Oran" />
							</el-col>
						</el-row>
					</el-form-item>

					<el-form-item label="Ekstra İndirim Orani">
						<el-row :gutter="24">
							<el-col :span="4">
								<el-select v-model="form.discount_type2" value-key="value" placeholder="Oran Tipi"
										   clearable>
									<el-option v-for="item in discountTypes" :key="item.value" :label="item.label"
											   :value="item.value"></el-option>
								</el-select>
							</el-col>
							<el-col :span="20">
								<el-input v-model="form.discount2" placeholder="Oran" />
							</el-col>
						</el-row>
					</el-form-item>
				</div>

				<div class="card-sub-title">Ozel Durumlar</div>
				<div class="card-custom-padding-box-items">
					<el-form-item>
						<el-select v-model="form.special_process" placeholder="Ozel Durum" clearable filterable>
							<el-option
									v-for="item in specialItems"
									:key="item.value"
									:label="item.label"
									:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</div>

				<div class="card-sub-title">Genel Kisitlamalar</div>
				<div class="card-custom-padding-box-items">
					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item label="Baslangic Tarihi">
								<el-date-picker v-model="form.start_at" type="datetime" placeholder="Tarih Seçiniz"
												value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="Bitis Tarihi">
								<el-date-picker v-model="form.end_at" type="datetime" placeholder="Tarih Seçiniz"
												value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>
					</el-row>

					<el-form-item label="Minimum Sepet Tutarı">
						<el-input v-model="form.minimum_price" placeholder="Minimum Sepet Tutarı" />
					</el-form-item>

					<el-form-item label="Minimum Baglilik">
						<el-input v-model="form.loyalty_year" placeholder="Minimum Baglilik" />
					</el-form-item>

					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item label="Kullanim Limiti">
								<el-input v-model="form.max_usage_count" placeholder="Kullanim Limiti" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Kullanilan">
								<el-input v-model="form.current_usage_count" placeholder="Kullanilan" disabled />
							</el-form-item>
						</el-col>
					</el-row>

                    <el-form-item>
                        <el-switch v-model="form.is_single" active-text="Bunun dışında başka indirim varsa kullanma" />
                    </el-form-item>

                    <el-form-item>
                        <el-switch v-model="form.is_active" active-text="Aktif" />
                    </el-form-item>
				</div>
			</el-card>

			<el-card class="custom-padding">
				<template slot="header">
					<div class="title">
						Iliskilere Bagli Kisitlamalar
					</div>
				</template>
				<div class="el-card__tabs fluid">
					<el-tabs class="el-card--tabs fluid" :tab-position="'top'">
						<el-tab-pane label="Odeme Yontemine Bağlı">
							<el-form-item>
								<el-switch v-model="form.is_payment" active-text="Etkinlestir!" />
							</el-form-item>
							<el-transfer
									filterable
									:filter-method="filterMethod"
									filter-placeholder="Odeme Yontemlerin de de Ara"
									:titles="['Tüm Odeme Yontemleri', 'Seçili Olanlar']"
									v-model="form.payment_requests"
									:props="{key: 'id', label: 'title'}"
									:data="payments">
							</el-transfer>
						</el-tab-pane>
						<el-tab-pane label="Ürün Icerigine Bağlı">
							<el-form-item>
								<el-switch v-model="form.is_product" active-text="Etkinlestir!" />
							</el-form-item>

							<el-form-item label="Minimum Sepet Urun Adeti">
								<el-input v-model="form.min_product" placeholder="Minimum Sepet Urun Adeti" />
							</el-form-item>

							<el-transfer
									filterable
									:filter-method="filterMethod"
									filter-placeholder="Ürünlerde Ara"
									:titles="['Tüm ürünler', 'Seçili Olanlar']"
									v-model="form.product_requests"
									:props="{key: 'id', label: 'title'}"
									:data="products">
							</el-transfer>


						</el-tab-pane>
						<el-tab-pane label="Kategoriye Bağlı">
							<el-form-item>
								<el-switch v-model="form.is_category" active-text="Etkinlestir!" />
							</el-form-item>

							<el-transfer
									filterable
									:filter-method="filterMethod"
									filter-placeholder="Kategoriler de Ara"
									:titles="['Tüm Kategoriler', 'Seçili Olanlar']"
									v-model="form.category_requests"
									:props="{key: 'id', label: 'title'}"
									:data="categories">
							</el-transfer>
						</el-tab-pane>
						<el-tab-pane label="Role Bağlı">
							<el-form-item>
								<el-switch v-model="form.is_role" active-text="Etkinlestir!" />
							</el-form-item>
							<el-transfer
									filterable
									:filter-method="filterMethod"
									filter-placeholder="Roller de Ara"
									:titles="['Tüm Roller', 'Seçili Olanlar']"
									v-model="form.role_requests"
									:props="{key: 'id', label: 'title'}"
									:data="roles">
							</el-transfer>
						</el-tab-pane>
						<el-tab-pane label="Meslege Bağlı">
							<el-form-item>
								<el-switch v-model="form.is_job" active-text="Etkinlestir!" />
							</el-form-item>

							<el-transfer filterable :filter-method="filterMethod" filter-placeholder="Meslekler de Ara"
										 :titles="['Tüm Meslekler', 'Seçili Olanlar']" v-model="form.job_requests"
										 :props="{key: 'id', label: 'title'}" :data="jobs" />
						</el-tab-pane>
					</el-tabs>
				</div>


			</el-card>
		</el-form>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
        },
        data() {
            return {
                pageLoading: false,
                form: {
                    category_requests: [],
                    job_requests: [],
                    payment_requests: [],
                    product_requests: [],
                    role_requests: [],
                },

                discountTypes: [
                    {
                        value: 1,
                        label: 'Fiyat - TL'
                    },
                    {
                        value: 2,
                        label: 'Yüzde - %'
                    },
                ],
                specialItems: [
                    //
                ],

                payments: [],
                products: [],
                categories: [],
                roles: [],
                jobs: [],

                filterMethod(query, item) {
                    return item.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
                },
            }
        },
        mounted() {
            if (this.$route.params.id) {
                this.show();
            }

            this.getPayments();
            this.getProducts();
            this.getCategories();
            this.getRoles();
            this.getJobs();

        },
        methods: {
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getPayments() {
                this.pageLoading = true;

                this.$http
                    .get('tools/plugins/payments')
                    .then(response => {
                        this.pageLoading = false;
                        this.payments = response.body.rows;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getProducts() {
                this.pageLoading = true;

                this.$http
                    .get('cms/products/contents', {params: {view: 'dropdown'}})
                    .then(response => {
                        this.pageLoading = false;
                        this.products = response.body.data;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getCategories() {
                this.pageLoading = true;

                this.$http
                    .get('cms/products/categories', {params: {view: 'dropdown'}})
                    .then(response => {
                        this.pageLoading = false;
                        this.categories = response.body.rows;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getRoles() {

                this.pageLoading = true;

                this.$http
                    .get('crm/roles')
                    .then(response => {
                        this.pageLoading = false;
                        this.roles = response.body.rows;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getJobs() {
                //this.jobs = Object.values(this.$store.state.ui.definitions.jobs);
            },
        }
    }
</script>
