<template>
	<div class="card-custom-padding-box margin-0">
		<div class="card-sub-title">Bakim Modu</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Durum">
				<lv-select :container="form" model="maintenance_status" :options="approveOptions" />
			</el-form-item>
			<el-form-item label="Mesaj">
				<el-input type="textarea" v-model="form.maintenance_message" placeholder="Mesaj" />
			</el-form-item>
		</div>

		<div class="card-sub-title">Yonlendirme Modu</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Durum">
				<lv-select :container="form" model="redirect_status" :options="approveOptions" />
			</el-form-item>
			<el-form-item label="Yonlendirilicek Domain">
				<el-input type="textarea" v-model="form.redirect_url" placeholder="Yonlendirilicek Domain" />
			</el-form-item>
		</div>

        <div class="card-sub-title">Bilgilendirme Mektuplari</div>
        <div class="card-custom-padding-box-items">
            <el-form-item label="Gun">
                <el-date-picker v-model="form.platform_information_letter_day" type="date" placeholder="Tarih" format="dd" value-format="dd" />
            </el-form-item>
        </div>
	</div>
</template>

<script>
	export default {
		name: 'OtherSetting',
		props: ['form'],
		data() {
			return {
				//
			}
		},
		mounted() {
			//
		},
		methods: {
			//
		}
	}
</script>