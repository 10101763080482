<template>
	<div v-loading="pageLoading">
		<el-menu
				:default-active="activeTab"
				mode="horizontal"
				background-color="#545c64"
				text-color="#fff"
				active-text-color="#e85f79"
				@select="handleSelect"
				v-if="$route.params.id"
				class="container-tab-menu"
		>
			<el-menu-item index="1">
				Detay
			</el-menu-item>
			<el-menu-item index="2">Resimler</el-menu-item>
			<el-menu-item index="3">Sekmeler</el-menu-item>
			<el-menu-item index="4">Ozellikler</el-menu-item>
		</el-menu>

		<div v-show="activeTab === '1'">
			<el-card class="custom-padding">
				<template slot="header">
					<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
				</template>
				<el-form :label-position="'top'" ref="form" :model="form">
					<div class="card-sub-title">Genel</div>
					<div class="card-custom-padding-box-items">
						<el-form-item label="Baslik">
							<el-input v-model="form.title" placeholder="Baslik" />
						</el-form-item>

						<el-form-item label="Yayınlanma Tarihi">
							<el-date-picker v-model="form.publish_at" type="datetime"
											placeholder="Yayınlanma Tarihibi Seçiniz"
											value-format="yyyy-MM-dd HH:mm:ss" />
						</el-form-item>

						<el-form-item label="Kisa Aciklama" class="is-editor">
							<editor :container="form" model="short_description" :small="true" />
						</el-form-item>

						<el-form-item label="Aciklama" class="is-editor">
							<editor :container="form" model="description" />
						</el-form-item>
					</div>

					<div class="card-sub-title">Baglantilar</div>
					<div class="card-custom-padding-box-items">
						<el-form-item label="Kategoriler">
							<el-select v-model="form.category_requests" placeholder="Bagli Oldugu Kategori" clearable
									   filterable multiple>
								<el-option v-for="item in categories" :key="item.id" :label="item.title_ui"
										   :value="item.id" />
							</el-select>
						</el-form-item>

						<el-form-item label="Rozetler">
							<statuses alias="badges" :container="form" model="badge_requests" :multiple="true" />
						</el-form-item>

						<el-form-item label="Onerilen Sayfalar">
							<el-select v-model="form.suggestion_requests" placeholder="Onerilen Sayfalar" clearable
									   filterable multiple>
								<el-option v-for="item in contents" :key="item.id" :label="item.title"
										   :value="item.id" />
							</el-select>
						</el-form-item>
					</div>

					<div class="card-sub-title">Resim</div>
					<div class="card-custom-padding-box-items">
						<el-row :gutter="24">
							<el-col :span="4">
								<el-form-item label="Liste Resmi">
									<fs v-model="form.file.path" />
								</el-form-item>
							</el-col>

                            <el-col :span="4" v-if="$route.meta.alias === 'exam_promos' || $route.meta.alias === 'city_promos'">
                                <el-form-item label="Kapak Resmi">
                                    <fs v-model="form.meta.cover_img" />
                                </el-form-item>
                            </el-col>
						</el-row>
					</div>

					<div class="card-sub-title d-flex justify-content-between align-items-center">
						<div>
							Seo
						</div>
						<div v-if="form.seo.slug">
							<el-tooltip content="Duzenle" placement="left">
								<el-button class="flat dark green" icon="el-icon-edit" @click="openBlankWindow('/#/tools/seo/links/' + form.seo.id)" />
							</el-tooltip>
							<el-tooltip content="Web Sitesinde Gor" placement="right">
								<el-button class="flat dark yellow" icon="el-icon-s-promotion"
										   @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '/' + form.seo.slug)" />
							</el-tooltip>
						</div>
					</div>
					<div class="card-custom-padding-box-items">
						<seo :container="form.seo" />
					</div>

					<div class="card-sub-title">Ozellikler</div>
					<div class="card-custom-padding-box-items">
						<el-form-item>
							<el-switch v-model="form.is_featured" active-text="One Cikar" />
						</el-form-item>

						<el-form-item label="Hedef Linki">
							<el-input v-model="form.target_link" placeholder="Hedef Linki" />
						</el-form-item>

						<el-form-item label="Siralama">
							<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
						</el-form-item>
					</div>

					<div class="card-sub-title">Tasarim</div>
					<div class="card-custom-padding-box-items">
						<el-form-item label="Sablon">
							<lv-select :options="layouts" :container="form" model="view_path" />
						</el-form-item>
					</div>
				</el-form>
			</el-card>
		</div>

		<div v-if="$route.params.id">
			<div v-show="activeTab === '2'">
				<Images :id="$route.params.id" />
			</div>
			<div v-show="activeTab === '3'">
				<Tabs :id="$route.params.id" />
			</div>
			<div v-show="activeTab === '4'">
				<Features :id="$route.params.id" />
			</div>
		</div>
	</div>
</template>

<script>
    import CrudHeader from "../../../Components/CrudHeader";
    import Images from "./Images";
    import Tabs from "./Tabs";
    import Features from "./Features";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader,
            Images,
            Tabs,
            Features,
        },
        data() {
            return {
                pageLoading: false,
                activeTab: '1',
                form: {
                    meta: {},
                    file: {},
                    seo: {
                        slug : null,
                    },

                    category_requests: [],
                    badge_requests: [],
                    suggestion_requests: [],
                },

                categories: [],
                contents: [],
                layouts: [
                    {
                        value: 'contact',
                        label: 'Iletisim',
                    },
                    {
                        value: 'franchising',
                        label: 'Franchising',
                    },
                    {
                        value: 'oxford',
                        label: 'Oxford Seviye Belirleme Sinavi',
                    },
                ],
            }
        },
        methods: {

            getCategories() {
                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.apiPoint.replace('contents', 'categories'), {params: {perPage: 1000}})
                    .then(response => {
                        this.categories = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getContents() {
                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.apiPoint, {params: {perPage: 1000, view: 'dropdown'}})
                    .then(response => {
                        this.contents = response.body.data;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        if (!this.lodash.size(this.form.meta)) {
                            this.form.meta = {};
                        }

						if (!this.form.file) {
							this.form.file = {};
						}

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            handleSelect(event) {
                this.activeTab = event
            }
        },
        mounted() {

            this.getCategories();
            this.getContents();

            if (this.$route.params.id) {
                this.show();
            }
        },
    }
</script>
