<template>
    <el-dialog width="90%" :visible.sync="$store.state.filemanager.dialog" :before-close="handleClose" append-to-body class="file-manager-dialog">
        <template slot="title">
            <div class="head">
                <div class="title">Dosya Yöneticisi</div>
                <div class="action">
                    <el-button class="modern green" @click="handleSelected">
                        Dosyayi Sec
                    </el-button>
                </div>
            </div>
        </template>
        <div class="ui message yellow" style="margin: 0; border-radius: 0">
            Lutfen resim yuklerken, dosya formatini <b>.webp</b> cevirmeyi ve <b>boyutunu kucultmeyi</b> unutmayiniz
        </div>
        <file-manager :settings="fileManagerSettings" />
    </el-dialog>
</template>

<script>
export default {
    name: 'GlobalFileManager',
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        handleSelected() {
            let files = this.$store.state.fm.left.selected.files;

            if (!this.lodash.size(files)) {
                return this.$notify
                    .error({
                        title: 'Upss!',
                        message: 'Dosya secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });
            }

            this.$store.state.filemanager.input(files[0])

            this.handleClose();
        },

        handleClose() {
            this.$store.commit('setVisibleDialogFileManager', false)
        }
    }
}
</script>