<template>
    <div v-loading="pageLoading">
        <div class="branches" v-if="!tab.id">
            <el-row :gutter="24">
                <template v-for="(item, index) in tabs">
                    <el-col :span="12" :key="index">
                        <div class="item" :style="{'background-color' : item.color}" @click="tab = item">
                            <div class="media">
                                <icon :icon="item.icon" />
                            </div>
                            <div class="text">
                                {{ item.title }}
                            </div>
                        </div>
                    </el-col>
                </template>
            </el-row>
        </div>
        <el-card v-if="tab.id">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">
                        {{ tab.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" placement="left">
                            <el-button class="flat dark grey" icon="el-icon-back" @click="tab = {}" />
                        </el-tooltip>
                    </div>
                </div>
            </template>
            <div class="ui message blue margin-bottom-20">
                <div v-html="'Konu ve template kisminda kullanilacak degiskenler. Ornek: Merhaba {{name}} ...'"></div>
                <div class="margin-top-20">
                    <el-tag class="margin-right-10">
                        <span v-html="'{{ name }}'"></span>
                    </el-tag>
                </div>
            </div>

            <Sms v-if="tab.id === 'sms'" :send="store" />
            <Mail v-if="tab.id === 'mail'" :send="store" />
            <Web v-if="tab.id === 'web'" :send="store" />
            <Android v-if="tab.id === 'android'" :send="store" />
            <Ios v-if="tab.id === 'ios'" :send="store" />
        </el-card>
    </div>
</template>

<script>
import Sms from "@/app/Pages/Marketing/BulkMessage/Sms";
import Mail from "@/app/Pages/Marketing/BulkMessage/Mail";
import Web from "@/app/Pages/Marketing/BulkMessage/Web";
import Android from "@/app/Pages/Marketing/BulkMessage/Android";
import Ios from "@/app/Pages/Marketing/BulkMessage/Ios";

export default {
    name: 'Index',
    components: {Ios, Android, Web, Mail, Sms},
    data() {
        return {
            pageLoading: false,
            tab: {
                //
            },
            tabs: [
                {
                    id: 'sms',
                    icon: 'message-text-outline',
                    color: '#f2711c',
                    title: 'Sms',
                },
                {
                    id: 'mail',
                    icon: 'email-outline',
                    color: '#a333c8',
                    title: 'Eposta',
                },
                {
                    id: 'android',
                    icon: 'android',
                    color: '#a5ca39',
                    title: 'Android',
                },
                {
                    id: 'ios',
                    icon: 'apple',
                    color: '#0040dd',
                    title: 'Ios',
                },
                {
                    id: 'web',
                    icon: 'web',
                    color: '#e03997',
                    title: 'Web',
                }
            ],
            products: []
        }
    },
    methods: {
        store(form) {
            form.bulk_type = this.tab.id;

            this.pageLoading = true;

            this.$http
                .post(this.$route.meta.apiPoint, form)
                .then(response => {
                    this.pageLoading = false;

                    this.$notify.success({
                        title: 'Başarılı',
                        message: response.body.data.message,
                        position: 'top-right'
                    });
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
