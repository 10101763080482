<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<div class="crud-header">
					<div class="title">
						{{ cardCrudTitle() }}
					</div>
					<div class="actions">

					</div>
				</div>
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				yapim asamasinda
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'Customer',
        components: {
            CrudHeader
        },
        data() {
            return {
                pageLoading: false,
                form: {
                    //
                },
            }
        },
        mounted() {
            //
        },
        methods: {
            //
        }
    }
</script>