<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list overflow-400">
			<template slot="header">
				<div class="title">Müşterinin Diğer Siparişleri</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                <template v-for="(item, index) in items">
                    <div class="item cursor-pointer" v-if="activeId !== item.id" :key="index">
                        <div class="text" @click="openBlankWindow('/#/shop/orders/' + item.id)">
                            <div class="title">#{{ item.code }}</div>
                            <div class="vertical-flex-items has-dimmer">
                                <div>{{ $n(item.total, 'currency') }}</div>
                                <div>{{ item.created_at }}</div>
                                <div>{{ item.payment_plugin.name }}</div>
                                <div v-html="item.status_badge"></div>
                            </div>
                        </div>
                    </div>
                </template>
			</div>
		</el-card>
	</div>
</template>

<script>
    export default {
        name: 'Orders',
        props: ['id', 'activeId'],
        data() {
            return {
                pageLoading: false,
                items: []
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get('shop/orders', {
                        params: {
                            perPage: 1000,
                            filters: [
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'user_id',
                                            field: 'user_id',
                                            type: 'singleSelect',
                                            value: this.id
                                        }
                                    ]
                                },
                            ],
                            sorts : {
                                created_at : 'desc'
                            }
                        }
                    })
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            }
        }
    }
</script>