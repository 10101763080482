<template>
    <div>
        <vue-good-table
            ref="DataTable"
            mode="remote"
            :is-loading="loading"
            :line-numbers="true"
            :pagination-options="paginationOptions"
            :totalRows="pagination.total"
            :columns="columns"
            :rows="rows"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            :select-options="{enabled: true, selectOnCheckboxOnly : true}"
        >
            <div slot="selected-row-actions">
                <el-button class="flat dark red" icon="el-icon-delete" @click="multipleDestroy">
                    Seçili Olanları Sil
                </el-button>
            </div>

            <template slot="loadingContent">
                <span class="vgt-loading__content">Yukleniyor...</span>
            </template>

            <template slot="emptystate">
                <div style="text-align: center">
                    Upss! Uzgunum her hangi bir sonuc bulunamadi.
                </div>
            </template>

            <template slot="table-row" slot-scope="props">

                <template v-if="props.column.field === 'action'">
                    <el-tooltip content="Sil">
                        <el-button type="text" class="flat dark red" icon="el-icon-delete" @click="handleRowActionCommand({type : 'destroy', id : props.row.id})" />
                    </el-tooltip>
                </template>

                <template v-else-if="props.column.linkable || props.column.field === 'title'">
                    <router-link :to="$route.fullPath + '/' + props.row.id" class="linkable">
                        <template v-if="props.column.html">
                            <div v-html="props.formattedRow[props.column.field]"></div>
                        </template>
                        <template v-if="!props.column.html">
                            <b>{{ props.formattedRow[props.column.field] }}</b>
                        </template>
                    </router-link>
                </template>

                <template v-else-if="props.column.type === 'image'">
                    <img :src="appConfig.s3Path + '/' + props.formattedRow[props.column.field]" alt="image"
                         v-if="props.formattedRow[props.column.field]" class="td-image">
                    <img src="@/resources/img/dummy.png" alt="image" v-if="!props.formattedRow[props.column.field]"
                         class="td-image">
                </template>

                <template v-else-if="props.column.type === 'currency'">
                    {{ $n(props.formattedRow[props.column.field], 'currency') }}
                </template>

                <template v-else-if="props.column.type === 'phone'">
                    <phone :item="props.formattedRow[props.column.field]"/>
                </template>

                <template v-else-if="props.column.type === 'email'">
                    <email :item="props.formattedRow[props.column.field]"/>
                </template>

                <template v-else-if="props.column.type === 'login'">
                    <el-tooltip content="Giris Yap">
                        <el-button type="text" class="flat dark green" icon="el-icon-user" @click="openBlankWindow('https://' + $store.state.ui.tenant.domain + '?login=' + props.formattedRow[props.column.field])" />
                    </el-tooltip>
                </template>

                <template v-else-if="props.column.html">
                    <span v-html="props.formattedRow[props.column.field]"></span>
                </template>

                <template v-else-if="props.column.is_loop">
                    <div v-for="(loopItem, loopItemKey) in props.row[props.column.loop_items]" :key="loopItemKey">

                        <div v-if="!props.column.is_loop_linkable">
                            {{ pathIndex(loopItem, props.column.loop_field) }}
                        </div>

                        <div v-if="props.column.is_loop_linkable">
                            <router-link v-bind:to="props.column.is_loop_linkable + '/' + loopItem.id" target="_blank">
                                {{ pathIndex(loopItem, props.column.loop_field) }}
                            </router-link>
                        </div>
                    </div>
                </template>

            </template>
        </vue-good-table>
    </div>
</template>

<script>
export default {
    name: 'Datatable',
    props: [
        'apiPoint'
    ],
    data() {
        return {
            loading: false,
            columns: [],
            rows: [],
            pagination: {
                total : 0
            },

            paginationOptions: {
                enabled: true,
                mode: 'pages',
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [10, 30, 50],
                dropdownAllowAll: false,
                nextLabel: 'Sonraki',
                prevLabel: 'Önceki',
                rowsPerPageLabel: 'Satır sayısı',
                ofLabel: 'görüntüleniyor. Toplam kayit sayısı:',
                pageLabel: 'Sayfa',
                allLabel: 'Tümü',
            },

            backupColumnFilter: {},

            params : {
                sorts: {
                    created_at: 'desc'
                },
                columnFilters: {
                    //
                },
                filters: [],
                page: null,
                perPage: 10
            }
        }
    },

    created() {
        if (!this.$store.state.datatable[this.$route.name]) {
            this.$store.commit('setDatatable', {
                key: this.$route.name,
                sorts: {
                    created_at: 'desc'
                },
                columnFilters: {
                    //
                },
                filters: [],
                page: 1,
                perPage: 10
            })
        }

        this.loadItems();
    },

    methods: {
        handleRowActionCommand(command) {
            if (command.type === 'destroy') {
                return this.destroy(command.id);
            }

            return this.$router.push(this.$route.fullPath + '/' + command.id)
        },

        destroy(id) {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.generateApiPointWithAlias(this.apiPoint) + '/' + id)
                        .then(response => {
                            this.responseSuccess(response);
                            this.loadItems();

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        multipleDestroy() {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    let ids = [];

                    this.lodash.forEach(this.$refs.DataTable.selectedRows, (row) => {
                        ids.push(row.id);
                    })

                    this.$http
                        .delete(this.generateApiPointWithAlias(this.apiPoint) + '/9999999', {
                            params: {
                                ids: ids,
                                process: 'multiple'
                            }
                        })
                        .then(response => {
                            this.responseSuccess(response);
                            this.loadItems();
                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        loadItems() {
            this.loading = true;

            this.$http
                .get(this.generateApiPointWithAlias(this.apiPoint), {
                    params: this.params
                })
                .then(response => {
                    this.loading = false;
                    this.columns = response.body.columns;
                    this.rows = response.body.rows;
                    this.pagination = response.body.pagination;

                    this.params.page = this.pagination.current_page;
                })
                .catch(response => {
                    this.loading = false;
                    this.columns = [];
                    this.rows = [];
                    this.pagination = {};
                    this.responseError(response);
                });
        },

        onPageChange(params) {
            this.params.page = params.currentPage;
            this.loadItems();
        },

        onPerPageChange(params) {
            this.params.page = 1;
            this.params.perPage = params.currentPerPage;

            this.loadItems();
        },

        onSortChange(params) {
            this.params.page = 1;
            let sorts = {};

            this.lodash.forEach(params, (item) => {
                sorts[item.field] = item.type;
            });

            this.params.sorts = sorts;

            this.$refs.DataTable.changePage(this.params.page);

            this.loadItems();
        },

        onColumnFilter(params) {
            this.$refs.DataTable.changePage(this.params.page);

            let diff = this.difference(this.backupColumnFilter, params.columnFilters);

            if (!this.lodash.size(diff)) {
                return;
            }

            this.backupColumnFilter = this.lodash.clone(params.columnFilters);
            this.params.columnFilters = this.backupColumnFilter;
            this.loadItems();
        },

        difference(o1, o2) {
            return Object.keys(o2).reduce((diff, key) => {
                if (o1[key] === o2[key]) return diff
                return {
                    ...diff,
                    [key]: o2[key]
                }
            }, {})
        },
    },
}
</script>
