<template>
	<footer class="footer"></footer>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                pageLoading: true,
            }
        },
        mounted() {
            this.pageLoading = false;
        },
    }
</script>