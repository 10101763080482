import _ from 'lodash';

import SettingConfig from '../config/settings'
import LayoutConfig from '../config/layout'

import Container from '../app/Container/Main'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Datatable'
import ViewContainer from "../app/Container/ViewContainer";

import Comment from "../app/Pages/Cms/Comment";
import Feature from "../app/Pages/Cms/Feature";
import Brand from "../app/Pages/Catalog/Brand";

import Category from "../app/Pages/Cms/Category";
import Content from "../app/Pages/Catalog/Content";
import Materials from "../app/Pages/Catalog/Materials";
import Teachers from "../app/Pages/Catalog/Teachers";
import ProductLandingPage from "../app/Pages/Catalog/LandingPage";

// Product types,
import IndexProduct from "../app/Pages/Catalog/Products/Index";
import ShowProduct from "../app/Pages/Catalog/Products/Show";

let categories = [];
let contents = [];

_.forEach(SettingConfig.alias.catalog, (alias) => {
    let apiPoint = 'cms/' + alias.key + '/categories';
    let uiPoint = '/catalog/categories/' + alias.key;
    let name = 'api.catalog.categories.' + alias.key;

    categories.push({
        path: alias.key,
        component: PageContainer,
        meta: {
            auth: true,
            layout: LayoutConfig.full,
            title: alias.title + ' Kategorileri',
            breadcrumb: name + '.index',
            alias: alias.key
        },
        children: [
            {
                name: name + '.index',
                path: '',
                component: DatatableContainer,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Kategorileri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            },
            {
                name: name + '.create',
                path: 'create',
                component: Category,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Kategorisi Olustur',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            },
            {
                name: name + '.edit',
                path: ':id',
                component: Category,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Kategori Bilgileri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            }
        ]
    });

    apiPoint = 'cms/' + alias.key + '/contents';
    uiPoint = '/catalog/contents/' + alias.key;
    name = 'api.catalog.contents.' + alias.key;

    let ContentAliasFile = null;

    switch (alias.key) {
        case 'products':
            ContentAliasFile = Content;
            break;
        case 'materials':
            ContentAliasFile = Materials;
            break;
        case 'teachers':
            ContentAliasFile = Teachers;
            break;
        case 'product-landing-pages':
            ContentAliasFile = ProductLandingPage;
            break;
        default:
            ContentAliasFile = Content;
            break;
        }

    contents.push({
        path: alias.key,
        component: PageContainer,
        meta: {
            auth: true,
            layout: LayoutConfig.full,
            title: alias.title + ' Icerikleri',
            breadcrumb: name + '.index',
            alias: alias.key
        },
        children: [
            {
                name: name + '.index',
                path: '',
                component: DatatableContainer,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Icerikleri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            },
            {
                name: name + '.create',
                path: 'create',
                component: ContentAliasFile,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Icerigi Olustur',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            },
            {
                name: name + '.edit',
                path: ':id',
                component: ContentAliasFile,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Icerik Bilgileri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            }
        ]
    })
});

export default {
    path: '/catalog',
    name: 'api.catalog.index',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Katalog',
    },
    children: [
        {
            path: 'comments',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Yorumlar',
                breadcrumb: 'api.catalog.comments.index',
            },
            children: [
                {
                    name: 'api.catalog.comments.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Yorumlar',
                        apiPoint: 'cms/comments',
                        uiPoint: '/catalog/comments',
                    },
                },
                {
                    name: 'api.catalog.comments.edit',
                    path: ':id',
                    component: Comment,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Yorum Bilgileri',
                        apiPoint: 'cms/comments',
                        uiPoint: '/catalog/comments',
                    },
                },
            ]
        },
        {
            path: 'features',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Ozellikler',
                breadcrumb: 'api.catalog.features.index',
            },
            children: [
                {
                    name: 'api.catalog.features.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ozellikler',
                        apiPoint: 'cms/features',
                    },
                },
                {
                    name: 'api.catalog.features.create',
                    path: 'create',
                    component: Feature,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ozellik Olustur',
                        apiPoint: 'cms/features',
                        uiPoint: '/catalog/features',
                    },
                },
                {
                    name: 'api.catalog.features.edit',
                    path: ':id',
                    component: Feature,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ozellik Bilgileri',
                        apiPoint: 'cms/features',
                        uiPoint: '/catalog/features',
                    },
                },
            ]
        },
        {
            path: 'brands',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Markalar',
                breadcrumb: 'api.catalog.brands.index',
            },
            children: [
                {
                    name: 'api.catalog.brands.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Markalar',
                        apiPoint: 'catalog/brands',
                    },
                },
                {
                    name: 'api.catalog.brands.create',
                    path: 'create',
                    component: Brand,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Marka Olustur',
                        apiPoint: 'catalog/brands',
                    },
                },
                {
                    name: 'api.catalog.brands.edit',
                    path: ':id',
                    component: Brand,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Marka Bilgileri',
                        apiPoint: 'catalog/brands',
                    },
                },
            ]
        },
        {
            path: 'categories',
            component: ViewContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Kategoriler',
                breadcrumb: 'api.catalog.categories.index',
            },
            children: categories
        },
        {
            path: 'contents',
            component: ViewContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Icerikler',
                breadcrumb: 'api.catalog.contents.index',
            },
            children: contents
        },
        {
            path: 'products',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Urunler',
                breadcrumb: 'api.catalog.products.index',
            },
            children: [
                {
                    name: 'api.catalog.products.groups.index',
                    path: 'groups',
                    component: IndexProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Gruplar',
                        apiPoint: 'catalog/contents',
                        type : 'group',
                    },
                },
                {
                    name: 'api.catalog.products.groups.edit',
                    path: 'groups/:id',
                    component: ShowProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Grup Bilgileri',
                        apiPoint: 'catalog/contents',
                        type : 'group',
                        breadcrumb: 'api.catalog.products.groups.index',
                    },
                },
                {
                    name: 'api.catalog.products.packages.index',
                    path: 'packages',
                    component: IndexProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Calisma Paketleri',
                        apiPoint: 'catalog/contents',
                        type : 'package'
                    },
                },
                {
                    name: 'api.catalog.products.packages.edit',
                    path: 'packages/:id',
                    component: ShowProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Calisma Paketi Bilgileri',
                        apiPoint: 'catalog/contents',
                        type : 'package',
                        breadcrumb: 'api.catalog.products.packages.index',
                    },
                },
                {
                    name: 'api.catalog.products.digitals.index',
                    path: 'digitals',
                    component: IndexProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Dijital Urunler',
                        apiPoint: 'catalog/contents',
                        type : 'digital'
                    },
                },
                {
                    name: 'api.catalog.products.digitals.edit',
                    path: 'digitals/:id',
                    component: ShowProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Dijital Urun Bilgileri',
                        apiPoint: 'catalog/contents',
                        type : 'digital',
                        breadcrumb: 'api.catalog.products.digitals.index',
                    },
                },
                {
                    name: 'api.catalog.products.physicals.index',
                    path: 'physicals',
                    component: IndexProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Fiziksel Urunler',
                        apiPoint: 'catalog/contents',
                        type : 'physical'
                    },
                },
                {
                    name: 'api.catalog.products.physicals.edit',
                    path: 'physicals/:id',
                    component: ShowProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Fiziksel Urun Bilgileri',
                        apiPoint: 'catalog/contents',
                        type : 'physical',
                        breadcrumb: 'api.catalog.products.physicals.index',
                    },
                },
                {
                    name: 'api.catalog.products.events.index',
                    path: 'events',
                    component: IndexProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Etkinlik / Webinar Urunleri',
                        apiPoint: 'catalog/contents',
                        type : 'event'
                    },
                },
                {
                    name: 'api.catalog.products.events.edit',
                    path: 'events/:id',
                    component: ShowProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Etkinlik / Webinar Urun Bilgileri',
                        apiPoint: 'catalog/contents',
                        type : 'event',
                        breadcrumb: 'api.catalog.products.events.index',
                    },
                },
                {
                    name: 'api.catalog.products.subscriptions.index',
                    path: 'subscriptions',
                    component: IndexProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Paketler',
                        apiPoint: 'catalog/contents',
                        type : 'subscription'
                    },
                },
                {
                    name: 'api.catalog.products.subscriptions.edit',
                    path: 'subscriptions/:id',
                    component: ShowProduct,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Paket Urun Bilgileri',
                        apiPoint: 'catalog/contents',
                        type : 'subscription',
                        breadcrumb: 'api.catalog.products.subscriptions.index',
                    },
                },
            ]
        },
    ]
}
