<template>
	<div class="board-page" v-loading="pageLoading">
		<div class="head">
			Gorev Yonetimi
		</div>
		<div class="items">
			<div class="item" v-for="item in items" :key="item.id" @click="goPipeline(item)">
				<icon icon="chart-box-outline" />
				<div class="title">
					{{ item.title }}
				</div>
			</div>
			<div class="item create" @click="store">
				<icon icon="plus" />
				<div class="title">
					Yeni Board Ekle
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Board',
		data() {
			return {
				pageLoading: false,
				form: {
					//
				},
				items: []
			}
		},
		mounted() {
			this.index()
		},
		methods: {
			goPipeline(item) {

                if (!item.pipeline) {
                    return alert('Hatali board! Lutfen yazilim ekibi ile iletisime gecin!')
                }

				return this.goRoute('boards/' + item.id + '/pipelines/' + item.pipeline.id);
			},
			index() {
				this.pageLoading = true;

				this.$http
					.get(this.$route.meta.apiPoint)
					.then(response => {
						this.items = response.body.rows;
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			store() {
				this.pageLoading = true;

				this.$http
					.post(this.generateApiPointWithAlias(this.$route.meta.apiPoint), this.form)
					.then(response => {
						this.pageLoading = false;
						return this.goPipeline(response.body.data);
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			}
		}
	}
</script>
