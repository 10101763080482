<template>
    <div class="catalog-page-detail-content-tab">
        <el-card>
            <template slot="header">
                <div class="title">
                    <icon :icon="tab.icon" />
                    {{ tab.title }}
                </div>
            </template>
            <el-form-item label="Kisa Aciklama" class="is-editor">
                <editor :container="form" model="short_description" :small="true" />
            </el-form-item>

            <el-form-item label="Aciklama" class="is-editor">
                <editor :container="form" model="description" />
            </el-form-item>

            <el-form-item label="Reklam" class="is-editor margin-bottom-0">
                <editor :container="form.meta" model="banner" :small="true" />
            </el-form-item>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'ProductText',
    props: ['tab', 'form'],
    components: {
        //
    },
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>
