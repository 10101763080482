<template>
    <div :key="form.payment_bank">
        <div class="ui message yellow" v-html="plugin.alert" v-if="plugin.alert"></div>
        <div class="payment-eft-container">
            <div class="eft-item" v-for="(row, rowKey) in plugin.rows" :key="rowKey"
                 :class="{active : form.payment_bank === row.title}">
                <div class="action" @click="handleClickBank(row)">
                    <div class="empty address-icon">
                        <icon icon="circle-outline" />
                    </div>
                    <div class="full address-icon">
                        <icon icon="check" />
                    </div>
                </div>
                <div class="content">
                    <div class="text">
                        <div class="text-title">
                            {{ row.title }}
                        </div>
                        <div class="eft-row">
                            <div class="title">IBAN</div>
                            <div class="description">{{ row.iban }}</div>
                        </div>
                        <div class="eft-row">
                            <div class="title">Hesap No</div>
                            <div class="description">{{ row.account }}</div>
                        </div>
                        <div class="eft-row">
                            <div class="title">Şube</div>
                            <div class="description">{{ row.branch }}</div>
                        </div>
                    </div>
                    <div class="media">
                        <img :src="appConfig.s3Path + '/' + row.image" alt="bank">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Eft',
    props: ['plugin', 'handle'],
    data() {
        return {
            form: {
                payment_bank : null
            }
        }
    },
    methods: {
        handleClickBank(row) {
            this.form.payment_bank = row.title;
            this.handle({
                payment_bank : row.title
            })
        }
    }
}
</script>