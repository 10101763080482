<template>
	<div>
		<el-tooltip content="SMS Gonder" placement="top">
			<div style="cursor: pointer; display: inline-block" @click="dialogVisible = true">{{ item }}</div>
		</el-tooltip>

		<el-dialog title="Sms Gonder" :visible.sync="dialogVisible" append-to-body v-loading="dialogLoading">
			<el-form :label-position="'top'" ref="form" :model="form">

				<div class="ui header">
					{{ item }}
				</div>

				<el-form-item class="mt-20 message-field mb-0">
					<el-input type="textarea" v-model="form.message" placeholder="Mesajiniz..." rows="4" />
				</el-form-item>
			</el-form>

			<template slot="footer">
				<el-button @click="store" class="modern green large fluid">
					Gonder
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'Phone',
        props: ['item'],
		data() {
            return {
                dialogVisible : false,
				dialogLoading : false,
				form : {
                    //
				}
			}
		},
		methods : {
            store() {
                this.dialogLoading = true;

                this.form.process = 'sms';
                this.form.receiver = this.item;

                this.$http
                    .post('marketing/bulk-messages', this.form)
                    .then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
			}
		}
    };
</script>
