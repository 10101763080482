<template>
    <div class="catalog-page-detail-content-tab">
        <el-card shadow="never" class="custom-padding">
            <template slot="header">
                <div class="title">
                    <icon :icon="tab.icon"/>
                    {{ tab.title }}
                </div>
            </template>
            <div class="el-card__tabs custom-padding">
                <el-tabs class="el-card--tabs fluid" :tab-position="'top'">
                    <el-tab-pane label="Genel">
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Urun Kodu">
                                <el-input v-model="form.product.reference" placeholder="Urun Kodu"/>
                            </el-form-item>

                            <el-form-item label="Marka">
                                <el-select v-model="form.product.brand_id" placeholder="Marka" clearable filterable>
                                    <el-option v-for="item in brands" :key="item.id" :label="item.title"
                                               :value="item.id"/>
                                </el-select>
                            </el-form-item>

                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="Satis Baslangic Tarihi">
                                        <el-date-picker v-model="form.product.start_at" type="datetime"
                                                        placeholder="Baslangic Tarihi"
                                                        value-format="yyyy-MM-dd HH:mm:ss"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Satis Bitis Tarihi">
                                        <el-date-picker v-model="form.product.end_at" type="datetime"
                                                        placeholder="Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-form-item label="Urun Sahibi (Urun sahibi yoksa kendi adinizi seciniz)"
                                          v-if="form.product.type !== 'event'">
                                <user :container="form.product" model="user_id" placeholder="Urun Sahibi"/>
                            </el-form-item>

                            <template v-if="form.product.type === 'package'">
                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Egitim Baslangic Tarihi">
                                            <el-date-picker v-model="form.product.meta.education_start_at"
                                                            type="datetime" placeholder="Egitim Baslangic Tarihi"
                                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Egitim Bitis Tarihi">
                                            <el-date-picker v-model="form.product.meta.education_end_at" type="datetime"
                                                            placeholder="Egitim Bitis Tarihi"
                                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </template>

                            <template v-if="form.product.type === 'package' || form.product.type === 'digital'">
                                <el-form-item
                                    label="Kullanim Suresi [Satin Almadan Itibaren Musterinin Bu Urune Erise Bilecegi Sure]">
                                    <el-input v-model="form.product.meta.lesson_usage_time"
                                              placeholder="Kullanim Suresi" type="number" prefix-icon="el-icon-time">
                                        <template slot="append">ay</template>
                                    </el-input>
                                </el-form-item>
                            </template>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Fiyat">
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Vergi Orani">
                                <definitions :container="form.product" model="tax_class_definition_id"
                                             alias="tax-classes"/>
                            </el-form-item>

                            <el-form-item label="Urun Fiyati" v-if="form.product.type !== 'package'">
                                <el-input v-model="form.product.price" placeholder="Urun Fiyati"/>
                            </el-form-item>

                            <el-form-item label="Ders Birim Fiyati" style="display: none">
                                <el-input v-model="form.lesson_price" placeholder="Ders Birim Fiyati"/>
                            </el-form-item>
                        </div>

                        <div class="card-sub-title">
                            <div>Indirim</div>
                            <div>
                                <el-button icon="el-icon-plus" class="flat dark blue" @click="createDiscount"/>
                            </div>
                        </div>

                        <el-tabs v-model="discountTab" class="el-card--tabs fluid" :tab-position="'top'" closable
                                 @tab-remove="destroyDiscount">
                            <el-tab-pane :label="'Indirim ' + (key + 1)"
                                         v-for="(discount, key) in form.product.discounts" :key="'key-' + key"
                                         :name="'' + key">
                                <div class="card-custom-padding-box-items">
                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <el-form-item label="Indirim Baslangic Tarihi">
                                                <el-date-picker v-model="discount.start_at" type="date"
                                                                placeholder="Indirim Baslangic Tarihi"
                                                                value-format="yyyy-MM-dd"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Indirim Bitis Tarihi">
                                                <el-date-picker v-model="discount.end_at" type="date"
                                                                placeholder="Indirim Bitis Tarihi"
                                                                value-format="yyyy-MM-dd"/>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-form-item label="Adet">
                                        <el-input v-model="discount.quantity" placeholder="Adet" type="number"/>
                                    </el-form-item>

                                    <el-row :gutter="24" v-if="form.product.type !== 'package'">
                                        <el-col :span="12">
                                            <el-form-item label="Fiyat">
                                                <el-input v-model="discount.price" placeholder="Fiyat"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Ders Fiyati">
                                                <el-input v-model="discount.per_price" placeholder="Ders Fiyati"/>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-form-item label="Oran">
                                        <div class="ib-groups">
                                            <el-input v-model="discount.percent" placeholder="Indirim Orani"/>

                                            <el-tooltip content="Oranı Hesapla" placement="top">
                                                <el-button class="flat dark blue" @click="handleFindDiscountPercent(discount)"
                                                           :loading="discountPercentLoading">
                                                    <icon icon="refresh"></icon>
                                                </el-button>
                                            </el-tooltip>
                                        </div>
                                    </el-form-item>

                                    <el-form-item label="Indirim Aciklamasi" v-if="form.type !== 'package'">
                                        <el-input v-model="discount.description" placeholder="Indirim Aciklamasi"
                                                  type="textarea" rows="2"/>
                                    </el-form-item>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-tab-pane>
                    <el-tab-pane label="Muhasebe">
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Urun Adi">
                                <el-input v-model="form.reference_name" placeholder="Urun Adi"/>
                            </el-form-item>
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="Periyod">
                                        <definition-box alias="periods" :container="form" model="period_definition_id"
                                                        placeholder="Periyod"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Sezon">
                                        <definition-box alias="seasons" :container="form" model="season_definition_id"
                                                        placeholder="Sezon"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Kargo / Stok">
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Kargolu Urun">
                                <lv-select :container="form.product" model="is_shipping"
                                           :options="approveOptionsBoolean"/>
                            </el-form-item>

                            <el-form-item label="Stokta Yoksa Durumu">
                                <statuses :container="form.product" model="stock_status_id" alias="stocks"/>
                            </el-form-item>

                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="Adet">
                                        <el-input v-model="form.product.quantity" placeholder="Adet" type="number"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Guncel Durum">
                                        <el-input v-model="form.product.current_quantity" placeholder="Guncel Durum"
                                                  type="number" disabled/>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row :gutter="24" v-if="form.product.type === 'physical'">
                                <el-col :span="12">
                                    <el-form-item label="Agirlik">
                                        <el-input v-model="form.product.weight" placeholder="Agirlik"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Agirlik Birimi">
                                        <definitions alias="weight-classes" model="weight_class_definition_id"
                                                     :container="form.product"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-row :gutter="24">
                                        <el-col :span="8">
                                            <el-form-item label="Uzunluk">
                                                <el-input v-model="form.product.length" placeholder="Uzunluk"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Genislik">
                                                <el-input v-model="form.product.width" placeholder="Genislik"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Yukseklik">
                                                <el-input v-model="form.product.height" placeholder="Yukseklik"/>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Uzunluk Birimi">
                                        <definitions label="Uzunluk Birimi" alias="length-classes"
                                                     model="length_class_definition_id" :container="form.product"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Etkinlik / Webinar" v-if="form.product.type === 'event'">
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Konusmaci">
                                <user :container="form.product" model="user_id" placeholder="Konusmaci" :key="form.id"/>
                            </el-form-item>

                            <el-form-item label="Konusmacilar">
                                <el-select v-model="form.product.meta.speakers" placeholder="Konusmacilar" clearable
                                           filterable multiple>
                                    <el-option v-for="item in contents" :key="item.id" :label="item.title"
                                               :value="item.id"/>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="Konferans Tarihi">
                                <el-date-picker v-model="form.product.meta.event_start_at" type="datetime"
                                                placeholder="Konferans Tarihi" value-format="yyyy-MM-dd HH:mm:ss"/>
                            </el-form-item>

                            <el-form-item label="Ders">
                                <education-content-remote-select alias="lessons" :container="form.product.meta"
                                                                 model="event_lesson_id"/>
                            </el-form-item>

                            <el-form-item label="Canli Yayin Linki">
                                <el-input v-model="form.product.meta.live_source" placeholder="Canli Yayin Linki"/>
                            </el-form-item>

                            <el-form-item label="Dokuman Dosyasi">
                                <fs v-model="form.product.meta.document_file" :isFile="true"/>
                            </el-form-item>

                            <el-row :gutter="24">
                                <el-col :span="6">
                                    <el-form-item label="Dokuman Kapak Resmi">
                                        <fs v-model="form.product.meta.document_image"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'ProductProduct',
    props: ['tab', 'form'],
    data() {
        return {
            users: [],
            brands: [],
            contents: [],

            weekTypes: [
                {
                    value: 'weekend',
                    label: 'Hafta Sonu',
                },
                {
                    value: 'weekdays',
                    label: 'Hafta Ici',
                }
            ],
            dayTypes: [
                {
                    value: 'night',
                    label: 'Gece',
                },
                {
                    value: 'day',
                    label: 'Gunduz',
                }
            ],
            variantTypes: [
                {
                    value: 'live_and_record',
                    label: 'Canli ve Kayittan',
                },
                {
                    value: 'live',
                    label: 'Canli',
                },
                {
                    value: 'record',
                    label: 'Kayittan',
                }
            ],

            discountTab: null,
            discountPercentLoading: false
        }
    },
    mounted() {
        this.initRelationItems();
    },
    methods: {
        initRelationItems() {

            if (!this.lodash.size(this.brands)) {
                this.getBrands();
            }

            if (!this.lodash.size(this.users)) {
                this.getUsers();
            }

            if (!this.lodash.size(this.contents)) {
                this.getContents();
            }
        },

        getBrands() {
            this.pageLoading = true;

            this.$http
                .get('catalog/brands', {params: {perPage: 1000}})
                .then(response => {
                    this.brands = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getUsers() {
            this.pageLoading = true;

            this.$http
                .get('crm/roles/teacher/users', {params: {perPage: 1000}})
                .then(response => {
                    this.users = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getContents() {
            this.pageLoading = true;

            this.$http
                .get('cms/teachers/contents', {params: {perPage: 1000, view: 'dropdown'}})
                .then(response => {
                    this.contents = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        createDiscount() {
            this.form.product.discounts.push({
                start_at: null,
                end_at: null,
                quantity: null,
                per_price: null,
                price: null,
                percent: null,
                description: null,
            })
        },

        destroyDiscount(key) {

            this
                .$confirm('Indirim ' + (parseInt(key) + 1) + ' kalici olarak silinecek!', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    this.form.product.discounts.splice(key, 1);
                    return true;
                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        findStokQuantityTitle() {
            return 'Adet';
        },

        handleFindDiscountPercent(discount) {
            this.discountPercentLoading = true;

            let form = discount

            form.process = 'discount'
            form.content_id = this.form.id
            form.product_id = this.form.product.id

            this.$http
                .put(this.createShowPageLink(), form)
                .then(response => {
                    this.discountPercentLoading = false;

                    discount.percent = response.body.data.percent

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.discountPercentLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
