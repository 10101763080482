<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-sub-title">Metin</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Baslik">
                        <el-input v-model="form.name" placeholder="Baslik" />
                    </el-form-item>

                    <el-form-item label="Aciklama">
                        <el-input v-model="form.description" placeholder="Aciklama" type="textarea" rows="3" />
                    </el-form-item>
                </div>
                <div class="card-sub-title" :key="form.start_at">Tarih [{{ form.start_at }} - {{ form.end_at }}]</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Ders Süresi (Dk)">
                        <el-input type="number" placeholder="Süre DK" v-model="form.duration"/>
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Saat Araligi">
                                <el-time-picker
                                    value-format="HH:mm:ss"
                                    is-range
                                    v-model="form.times"
                                    range-separator="Ve"
                                    start-placeholder="Baslangic Saati"
                                    end-placeholder="Bitis Saati" @change="handleChangeDate">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Tarih">
                                <el-date-picker v-model="form.date" type="date" placeholder="Tarih" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" @change="handleChangeDate"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <div class="card-sub-title">Ozellikler</div>
                        <div class="card-custom-padding-box-items">
                            <el-form-item label="Webinar">
                                <lv-select :container="form.meta" model="is_webinar" :options="approveOptionsInteger" />
                            </el-form-item>
                            <el-form-item label="Deneme Dersi">
                                <lv-select :container="form.meta" model="is_sample" :options="approveOptionsInteger" />
                            </el-form-item>
                            <el-form-item label="Tanitim Dersi">
                                <lv-select :container="form.meta" model="is_promotion" :options="approveOptionsInteger" />
                            </el-form-item>
                            <el-form-item label="Ders Id" v-if="form.meta.is_promotion === 1">
                                <el-input v-model="form.meta.promotion_id" placeholder="Ders Id" />
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="card-sub-title">Ayarlar</div>
                        <div class="card-custom-padding-box-items">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="Ogrenci Video Acabilir">
                                        <lv-select :container="form.meta" model="is_video_stream" :options="approveOptionsInteger" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Ogrenci Mikrofon Acabilir">
                                        <lv-select :container="form.meta" model="is_audio_stream" :options="approveOptionsInteger" />
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-form-item label="Youtube">
                                <el-input v-model="form.meta.stream_link" placeholder="Youtube" />
                            </el-form-item>

                            <el-form-item label="Ders Durumu">
                                <status-box alias="lessons" :container="form" model="feedback_status_id" />
                            </el-form-item>
                        </div>
                    </el-col>
                </el-row>

                <div class="card-sub-title">Baglantilar</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Servis">
                        <lv-select :container="form" model="plugin_id" :options="plugins"/>
                    </el-form-item>

                    <el-form-item label="Ogretmen">
                        <user :container="form" model="teacher_id" placeholder="Ogretmen" />
                    </el-form-item>

                    <el-alert :closable="false" type="warning">
                        Ogrenci secimini sadece <b>abonelik</b> ile urun almis kullanicilar icin kullaniniz.
                    </el-alert>
                    <el-form-item label="Ogrenci">
                        <user :container="form" model="customer_id" placeholder="Ogrenci" />
                    </el-form-item>

                    <el-form-item>
                        <product-transfer :container="form" model="product_requests" :types="['group', 'subscription']" />
                    </el-form-item>
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "@/app/Components/CrudHeader";

export default {
    name: 'LessonCreate',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                start_at: null,
                end_at: null,
                product_requests: [],
                meta: {
                    is_sample : 0,
                    is_webinar : 0,
                    is_promotion : 0,
                }
            },

            plugins: [],
        }
    },
    mounted() {
        this.getPlugins();
    },
    methods: {
        getPlugins() {
            this.pageLoading = true;

            this.$http
                .get('tools/search/plugins', {params: {alias: 'services', sub_alias: 'meetings'}})
                .then(response => {
                    this.plugins = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleChangeDate() {
            if (this.elementSize(this.form.times) && this.form.date) {
                this.form.start_at = this.form.date + ' ' + this.form.times[0];
                this.form.end_at = this.form.date + ' ' + this.form.times[1];
            } else {
                this.form.start_at = null;
                this.form.end_at = null;
            }
        }
    }
}
</script>
