<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form"/>
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-sub-title">Genel</div>
                <div class="card-custom-padding-box-items">
                    <el-form-item label="Veli" :key="form.parent_id">
                        <user :container="form" model="parent_id" placeholder="Veli"/>
                    </el-form-item>

                    <el-form-item label="Ogrenci Ad Soyad">
                        <el-input v-model="form.name" placeholder="Ogrenci Ad Soyad"/>
                    </el-form-item>

                    <el-form-item label="T.C. No  (E-Posta)">
                        <el-input v-model="form.email" placeholder="T.C. No" v-mask="'###########'"/>
                    </el-form-item>
                    <div class="ui message blue">
                        Ogrencinin platforma erisimi icin gerekli. Bu alana lutfen t.c. kimlik numarasini yazin.
                    </div>
                    <div class="mb-20"></div>
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "@/app/Components/CrudHeader.vue";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        /**
         * Show page
         */
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    },
}
</script>
