import Vue from 'vue'
import Icon from "./icon";
import Alert from "./alert";
import Statuses from "./statuses";
import Definitions from "./definitions";
import Fs from "./fs";
import LabelValueSelect from "./label-value-select";
import TitleIdSelect from "./title-id-select";
import Seo from "./seo";
import Editor from "./editor";
import ContentEditor from "./content-editor";
import Email from "./email";
import Phone from "./phone";
import Pic from "./pic";
import PluginPic from "./plugin-pic";
import User from "./user";
import UserMultiple from "./user-multiple.vue";
import SocialMetas from "./social_metas";
import AppTab from "./app-tab";
import UserWithRole from "./user-with-role";
import Empty from "./empty";
import DefinitionBox from "./definition-box";
import StatusBox from "./status-box";
import EducationContentRemoteSelect from "./education-content-remote-select";
import ProductTransfer from "./product-transfer";
import ProductDropdown from "./product-dropdown";
import PluginDropdown from "./plugin-dropdown";
import SuggestionSelect from "./suggestion-select";
import VideoForm from "./video-form";
import Avatar from "./avatar";

Vue.component('icon', Icon);
Vue.component('alert', Alert);
Vue.component('statuses', Statuses);
Vue.component('definitions', Definitions);
Vue.component('fs', Fs);
Vue.component('lv-select', LabelValueSelect);
Vue.component('ti-select', TitleIdSelect);
Vue.component('seo', Seo);
Vue.component('editor', Editor);
Vue.component('content-editor', ContentEditor);
Vue.component('email', Email);
Vue.component('phone', Phone);
Vue.component('pic', Pic);
Vue.component('plugin-pic', PluginPic);
Vue.component('user', User);
Vue.component('user-multiple', UserMultiple);
Vue.component('social-metas', SocialMetas);
Vue.component('app-tab', AppTab);
Vue.component('user-with-role', UserWithRole);
Vue.component('empty', Empty);
Vue.component('definition-box', DefinitionBox);
Vue.component('status-box', StatusBox);
Vue.component('education-content-remote-select', EducationContentRemoteSelect);
Vue.component('product-transfer', ProductTransfer);
Vue.component('product-dropdown', ProductDropdown);
Vue.component('plugin-dropdown', PluginDropdown);
Vue.component('suggestion-select', SuggestionSelect);
Vue.component('video-form', VideoForm);
Vue.component('avatar', Avatar);
