<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<div class="card-sub-title">Genel</div>
				<div class="card-custom-padding-box-items">
					<el-form-item label="Baslik">
						<el-input v-model="form.title" placeholder="Baslik" />
					</el-form-item>
				</div>

				<div class="card-sub-title">Bağlantılar</div>
				<div class="card-custom-padding-box-items">
					<el-form-item label="Konu Etiketleri">
						<el-cascader v-model="form.topic_cascader_requests" :options="topics" :props="{multiple: true}" clearable filterable />
					</el-form-item>
				</div>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	import CrudHeader from "../../Components/CrudHeader";

	export default {
		name: 'CrudItem',
		components: {
			CrudHeader
		},
		data() {
			return {
				pageLoading: false,
				form: {
					meta: {
						//
					},
					topic_cascader_requests: []
				},

				parents : [],
				topics : []
			}
		},
		mounted() {
			if (this.$route.params.id) {
				this.show();
			}

			//this.getParents();
			this.getTopics();
		},
		methods: {

			getParents() {
				this.pageLoading = true;

				this.$http
					.get(this.generateApiPointWithAlias(this.$route.meta.apiPoint), {
						params: {
							perPage: 1000,
							filters: [
								{
									relation: null,
									inputs: [
										{
											name: 'id',
											field: 'id',
											type: 'notIn',
											value: [this.$route.params.id]
										},
									]
								},
							],
						}
					})
					.then(response => {
						this.pageLoading = false;
						this.parents = response.body.rows
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			getTopics() {
				this.pageLoading = true;

				this.$http
					.get('archives/topics', {params: {perPage: 10000, view : 'cascader'}})
					.then(response => {
						this.pageLoading = false;
						this.topics = response.body.data;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			show() {
				this.pageLoading = true;

				this.$http
					.get(this.createShowPageLink())
					.then(response => {
						this.pageLoading = false;
						this.form = response.body.data;

						if (!this.lodash.size(this.form.meta)) {
							this.form.meta = {
								//
							};
						}
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			}
		}
	}
</script>