<template>
	<div class="stage-wrapper" v-loading="pageLoading">
		<div class="stage" v-for="stage in stages" :key="stage.id">
			<div class="list">
				<div class="list-header" :style="{'border-bottom-color' : stage.color}">
					<div class="title">
						{{ stage.title }}
					</div>
					<div class="action">
						<div class="el-dropdown-link" @click="archive(stage)" v-if="stage.has_archive_mode">
							<icon icon="archive-arrow-up-outline"></icon>
						</div>
						<div class="el-dropdown-link" @click="edit(stage)">
							<icon icon="dots-vertical"></icon>
						</div>
					</div>
				</div>

				<Cards :stageId="stage.id" />
			</div>
		</div>
		<div class="stage">
			<div class="list">
				<div class="list-header list-header-create" @click="store">
					<div class="media">
						<icon icon="plus" />
					</div>
					<div class="title">
						Yeni Alan Ekle
					</div>
				</div>
			</div>
		</div>

		<el-dialog title="Islem Hatti" :visible.sync="dialogVisible" v-loading="dialogLoading">
			<el-form :label-position="'top'" ref="stage" :model="form">
				<el-form-item label="Baslik">
					<el-input v-model="form.title" placeholder="Baslik" />
				</el-form-item>

				<el-form-item label="Renk">
					<el-select v-model="form.color" placeholder="Renk">
						<el-option v-for="color in appConfig.config.colors" :key="color.value" :label="color.label"
								   :value="color.value" class="color-option" :style="{background : color.value}" />
					</el-select>
				</el-form-item>

				<el-form-item label="Siralama">
					<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.has_archive_mode"
							   active-text="Kartlarin toplu olarak arsive eklenmesine izin ver." />
				</el-form-item>
			</el-form>

			<template slot="footer">
				<el-button class="modern red large fluid" icon="el-icon-delete" @click="destroy">
					Sil
				</el-button>
				<el-button class="modern green large fluid" icon="el-icon-edit" @click="update">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import Cards from "./Cards";

	export default {
		name: 'Stage',
		components: {Cards},
		props: ['pipelineId'],
		data() {
			return {
				pageLoading: false,
				dialogLoading: false,
				dialogVisible: false,
				stages: [],
				form: {
					//
				}
			}
		},
		mounted() {
			this.index();
		},
		methods: {
			endPoint(key) {
				if (!key) {
					return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages'
				}

				return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + key
			},

			index() {

                if (this.$route.name === 'api.boards.pipelines.stages.cards.show') {
                    return false;
                }

				this.pageLoading = true;

				this.$http
					.get(this.endPoint(), {
						params: {
							sorts : {
								sort : 'asc'
							}
						}
					})
					.then(response => {
						this.stages = response.body.rows;
						this.pageLoading = false;
					})
					.catch(response => {
						this.stages = [];
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			edit(stage) {
				this.form = stage;
				this.dialogLoading = false;
				this.dialogVisible = true;
			},

			store() {
				this.pageLoading = true;
				this.form = {
					//
				}

				this.$http
					.post(this.endPoint(), this.form)
					.then(response => {
						this.pageLoading = false;
						this.index();
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			update() {
				this.dialogLoading = true;

				this.$http
					.put(this.endPoint(this.form.id), this.form)
					.then(response => {
						this.dialogLoading = false;
						this.dialogVisible = false;
						this.form = {
							//
						};
					})
					.catch(response => {
						this.dialogLoading = false;
						return this.responseError(response);
					})
			},

			destroy() {
				this.dialogLoading = true;

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.delete(this.endPoint(this.form.id))
							.then(response => {
								this.responseSuccess(response);
								this.dialogVisible = false;
								this.dialogLoading = false;

								this.index();
							})
							.catch(response => {
								this.responseError(response);
								this.dialogLoading = false;
							});

						return true;
					})
					.catch((response) => {

						this.dialogLoading = false;

						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},

			archive(stage) {
				this.pageLoading = true;

				this
					.$confirm('Tum kartlarin arsive tasinmasini istediginizden emin misiniz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.put(this.endPoint(stage.id), {process: 'archive'})
							.then(response => {
								this.pageLoading = false;
								this.index();
							})
							.catch(response => {
								this.pageLoading = false;
								return this.responseError(response);
							})

						return true;
					})
					.catch((response) => {

						this.pageLoading = false;

						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			}
		}
	}
</script>
