<template>
	<div class="item icon-link notifications" style="display: none">
		<el-popover placement="bottom-end" width="360" trigger="click">
			<div slot="reference">
				<el-badge :value="12" type="primary">
					<icon icon="bell-outline" />
				</el-badge>
			</div>

			<div class="notifications-pop">
				<div class="header">
					<div>
						<div class="title">Bildirimler</div>
					</div>
				</div>
				<div class="content" v-scroll:throttle="{ throttle: 500 }">
					<div v-for="item in 20" :key="item" class="item">
						<div class="media text-yellow">
							<icon icon="account" />
							<div>
								<div>New Message</div>
								<small>Are your going to meet me tonight?</small>
							</div>
						</div>
						<small>10 sec ago</small>
					</div>
				</div>
				<div class="footer">
					Tum Bildirimler
				</div>
			</div>
		</el-popover>
	</div>
</template>

<script>
    export default {
        name: 'Notifications',
    }
</script>