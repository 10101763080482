<template>
    <div>
        <el-select v-model="container[model]" :placeholder="placeholder" :multiple="multiple" clearable filterable @change="handleChange" remote reserve-keyword :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id" />
        </el-select>
    </div>
</template>

<script>
export default {
    name: 'SuggestionSelect',
    props: {
        alias: {
            type: String,
            required: true,
            default: 'products'
        },
        container: {
            type: Object,
        },
        model: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Seçiminiz'
        },
        multiple: {
            type: Boolean,
            default: true
        },
        change: {
            type: Function,
        }
    },
    data() {
        return {
            options: [],
            loading: false,
        }
    },
    mounted() {
        if (this.container[this.model]) {
            this.getItems(this.container[this.model]);
        }
    },
    methods: {
        remoteMethod(query) {
            this.options = [];

            if (this.lodash.size(query) <= 2) {
                return false;
            }

            this.loading = true;

            setTimeout(() => {
                this.$http
                    .get('tools/search/contents', {params: {q: query, alias : this.alias}})
                    .then(response => {
                        this.options = response.body.data;
                        this.loading = false;
                    })
                    .catch(response => {
                        this.loading = false;
                        this.options = [];
                        return this.responseError(response);
                    })
            }, 1000)
        },

        getItems(ids) {
            this.loading = true;

            this.$http
                .post('tools/search/contents-by-ids', {ids: ids})
                .then(response => {
                    this.options = response.body.data;
                    this.loading = false;
                })
                .catch(response => {
                    this.loading = false;
                    this.options = [];
                    return this.responseError(response);
                })
        },

        handleChange(element) {
            if (this.change) {
                return this.change(element)
            }
        }
    }
}
</script>