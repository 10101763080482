<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Baslik" />
                </el-form-item>

                <el-form-item label="Periyod">
                    <el-date-picker v-model="form.period" type="year" placeholder="Periyod" value-format="yyyy" />
                </el-form-item>

                <el-form-item label="Rapor Islemi Yapilacak Tablo">
                    <ti-select :options="tables" :container="form" model="table" placeholder="Rapor Islemi Yapilacak Tablo" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslangic Tarihi">
                            <el-date-picker v-model="form.start_at" type="date" placeholder="Baslangic Tarihi" value-format="yyyy-MM-dd" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitis Tarihi">
                            <el-date-picker v-model="form.end_at" type="date" placeholder="Bitis Tarihi" value-format="yyyy-MM-dd" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Islem Turu">
                    <ti-select :options="processType" :container="form" model="table_type" placeholder="Islem Turu" />
                </el-form-item>

                <el-form-item label="Stok Turu">
                    <ti-select :options="productTypes" :container="form" model="product_type" placeholder="Stok Turu" />
                </el-form-item>

                <el-form-item label="Urun Kodu Baslangic">
                    <el-input v-model="form.search_code" placeholder="Urun Kodu Baslangic" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "@/app/Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {CrudHeader},
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },
            tables: [
                {
                    id: 'invoices',
                    title: 'Fatura',
                },
                {
                    id: 'orders',
                    title: 'Siparis',
                }
            ],
            productTypes : [
                {
                    id : 'service',
                    title : 'Hizmet'
                },
                {
                    id : 'stock',
                    title : 'Stok'
                }
            ],
            processType : [
                {
                    id : 'buy',
                    title : 'Alim'
                },
                {
                    id : 'sale',
                    title : 'Satim'
                }
            ],
        }
    }
}
</script>