<template>
    <div v-loading="pageLoading">

        <app-tab :items="appTabs" :active="activeAppTab" :update="handleSelectAppTab" v-if="form.id" />

        <div class="margin-top-40">
            <el-card class="custom-padding" v-show="activeAppTab === 'homework'">
                <template slot="header">
                    <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
                </template>
                <el-form :label-position="'top'" ref="form" :model="form">
                    <div class="card-sub-title">Metin</div>
                    <div class="card-custom-padding-box-items">
                        <el-form-item label="Baslik">
                            <el-input v-model="form.title" placeholder="Baslik" />
                        </el-form-item>

                        <el-form-item label="Aciklama" class="is-editor">
                            <editor :container="form" model="description" :small="true" />
                        </el-form-item>
                    </div>

                    <div class="card-sub-title">Tarih</div>
                    <div class="card-custom-padding-box-items">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Baslangic">
                                    <el-date-picker v-model="form.start_at" type="datetime" placeholder="Baslangic" value-format="yyyy-MM-dd HH:mm:ss" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Bitis">
                                    <el-date-picker v-model="form.end_at" type="datetime" placeholder="Bitis" value-format="yyyy-MM-dd HH:mm:ss" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="card-sub-title">Baglantilar</div>
                    <div class="card-custom-padding-box-items">
                        <el-form-item label="Ogretmen">
                            <user :container="form" model="teacher_id" placeholder="Ogretmen" :key="form.id" />
                        </el-form-item>

                        <el-form-item label="Urunler">
                            <product-transfer :container="form" model="product_requests" :types="['group', 'subscription']" />
                        </el-form-item>
                    </div>
                </el-form>
            </el-card>

            <HomeworkContents :id="form.id" v-if="form.id" v-show="activeAppTab === 'contents'" />
            <HomeworkFiles :id="form.id" v-if="form.id" v-show="activeAppTab === 'files'" />
            <HomeworkUser :id="form.id" v-if="form.id" v-show="activeAppTab === 'students'" />
        </div>
    </div>
</template>

<script>
import CrudHeader from "../../../Components/CrudHeader";
import HomeworkContents from "@/app/Pages/Education/Homework/Contents";
import HomeworkFiles from "@/app/Pages/Education/Homework/Files";
import HomeworkUser from "@/app/Pages/Education/Homework/User";

export default {
    name: 'CrudItem',
    components: {
        HomeworkUser,
        HomeworkContents,
        HomeworkFiles,
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                product_requests: [],
            },

            activeAppTab: 'homework',
            appTabs : [
                {
                    key : 'homework',
                    title : 'Odev'
                },
                {
                    key : 'contents',
                    title : 'Icerikler'
                },
                {
                    key : 'files',
                    title : 'Dosyalar'
                },
                {
                    key : 'students',
                    title : 'Ogrenciler'
                }
            ],
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        handleSelectAppTab(event) {
            this.activeAppTab = event
        },
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>
