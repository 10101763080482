export const tr = {
	first_name: 'Ad',
	last_name: 'Soyad',
	email: 'Eposta',
	phone: 'Telefon',
	password: 'Şifre',
	login_btn: 'Giriş',
	search_placeholder: 'arama yap...',
	name: 'Ad',
	city: 'Sehir',
	town: 'Ilce',
	quesion_content_type_singular: 'Tekil',
	quesion_content_type_connected: 'Bağlı',
	quesion_content_view_multiple_choice: 'Çoktan Seçmeli',
	quesion_content_owner_examination: 'Gerçek Sınav',
	quesion_content_owner_owner: 'Orjinal',
	logs : {
		actions : {
			show : 'görüntülendi.',
			update : 'güncellendi.',
			destroy : 'silindi.',
		}
	}
};