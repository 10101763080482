<template>
	<div class="app-tab">
		<div class="head">
			<div class="items">
				<template v-for="(item, index) in items">
					<input type="radio" :id="'radio-' + index" name="tabs" :checked="current === item.key" />
					<label @click="handleChange(item)" class="tab" :for="'radio-' + index">{{ item.title }}</label>
				</template>
				<span class="glider"></span>
			</div>
		</div>
		<div class="content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AppTab',
		props: ['items', 'active', 'update'],
		data() {
			return {
				current: null,
			}
		},
		mounted() {
			this.current = this.active;
		},
		methods : {
			handleChange(item) {
				this.current = item.key;
				this.update(item.key);
			}
		}
	};
</script>
