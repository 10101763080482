<template>
    <div v-loading="pageLoading" style="min-height: 400px" :key="shop.create.form.user_id">
        <el-card v-if="customer && product" class="custom-padding">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">Ozet</div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" placement="left">
                            <el-button class="flat dark blue" icon="el-icon-back"
                                       @click="$store.commit('setShopCreateChangeStep', 'customer')" />
                        </el-tooltip>
                    </div>
                </div>
            </template>
            <el-row :gutter="24">
                <el-col :span="8">
                    <div class="basket-customer">
                        <div class="card-sub-title">Musteri Bilgileri</div>
                        <div class="card-custom-padding-box-items">
                            <div v-if="product.teacher">
                                <div class="title">Egitmen</div>
                                <div class="description">
                                    {{ product.teacher.name }}
                                </div>
                            </div>
                            <div class="margin-top-20">
                                <div class="title">Musteri</div>
                                <div class="description">{{ customer.name }}</div>
                            </div>
                            <div class="margin-top-20">
                                <div class="title">Fatura Adresi</div>
                                <template v-for="address in customer.addresses">
                                    <div class="description"  :key="address.id"
                                         v-if="address.id === shop.create.form.invoice_address_id">
                                        {{ address.title }}
                                    </div>
                                </template>
                            </div>
                            <div class="margin-top-20">
                                <div class="title">Teslimat Adresi</div>
                                <template v-for="address in customer.addresses">
                                    <div class="description"  :key="address.id"
                                         v-if="address.id === shop.create.form.delivery_address_id">
                                        {{ address.title }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="16">
                    <div class="card-sub-title">Urun Bilgileri</div>
                    <div class="">
                        <el-card class="card-flat-list">
                            <div class="card-flat-list-items sm ">
                                <div class="item">
                                    <div class="text">
                                        <div class="title">{{ product.content.title }}</div>
                                        <div class="vertical-flex-items has-dimmer" v-html="product.description">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>

                        <div v-if="product.type === 'private'">
                            <div class="card-sub-title">Ozel Ders Tarihleri</div>
                            <div class="program-summary">
                                <div class="program-summary__wrp">
                                    <template v-for="event in handleBasketEvents()">
                                        <div class="program-summary__item" :key="event.id">
                                            <div>
											<span class="program-summary__icon">
												<icon icon="calendar" />
											</span>
                                                <span class="program-summary__txt">{{ event.date }} / {{ event.day }}</span>
                                            </div>
                                            <div class="pick-lesson-time__btns">
                                                <template v-for="date in event.items">
                                                    <button  :key="date.id" v-if="date.selected">
                                                        {{ date.time.start }} - {{ date.time.end }}
                                                    </button>
                                                </template>
                                            </div>
                                        </div>
                                    </template>

                                </div>
                            </div>
                        </div>

                        <div class="basket-end-button text-right padding-right-20 margin-top-20">
                            <el-button class="modern large extract" style="padding: 15px 20px !important"
                                       @click="store">
                                Sepeti Kaydet
                            </el-button>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'Summary',
    computed: mapState(['shop']),
    data() {
        return {
            pageLoading: false,
            customer: null,
            product: null,
            basket: null
        }
    },
    mounted() {
        this.getCustomer();
        this.getProduct();
    },
    methods: {
        getCustomer() {
            this.pageLoading = true;

            this.$http
                .get('crm/users/' + this.shop.create.form.user_id, {params: {with: ['addresses']}})
                .then(response => {
                    this.customer = response.body.data;
                    this.pageLoading = false;

                    console.log(this.shop.create.form)
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getProduct() {
            this.pageLoading = true;

            this.$http
                .get('catalog/products/' + this.shop.create.form.product_id)
                .then(response => {
                    this.product = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleBasketEvents() {
            let events = [];

            this.lodash.forEach(this.shop.create.form.events, (event) => {

                let status = false;

                this.lodash.forEach(event.items, (date) => {
                    if (date.selected) {
                        status = true;
                    }
                })

                if (status) {
                    events.push(event)
                }
            });

            return events;
        },

        store() {
            this.$confirm('Eger musterinin aktif bir sepeti var ise, gonderdiginiz bilgiler ile degistirilecek?', 'Uyari', {
                confirmButtonText: 'Onayla',
                cancelButtonText: 'Iptal',
                type: 'warning'
            }).then(() => {

                this.pageLoading = true;
                let form = this.shop.create.form;
                form.options = [];

                this.lodash.forEach(this.shop.create.form.events, (group) => {
                    this.lodash.forEach(group.items, (date) => {
                        if (date.selected) {
                            form.options.push(date);
                        }
                    })
                });

                this.$http.post('shop/baskets', form).then(response => {
                    this.pageLoading = false;
                    this.goShowPage(response.body.data.id);
                    return this.responseSuccess(response);
                }).catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })

                return true;
            }).catch(() => {

                this.$notify({
                    type: 'success',
                    message: 'Isleminiz basarili bir sekilde iptal edildi!'
                });

                this.pageLoading = false;

                return false;
            });
        }
    }
}
</script>
