<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list overflow-400">
            <template slot="header">
                <div class="title">Urunler</div>
                <div class="actions">
                    <el-tooltip content="Yenile" placement="left">
                        <el-button class="flat dark purple" icon="el-icon-refresh" @click="index" />
                    </el-tooltip>
                    <el-tooltip content="Urun Ekle" placement="left">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
                    </el-tooltip>
                </div>
            </template>
            <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                <div class="item" v-for="item in items" :key="item.id" :class="{'bg-blue-opack' : item.is_gift, 'bg-red-opack' : item.is_return}" v-if="hasShipping(item) && !item.parent_id" >
                    <div class="text display-flex align-items-center" style="align-items: flex-start !important;">
                        <div class="media-icon">
                            <icon :icon="findProductIcon(item.type)" />
                        </div>
                        <div>
                            <div class="title">{{ item.title }}</div>
                            <div class="vertical-flex-items has-dimmer">
                                <div>{{ findProductMainType(item.type) }}</div>
                                <div v-if="item.teacher">
                                    {{ item.teacher.name }}
                                </div>
                                <div v-if="!item.teacher && item.product.teacher">
                                    {{ item.product.teacher.name }}
                                </div>
                                <div v-if="item.student">
                                    {{ item.student.name }}
                                </div>
                                <div v-if="item.variant_type">{{ findProductType(item.variant_type) }}</div>
                                <div v-if="item.day_type">{{ findProductType(item.day_type) }}</div>
                                <div v-if="item.week_type">{{ findProductType(item.week_type) }}</div>
                            </div>
                            <div class="vertical-flex-items has-dimmer">
                                <div>#{{ item.reference }}</div>
                                <template v-if="item.type !== 'physical'">
                                    <div>{{ item.start_at }} - {{ item.end_at }}</div>
                                </template>
                                <div>{{ $n(item.price, 'currency') }}</div>
                                <div>{{ $n(item.discount_price, 'currency') }}</div>
                                <div>{{ $n(item.total, 'currency') }}</div>
                                <div>{{ item.quantity }} Adet</div>
                            </div>
                            <div class="products">
                                <template v-for="(product, index) in item.childs">
                                    <div class="product margin-top-10 cursor-pointer" :key="index" @click="edit(product.id)">
                                        <div class="product-text">
                                            <div class="title">{{ product.title }}</div>
                                            <div class="vertical-flex-items has-dimmer">
                                                <template v-if="product.type === 'group' || product.type === 'private'">
                                                    <el-tooltip content="Baslangic Tarihi - Bitis Tarihi" placement="bottom">
                                                        <div>{{ product.start_at }} - {{ product.end_at }}</div>
                                                    </el-tooltip>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="actions">
                        <el-tooltip content="Duzenle" placement="left">
                            <el-button icon="el-icon-edit" class="flat dark teal" @click="edit(item.id)"/>
                        </el-tooltip>
                        <el-tooltip content="Sil" placement="left">
                            <el-button icon="el-icon-delete" class="flat dark red" @click="destroy(item.id)" v-if="item.is_gift"/>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </el-card>

        <el-dialog title="Urun Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Urun">
                    <product-dropdown :container="form" model="product_id" />
                </el-form-item>

                <el-row :gutter="24"  v-if="form.type === 'group' || form.type === 'subscription'">
                    <el-col :span="12">
                        <el-form-item label="Ogretmen">
                            <user :container="form" model="user_id" placeholder="Ogretmen" :key="form.user_id"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Ogrenci">
                            <user :container="form" model="customer_id" placeholder="Ogrenci" :key="form.customer_id"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Urun Fiyati">
                            <el-input v-model="form.price" placeholder="Urun Fiyati"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Urun Adeti">
                            <el-input v-model="form.quantity" placeholder="Urun Adeti" type="number"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Ders Birim Fiyati">
                            <el-input v-model="form.lesson_price" placeholder="Ders Birim Fiyati"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Ders Kullanimi">
                            <el-input v-model="form.usage_count" placeholder="Ders Kullanimi" :disabled="true"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Taksit Farki">
                    <el-input v-model="form.installment_price" placeholder="Taksit Farki"/>
                </el-form-item>

                <el-form-item label="Indirim Toplami">
                    <el-input v-model="form.discount_price" placeholder="Indirim Toplami"/>
                </el-form-item>

                <el-form-item label="Toplam">
                    <el-input v-model="form.total" placeholder="Toplam"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslangic Tarihi">
                            <el-date-picker v-model="form.start_at" type="datetime" placeholder="Baslangic Tarihi"
                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitis Tarihi">
                            <el-date-picker v-model="form.end_at" type="datetime" placeholder="Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item>
                    <el-switch v-model="form.is_shipping" active-text="Kargolu Urun"/>
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_gift" active-text="Hediye"/>
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_return" active-text="Iade Edildi"/>
                </el-form-item>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="update">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>

        <el-dialog title="Hediye Urun Bilgileri" :visible.sync="createDialogVisible" v-loading="pageLoading">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Hediye Urun">
                    <product-dropdown :container="form" model="product_id" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslangic Tarihi" class="margin-bottom-0">
                            <el-date-picker v-model="form.start_at" type="datetime" placeholder="Baslangic Tarihi" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitis Tarihi" class="margin-bottom-0">
                            <el-date-picker v-model="form.end_at" type="datetime" placeholder="Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store">
                    Kaydet
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name   : 'Product',
    props  : ['order', 'shipping'],
    data() {
        return {
            pageLoading  : false,
            dialogVisible: false,
            createDialogVisible: false,
            form         : {
                //
            },

            items: [],
        }
    },
    mounted() {
        this.index();
    },
    methods: {

        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = 'shop/orders/' + this.order.id + '/products';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        /**
         * All items
         */
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {sorts: {created_at: 'desc'}}})
                .then(response => {
                    this.items = response.body.rows;
                    this.pageLoading = false;

                    this.$emit('productLoaded', true)
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         *  Create modal
         */
        create() {
            this.form = {
                //
            };
            this.createDialogVisible = true;
        },

        /**
         * Form save
         */
        store() {
            this.pageLoading = true;
            this.form.order_id = this.order.id

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.createDialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;

                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        /**
         * Is shipping?
         *
         * @param item
         * @returns {boolean}
         */
        hasShipping(item) {
            if (!this.shipping) {
                return true;
            }

            if (item.is_shipping && !item.is_return) {
                return true;
            }

            return false;
        },

        findProductIcon(type) {
            switch (type) {
                case 'group':
                    return 'message-video';
                case 'package':
                    return 'archive-outline';
                case 'physical':
                    return 'bookshelf';
                case 'digital':
                    return 'gesture-tap-button';
                case 'event':
                    return 'party-popper';
                case 'subscription':
                    return 'youtube-subscription';
                default :
                    return type;
            }
        },
    }
}
</script>
