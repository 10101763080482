<template>
    <main id="Main" class="main">
        <Sidebar />
        <div :class="$route.meta.layout.header ? 'container' : 'container-full-with'">
            <router-view />
        </div>

        <FileManager v-if="$store.state.account && $route.meta.layout.header" />
    </main>
</template>

<script>
import Sidebar from "./Sidebar";
import FileManager from "@/app/Components/FileManager";

export default {
    name: 'Main',
    components: {Sidebar, FileManager},
    data() {
        return {
            pageLoading: true,
        }
    },

    mounted() {
        this.pageLoading = false;
    },
}
</script>