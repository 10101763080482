<template>
    <el-form-item :label="item.label">
        <lv-select :container="container" :model="item.name" :options="approveOptionsInteger" />
    </el-form-item>
</template>

<script>
export default {
    name: 'Approve',
    props : ['form', 'container', 'item'],
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>