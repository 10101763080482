import LayoutConfig from '../config/layout'
import ViewContainer from '../app/Container/ViewContainer'

import Board from '../app/Pages/Boards/Board'
import Pipeline from '../app/Pages/Boards/Pipeline'
import Card from '../app/Pages/Boards/Card'

export default {
	path: '/boards',
	component: ViewContainer,
	meta: {
		auth: true,
		layout: LayoutConfig.full,
		title: 'Gorev Yonetimi',
	},
	children: [
		{
			path: '',
			component: ViewContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Gorev Yonetimi',
			},
			children: [
				{
					name: 'api.boards.index',
					component: Board,
					path: '',
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Gorev Yonetimi',
						apiPoint: 'boards',
					},
				},
				{
					name: 'api.boards.pipelines.index',
					path: ':id/pipelines/:pipeId',
					component: Pipeline,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Pipeline',
						apiPoint: 'boards',
					},
					children : [
						{
							name: 'api.boards.pipelines.stages.cards.show',
							path: 'stages/:stageId/cards/:cardId',
							component: Card,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Card',
								apiPoint: 'boards',
							},
						},
					]
				},
			]
		},
	]
}