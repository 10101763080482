<template>
    <div v-loading="pageLoading">
        <div class="card-sub-title">Osym Sever</div>
        <div class="padding-20">
            <el-row :gutter="24">
                <el-col :span="4">
                    <el-form-item label="Sablon">
                        <fs v-model="form.meta.social_osym_sever_image" />
                    </el-form-item>
                </el-col>
                <el-col :span="20">
                    <el-form-item label="Style Code">
                        <vue-prism-editor v-model="form.meta.social_osym_sever_style" language="css" :lineNumbers="true" />
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialShowTheme',
    props: ['form'],
    data() {
        return {
            pageLoading: false,
        }
    }
}
</script>
