<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list overflow-400">
			<template slot="header">
				<div class="title">
					Bildirim Ayarlari
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text" style="display: flex; align-items: center">
						<div class="title" style="margin: 0">{{ item.alias_title }}</div>
					</div>
					<div class="actions" style="right: 20px">
						<el-dropdown @command="handleRowActionCommand">
							<el-button type="text" class="flat dark grey" icon="el-icon-arrow-down" />
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{type : 'edit', id : item.id}">
									<i class="el-icon-edit text-green"></i> Duzenle
								</el-dropdown-item>
								<el-dropdown-item :command="{type : 'destroy', id : item.id}">
									<i class="el-icon-delete text-red"></i> Sil
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog title="Bildirim Ayarlari" :visible.sync="dialogVisible" v-loading="pageLoading">
			<el-form :label-position="'top'" ref="form" :model="form">
				<el-form-item>
					<el-switch v-model="form.is_mobile" active-text="Mobil Bildirim"/>
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_sms" active-text="Sms"/>
				</el-form-item>

				<el-form-item>
					<el-switch v-model="form.is_email" active-text="E-Posta"/>
				</el-form-item>
			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="update" v-if="form.id">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'Notifications',
        props: ['id'],
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    //
                },

                items: []
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = 'crm/users/' + this.id + '/notifications';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params : {perPage : 1000}})
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            /**
             * Item dropdown actions
             *
             * @param command
             */
            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroy(command.id);
                }

                this.edit(command.id);
            }
        }
    }
</script>