<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<div class="title">
					{{ $route.meta.title }}
				</div>
				<div class="actions">
					<el-tooltip content="Kaydet" placement="left">
						<el-button class="flat dark green" icon="el-icon-check" @click="store" />
					</el-tooltip>
				</div>
			</template>
			<div class="el-card__tabs custom-padding">
				<el-form :label-position="'top'" ref="form" :model="form">
					<el-tabs class="el-card--tabs fluid" :tab-position="'top'">
						<el-tab-pane label="Site">
							<SiteSetting :form="form" :themes="themes"/>
						</el-tab-pane>

						<el-tab-pane label="Yerellestirme">
							<LocalSetting :form="form" :countries="countries" />
						</el-tab-pane>

                        <el-tab-pane label="Ders">
                            <LessonSetting :form="form"/>
                            <ArchiveSetting :form="form"/>
                        </el-tab-pane>

						<el-tab-pane label="Cms">
							<CmsSetting :form="form" :pages="pages" />
						</el-tab-pane>

						<el-tab-pane label="Satis">
							<OrderSetting :form="form" :plugins="plugins"/>
						</el-tab-pane>

						<el-tab-pane label="Eposta / Sms">
							<MessageSetting :form="form" :plugins="plugins"/>
						</el-tab-pane>

						<el-tab-pane label="Kullanici Islemleri">
							<CustomerSetting :form="form" :roles="roles" />
						</el-tab-pane>

						<el-tab-pane label="Diger">
							<OtherSetting :form="form" />
							<BoardSetting :form="form" :boards="boards" v-if="elementSize(boards)"/>

						</el-tab-pane>
					</el-tabs>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import SiteSetting from "./Settings/Site";
	import LocalSetting from "./Settings/Local";
	import CmsSetting from "./Settings/Cms";
	import OtherSetting from "./Settings/Other";
	import CustomerSetting from "./Settings/Customer";
	import MessageSetting from "./Settings/Message";
	import OrderSetting from "./Settings/Order";
	import BoardSetting from "./Settings/Board";
	import ArchiveSetting from "./Settings/Archive";
    import LessonSetting from "@/app/Pages/System/Settings/Lesson";

	export default {
		name: 'Setting',
		components: {
            LessonSetting,
			ArchiveSetting,
			BoardSetting,
			OrderSetting,
			MessageSetting,
			CustomerSetting,
			OtherSetting,
			CmsSetting,
			LocalSetting,
			SiteSetting
		},
		data() {
			return {
				pageLoading: true,
				form: {
					//
				},

				countries: [],
				themes: [],
				pages: [],
				roles: [],
				boards: [],
				plugins: {
					services: {
						items: []
					},
					payments: {
						items: []
					},
					deliveries: {
						items: []
					},
					modules: {
						items: []
					},
					others: {
						items: []
					}
				},
			}
		},
		mounted() {
			this.index();

			this.getPlugins();
			this.getBoards();
		},

		methods: {

			getPlugins() {
				this.pageLoading = true;

				this.$http
					.get('plugins')
					.then(response => {
						this.plugins = response.body.data;
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			getBoards() {
				this.pageLoading = true;

				this.$http
					.get('boards', {params : {view : 'cascader'}})
					.then(response => {
						this.boards = response.body.data;
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},


			index() {
				this.pageLoading = true;

				this.$http
					.get(this.$route.meta.apiPoint)
					.then(response => {
						this.form = response.body.data;
						this.roles = response.body.roles;
						this.themes = response.body.themes;
						this.pages = response.body.pages;
						this.countries = response.body.countries;

						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;

						return this.responseError(response);
					})
			},

			store() {
				this.pageLoading = true;

				this.$http
					.post(this.$route.meta.apiPoint, {settings: this.form})
					.then(response => {
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			}
		},
	}
</script>
