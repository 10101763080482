<template>
	<div>
		<div v-if="!isFile">
			<div class="el-upload-list el-upload-list--picture-card" v-if="!multiple">
				<div class="el-upload-list__item">
					<img src="@/resources/img/dummy.png" alt="image" class="el-upload-list__item-thumbnail" v-if="!value">
					<img :src="appConfig.s3Path + '/' + value" alt="image" class="el-upload-list__item-thumbnail" v-if="value">
					<span class="el-upload-list__item-actions">
						<span class="el-upload-list__item-preview" @click="show">
							<el-button type="primary" icon="el-icon-edit" />
						</span>
						<span class="el-upload-list__item-delete text-color-danger" @click="destroy">
							<el-button type="danger" icon="el-icon-delete" />
						</span>

                        <span class="el-upload-list__item-open" @click="open">
							<el-button type="warning" icon="mdi mdi-open-in-new" />
						</span>
					</span>
				</div>
			</div>
		</div>

		<div v-if="isFile" class="ib-groups two-btn">
			<el-input v-model="value" :placeholder="placeholder ? placeholder : 'Dosya'" />

            <el-tooltip content="Duzenle" placement="top">
                <el-button class="flat dark blue" @click="show">
                    <icon icon="folder-multiple-outline"></icon>
                </el-button>
            </el-tooltip>

            <el-tooltip content="Sil" placement="top">
                <el-button class="flat dark red" @click="destroy">
                    <icon icon="trash-can-outline"></icon>
                </el-button>
            </el-tooltip>

            <el-tooltip content="Ac" placement="top">
                <el-button class="flat dark grey" @click="open">
                    <icon icon="open-in-new"></icon>
                </el-button>
            </el-tooltip>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Fs',
		props: [
			'value',
			'multiple',
			'isFile',
			'placeholder',
		],
		data() {
			return {
				dialogVisible: false
			}
		},
		methods: {
			show() {
				this.$store.commit('setVisibleDialogFileManager', true);
				this.$store.commit('setInputFileManager', this.add);
			},

			destroy() {
				this.$emit('input', '');
			},

            add(value) {
                this.$emit('input', value);
            },

            open() {

                if (!this.value) {
                    return false;
                }

                return this.openBlankWindow(this.appConfig.s3Path + '/' + this.value)
            }
		}
	};
</script>
