<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <div class="crud-header">
                    <div class="title">Musteri Secimi</div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" placement="left">
                            <el-button class="flat dark blue" icon="el-icon-back"
                                       @click="$store.commit('setShopCreateChangeStep', 'product_list')" />
                        </el-tooltip>
                        <el-tooltip content="Kaydet" placement="left">
                            <el-button class="flat dark green" icon="el-icon-check" @click="store" />
                        </el-tooltip>
                    </div>
                </div>
            </template>

            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Musteri">
                    <user :container="form" model="user_id" placeholder="Musteri" :key="form.id" :callback="handleChange" />
                </el-form-item>

                <div v-if="form.user_id" :key="form.user_id">
                    <el-form-item label="Ogrenci">
                        <ti-select :options="students" :container="form" model="student_id" title="name" placeholder="Ogrenci" />
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Fatura Adresi">
                                <ti-select :options="addresses" :container="form" model="invoice_address_id" placeholder="Fatura Adresi" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Teslimat Adresi">
                                <ti-select :options="addresses" :container="form" model="delivery_address_id"
                                           placeholder="Teslimat Adresi" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'Customer',
    components: {
        //
    },
    computed: mapState(['shop']),
    data() {
        return {
            pageLoading: false,
            addresses: [],
            students: [],
            form: {
                //
            },
        }
    },
    mounted() {
        //
    },
    methods: {
        handleChange(response) {
            this.getStudents();
            this.getAddress();
        },

        store() {
            if (!this.form.user_id) {
                return this.$message.error('Uzgunum Musteri secimi alani zorunludur!');
            }

            if (!this.form.invoice_address_id) {
                return this.$message.error('Uzgunum Fatura Adresi secimi alani zorunludur!');
            }

            if (!this.form.delivery_address_id) {
                return this.$message.error('Uzgunum Teslimat Adresi secimi alani zorunludur!');
            }

            this.$store.commit('setShopCreateSetForm', this.form);
            this.$store.commit('setShopCreateChangeStep', 'basket');
        },

        getAddress() {
            this.pageLoading = true;

            this.$http
                .get('crm/users/' + this.form.user_id + '/addresses', {params: {perPage: 1000}})
                .then(response => {
                    this.addresses = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getStudents() {
            this.pageLoading = true;

            this.$http
                .get('crm/students', {
                    params: {
                        perPage: 1000,
                        filters: [
                            {
                                relation: null,
                                inputs: [
                                    {
                                        name: 'parent_id',
                                        field: 'parent_id',
                                        type: 'singleSelect',
                                        value: this.form.user_id
                                    }
                                ]
                            },
                        ],
                        sorts : {
                            created_at : 'desc'
                        }
                    }
                })
                .then(response => {
                    this.students = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>
