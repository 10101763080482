<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" :disable="true" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">

                <div class="ui message">
                    {{ form.message }}
                </div>

                <table class="table align-middle">
                    <thead>
                    <tr>
                        <th>Urun</th>
                        <th width="80" class="text-right">Adet</th>
                        <th width="110" class="text-right">Kullanim</th>
                        <th width="110" class="text-right">K.Toplam</th>
                        <th width="120" class="text-right">Ekstra</th>
                        <th width="110" class="text-right">Fiyat</th>
                        <th width="110" class="text-right">Indirim</th>
                        <th width="110" class="text-right">T.Farki</th>
                        <th width="120" class="text-right">Toplam</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="product in form.products" :key="product.id">
                        <td class="align-middle fs-16">
                            <!--							<div>-->
                            <!--								<div class="title" style="font-weight: bold">{{ product.title }}</div>-->
                            <!--								<div class="vertical-flex-items has-dimmer" style="font-size: 14px" v-if="product.order_product.type === 'group' || product.order_product.type === 'private'">-->
                            <!--									<div>{{ product.order_product.product.teacher.name }}</div>-->
                            <!--									<div>{{ product.order_product.reference }}</div>-->
                            <!--									<div>{{ findProductMainType(product.order_product.type) }}</div>-->
                            <!--									<div>{{ findProductType(product.order_product.variant_type) }}</div>-->
                            <!--									<div>{{ findProductType(product.order_product.day_type) }}</div>-->
                            <!--									<div>{{ findProductType(product.order_product.week_type) }}</div>-->
                            <!--								</div>-->
                            <!--								<div v-if="product.order_product.type === 'digital' || product.order_product.type === 'physical'">-->
                            <!--									{{ findProductMainType(product.order_product.type) }}-->
                            <!--								</div>-->
                            <!--								<div class="vertical-flex-items has-dimmer">-->
                            <!--									<template v-if="product.order_product.type === 'group' || product.order_product.type === 'private'">-->
                            <!--										<el-tooltip content="Baslangic Tarihi - Bitis Tarihi" placement="bottom">-->
                            <!--											<div>{{ product.order_product.start_at }} - {{ product.order_product.end_at }}</div>-->
                            <!--										</el-tooltip>-->
                            <!--										<el-tooltip content="Urun Adeti" placement="bottom">-->
                            <!--											<div>{{ product.order_product.quantity }} Adet</div>-->
                            <!--										</el-tooltip>-->
                            <!--									</template>-->

                            <!--									<el-tooltip content="Fiyat" placement="bottom" v-if="!product.order_product.product.is_hipping">-->
                            <!--										<div>{{ $n(product.order_product.price, 'currency') }}</div>-->
                            <!--									</el-tooltip>-->
                            <!--									<el-tooltip content="Indirim Toplami" placement="bottom" v-if="!product.order_product.product.is_hipping">-->
                            <!--										<div>{{ $n(product.order_product.discount_price, 'currency') }}</div>-->
                            <!--									</el-tooltip>-->
                            <!--									<el-tooltip content="Toplam" placement="bottom" v-if="!product.order_product.product.is_hipping">-->
                            <!--										<div>{{ $n(product.order_product.total, 'currency') }}</div>-->
                            <!--									</el-tooltip>-->
                            <!--								</div>-->
                            <!--							</div>-->
                            <div class="text display-flex align-items-center">
                                <div>
                                    <div class="title" style="font-weight:bold">{{ product.title }}</div>
                                    <div style="font-size:14px">
                                        <div class="vertical-flex-items has-dimmer"
                                             v-if="product.order_product.type === 'group' || product.order_product.type === 'private'">
                                            <div>{{ product.order_product.product.teacher.name }}</div>
                                            <div>{{ product.order_product.reference }}</div>
                                            <div>{{ findProductMainType(product.order_product.type) }}</div>
                                            <div>{{ findProductType(product.order_product.variant_type) }}</div>
                                            <div>{{ findProductType(product.order_product.day_type) }}</div>
                                            <div>{{ findProductType(product.order_product.week_type) }}</div>
                                        </div>
                                        <div
                                            v-if="product.order_product.type === 'digital' || product.order_product.type === 'physical'">
                                            {{ findProductMainType(product.order_product.type) }}
                                        </div>
                                        <div class="vertical-flex-items has-dimmer">
                                            <template
                                                v-if="product.order_product.type === 'group' || product.order_product.type === 'private'">
                                                <el-tooltip content="Baslangic Tarihi - Bitis Tarihi"
                                                            placement="bottom">
                                                    <div>{{ product.order_product.start_at }} -
                                                        {{ product.order_product.end_at }}
                                                    </div>
                                                </el-tooltip>
                                                <el-tooltip content="Urun Adeti" placement="bottom">
                                                    <div>{{ product.order_product.quantity }} Adet</div>
                                                </el-tooltip>
                                            </template>

                                            <el-tooltip content="Fiyat" placement="bottom"
                                                        v-if="!product.order_product.product.is_hipping">
                                                <div>{{ $n(product.order_product.price, 'currency') }}</div>
                                            </el-tooltip>
                                            <el-tooltip content="Indirim Toplami" placement="bottom"
                                                        v-if="!product.order_product.product.is_hipping">
                                                <div>{{ $n(product.order_product.discount_price, 'currency') }}</div>
                                            </el-tooltip>
                                            <el-tooltip content="Toplam" placement="bottom"
                                                        v-if="!product.order_product.product.is_hipping">
                                                <div>{{ $n(product.order_product.total, 'currency') }}</div>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="align-middle text-right">
                            {{ product.quantity }}x
                        </td>
                        <td class="align-middle text-center">
                            {{ product.usage_count }} / {{ $n(product.lesson_price, 'currency') }}
                        </td>
                        <td class="align-middle text-center">
                            {{ $n(product.lesson_price * product.usage_count, 'currency') }}
                        </td>
                        <td class="align-middle text-right">
                            {{ $n(product.custom_price, 'currency') }}
                        </td>
                        <td class="align-middle text-right">
                            {{ $n(product.price, 'currency') }}
                        </td>
                        <td class="align-middle text-right">
                            {{ $n(product.discount_price, 'currency') }}
                        </td>
                        <td class="align-middle text-right">
                            {{ $n(product.installment_price, 'currency') }}
                        </td>
                        <td class="align-middle text-right">
                            <b>{{ $n(product.total, 'currency') }}</b>
                        </td>
                    </tr>

                    </tbody>
                    <tfoot>
                    <tr style="font-size: 16px">
                        <td colspan="8" style="text-align: right; padding-right: 20px">
                            <b>Adet :</b>
                        </td>
                        <td class="text-right">{{ form.quantity }}x</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="8" style="text-align: right; padding-right: 20px">
                            <b>Urun Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(form.product_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="8" style="text-align: right; padding-right: 20px">
                            <b>Indirim Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(form.discount_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="8" style="text-align: right; padding-right: 20px">
                            <b>Taksit Farki :</b>
                        </td>
                        <td class="text-right">{{ $n(form.installment_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="8" style="text-align: right; padding-right: 20px">
                            <b>Vergi Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(form.tax_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="8" style="text-align: right; padding-right: 20px">
                            <b>Kullanim Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(form.usage_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="8" style="text-align: right; padding-right: 20px">
                            <b>Ekstra Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(form.custom_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 18px">
                        <td colspan="8" style="text-align: right; padding-right: 22px" class="text-green">
                            <b>Toplam :</b>
                        </td>
                        <td class="text-right">{{ $n(form.total, 'currency') }}</td>
                    </tr>
                    </tfoot>
                </table>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'ReturnOrder',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink(), {params: {with: ['products.orderProduct']}})
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

    }
}
</script>