<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list overflow-400">
			<template slot="header">
				<div class="title">Bloklar</div>
				<div class="actions">
					<el-tooltip content="Ekle" placement="left">
						<el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
					</el-tooltip>
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text">
						<div class="title">{{ item.title }}</div>
					</div>
					<div class="actions" style="right: 20px">
						<el-dropdown @command="handleRowActionCommand">
							<el-button type="text" class="flat dark grey" icon="el-icon-arrow-down" />
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{type : 'edit', id : item.id}">
									<i class="el-icon-edit text-green"></i> Duzenle
								</el-dropdown-item>
								<el-dropdown-item :command="{type : 'destroy', id : item.id}">
									<i class="el-icon-delete text-red"></i> Sil
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog title="Adim Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="90%" append-to-body>
			<el-form :label-position="'top'" ref="form" :model="form">

				<el-form-item label="Başlık">
					<el-input v-model="form.title" placeholder="Başlık" />
				</el-form-item>

				<el-form-item label="Sablon" v-if="!form.id">
					<lv-select :options="inputs.layouts" :container="form" model="name" />
				</el-form-item>

				<div v-if="form.id">
					<Sliders :block="form" :items="form.rows" v-if="name === 'Sliders'" />
					<SocialLinks :block="form" :items="form.rows" v-if="name === 'SocialLinks'" />
					<Contents :block="form" :items="form.rows" v-if="name === 'Contents'" />
					<BoxList :block="form" :items="form.rows" v-if="name === 'BoxList'" />
					<HtmlContent :block="form" :items="form.rows" v-if="name === 'HtmlContent'" />
					<Feedbacks :block="form" :items="form.rows" v-if="name === 'Feedbacks'" />
				</div>
			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
					Kaydet
				</el-button>
				<el-button class="fluid flat dark green large" @click="update" v-if="form.id">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import Sliders from "./Components/Sliders";
	import SocialLinks from "./Components/SocialLinks";
	import Contents from "./Components/Contents";
	import BoxList from "./Components/BoxList";
	import HtmlContent from "./Components/HtmlContent";
	import Feedbacks from "./Components/Feedbacks";

	export default {
		name: 'Block',
		props: ['id', 'name', 'inputs'],
		components: {
			Feedbacks,
			HtmlContent,
			BoxList,
			Contents,
			SocialLinks,
			Sliders

		},
		data() {
			return {
				pageLoading: false,
				dialogVisible: false,
				form: {
					meta: {
						//
					}
				},

				items: [],
			}
		},
		mounted() {
			this.index();
		},
		methods: {
			endPoint(suffix) {

				const path = 'plugins/modules/' + this.id + '/blocks';

				if (suffix) {
					return path + '/' + suffix;
				}

				return path;
			},

			/**
			 * All items
			 */
			index() {
				this.pageLoading = true;

				this.$http
					.get(this.endPoint(), {params: {perPage: 1000}})
					.then(response => {

						this.items = response.body.rows;

						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			/**
			 *  Create modal
			 */
			create() {
				this.form = {};
				this.dialogVisible = true;
			},

			/**
			 * Edit modal
			 */
			edit(id) {
				this.form = {};
				this.dialogVisible = true;

				this.show(id);
			},

			/**
			 * Show page
			 */
			show(id) {
				this.pageLoading = true;

				this.$http
					.get(this.endPoint(id))
					.then(response => {
						this.form = response.body.data;

						if (!this.lodash.size(this.form.meta)) {
							this.form.meta = {
								//
							}
						}

						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			/**
			 * Form save
			 */
			store() {
				this.pageLoading = true;

				this.$http
					.post(this.endPoint(), this.form)
					.then(response => {
						this.pageLoading = false;
						this.dialogVisible = false;

						this.index();

						return this.responseSuccess(response);
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			/**
			 * Form update
			 */
			update() {
				this.pageLoading = true;

				this.$http
					.put(this.endPoint(this.form.id), this.form)
					.then(response => {
						this.pageLoading = false;

						this.dialogVisible = false;

						this.index();

						return this.responseSuccess(response);
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			/**
			 * Destroy item
			 */
			destroy(id) {

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {

						this.$http
							.delete(this.endPoint(id))
							.then(response => {

								this.index();

								this.responseSuccess(response);

							})
							.catch(response => {
								this.responseError(response);
							});


						return true;

					})
					.catch((response) => {
						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},

			/**
			 * Item dropdown actions
			 *
			 * @param command
			 */
			handleRowActionCommand(command) {
				if (command.type === 'destroy') {
					return this.destroy(command.id);
				}

				this.edit(command.id);
			}
		},
	}
</script>