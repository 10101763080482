<template>
	<div v-loading="itemLoading">
		<el-form :label-position="'top'" ref="form" :model="form">

			<div class="tab-add-button text-right mb-2">
				<el-button class="modern secondary large extract" icon="el-icon-plus" @click="createItem">Ekle</el-button>
			</div>


			<el-tabs v-model="tabValue" type="border-card" closable @tab-remove="destroyRowItem">
				<el-tab-pane :label="'Tab ' + key" v-for="(item, key) in items" :key="key" :name="'key-' + key">
                    <el-form-item label="Gorunum Turu">
                        <ti-select :options="types" :container="item.variables" model="view_type" placeholder="Gorunum Turu" />
                    </el-form-item>

                    <el-row :gutter="24" v-if="item.variables.view_type !== 'video'">
                        <el-col :span="4">
                            <fs v-model="item.variables.image" />
                        </el-col>
                    </el-row>

                    <el-form-item label="Video" v-if="item.variables.view_type === 'video'">
                        <fs v-model="item.variables.video" :isFile="true" />
                    </el-form-item>

                    <el-form-item label="Icerik" class="is-editor">
                        <editor :container="item.variables" model="description" :small="true"/>
                    </el-form-item>

                    <el-form-item label="Widget" class="is-editor">
                        <editor :container="item.variables" model="widget" :small="true"/>
                    </el-form-item>

                    <el-form-item label="Sira">
                        <el-input v-model="item.sort" placeholder="Sira" />
                    </el-form-item>

				</el-tab-pane>
			</el-tabs>
		</el-form>
	</div>
</template>

<script>
    export default {
        name: 'Sliders',
		props: ['block', 'items'],
        data() {
            return {
                itemLoading: true,
				tabValue: 'key-0',
                form: {
                    //
                },
                types : [
                    {
                        id : 'image',
                        title : 'Resim'
                    },
                    {
                        id : 'video',
                        title : 'Video'
                    }
                ],
            }
        },
        created() {
            this.itemLoading = false;
        },
        methods: {

            createItem() {
                this.items.push({
                    sort: 100,
                    variables: {
                    	//
					}
                })
            },

			destroyRowItem(targetName) {

				let key = targetName;

				if (!key) {
					key = this.tabValue;
				}

				key = key.replace('key-', '');

				this
					.$confirm('Tab ' + key + ' kalici olarak silinecek!', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {

						this.items.splice(key, 1);

						return true;

					})
					.catch((response) => {
						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},
        }
    }
</script>