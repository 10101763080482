<template>
	<img :src="find()" alt="image" :class="classes">
</template>

<script>
    export default {
        name: 'Pic',
        props: ['src', 'type', 'classes'],
		methods : {
            find() {

                if (this.src) {
                    return this.appConfig.s3Path + '/' + this.src;
				}

                if (this.type === 'avatar') {
                    return '/resources/img/avatar.png'
				}

                return '/resources/img/dummy.png'
			}
		}
    };
</script>
