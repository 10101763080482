<template>
	<div class="card-custom-padding-box margin-0">
		<div class="card-sub-title">Servis Ayarları</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Varsayilan Teslimat Yontemi">
                <plugin-dropdown :container="form" model="delivery_plugin_id" :options="plugins.deliveries.items" />
			</el-form-item>

			<el-form-item label="Varsayilan Odeme Yontemi">
                <plugin-dropdown :container="form" model="payment_plugin_id" :options="plugins.payments.items" />
			</el-form-item>

			<el-form-item label="Varsayilan Muhasebe Programi">
                <plugin-dropdown :container="form" model="accounting_plugin_id" sub="accounting" :options="plugins.services.items" />
			</el-form-item>

			<el-form-item label="Varsayilan Özel Ders Servisi">
                <plugin-dropdown :container="form" model="education_meeting_id" sub="meetings" :options="plugins.services.items" />
			</el-form-item>

            <el-form-item label="Siparis sirasinda verilecek max. hediye sayisi">
                <el-input v-model="form.max_gift_count" placeholder="Hediye sayisi" />
            </el-form-item>
		</div>

        <div class="card-sub-title">Varsayılan Demo Ürünü</div>
        <div class="card-custom-padding-box-items">
            <el-form-item label="Ürün">
                <product-dropdown :container="form" model="default_demo_product_id" />
            </el-form-item>
        </div>

        <div class="card-sub-title">Stok Durumu Degilse Satisa Kapat</div>
        <div class="card-custom-padding-box-items">
            <el-form-item label="Durum">
                <statuses :container="form" model="default_stock_status_id" alias="stocks" />
            </el-form-item>
        </div>

		<div class="card-sub-title">Siparis Durumlari</div>
		<div class="card-custom-padding-box-items">
			<el-row :gutter="24">
				<el-col :span="12">
					<el-form-item label="Bekliyor">
						<statuses alias="orders" :container="form" model="order_wait_status_id"  />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Tamamlandi">
						<statuses alias="orders" :container="form" model="order_success_status_id"  />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Iptal Edildi">
						<statuses alias="orders" :container="form" model="order_cancel_status_id"  />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Hatali Odeme">
						<statuses alias="orders" :container="form" model="order_fail_payment_status_id"  />
					</el-form-item>
				</el-col>
                <el-col :span="12">
                    <el-form-item label="Iade Edildi">
                        <statuses alias="orders" :container="form" model="order_return_status_id"  />
                    </el-form-item>
                </el-col>
			</el-row>
		</div>

		<div class="card-sub-title">Iade Durumlari</div>
		<div class="card-custom-padding-box-items">
			<el-row :gutter="24">
				<el-col :span="12">
					<el-form-item label="Bekliyor">
						<statuses alias="order-returns" :container="form" model="return_wait_status_id"  />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Tamamlandi">
						<statuses alias="order-returns" :container="form" model="return_success_status_id"  />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Iptal Edildi">
						<statuses alias="order-returns" :container="form" model="return_cancel_status_id"  />
					</el-form-item>
				</el-col>
			</el-row>
		</div>

		<div class="card-sub-title">Kargo Durumlari</div>
		<div class="card-custom-padding-box-items">
			<el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="Adres Onayi Alindi">
                        <statuses alias="shippings" :container="form" model="shipping_address_status_id"  />
                    </el-form-item>
                </el-col>
				<el-col :span="12">
					<el-form-item label="Fatura Bekleniyor">
						<statuses alias="shippings" :container="form" model="shipping_bill_waited_status_id"  />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Fatura Kesildi">
						<statuses alias="shippings" :container="form" model="shipping_bill_ok_status_id"  />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Kargoya Teslim Edildi">
						<statuses alias="shippings" :container="form" model="shipping_delivery_ok_status_id"  />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Iptal Edildi">
						<statuses alias="shippings" :container="form" model="shipping_cancel_status_id"  />
					</el-form-item>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'OrderSetting',
		props: ['form', 'plugins'],
		data() {
			return {
				//
			}
		},
		mounted() {
			//
		},
		methods: {
			//
		}
	}
</script>