<template>
    <div>
        <el-card class="custom-padding">
            <div class="el-card__tabs custom-padding">
                <el-tabs class="el-card--tabs fluid" :tab-position="'top'">
                    <template v-for="(stat, index) in report.stats">
                        <el-tab-pane :label="pointName(index)" :key="index" v-if="index !== 'user_count'">
                            <div class="card-custom-padding-box-items">
                                <table style="width: 100%; margin-bottom: 40px" border="1">
                                    <thead>
                                    <tr>
                                        <th class="text-center">Standart Sapma</th>
                                        <th class="text-center">AgirlikLi Puanlarin Toplami</th>
                                        <th class="text-center">AgirlikLi Puanlarin Ortalamasi</th>
                                        <th class="text-center">En Iyi Agirlikli Puan</th>
                                        <th class="text-center">En Kotu Agirlikli Puan</th>
                                        <th class="text-center">Katilimci Sayisi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td align="center">{{ stat.standard_deviation }}</td>
                                        <td align="center">{{ stat.sum }}</td>
                                        <td align="center">{{ stat.avarage }}</td>
                                        <td align="center">{{ stat.best }}</td>
                                        <td align="center">{{ stat.bad }}</td>
                                        <td align="center">{{ report.stats.user_count }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <el-table :data="report.items" stripe>
                                <el-table-column prop="id" label="#Id" width="100" align="left">
                                    <template slot-scope="scope">
                                        <a :href="'/crm/users/' + scope.row.id" target="_blank">
                                            #{{ scope.row.id }}
                                        </a>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="Kullanici" sortable align="left" />
                                <el-table-column label="Dogru" sortable align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row[index].correct }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Yanlis" sortable align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row[index].wrong }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Net" sortable align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row[index].net }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Siralama" sortable align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row[index].rate }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Puan" sortable align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row[index].point }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </template>
                </el-tabs>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'TestUser',
    props : ['report'],
    methods : {
        pointName(name) {
            if (name === 'esit_agirlik') {
                return 'Esit Agirlik Istatistikleri'
            }

            if (name === 'sayisal') {
                return 'Sayisal Istatistikleri'
            }

            if (name === 'sozel') {
                return 'Sozel Istatistikleri'
            }

            return name;
        },

        handleChange() {

        }
    }
}
</script>