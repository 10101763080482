<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">

				<el-form-item label="Başlık">
					<el-input v-model="form.title" placeholder="Başlık" />
				</el-form-item>

				<el-row :gutter="24">
					<el-col :span="20">
						<el-form-item label="Açıklama">
							<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="Açıklama" v-model="form.description" />
						</el-form-item>

						<el-form-item label="Sıralama">
							<el-input-number v-model="form.sort" placeholder="Sıralama" />
						</el-form-item>

						<el-form-item>
							<el-switch v-model="form.is_active" active-text="Aktif" />
						</el-form-item>
					</el-col>

					<el-col :span="4">
						<el-form-item label="Resim">
							<fs v-model="form.path" />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
		},
        data() {
            return {
                pageLoading: false,
                form: {
                    //
                },
            }
        },
        methods: {

            /**
             * Show page
             */
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },


        },
        mounted() {
            this.show();
        },
    }
</script>