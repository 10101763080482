<template>
	<div v-loading="itemLoading">
		<el-form :label-position="'top'" ref="form" :model="form">
			<el-form-item label="Mesajiniz" class="is-editor">
				<editor :container="block.meta" model="message" />
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
    export default {
        name: 'HtmlContent',
		props: ['block', 'items'],
        data() {
            return {
                itemLoading: true,
                form: {
                    //
                }
            }
        },
        created() {
            this.itemLoading = false;
        },
        methods: {
			//
        }
    }
</script>