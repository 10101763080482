<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list overflow-400">
            <template slot="header">
                <div class="title">
                    Iadeler
                </div>
                <div class="actions">
                    <el-button icon="el-icon-plus" class="flat dark blue" @click="create" :loading="dialogLoading" />
                </div>
            </template>
            <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                <template v-for="item in items">
                    <div class="item"  :key="item.id">
                        <div class="text display-flex align-items-center justify-content-between">
                            <div class="title">{{ $n(item.total, 'currency') }}</div>
                            <div class="title">{{ item.created_at }}</div>
                        </div>
                        <div class="actions">
                            <el-tooltip content="Duzenle" placement="left">
                                <el-button icon="el-icon-edit" class="flat dark teal" @click="edit(item.id)" />
                            </el-tooltip>
                        </div>
                    </div>
                </template>
            </div>
        </el-card>

        <el-dialog title="Iade Bilgileri" :visible.sync="dialogVisible" v-loading="dialogLoading" width="85%"
                   class="custom-padding">
            <el-form :label-position="'top'" ref="form" :model="form">
                <table class="table align-middle">
                    <thead>
                    <tr>
                        <th width="1"></th>
                        <th>Urun</th>
                        <th width="80">Adet</th>
                        <th width="110" class="text-right">Kullanim</th>
                        <th width="110" class="text-right">K.Toplam</th>
                        <th width="110" class="text-right">Fiyat</th>
                        <th width="110" class="text-right">Indirim</th>
                        <th width="110" class="text-right">T.Farki</th>
                        <th width="120" class="text-center">Ekstra</th>
                        <th width="120" class="text-right">Toplam</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="product in findProducts()">
                        <tr  :key="product.id" v-if="!product.is_return && !product.parent_id">
                            <td class="align-middle">
                                <el-button icon="el-icon-check" class="flat dark"
                                           :class="{grey : !product.is_selected, green : product.is_selected}"
                                           @click="handleSelected(product)" v-if="!form.id" />
                            </td>
                            <td class="align-middle fs-16">
                                <b>{{ product.title }} ({{ findProductMainType(product.type) }})</b>
                            </td>
                            <td class="align-middle fs-16 text-center">
                                {{ product.quantity }}x
                            </td>
                            <td class="align-middle fs-16 text-center" v-if="product.type !== 'package'">
                                {{ product.usage_count }} / {{ $n(product.lesson_price, 'currency') }}
                            </td>
                            <td class="align-middle fs-16 text-center" v-if="product.type !== 'package'">
                                {{ $n(product.lesson_price * product.usage_count, 'currency') }}
                            </td>

                            <td class="align-middle fs-16 text-center" v-if="product.type === 'package'">
                                {{ product.package.usage_count }} / {{ $n(product.package.lesson_price, 'currency') }}
                            </td>
                            <td class="align-middle fs-16 text-center" v-if="product.type === 'package'">
                                {{ $n(product.package.lesson_price * product.package.usage_count, 'currency') }}
                            </td>

                            <td class="align-middle fs-16 text-right">
                                {{ $n(product.price, 'currency') }}
                            </td>
                            <td class="align-middle fs-16 text-right">
                                {{ $n(product.discount_price, 'currency') }}
                            </td>
                            <td class="align-middle fs-16 text-right">
                                {{ $n(product.installment_price, 'currency') }}
                            </td>
                            <td>
                                <el-form-item class="mb-0">
                                    <el-input v-model="product.custom_price" placeholder="Ekstra"
                                              :disabled="form.id ? true : false" />
                                </el-form-item>
                            </td>
                            <td class="align-middle fs-16 text-right">
                                <b>{{ $n(product.total, 'currency') }}</b>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="10">
                            <el-form-item class="mb-0">
                                <el-input v-model="form.message" type="textarea" rows="4"
                                          placeholder="Iade Nedeni..." />
                            </el-form-item>
                        </td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="9" style="text-align: right; padding-right: 20px">
                            <b>Adet :</b>
                        </td>
                        <td class="text-right">{{ money.quantity }}x</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="9" style="text-align: right; padding-right: 20px">
                            <b>Urun Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(money.product_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="9" style="text-align: right; padding-right: 20px">
                            <b>Indirim Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(money.discount_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="9" style="text-align: right; padding-right: 20px">
                            <b>Taksit Farki :</b>
                        </td>
                        <td class="text-right">{{ $n(money.installment_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="9" style="text-align: right; padding-right: 20px">
                            <b>Vergi Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(money.tax_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="9" style="text-align: right; padding-right: 20px">
                            <b>Kullanim Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(money.usage_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="9" style="text-align: right; padding-right: 20px">
                            <b>Ekstra Toplami :</b>
                        </td>
                        <td class="text-right">{{ $n(money.custom_price, 'currency') }}</td>
                    </tr>
                    <tr style="font-size: 18px">
                        <td colspan="9" style="text-align: right; padding-right: 22px" class="text-green">
                            <b>Toplam :</b>
                        </td>
                        <td class="text-right">{{ $n(money.total, 'currency') }}</td>
                    </tr>
                    <tr>
                        <td colspan="8" style="padding-right: 22px"></td>
                        <td colspan="2">
                            <el-button class="flat dark blue fluid large" @click="calculate()" v-if="!form.id">
                                Hesapla
                            </el-button>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'OrderReturn',
    props: ['order'],
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            dialogLoading: false,
            form: {
                //
            },

            items: [],
            products: [],
            money: {
                quantity: 0,
                product_price: 0,
                discount_price: 0,
                installment_price: 0,
                tax_price: 0,
                custom_price: 0,
                usage_price: 0,
                total: 0,
            }
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        endPoint(suffix) {

            const path = 'shop/returns';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {
                    params: {
                        sorts: {
                            created_at: 'desc'
                        },
                        filters: [
                            {
                                relation: null,
                                inputs: [
                                    {
                                        name: 'order_id',
                                        field: 'order_id',
                                        type: 'singleSelect',
                                        value: this.order.id
                                    }
                                ]
                            }
                        ],
                        with: [
                            'products'
                        ],
                    },

                })
                .then(response => {
                    this.items = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         *  Create modal
         */
        create() {
            this.form = {};
            this.dialogLoading = true;
            this.dialogVisible = true;

            this.$http
                .get('shop/orders/' + this.order.id + '/products', {params: {sorts: {created_at: 'desc'}}})
                .then(response => {
                    this.products = response.body.rows;
                    this.dialogLoading = false;
                })
                .catch(response => {
                    this.dialogLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id), {params: {with: ['products']}})
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form save
         */
        store() {
            this.pageLoading = true;

            let requestData = [];

            this.lodash.forEach(this.products, (product) => {

                if (product.is_selected) {
                    requestData.push({
                        id: product.id,
                        quantity: product.quantity,
                        custom_price: product.custom_price,
                    })
                }
            })

            this.$http
                .post(this.endPoint(), {
                    order_id: this.order.id,
                    products: requestData,
                    message: this.form.message
                })
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Item dropdown actions
         *
         * @param command
         */
        handleRowActionCommand(command) {
            this.edit(command.id);
        },

        handleSelected(product) {
            if (product.is_selected) {
                product.is_selected = false;
            } else {
                product.is_selected = true
            }

            this.calculate();
        },

        calculate() {
            this.money = {
                quantity: 0,
                product_price: 0,
                discount_price: 0,
                installment_price: 0,
                tax_price: 0,
                usage_price: 0,
                custom_price: 0,
                total: 0,
            };

            this.lodash.forEach(this.products, (product) => {
                if (product.is_selected && !product.is_return) {
                    this.money.quantity += parseInt(product.quantity);
                    this.money.product_price += parseFloat(product.price) * parseInt(product.quantity);
                    this.money.discount_price += parseFloat(product.discount_price);
                    this.money.installment_price += parseFloat(product.installment_price);
                    this.money.tax_price += 0;
                    this.money.custom_price += parseFloat(product.custom_price);

                    if (product.package) {
                        this.money.usage_price += parseFloat(product.package.lesson_price) * product.package.usage_count;
                    } else {
                        this.money.usage_price += parseFloat(product.lesson_price) * product.usage_count;
                    }

                    this.money.total += parseFloat(product.total) - ((parseFloat(product.lesson_price) * product.usage_count) + parseFloat(product.custom_price));
                }
            });
        },

        findProducts() {
            if (this.form.id) {

                this.money = this.form;

                return this.form.products;
            }

            return this.products
        }
    }
}
</script>
