<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<div class="card-sub-title">Genel</div>
				<div class="card-custom-padding-box-items">
					<el-form-item label="Benzersiz Ad">
						<el-input v-model="form.alias" placeholder="Benzersiz Ad" />
					</el-form-item>

					<el-form-item label="Başlık">
						<el-input v-model="form.title" placeholder="Başlık" />
					</el-form-item>

					<el-form-item label="Giriş yaptıktan sonra yönlendirileceği adres">
						<el-input v-model="form.redirect" placeholder="Giriş yaptıktan sonra yönlendirileceği adres" />
					</el-form-item>

					<el-form-item label="Siralama">
						<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
					</el-form-item>

					<el-form-item>
						<el-switch v-model="form.is_active" active-text="Aktif" />
					</el-form-item>
				</div>

				<template v-if="$route.params.id">
					<div class="card-sub-title">Yetkilendirme</div>
					<div class="card-custom-padding-box-items">
						<el-row :gutter="24">
							<el-col :span="8" v-for="item in form.accesses" :key="item.key">
								<el-form-item>
									<el-switch v-model="item.status" :active-text="item.title"/>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</template>
			</el-form>
		</el-card>

		<div v-if="$route.params.id">
			<RoleRoutePermission :id="$route.params.id" />
		</div>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";
    import RoleRoutePermission from "./RoleRoutePermission";

    export default {
        name: 'CrudItem',
        components: {
            RoleRoutePermission,
            CrudHeader
		},
        data() {
            return {
                pageLoading: false,
                form: {
					accesses : []
                },
            }
        },
        methods: {

            /**
             * Show page
             */
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;
                        this.form.accesses = this.form.data_accesses;
                        this.form.data_accesses = null;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },
        },
        mounted() {
            if (this.$route.params.id) {
                this.show();
            }
        },
    }
</script>
