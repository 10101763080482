<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list overflow-400">
            <template slot="header">
                <div class="title">
                    Ders Izleme Gecmisi
                </div>
            </template>
            <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                <div class="item" v-for="item in items" :key="item.id">
                    <div class="text">
                        <div class="title" style="margin: 0">{{ item.lesson.name }}</div>
                        <div class="vertical-flex-items has-dimmer">
                            <el-tooltip content="Ilk Izleme Tarihi" placement="bottom">
                                <div>{{ item.created_at }}</div>
                            </el-tooltip>
                            <el-tooltip content="Son Izleme Tarihi" placement="bottom">
                                <div>{{ item.updated_at }}</div>
                            </el-tooltip>
                        </div>
                        <div class="actions" style="right: 20px">
                            <el-button class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)" />
                        </div>
                    </div>
                </div>
            </div>
        </el-card>

        <div class="text-center margin-top-20" v-if="response && response.pagination.total > response.pagination.per_page">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="response.pagination.total"
                :page-size="response.pagination.per_page"
                :current-page.sync="response.pagination.current_page"
                @current-change="handleChangePage"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LessonMonitoring',
    props: ['id'],
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            form: {
                //
            },

            items: [],

            page: 1,
            response : null
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = 'crm/users/' + this.id + '/lesson-monitorings';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        /**
         * All items
         */
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {page: this.page}})
                .then(response => {
                    this.items = response.body.rows;
                    this.response = response.body;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;

                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        handleChangePage(page) {
            this.page = page;
            this.index();
        },

    }
}
</script>