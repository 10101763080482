<template>
	<div class="sidebar-box" v-loading="pageLoading">
		<div class="head">
			<div class="title">Yetkililer</div>
			<div class="actions">
				<el-tooltip content="Ekle" placement="bottom">
					<el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
				</el-tooltip>
			</div>
		</div>
		<div class="section items">
			<div class="item" v-for="item in items" :key="item.id">
				<div class="title" style="display: flex; align-items: center">
					<div class="media" style="margin-right: 10px">
						<pic :src="item.assignee.avatar" type="avatar" style="height: 35px; width: 35px; border-radius: 8px"/>
					</div>
					<div class="text" style="font-size: 16px; font-weight: 500">
						{{ item.assignee.name }}
					</div>
				</div>
				<div class="description">
					<el-tooltip content="Sil" placement="bottom">
						<el-button class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)" />
					</el-tooltip>
				</div>
			</div>
		</div>

		<el-dialog title="Yetkili Bilgileri" :visible.sync="dialogVisible" v-loading="dialogLoading" append-to-body>
			<el-form :label-position="'top'" ref="stage" :model="form">
				<el-form-item label="Yetkili">
					<user :container="form" model="user_id" placeholder="Yetkili"/>
				</el-form-item>
			</el-form>
			<template slot="footer">
				<el-button class="modern green large fluid" icon="el-icon-check" @click="store">
					Kaydet
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'CardAssignee',
		props : ['cardId'],
		data() {
			return {
				pageLoading : false,
				dialogLoading : false,
				dialogVisible : false,
				form : {
					//
				},

				items : []
			}
		},
		mounted() {
			this.index();
		},
		methods: {
			endPoint(key) {
				if (!key) {
					return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.$route.params.stageId + '/cards/' + this.cardId + '/assignees'
				}

				return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.$route.params.stageId + '/cards/' + this.cardId + '/assignees/' + key
			},

			index() {
				this.pageLoading = true;

				this.$http
					.get(this.endPoint())
					.then(response => {
						this.items = response.body.rows;
						this.pageLoading = false;
					})
					.catch(response => {
						this.items = [];
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			create() {
				this.dialogVisible = true;
				this.form = {}
			},

			store() {
				this.dialogLoading = true;

				this.$http
					.post(this.endPoint(), this.form)
					.then(response => {
						this.dialogLoading = false;
						this.dialogVisible = false;
						this.index();
					})
					.catch(response => {
						this.dialogLoading = false;
						return this.responseError(response);
					})
			},

			destroy(id) {
				this.pageLoading = true;

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.delete(this.endPoint(id))
							.then(response => {
								this.pageLoading = false;
								this.responseSuccess(response);

								this.index();
							})
							.catch(response => {
								this.responseError(response);
								this.pageLoading = false;
							});

						return true;
					})
					.catch((response) => {

						this.pageLoading = false;

						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},
		}
	}
</script>