<template>
    <div class="loading-container" v-loading="pageLoading">
        <div v-if="form.id">
            <el-card class="catalog-page-detail-head">
                <el-row :gutter="24">
                    <el-col :span="19">
                        <el-form :label-position="'top'" ref="form" :model="form">
                            <el-form-item class="margin-bottom-0">
                                <el-input v-model="form.name" placeholder="Başlık" prefix-icon="mdi mdi-subtitles-outline"/>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="5">
                        <el-button class="fluid modern green large" icon="icon mdi mdi-check-bold" @click="update">
                            Güncelle
                        </el-button>
                    </el-col>
                </el-row>
            </el-card>

            <el-row :gutter="24">
                <el-col :span="19">
                    <div class="catalog-page-detail-content">
                        <div v-show="tab.key === 'text'">
                            <el-form :label-position="'top'" ref="form" :model="form">
                                <div class="catalog-page-detail-content-tab">
                                    <el-card class="custom-padding">
                                        <template slot="header">
                                            <div class="title">
                                                <icon :icon="tab.icon" />
                                                {{ tab.title }}
                                            </div>
                                        </template>
                                    </el-card>
                                    <el-card class="custom-padding">
                                        <div class="card-custom-padding-box-items">
                                            <el-form-item label="Aciklama">
                                                <el-input v-model="form.description" placeholder="Aciklama" type="textarea" rows="3" />
                                            </el-form-item>
                                        </div>
                                        <div class="card-sub-title" :key="form.start_at">Tarih [{{ form.start_at }} - {{ form.end_at }}]</div>
                                        <div class="card-custom-padding-box-items">
                                            <el-form-item label="Ders Süresi (Dk)">
                                                <el-input type="number" placeholder="Süre DK" v-model="form.duration" />
                                            </el-form-item>

                                            <el-row :gutter="24">
                                                <el-col :span="12">
                                                    <el-form-item label="Saat Araligi">
                                                        <el-time-picker
                                                            value-format="HH:mm:ss"
                                                            is-range
                                                            v-model="form.times"
                                                            range-separator="Ve"
                                                            start-placeholder="Baslangic Saati"
                                                            end-placeholder="Bitis Saati" @change="handleChangeDate">
                                                        </el-time-picker>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="12">
                                                    <el-form-item label="Tarih">
                                                        <el-date-picker v-model="form.date" type="date" placeholder="Tarih"
                                                                        value-format="yyyy-MM-dd" :picker-options="datePickerOptions"
                                                                        @change="handleChangeDate" />
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </div>
                                        <el-row :gutter="24">
                                            <el-col :span="12">
                                                <div class="card-sub-title">Ozellikler</div>
                                                <div class="card-custom-padding-box-items">
                                                    <el-form-item label="Webinar">
                                                        <lv-select :container="form.meta" model="is_webinar" :options="approveOptionsInteger" />
                                                    </el-form-item>
                                                    <el-form-item label="Deneme Dersi">
                                                        <lv-select :container="form.meta" model="is_sample" :options="approveOptionsInteger" />
                                                    </el-form-item>
                                                    <el-form-item label="Tanitim Dersi">
                                                        <lv-select :container="form.meta" model="is_promotion" :options="approveOptionsInteger" />
                                                    </el-form-item>
                                                    <el-form-item label="Ders Id" v-if="form.meta.is_promotion === 1">
                                                        <el-input v-model="form.meta.promotion_id" placeholder="Ders Id" />
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                            <el-col :span="12">
                                                <div class="card-sub-title">Ayarlar</div>
                                                <div class="card-custom-padding-box-items">
                                                    <el-row :gutter="24">
                                                        <el-col :span="12">
                                                            <el-form-item label="Ogrenci Video Acabilir">
                                                                <lv-select :container="form.meta" model="is_video_stream" :options="approveOptionsInteger" />
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="12">
                                                            <el-form-item label="Ogrenci Mikrofon Acabilir">
                                                                <lv-select :container="form.meta" model="is_audio_stream" :options="approveOptionsInteger" />
                                                            </el-form-item>
                                                        </el-col>
                                                    </el-row>

                                                    <el-form-item label="Youtube">
                                                        <el-input v-model="form.meta.stream_link" placeholder="Youtube" />
                                                    </el-form-item>

                                                    <el-form-item label="Ders Durumu">
                                                        <status-box alias="lessons" :container="form" model="feedback_status_id" />
                                                    </el-form-item>
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <div class="card-sub-title">Baglantilar</div>
                                        <div class="card-custom-padding-box-items">
                                            <el-form-item label="Servis">
                                                <lv-select :container="form" model="plugin_id" :options="plugins" :disabled="true" />
                                            </el-form-item>

                                            <el-form-item label="Ogretmen">
                                                <user :container="form" model="teacher_id" placeholder="Ogretmen" />
                                            </el-form-item>

                                            <el-alert :closable="false" type="warning">
                                                Ogrenci secimini sadece <b>abonelik</b> ile urun almis kullanicilar icin kullaniniz.
                                            </el-alert>
                                            <el-form-item label="Ogrenci">
                                                <user :container="form" model="customer_id" placeholder="Ogrenci" />
                                            </el-form-item>

                                            <el-form-item>
                                                <product-transfer :container="form" model="product_requests" :types="['group', 'subscription']" />
                                            </el-form-item>
                                        </div>
                                    </el-card>
                                </div>
                            </el-form>
                        </div>
                        <LessonUsers :lessonId="form.id" :lessonServiceId="form.service_id" v-show="tab.key === 'users'"/>
                        <LessonRecord :form="form" v-show="tab.key === 'records'"/>
                    </div>
                </el-col>
                <el-col :span="5">
                    <aside class="catalog-page-detail-sidebar">
                        <div class="tabs">
                            <template v-for="(item, index) in tabs">
                                <div class="tab" :key="index" :class="{active : tab.key === item.key}" @click="tab = item">
                                    <div class="title">{{ item.title }}</div>
                                    <div class="media">
                                        <icon :icon="item.icon"/>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </aside>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import CrudHeader from "@/app/Components/CrudHeader";
import LessonRecord from "@/app/Pages/Education/Lessons/Lessons/Record";
import LessonUsers from "@/app/Pages/Education/Lessons/Lessons/Users";

export default {
    name: 'LessonShow',
    components: {
        CrudHeader,
        LessonUsers,
        LessonRecord
    },
    data() {
        return {
            pageLoading: false,
            form: {
                start_at: null,
                end_at: null,
                product_requests: [],
                meta: {
                    is_sample: 0,
                    is_webinar: 0,
                    is_promotion: 0,
                },
                rating : {
                    //
                }
            },
            plugins: [],
            tab: {
                //
            },
            tabs: [
                {
                    key: 'text',
                    icon: 'text-box-outline',
                    title: 'Ders Bilgileri',
                },
                {
                    key: 'users',
                    icon: 'cast-connected',
                    title: 'Kullanicilar',
                },
                {
                    key: 'records',
                    icon: 'video',
                    title: 'Video / Servis Islemleri',
                }
            ]
        }
    },
    mounted() {
        this.tab = this.tabs[0];

        this.getPlugins();
        this.show();
    },
    methods: {
        handleChangeTab(event) {
            this.activeTab = event
        },

        handleChangeDate() {
            if (this.elementSize(this.form.times) && this.form.date) {
                this.form.start_at = this.form.date + ' ' + this.form.times[0];
                this.form.end_at = this.form.date + ' ' + this.form.times[1];
            } else {
                this.form.start_at = null;
                this.form.end_at = null;
            }
        },

        getPlugins() {
            this.pageLoading = true;

            this.$http
                .get('tools/search/plugins', {params: {alias: 'services', sub_alias: 'meetings'}})
                .then(response => {
                    this.plugins = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        update() {
            this.pageLoading = true;

            this.$http
                .put(this.createShowPageLink(), this.form)
                .then(response => {
                    this.initForm(response.body.data)
                    this.pageLoading = false;

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>


