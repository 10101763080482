<template>
	<div>
		<el-card class="custom-padding">
			<template slot="header">
				<div class="crud-header">
					<div class="title">Urun Tipi Secimi</div>
				</div>
			</template>
		</el-card>
		<div class="board-page">
			<div class="items">
				<div class="item" v-for="item in items" :key="item.value" @click="handleSelected(item.value)">
					<icon :icon="item.icon" />
					<div class="title">{{ item.label }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'ProductType',
        components: {
            //
		},
        data() {
            return {
                items : [
					{
						icon: 'package-variant',
						value: 'physical',
						label: 'Fiziksel',
					},
					{
						icon: 'package',
						value: 'digital',
						label: 'Dijital',
					},
					{
						icon: 'account-group-outline',
						value: 'group',
						label: 'Grup',
					},
					{
						icon: 'school-outline',
						value: 'subscription',
						label: 'Abonelik',
					}
				]
            }
        },
        mounted() {
            //
        },
        methods: {
			handleSelected(value) {
				this.$store.commit('setShopCreateProductType', value);
				this.$store.commit('setShopCreateChangeStep', 'product_list')
			}
        }
    }
</script>
