<template>
    <div v-loading="pageLoading" v-if="form.id">

        <el-menu
            :default-active="activeTab"
            mode="horizontal"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#e85f79"
            @select="handleSelect"
            v-if="$route.params.id"
            class="container-tab-menu"
        >
            <el-menu-item index="1">Siparis Yonetimi</el-menu-item>
            <el-menu-item index="2">Adres Yonetimi</el-menu-item>
            <el-menu-item index="3">Iade Yonetimi</el-menu-item>
        </el-menu>

        <div v-show="activeTab === '1'">
            <el-card class="custom-padding">
                <template slot="header">
                    <div class="crud-header">
                        <div class="title">
                            #{{ form.code }} | {{ form.payment_at }}
                        </div>
                        <div class="actions d-flex align-items-center">
                            <Status :order="form" :handle="show" />
                        </div>
                    </div>
                </template>
                <div class="box-list">
                    <div class="box-list-item box-list-item-media">
                        <div class="box-list-item-media-item">
                            <div class="media">
                                <pic type="avatar" width="150" height="150" alt="avatar" :src="form.user.avatar" />
                            </div>
                            <div class="text ml-2">
                                <div class="name fs-18">
                                    {{ form.first_name }} {{ form.last_name }}
                                </div>
                                <div>
                                    <email :item="form.email" />
                                </div>
                                <div>
                                    <phone :item="form.phone" />
                                </div>
                            </div>
                            <div class="buttons d-flex">
                                <el-tooltip content="Test Siparisi Olarak Isaretle" placement="bottom">
                                    <el-button icon="mdi mdi-credit-card-check-outline" class="modern secondary"
                                               @click="handleSendTestOrder" />
                                </el-tooltip>
                                <el-tooltip content="Muhasebe Google Drive" placement="bottom">
                                    <el-button icon="mdi mdi-google-drive" class="modern secondary"
                                               @click="accountingDialog = true" />
                                </el-tooltip>
                                <el-tooltip content="Muhasebe Programina Aktar" placement="bottom">
                                    <el-button icon="mdi mdi-share-outline" class="modern secondary"
                                               @click="sendAccounting" />
                                </el-tooltip>
                                <el-tooltip content="İşlem Kayıtlarını Görüntüle" placement="bottom">
                                    <el-button icon="mdi mdi-eye" class="modern secondary"
                                               @click="handleVisibleLog" />
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="box-list-item" v-if="form.message">
                        {{ form.message }}
                    </div>
                </div>

                <div class="card-sub-title d-flex align-items-center justify-content-between">
                    <div>Siparis Bilgileri</div>
                    <div>
                        <el-tooltip content="Duzenle" placement="left">
                            <el-button icon="el-icon-edit" class="flat dark teal" @click="orderDialog= true" />
                        </el-tooltip>
                    </div>
                </div>
                <div class="box-list">
                    <el-row :gutter="24">
                        <el-col :span="12">

                            <div class="box-list-item">
                                <div class="name">Urun Adeti</div>
                                <div class="description">{{ form.quantity }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Taksit Sayisi</div>
                                <div class="description">{{ form.installment_no }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Taksit Farki</div>
                                <div class="description">+ {{ $n(form.installment_price, 'currency') }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Urun Toplami</div>
                                <div class="description">+ {{ $n(form.price, 'currency') }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Kargo Ucreti</div>
                                <div class="description" v-if="!form.is_free_shipping">+ {{
                                        $n(form.shipping_price,
                                            'currency')
                                    }}
                                </div>
                                <div class="description text-green" v-if="form.is_free_shipping">ucretsiz kargo</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Vergi</div>
                                <div class="description">+ {{ $n(form.tax_price, 'currency') }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Indirim</div>
                                <div class="description">- {{ $n(form.discount_price, 'currency') }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Toplam</div>
                                <div class="description">{{ $n(form.total, 'currency') }}</div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="box-list-item">
                                <div class="name">Ip Adresi</div>
                                <div class="description">{{ form.ip_address }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Odeme Yontemi</div>
                                <div class="description" v-if="form.payment_plugin">{{ form.payment_plugin.title }} ({{
                                        form.payment_plugin.name
                                    }})
                                </div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Odeme Tarihi</div>
                                <div class="description">{{ form.payment_at }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Odemenin Yapildigi Banka</div>
                                <div class="description">{{ form.payment_bank }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Odeme Mesaji</div>
                                <div class="description">
                                    <el-button class="flat dark blue" icon="mdi mdi-eye"
                                               @click="paymentMessageDialog = true" />
                                </div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Teslimat Yontemi</div>
                                <div class="description">{{ form.delivery_plugin.title }} ({{
                                        form.delivery_plugin.name
                                    }})
                                </div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Teslimat Takip Kodu</div>
                                <div class="description">{{ form.delivery_no }}</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Teslimat Tarihi</div>
                                <div class="description">{{ form.delivery_at }}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-card>

            <Note :path="$route.fullPath" />

            <el-row :gutter="24">
                <el-col :span="16">
                    <Product :order="form" />
                </el-col>
                <el-col :span="8">
                    <Discount :order="form" />
                </el-col>
            </el-row>

            <Orders :id="form.user_id" :activeId="form.id" />

        </div>
        <div v-show="activeTab === '2'">
            <Address :order="form" />
        </div>
        <div v-show="activeTab === '3'">
            <OrderReturn :order="form" />
        </div>

        <ProcessLog ref="ProcessLog" :id="form.id" controller="App\Database\Orders\Order" />

        <el-dialog title="Odeme Mesaji" :visible.sync="paymentMessageDialog" width="50%">
            <vue-prism-editor v-model="form.payment_message" language="json" :lineNumbers="true" :readonly="true"
                              :autoStyleLineNumbers="true" />
        </el-dialog>

        <el-dialog title="Siparis Bilgileri" :visible.sync="orderDialog" width="70%" v-loading="pageLoading">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Ad">
                            <el-input v-model="form.first_name" placeholder="Ad" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Soyad">
                            <el-input v-model="form.last_name" placeholder="Soyad" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Telefon">
                    <el-input v-model="form.phone" placeholder="Telefon" v-mask="'0 (###) ### ## ##'" />
                </el-form-item>

                <el-form-item label="Eposta">
                    <el-input v-model="form.email" placeholder="Soyad" type="email" />
                </el-form-item>

                <el-form-item label="Odeme Tarihi">
                    <el-date-picker v-model="form.payment_at" type="datetime" placeholder="Odeme Tarihi"
                                    value-format="yyyy-MM-dd HH:mm:ss" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Taksit Sayisi">
                            <el-input v-model="form.installment_no" placeholder="Taksit Sayisi" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Taksit Farki">
                            <el-input v-model="form.installment_price" placeholder="Taksit Farki" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Urun Toplami">
                    <el-input v-model="form.price" placeholder="Urun Toplami" />
                </el-form-item>

                <el-form-item label="Teslimat Ucreti">
                    <el-input v-model="form.shipping_price" placeholder="Teslimat Ucreti" />
                </el-form-item>

                <el-form-item label="Vergi">
                    <el-input v-model="form.tax_price" placeholder="Vergi" />
                </el-form-item>

                <el-form-item label="Indirim Toplami">
                    <el-input v-model="form.discount_price" placeholder="Indirim Toplami" />
                </el-form-item>

                <el-form-item label="Toplam">
                    <el-input v-model="form.total" placeholder="Toplam" />
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_shipping" active-text="Kargolu Siparis" />
                </el-form-item>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="update">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>

        <el-dialog title="Muhasebe Islemleri" :visible.sync="accountingDialog" width="95%">
            <table class="el-table" style="width: 100%">
                <thead>
                <tr>
                    <th>Dia Kodu</th>
                    <th>Sipariş No</th>
                    <th>Tarih</th>
                    <th>Ad Soyad</th>
                    <th>E mail</th>
                    <th>T.C. Kimlik No</th>
                    <th>Telefon</th>
                    <th>Grup</th>
                    <th>Tutar</th>
                </tr>
                </thead>
                <tbody>
                <tr class="fs-14" v-for="product in form.products" :key="product.id">
                    <td>{{ form.accounting_no }}</td>
                    <td>{{ form.code }}</td>
                    <td>{{ form.payment_at }}</td>
                    <td>{{ form.first_name }} {{ form.last_name }}</td>
                    <td>{{ form.email }}</td>
                    <td>{{ form.invoice_address.identification_no }}</td>
                    <td>{{ form.invoice_address.phone }}</td>
                    <td>
                        <span v-if="product.product && product.product.teacher">{{ product.product.teacher.name }}</span>
                        {{ product.title }} {{ findProductType(product.variant_type) }} {{
                            findProductType(product.day_type)
                        }} {{ findProductType(product.week_type) }}
                    </td>
                    <td>{{ product.is_gift ? 0 : product.total }}</td>
                </tr>
                </tbody>
            </table>
        </el-dialog>
    </div>
</template>

<script>
import Status from "./Edit/Status";
import Product from "./Edit/Product";
import Discount from "./Edit/Discount";
import Note from "../../../Components/Note";
import Address from "./Edit/Address";
import OrderReturn from "./Edit/OrderReturn";
import Orders from "./Edit/Orders";
import ProcessLog from "@/app/Components/ProcessLog";

export default {
    name: 'Order',
    components: {
        ProcessLog,
        Orders,
        OrderReturn,
        Address,
        Note,
        Discount,
        Product,
        Status,
    },
    data() {
        return {
            pageLoading: false,
            activeTab: '1',
            form: {
                //
            },

            paymentMessageDialog: false,
            orderDialog: false,
            accountingDialog: false,
        }
    },
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink(), {
                    params: {
                        with: [
                            'invoiceAddress',
                            'products',
                        ]
                    }
                })
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        update() {
            this.pageLoading = true;

            this.$http
                .put(this.createShowPageLink(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.orderDialog = false;
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleSelect(key) {
            this.activeTab = key;
        },

        sendAccounting() {
            this
                .$confirm('Gondermeden once ilgili siparis kaydinin muhasebe programindna silindiginden emin olunuz. Siparisi muhasebe programina aktarmak istediginizden emin misiniz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    this.pageLoading = true;

                    this.$http
                        .put(this.createShowPageLink(), {process: 'accounting'})
                        .then(response => {
                            this.pageLoading = false;

                            return this.responseSuccess(response);
                        })
                        .catch(response => {
                            this.pageLoading = false;
                            return this.responseError(response);
                        })

                    return true;

                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        handleSendTestOrder() {
            this
                .$confirm('Siparisiniz test siparisi olarak isaretlenecek ve Muhasebe programina aktarilmayacak!', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    this.pageLoading = true;

                    this.$http
                        .put(this.createShowPageLink(), {process: 'test'})
                        .then(response => {
                            this.pageLoading = false;

                            return this.responseSuccess(response);
                        })
                        .catch(response => {
                            this.pageLoading = false;
                            return this.responseError(response);
                        })

                    return true;

                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        handleVisibleLog() {
            this.$refs.ProcessLog.handleVisible();
        }
    }
}
</script>
