<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">Urun Secimi ({{ shop.create.product_type }})</div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" placement="left">
                            <el-button class="flat dark blue" icon="el-icon-back"
                                       @click="$store.commit('setShopCreateChangeStep', 'product_type')" />
                        </el-tooltip>
                    </div>
                </div>
            </template>
            <div class="card-flat-list-items sm">
                <div class="item">
                    <el-form :label-position="'top'" ref="formFilter" name="formFilter" :model="formFilter">
                        <el-row :gutter="24">
                            <el-col :span="22">
                                <el-row :gutter="24">
                                    <el-col :span="8">
                                        <el-form-item class="margin-bottom-0">
                                            <el-input v-model="formFilter.reference" placeholder="Urun Kodu" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item class="margin-bottom-0">
                                            <lv-select :container="formFilter" model="variant_type" :options="variantTypes" placeholder="Varyasyon" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item class="margin-bottom-0">
                                            <user :container="formFilter" model="user_id" placeholder="Ogretmen" :key="formFilter.id" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="2">
                                <el-button class="modern blue large extract" icon="el-icon-search" style="font-size: 22px; width: 100% !important; min-width: auto; height: 55px; margin-top: 2px" @click="search" />
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <el-table :data="response.rows" width="100%">
                    <el-table-column width="1" align="center"></el-table-column>
                    <el-table-column label="Urun">
                        <template slot-scope="props">
                            <div class="d-flex align-items-center">
                                <div class="text">
                                    <div class="title fs-16 font-weight-500">{{ props.row.content.title }}</div>
                                    <div v-if="props.row.teacher">{{ props.row.teacher.name }}</div>
                                    <div v-if="props.row.teacher">{{ props.row.teacher.email }}</div>
                                    <div>{{ props.row.reference }}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Zaman Kisitlamasi" width="300">
                        <template slot-scope="props">
                            <div v-if="!shop.create.product_type === 'subscription'">
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Başlangıç Tarihi</div>
                                    <div>{{ props.row.start_at }}</div>
                                </div>
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Bitiş Tarihi</div>
                                    <div>{{ props.row.end_at }}</div>
                                </div>
                            </div>
                            <div v-if="shop.create.product_type === 'subscription'">
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Kullanim Suresi</div>
                                    <div>{{ props.row.sort }} Ay</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Varyasyon" width="240">
                        <template slot-scope="props">
                            <div v-if="props.row.type !== 'group' && props.row.type !== 'subscription'">
                                -
                            </div>
                            <div v-if="props.row.type === 'group'">
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Variant</div>
                                    <div>{{ findProductType(props.row.variant_type) }}</div>
                                </div>
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Hafta</div>
                                    <div>{{ findProductType(props.row.week_type) }}</div>
                                </div>
                                <div class="d-flex align-item-center justify-content-between">
                                    <div>Gün</div>
                                    <div>{{ findProductType(props.row.day_type) }}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Stok" align="center" width="140">
                        <template slot-scope="props">
                            <div v-if="props.row.type === 'private'">
                                {{ props.row.quantity }} Saat
                            </div>
                            <div v-if="props.row.type === 'group'">
                                {{ props.row.current_quantity }} kisi / {{ props.row.quantity }} kisi
                            </div>
                            <div v-if="props.row.type === 'physical' || props.row.type === 'digital'">
                                {{ props.row.quantity - props.row.current_quantity }} adet
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Fiyat" align="right" width="100">
                        <template slot-scope="props">
                            <div class="flex-column" v-if="props.row.discount">
                                <div class="text-red">
                                    <del><b>{{ $n(props.row.discount.price, 'currency') }}</b></del>
                                </div>
                                <div class="text-green">
                                    <b>{{ $n(props.row.price, 'currency') }}</b>
                                </div>
                            </div>
                            <div v-if="!props.row.discount">
                                <b>{{ $n(props.row.price, 'currency') }}</b>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="right" class="actions" width="120">
                        <template slot-scope="props">
                            <el-button class="modern violet add-to-basket-btn" icon="el-icon-plus"
                                       @click="handleSelected(props.row)" v-if="props.row.type !== 'private'" />
                            <el-button class="modern violet add-to-basket-btn" icon="el-icon-plus"
                                       @click="handleVisibleDialog(props.row)" v-if="props.row.type === 'private'" />
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <div class="text-center margin-top-20" v-if="response.pagination.total > response.pagination.per_page">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="response.pagination.total"
                    :page-size="response.pagination.per_page"
                    :current-page.sync="response.pagination.current_page"
                    @current-change="handleChangePage"
            />
        </div>

        <el-dialog :title="'Ozel Ders ' + totalSelectedDate +' / ' + dialogResponse.hours "
                   :visible.sync="dialogVisible" v-loading="pageLoading"
                   class="dialog-with-page body-overflow custom-padding" :before-close="dialogClose">
            <div v-loading="dialogLoading" style="min-height: 400px" class="private-page-section">
                <swiper :options="eventSlider" class="pick-lesson-time" ref="EventSwiper">
                    <swiper-slide class="pick-lesson-time__column" v-for="item in dialogResponse.events" :key="item.id">
                        <div class="pick-lesson-time__head">
                            <div class="pick-lesson-time__date">
								<span class="pick-lesson-time__date__icon">
									<icon icon="calendar"></icon>
								</span>
                                <span class="pick-lesson-time__date__txt">{{ item.date }}</span>
                            </div>
                            <span class="pick-lesson-time__day">{{ item.day }}</span>
                        </div>
                        <div class="pick-lesson-time__btns">
                            <button v-for="date in item.items" :key="date.id" type="button"
                                    :class="{active : date.selected, completed : totalSelectedDate === dialogResponse.hours && date.selected}"
                                    :disabled="!date.id" @click="handleSelectedDate(item, date)">
                                {{ date.time.start }} - {{ date.time.end }}
                            </button>
                        </div>
                    </swiper-slide>
                    <template slot="button-prev">
                        <div class="slider-nav slider-prev">
                            <span class="slider-nav__icon"><icon icon="arrow-left"></icon></span>
                        </div>
                    </template>
                    <template slot="button-next">
                        <div class="slider-nav slider-next">
                            <span class="slider-nav__icon"><icon icon="arrow-right"></icon></span>
                        </div>
                    </template>
                </swiper>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'

export default {
    name: 'ProductList',
    components: {
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    computed: mapState(['shop']),
    data() {
        return {
            pageLoading: false,
            page: 1,
            response: {
                pagination: {
                    //
                },
                rows: []
            },
            form: {
                //
            },
            formFilter: {
                //
            },

            variantTypes: [
                {
                    value: 'live_and_record',
                    label: 'Canli ve Kayittan',
                },
                {
                    value: 'live',
                    label: 'Canli',
                },
                {
                    value: 'record',
                    label: 'Kayittan',
                }
            ],

            dialogVisible: false,
            dialogLoading: false,
            dialogResponse: {
                events: []
            },
            totalSelectedDate: 0,

            eventSlider: {
                effect: 'coverflow',
                grabCursor: false,
                loop: false,
                keyboard: true,
                centeredSlides: false,
                slidesPerView: 7,
                spaceBetween: 20,
                speed: 500,
                simulateTouch: false,
                navigation: {
                    nextEl: '.pick-lesson-time .slider-next',
                    prevEl: '.pick-lesson-time .slider-prev'
                },
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    slideShadows: false
                },
                breakpoints: {
                    320: {
                        slidesPerView: 2,
                    },
                    481: {
                        slidesPerView: 3,
                    },
                    577: {
                        slidesPerView: 4,
                    },
                    801: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                    991: {
                        slidesPerView: 6,
                    },
                    1024: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                    },
                    1200: {
                        slidesPerView: 7,
                        spaceBetween: 10,
                    },
                    1400: {
                        slidesPerView: 7,
                        spaceBetween: 20,
                    }
                }
            }
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.pageLoading = true;

            this.$http
                    .get('catalog/products', {
                        params: {
                            page: this.page,
                            sorts: {
                                created_at: 'desc'
                            },
                            filters: [
                                {
                                    relation: null,
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'type',
                                            field: 'type',
                                            type: 'singleSelect',
                                            value: this.shop.create.product_type
                                        },
                                        {
                                            name: 'reference',
                                            field: 'reference',
                                            type: 'singleSelect',
                                            value: this.formFilter.reference
                                        },
                                        {
                                            name: 'variant_type',
                                            field: 'variant_type',
                                            type: 'singleSelect',
                                            value: this.formFilter.variant_type
                                        },
                                        {
                                            name: 'user_id',
                                            field: 'user_id',
                                            type: 'singleSelect',
                                            value: this.formFilter.user_id
                                        }
                                    ]
                                },
                            ],
                        }
                    })
                    .then(response => {
                        this.response = response.body;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
        },

        show(id) {

            this.dialogLoading = true;

            this.$http
                    .get('catalog/products/' + id, {
                        params: {
                            process: 'private',
                        }
                    })
                    .then(response => {
                        this.dialogResponse = response.body.data;
                        this.dialogLoading = false;
                    })
                    .catch(response => {
                        this.dialogLoading = false;
                        return this.responseError(response);
                    })
        },

        handleChangePage(page) {
            this.page = page;
            this.index();
        },

        handleSelected(product) {
            this.$store.commit('setShopCreateSetForm', {
                product_id: product.id,
                content_id: product.content_id,
                quantity: 1,
                events: this.dialogResponse.events
            });
            this.$store.commit('setShopCreateChangeStep', 'customer');
        },

        handleVisibleDialog(product) {
            this.form = product;
            this.dialogVisible = true;
            this.totalSelectedDate = 0;

            this.show(product.id);
        },

        handleSelectedDate(event, date) {
            if (!date.id) {
                return false;
            }

            let eventSelectedCounter = 0;
            let firstSelectedEventItem = null;

            this.lodash.forEach(event.items, (eventItem) => {
                if (eventItem.selected) {

                    if (!firstSelectedEventItem) {
                        firstSelectedEventItem = eventItem;
                    }

                    eventSelectedCounter++;
                }
            });

            if (eventSelectedCounter >= 2 && !date.selected) {
                this.$alert('Uzgunum birden fazla secim yapamazsiniz!', 'Uyari', {
                    confirmButtonText: 'OK',
                });
            }

            if (firstSelectedEventItem && !date.selected) {

                let nextKey = this.findNextTime(firstSelectedEventItem.time.parse_start, firstSelectedEventItem.time.parse_end);
                let prevKey = this.findPrevTime(firstSelectedEventItem.time.parse_start, firstSelectedEventItem.time.parse_end);

                let nextStatus = false;
                let prevStatus = false;

                if (event.items[nextKey].id) {
                    if (date.id !== event.items[nextKey].id) {
                        nextStatus = true;
                    }
                }

                if (event.items[prevKey].id) {
                    if (date.id !== event.items[prevKey].id) {
                        prevStatus = true;
                    }
                }

                if (nextStatus && prevStatus) {

                    this.$alert('Uzgunum arka arkaya secim yapamazsiniz!', 'Uyari', {
                        confirmButtonText: 'OK',
                    });

                    return false;
                }
            }

            if (this.totalSelectedDate >= this.dialogResponse.hours) {
                this.$alert('Uzgunum En fazla ' + this.dialogResponse.hours + ' adet tarih secebilirsiniz!', 'Uyari', {
                    confirmButtonText: 'OK',
                });
                return false;
            }

            date.selected = !date.selected;

            if (date.selected) {
                this.totalSelectedDate++;
            } else {
                this.totalSelectedDate--;
            }
        },

        findNextTime(start, end) {
            let startTime = start + 1;
            let endTime = end + 1;

            if (startTime <= 9) {
                startTime = '0' + startTime + ':00'
            } else {
                startTime = startTime + ':00'
            }

            if (endTime <= 9) {
                endTime = '0' + endTime + ':00'
            } else {
                endTime = endTime + ':00'
            }

            return startTime + '-' + endTime;
        },

        findPrevTime(start, end) {

            let startTime;
            let endTime;

            if (start === 0) {
                startTime = 23;
            } else {
                startTime = start - 1;
            }

            if (end === 0) {
                endTime = 23;
            } else {
                endTime = end - 1;
            }

            if (startTime <= 9) {
                startTime = '0' + startTime + ':00'
            } else {
                startTime = startTime + ':00'
            }

            if (endTime <= 9) {
                endTime = '0' + endTime + ':00'
            } else {
                endTime = endTime + ':00'
            }

            return startTime + '-' + endTime;
        },

        dialogClose() {
            if (this.totalSelectedDate !== this.dialogResponse.hours) {
                this
                        .$confirm('Uzgunum ' + this.dialogResponse.hours + ' kadar ders secmediginiz icin bu ders kayit edilmiycek', 'Uyari', {
                            confirmButtonText: 'Onayla',
                            cancelButtonText: 'Iptal',
                            type: 'warning'
                        })
                        .then(() => {
                            this.dialogVisible = false;
                            return true;

                        })
                        .catch((response) => {
                            this.$notify({
                                type: 'success',
                                message: 'Isleminiz basarili bir sekilde iptal edildi!'
                            });

                            return false;
                        });

                return false;
            }

            this.$notify({
                type: 'success',
                message: 'Tebrikler seciminiz basarili bir sekilde kayit edildi!'
            });

            this.handleSelected({
                id: this.dialogResponse.product_id,
                content_id: this.dialogResponse.content_id,
            });
        },

        search() {
            this.page = 1;
            this.index();
        }
    }
}
</script>
