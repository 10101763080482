<template>
    <el-form-item :label="item.label">
        <product-dropdown :container="container" :model="item.name" :multiple="item.multiple" :placeholder="item.label" />
    </el-form-item>
</template>

<script>
export default {
    name: 'Product',
    props : ['form', 'container', 'item'],
    mounted() {
        //
    },
    methods: {
        //
    }
}
</script>