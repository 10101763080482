import LayoutConfig from '../config/layout'
import Container from '../app/Container/Main'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Datatable'

import Branch from '../app/Pages/Archives/Branch';
import Level from '../app/Pages/Archives/Level';
import Audience from '../app/Pages/Archives/Audience';
import Examination from '../app/Pages/Archives/Examination';
import Topic from '../app/Pages/Archives/Topic';
import Knowledge from '../app/Pages/Archives/Knowledge';

import QuestionContentIndex from '../app/Pages/Archives/QuestionContent/Index';
import QuestionContentCreate from '../app/Pages/Archives/QuestionContent/Create';
import QuestionContentEdit from '../app/Pages/Archives/QuestionContent/Edit';

import WordIndex from '../app/Pages/Archives/Words/Index';
import WordShow from '../app/Pages/Archives/Words/Show';
import Sentence from '../app/Pages/Archives/Sentence';

export default {
	path: '/archives',
	name: 'api.archives.index',
	component: Container,
	meta: {
		auth: true,
		layout: LayoutConfig.full,
		title: 'Eğitim Arşivi',
	},
	children: [
		{
			path: 'branches',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Branşlar',
				breadcrumb: 'api.archives.branches.index',
			},
			children: [
				{
					name: 'api.archives.branches.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Branşlar',
						apiPoint: 'archives/branches',
					},
				},
				{
					name: 'api.archives.branches.create',
					path: 'create',
					component: Branch,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Branş Olustur',
						apiPoint: 'archives/branches',
					}
				},
				{
					name: 'api.archives.branches.edit',
					path: ':id',
					component: Branch,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Branş Bilgileri',
						apiPoint: 'archives/branches',
					},
				},
			]
		},
		{
			path: 'levels',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Seviyeler',
				breadcrumb: 'api.archives.levels.index',
			},
			children: [
				{
					name: 'api.archives.levels.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Seviyeler',
						apiPoint: 'archives/levels',
					},
				},
				{
					name: 'api.archives.levels.create',
					path: 'create',
					component: Level,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Seviye Olustur',
						apiPoint: 'archives/levels',
					}
				},
				{
					name: 'api.archives.levels.edit',
					path: ':id',
					component: Level,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Seviye Bilgileri',
						apiPoint: 'archives/levels',
					},
				},
			]
		},
		{
			path: 'audiences',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Hedef Kitle',
				breadcrumb: 'api.archives.audiences.index',
			},
			children: [
				{
					name: 'api.archives.audiences.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Hedef Kitle',
						apiPoint: 'archives/audiences',
					},
				},
				{
					name: 'api.archives.audiences.create',
					path: 'create',
					component: Audience,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Hedef Kitle Olustur',
						apiPoint: 'archives/audiences',
					}
				},
				{
					name: 'api.archives.audiences.edit',
					path: ':id',
					component: Audience,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Hedef Kitle Bilgileri',
						apiPoint: 'archives/audiences',
					},
				},
			]
		},
		{
			path: 'examinations',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Sınavlar',
				breadcrumb: 'api.archives.examinations.index',
			},
			children: [
				{
					name: 'api.archives.examinations.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Sınavlar',
						apiPoint: 'archives/examinations',
					},
				},
				{
					name: 'api.archives.examinations.create',
					path: 'create',
					component: Examination,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Sınav Olustur',
						apiPoint: 'archives/examinations',
					}
				},
				{
					name: 'api.archives.examinations.edit',
					path: ':id',
					component: Examination,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Sınav Bilgileri',
						apiPoint: 'archives/examinations',
					},
				},
			]
		},
		{
			path: 'topics',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Konu Etiketleri',
				breadcrumb: 'api.archives.topics.index',
			},
			children: [
				{
					name: 'api.archives.topics.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Konu Etiketleri',
						apiPoint: 'archives/topics',
					},
				},
				{
					name: 'api.archives.topics.create',
					path: 'create',
					component: Topic,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Konu Etiketi Olustur',
						apiPoint: 'archives/topics',
					}
				},
				{
					name: 'api.archives.topics.edit',
					path: ':id',
					component: Topic,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Konu Etiketi Bilgileri',
						apiPoint: 'archives/topics',
					},
				},
			]
		},
		{
			path: 'knowledges',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Bilgi Havuzu',
				breadcrumb: 'api.archives.knowledges.index',
			},
			children: [
				{
					name: 'api.archives.knowledges.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Bilgi Havuzu',
						apiPoint: 'archives/knowledges',
					},
				},
				{
					name: 'api.archives.knowledges.create',
					path: 'create',
					component: Knowledge,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Bilgi Havuzu Olustur',
						apiPoint: 'archives/knowledges',
					}
				},
				{
					name: 'api.archives.knowledges.edit',
					path: ':id',
					component: Knowledge,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Bilgi Havuzu Bilgileri',
						apiPoint: 'archives/knowledges',
					},
				},
			]
		},
		{
			path: 'question-contents',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Sorular',
				breadcrumb: 'api.archives.question_contents.index',
			},
			children: [
				{
					name: 'api.archives.question_contents.index',
					path: '',
					component: QuestionContentIndex,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Sorular',
						apiPoint: 'archives/question-contents',
					},
				},
				{
					name: 'api.archives.question_contents.create',
					path: 'create',
					component: QuestionContentCreate,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Branş Secimi',
						apiPoint: 'archives/question-contents',
					}
				},
				{
					name: 'api.archives.question_contents.edit',
					path: ':id',
					component: QuestionContentEdit,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Soru Bilgileri',
						apiPoint: 'archives/question-contents',
					},
				},
			]
		},
		{
			path: 'words',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Kelimeler',
				breadcrumb: 'api.archives.words.index',
			},
			children: [
				{
					name: 'api.archives.words.index',
					path: '',
					component: WordIndex,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kelimeler',
						apiPoint: 'archives/words',
					},
				},
				{
					name: 'api.archives.words.create',
					path: 'create',
					component: WordShow,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kelime Olustur',
						apiPoint: 'archives/words',
					}
				},
				{
					name: 'api.archives.words.edit',
					path: ':id',
					component: WordShow,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kelime Bilgileri',
						apiPoint: 'archives/words',
					},
				},
			]
		},
		{
			path: 'sentences',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Cumleler',
				breadcrumb: 'api.archives.sentences.index',
			},
			children: [
				{
					name: 'api.archives.sentences.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Cumleler',
						apiPoint: 'archives/sentences',
					},
				},
				{
					name: 'api.archives.sentences.create',
					path: 'create',
					component: Sentence,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Cumle Olustur',
						apiPoint: 'archives/sentences',
					}
				},
				{
					name: 'api.archives.sentences.edit',
					path: ':id',
					component: Sentence,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Cumle Bilgileri',
						apiPoint: 'archives/sentences',
					},
				},
			]
		}
	]
}
