<template>
	<el-dialog title="Kart Bilgileri" :visible.sync="visible" class="dialog-with-page card-page" append-to-body :before-close="dialogClose" v-loading="pageLoading">
		<div class="el-dialog__body-wrapper" v-if="form">
			<el-row :gutter="24">
				<el-col :span="18">
					<div class="left-column">
						<el-card :class="{'custom-padding' : !form.description}">
							<template slot="header">
								<div class="title">{{ form.title }}</div>
								<div class="actions">
									<el-tooltip content="Duzenle" placement="bottom">
										<el-button class="flat dark blue" icon="el-icon-edit" @click="edit" />
									</el-tooltip>
								</div>
							</template>
							<div class="section" v-if="form.description">{{ form.description }}</div>
						</el-card>
						<el-card class="custom-padding" v-if="elementSize(form.inputs)">
							<template slot="header">
								<div class="title">Form Data</div>
							</template>
							<div class="section items">
								<div class="item" v-for="input in form.inputs">
                                    <div class="title">{{ $t(input.key) }}</div>
									<div class="description">
                                        <template v-if="input.key === 'phone'">
                                            <phone :item="input.value"/>
                                        </template>

                                        <template v-if="input.key === 'email'">
                                            <email :item="input.value"/>
                                        </template>

                                        <template v-if="input.key !== 'email' && input.key !== 'phone'">
                                            {{ input.value }}
                                        </template>
                                    </div>
								</div>
							</div>
						</el-card>

						<CardFile :cardId="form.id" />

						<CardChecklist ref="CardCheckList" :cardId="form.id" />

						<Note :path="'/cards/' + form.id" />
					</div>
				</el-col>
				<el-col :span="6">
					<div class="sidebar">
						<div class="sidebar-box">
							<div class="section actions">
								<el-tooltip effect="dark" content="Checklist Ekle" placement="bottom">
									<div class="button blue" @click="handleCreateChecklist">
										<icon icon="playlist-plus" />
									</div>
								</el-tooltip>

								<el-tooltip effect="dark" content="Arsive Tasi" placement="bottom" v-if="!form.is_archive">
									<div class="button green" @click="moveArchive(true)">
										<icon icon="archive-arrow-up-outline" />
									</div>
								</el-tooltip>

								<el-tooltip effect="dark" content="Arsivden Cikar" placement="bottom" v-if="form.is_archive">
									<div class="button secondary" @click="moveArchive(false)">
										<icon icon="archive-arrow-down-outline" />
									</div>
								</el-tooltip>

								<el-tooltip effect="dark" content="Sil" placement="bottom">
									<div class="button red" @click="destroy">
										<icon icon="trash-can-outline" />
									</div>
								</el-tooltip>
							</div>
						</div>
						<div class="sidebar-box">
							<div class="head">
								<div class="title">Islem Hatti</div>
							</div>
							<div class="section">
								<el-form :label-position="'top'" ref="CardStage" :model="form">
									<el-form-item class="margin-0">
										<el-select v-model="form.board_pipeline_stage_id" placeholder="Islem Hatti"
												   @change="update">
											<el-option v-for="stage in stages" :key="stage.id" :label="stage.title"
													   :value="stage.id" />
										</el-select>
									</el-form-item>
								</el-form>
							</div>
						</div>
						<div class="sidebar-box" v-if="form.customer">
							<div class="head">
								<div class="title">Musteri Bilgileri</div>
							</div>
							<div class="section customer">
								<div class="media">
									<pic :src="form.customer.avatar" type="avatar" />
								</div>
								<div class="text">
									<div class="title">{{ form.customer.name }}</div>
									<div class="email">
										<email :item="form.customer.email" />
									</div>
									<div class="phone">
										<phone :item="form.customer.phone" />
									</div>
								</div>
							</div>
						</div>
						<CardLabel :cardId="form.id" />
						<CardAssignee :cardId="form.id" />
					</div>
				</el-col>
			</el-row>

			<el-dialog title="Karti Duzenle" :visible.sync="dialogVisible" v-loading="dialogLoading" append-to-body>
				<el-form :label-position="'top'" ref="stage" :model="form">
					<el-form-item label="Baslik">
						<el-input v-model="form.title" placeholder="Baslik" />
					</el-form-item>

					<el-form-item label="Aciklama">
						<el-input v-model="form.description" placeholder="Aciklama" type="textarea" rows="4" />
					</el-form-item>
				</el-form>

				<template slot="footer">
					<el-button class="modern green large fluid" icon="el-icon-edit" @click="update">
						Guncelle
					</el-button>
				</template>
			</el-dialog>
		</div>
	</el-dialog>
</template>

<script>
	import Note from "../../Components/Note";
	import CardLabel from "./Card/Label";
	import CardAssignee from "./Card/Assignee";
	import CardChecklist from "./Card/Checklist";
	import CardFile from "./Card/File";

	export default {
		name: 'Card',
		components: {
			CardFile,
			CardChecklist,
			CardAssignee,
			CardLabel,
			Note
		},
		data() {
			return {
				withs: ['inputs', 'customer'],
				visible: true,
				pageLoading: false,
				dialogVisible: false,
				dialogLoading: false,
				form: null,

				stages: [],
			}
		},
		mounted() {
			this.dialogShow();
			this.show(this.$route.params.cardId);
			this.getStages();
		},
		methods: {
			endPoint(key) {
				if (!key) {
					return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.$route.params.stageId + '/cards'
				}

				return 'boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.$route.params.stageId + '/cards/' + key
			},

			dialogShow() {
				this.$store.commit('visibleFullPageDialog');
			},

			dialogClose() {
				this.$store.commit('hideFullPageDialog');
				this.$router.push('/boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId)
			},

			getStages() {
				this.pageLoading = true;

				this.$http
					.get('boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages')
					.then(response => {
						this.stages = response.body.rows;
						this.pageLoading = false;
					})
					.catch(response => {
						this.stages = [];
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			show(key) {
				this.pageLoading = true;

				this.$http
					.get(this.endPoint(key), {
						params: {
							with: this.withs
						}
					})
					.then(response => {
						this.form = response.body.data;
						this.pageLoading = false;
					})
					.catch(response => {
						this.stages = [];
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			edit() {
				this.dialogVisible = true;
			},

			update() {
				this.pageLoading = true;
				this.dialogLoading = true;

				let form = this.form;
				form.with = this.withs;

				this.$http
					.put(this.endPoint(this.$route.params.cardId), form)
					.then(response => {
						this.pageLoading = false;
						this.dialogLoading = false;
						this.dialogVisible = false;
						this.form = response.body.data;

						if (this.form.board_pipeline_stage_id !== this.$route.params.stageId) {
							this.goRoute('/boards/' + this.$route.params.id + '/pipelines/' + this.$route.params.pipeId + '/stages/' + this.form.board_pipeline_stage_id + '/cards/' + this.form.id)
						}
					})
					.catch(response => {
						this.pageLoading = false;
						this.dialogLoading = false;
						return this.responseError(response);
					})
			},

			destroy() {
				this.pageLoading = true;

				this
					.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {
						this.$http
							.delete(this.endPoint(this.$route.params.cardId))
							.then(response => {
								this.responseSuccess(response);
								this.dialogClose();
							})
							.catch(response => {
								this.responseError(response);
								this.pageLoading = false;
							});

						return true;
					})
					.catch((response) => {

						this.pageLoading = false;

						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},

			handleCreateChecklist() {
				this.$refs.CardCheckList.create();
			},

			moveArchive(status) {
				this.form.is_archive = status;
				this.update();
			}
		}
	}
</script>
