<template>
    <div class="catalog-page-detail-content-tab" v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    <icon :icon="tab.icon" />
                    {{ tab.title }}
                </div>
            </template>

            <div class="card-custom-padding-box-items">
                <el-form-item label="Rozetler">
                    <statuses alias="badges" :container="form" model="badge_requests" :multiple="true" />
                </el-form-item>

                <el-form-item label="Kategoriler">
                    <el-select v-model="form.category_requests" placeholder="Bagli Oldugu Kategori" clearable filterable multiple>
                        <el-option v-for="item in categories" :key="item.id" :label="item.title_ui" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="Onerilen Sayfalar">
                    <el-select v-model="form.suggestion_requests" placeholder="Onerilen Sayfalar" clearable filterable multiple>
                        <el-option v-for="item in suggestions" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="Birlikte Satisi Olacak Urunler">
                    <el-select v-model="form.suggestion_sale_requests" placeholder="Birlikte Satisi Olacak Urunler" clearable filterable multiple>
                        <el-option v-for="item in sales" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="Sikca Sorulan Sorular">
                    <el-select v-model="form.meta.faq_requests" placeholder="Sikca Sorulan Sorular" clearable filterable multiple>
                        <el-option v-for="item in faqs" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                </el-form-item>

                <el-form-item label="Geri Bildirim Etiketleri">
                    <definitions :container="form.meta" model="comment_feedback_tags" alias="feedback-tags" :multiple="true" />
                </el-form-item>
            </div>

            <div class="card-sub-title">Diger</div>
            <div class="card-custom-padding-box-items">
                <el-form-item label="Yayınlanma Tarihi">
                    <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" />
                </el-form-item>

                <el-form-item label="Hedef Linki (Urun detayina girildiginde bu linke yonlendirilir)">
                    <el-input v-model="form.target_link" placeholder="Hedef Linki" prefix-icon="mdi mdi-web-plus" />
                </el-form-item>

                <el-form-item label="Siralama">
                    <el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_featured" active-text="One Cikar" />
                </el-form-item>

                <el-form-item class="m-0">
                    <el-switch v-model="form.is_active" active-text="Aktif" />
                </el-form-item>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'ProductConnect',
    props: ['tab', 'form'],
    data() {
        return {
            pageLoading : false,
            categories : [],
            sales : [],
            suggestions : [],
            faqs : [],
        }
    },
    mounted() {
        this.getCategories();
        this.getContents();
        this.getSales();
        this.getFaqs();
    },
    methods: {
        getCategories() {
            this.pageLoading = true;

            this.$http
                .get('cms/products/categories', {params: {perPage: 1000}})
                .then(response => {
                    this.categories = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getSales() {
            this.pageLoading = true;

            let type = 'physical';

            if (this.$route.meta.type === 'physical') {
                type = 'digital'
            }

            if (this.$route.meta.type === 'package') {
                type = 'group'
            }

            this.$http
                .get(this.$route.meta.apiPoint, {params: {perPage: 1000, view: 'dropdown', type : type}})
                .then(response => {
                    this.sales = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getContents() {
            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {params: {perPage: 1000, view: 'dropdown', type : this.$route.meta.type}})
                .then(response => {
                    this.suggestions = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        getFaqs() {
            this.pageLoading = true;

            this.$http
                .get('cms/faqs/contents', {params: {perPage: 1000, view: 'dropdown'}})
                .then(response => {
                    this.faqs = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>
