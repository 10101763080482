<template>
    <div class="product-transfer">
        <el-transfer filterable :filter-method="filterMethod" filter-placeholder="Ürünlerde Ara" :titles="['Tüm ürünler', 'Seçili Olanlar']" v-model="container[model]" :props="{key: 'id', label: 'ui_title'}" :data="items" />
    </div>
</template>

<script>
export default {
    name: 'ProductTransfer',
    props: ['container', 'model', 'types'],
    data() {
        return {
            items : [],
        };
    },
    mounted() {
        this.index();
    },
    methods: {
        filterMethod(query, item) {
            return this.textSearch(query, item.ui_title);
        },
        index() {
            this.pageLoading = true;

            if (this.$store.getters.products) {
                this.items = this.$store.getters.products;

                return;
            }

            this.$http
                .get('tools/search/products', {params : { types : this.types}})
                .then(response => {
                    this.pageLoading = false;
                    this.items = response.body.data;

                    this.$store.commit('setProducts', response.body.data);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    },
};
</script>
