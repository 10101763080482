<template>
	<div v-loading="pageLoading">
		<el-card class="overflow-600">
			<template slot="header">
				<div class="title">
                    <icon icon="image-multiple-outline" />
                    Resimler
                </div>
			</template>
			<div v-scroll:throttle="{ throttle: 500 }" class="images">
				<el-row :gutter="24">
					<el-col :span="size ? size : 4" v-for="item in items" :key="item.id">
						<div class="image margin-bottom-40">
							<img src="@/resources/img/dummy.png" alt="avatar" v-if="!item.file && !item.file.path">
							<img :src="appConfig.s3Path + '/' + item.file.path" alt="avatar" v-if="item.file && item.file.path">

							<div class="buttons">
								<el-button icon="el-icon-edit" class="modern dark black" @click="handleRowActionCommand({type : 'edit', id : item.id})"/>
								<el-button icon="el-icon-delete" class="modern dark red" @click="handleRowActionCommand({type : 'destroy', id : item.id})" />
							</div>
						</div>
					</el-col>
					<el-col :span="size ? size : 4">
						<div class="plus" @click="create">
							<icon icon="plus-thick"/>
						</div>
					</el-col>
				</el-row>
			</div>
		</el-card>

		<el-card class="card-flat-list overflow-400" style="display: none">
			<template slot="header">
				<div class="title">
					Resimler
				</div>
				<div class="actions">
					<el-tooltip content="Ekle" placement="left">
						<el-button class="flat dark blue" icon="el-icon-plus" @click="create" />
					</el-tooltip>
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text display-flex align-items-center">
						<div class="avatar margin-right-20">
							<img src="@/resources/img/dummy.png" class="avatar" alt="avatar" v-if="!item.file && !item.file.path">
							<img :src="appConfig.s3Path + '/' + item.file.path" class="avatar" alt="avatar" v-if="item.file && item.file.path">
						</div>
						<div>
							<div class="title">{{ item.file.title }}</div>
							<div class="fs-14">{{ item.file.description }}</div>
						</div>
					</div>
					<div class="actions" style="right: 20px">
						<el-dropdown @command="handleRowActionCommand">
							<el-button type="text" class="flat dark grey" icon="el-icon-arrow-down" />
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{type : 'edit', id : item.id}">
									<i class="el-icon-edit text-green"></i> Duzenle
								</el-dropdown-item>
								<el-dropdown-item :command="{type : 'destroy', id : item.id}">
									<i class="el-icon-delete text-red"></i> Sil
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog title="Resim Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="70%">
			<el-form :label-position="'top'" ref="form" :model="form">

				<el-form-item label="Başlık">
					<el-input v-model="form.file.title" placeholder="Başlık"/>
				</el-form-item>

                <el-form-item label="Kisa Aciklama">
                    <el-input v-model="form.file.description" :rows="2" type="textarea" placeholder="Kisa Aciklama" />
                </el-form-item>

				<el-form-item label="Siralama">
					<el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
				</el-form-item>

                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item label="Resim" class="margin-bottom-0">
                            <fs v-model="form.file.path" />
                        </el-form-item>
                    </el-col>
                </el-row>
			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
					Kaydet
				</el-button>
				<el-button class="fluid flat dark green large" @click="update" v-if="form.id">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'Images',
        props: ['id', 'path', 'size'],
        data() {
            return {
                pageLoading: false,
                dialogVisible: false,
                form: {
                    file : {}
                },

                items: [],
            }
        },
        methods: {

            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = (this.path ? this.path : this.$route.meta.apiPoint) + '/' + this.id + '/files';

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {params: {perPage: 1000, sorts : {sort : 'asc'}}})
                    .then(response => {

                        this.items = response.body.rows;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             *  Create modal
             */
            create() {
                this.form = {
                    file : {}
				};
                this.dialogVisible = true;
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {};
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            /**
             * Item dropdown actions
             *
             * @param command
             */
            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroy(command.id);
                }

                this.edit(command.id);
            }
        },
        mounted() {
            this.index();
        },
    }
</script>
