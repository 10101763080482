<template>
    <el-form-item :label="item.label">
        <ti-select :options="getSource()" :container="container" :model="item.name" :placeholder="item.label" :multiple="item.multiple" />
    </el-form-item>
</template>

<script>
export default {
    name: 'Language',
    props : ['form', 'container', 'item'],
    methods: {
        getSource() {
            return this.$store.state.ui[this.item.source];
        }
    }
}
</script>