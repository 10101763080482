<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<div class="card-sub-title">Genel</div>
				<div class="card-custom-padding-box-items">
					<el-form-item label="Baslik">
						<el-input v-model="form.title" placeholder="Baslik" />
					</el-form-item>

					<el-form-item label="Aciklama">
						<el-input v-model="form.description" type="textarea" placeholder="Aciklama" />
					</el-form-item>

					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item label="Sepetinde Bulunmasi Gereken Urun Sayisi">
								<el-input v-model="form.min_product_count"
										  placeholder="Sepetinde Bulunmasi Gereken Urun Sayisi" type="number" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Min. Sepet Tutari">
								<el-input placeholder="Min. Sepet Tutari" v-model="form.min_total" />
							</el-form-item>
						</el-col>
					</el-row>

					<el-row :gutter="24">
						<el-col :span="12">
							<el-form-item label="Baslangic Tarihi">
								<el-date-picker v-model="form.start_at" type="datetime" placeholder="Tarih Seçiniz"
												value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="Bitis Tarihi">
								<el-date-picker v-model="form.end_at" type="datetime" placeholder="Tarih Seçiniz"
												value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>
					</el-row>

					<el-form-item>
						<el-switch v-model="form.is_active" active-text="Aktif" />
					</el-form-item>
				</div>

				<div class="card-sub-title">Gecerli Urunler</div>
				<div class="card-custom-padding-box-items">
					<el-form-item>
						<el-transfer filterable :filter-method="filterMethod" filter-placeholder="Ürünlerde Ara" :titles="['Tüm ürünler', 'Seçili Olanlar']" v-model="form.in_product_requests" :props="{key: 'id',label: 'title'}" :data="products" />
					</el-form-item>

				</div>

				<div class="card-sub-title">Verilecek Urunler</div>
				<div class="card-custom-padding-box-items">
					<el-form-item>
						<el-transfer filterable :filter-method="filterMethod" filter-placeholder="Ürünlerde Ara" :titles="['Tüm ürünler', 'Seçili Olanlar']" v-model="form.give_product_requests" :props="{key: 'id',label: 'title'}" :data="giveProducts" />
					</el-form-item>
				</div>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
        },
        data() {
            return {
                pageLoading: false,
                form: {
                    in_product_requests: [],
                    give_product_requests: [],
                },

                products: [],
				giveProducts : [],

                filterMethod(query, item) {
                    return item.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
                },
            }
        },
        mounted() {
            if (this.$route.params.id) {
                this.show();
            }

            this.getProducts();
            this.getGiveProducts();
        },
        methods: {
            getProducts() {
                this.pageLoading = true;

                this.$http
                    .get('cms/products/contents', {
                        params: {
                            view: 'dropdown',
                            sorts: {
                                title: 'asc'
                            },
                            filters: [
                                {
                                    relation: 'products',
                                    add_with: null,
                                    inputs: [
                                        {
                                            name: 'type',
                                            field: 'type',
                                            type: 'multiSelect',
                                            value: [
                                                'group',
                                                'private',
                                            ]
                                        }
                                    ]
                                },
                            ],
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.products = response.body.data;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getGiveProducts() {
                this.pageLoading = true;

                this.$http
                    .get('cms/products/contents', {
                        params: {
                            view: 'dropdown',
                            sorts: {
                                title: 'asc'
                            },
                            filters: [
                                {
                                    relation: 'products',
                                    add_with: null,
                                    inputs: [
                                        {
                                            name: 'type',
                                            field: 'type',
                                            type: 'multiSelect',
                                            value: [
                                                'digital',
                                                'physical',
                                            ]
                                        }
                                    ]
                                },
                            ],
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.giveProducts = response.body.data;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
			},

            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },


        },
    }
</script>