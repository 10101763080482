<template>
    <div>
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">{{ item.label }}</div>
            </template>
            <div class="el-card__tabs custom-padding">
                <el-form :label-position="'top'" ref="form" :model="form">
                    <el-tabs class="el-card--tabs fluid" :tab-position="'top'">
                        <template v-for="language in $store.state.ui.languages">
                            <el-tab-pane :label="language.name">
                                <el-form-item>
                                    <vue-prism-editor v-model="container[item.name][language.iso_code]" language="js" :lineNumbers="true" />
                                </el-form-item>
                            </el-tab-pane>
                        </template>
                    </el-tabs>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'LanguageFile',
    props : ['form', 'container', 'item'],
    mounted() {
       //
    }
}
</script>
