<template>
    <div v-loading="pageLoading" v-if="form.id">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">
                        #{{ form.code }}
                    </div>
                    <div class="actions d-flex align-items-center">
                        <Status :order="form" :handle="show" />
                        <el-tooltip content="Yazdır" placement="left">
                            <el-button icon="el-icon-printer" v-show="isProductLoaded && isAddressLoaded"
                                       @click="printDelivery"
                                       class="flat dark teal" style="margin-left:10px" />
                        </el-tooltip>

                    </div>
                </div>
            </template>
            <div class="box-list">
                <div class="box-list-item box-list-item-media">
                    <div class="box-list-item-media-item">
                        <div class="media">
                            <pic type="avatar" width="150" height="150" alt="avatar" :src="form.user.avatar" />
                        </div>
                        <div class="text ml-2">
                            <div class="name fs-18">
                                {{ form.first_name }} {{ form.last_name }}
                            </div>
                            <div>
                                <email :item="form.email" />
                            </div>
                            <div>
                                <phone :item="form.phone" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-list-item" v-if="form.message">
                    {{ form.message }}
                </div>
            </div>
            <div class="card-sub-title d-flex align-items-center justify-content-between">
                <div>Siparis Bilgileri</div>
                <div>
                    <el-tooltip content="Duzenle" placement="left">
                        <el-button icon="el-icon-edit" class="flat dark teal" @click="orderDialog= true" />
                    </el-tooltip>
                </div>
            </div>
            <div class="box-list">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <div class="box-list-item">
                            <div class="name">Urun Adeti</div>
                            <div class="description">{{ form.quantity }}</div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">Teslimat Yontemi</div>
                            <div class="description">{{ form.delivery_plugin.title }} ({{
                                    form.delivery_plugin.name
                                }})
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">

                        <div class="box-list-item">
                            <div class="name">Teslimat Kodu</div>
                            <div class="description">{{ form.delivery_no }}</div>
                        </div>
                        <div class="box-list-item">
                            <div class="name">Teslimat Tarihi</div>
                            <div class="description">{{ form.delivery_at }}</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-card>

        <el-row :gutter="24">
            <el-col :span="16">
                <Note :path="'/shop/orders/' + form.id" />
                <Product :order="form" @productLoaded="productLoaded" ref="product" :shipping="true" />
            </el-col>
            <el-col :span="8">
                <Address :order="form" @addressLoaded="addressLoaded" ref="address" finder="delivery" />
            </el-col>
        </el-row>

        <el-dialog title="Siparis Bilgileri" :visible.sync="orderDialog" width="70%" v-loading="pageLoading">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Teslimat Tarihi">
                    <el-date-picker v-model="form.delivery_at" type="datetime" placeholder="Teslimat Tarihi"
                                    value-format="yyyy-MM-dd HH:mm:ss" />
                </el-form-item>

                <el-form-item label="Teslimat Kodu">
                    <el-input v-model="form.delivery_no" placeholder="Teslimat Kodu" />
                </el-form-item>

            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="update">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>

        <div id="print-area" class="print-area">

            <div class="logo">
                <img :src="'https://'+$store.state.ui.tenant.domain +'/'+ $store.state.ui.theme.logo" height="60">
            </div>

            <div class="title">
                {{ $store.state.ui.tenant.name }}
            </div>
            <div class="info">
                <div class="info_item">
                    Email : {{ $store.state.ui.settings.site_email }}
                </div>
                <div class="info_item">
                    Tel : {{ $store.state.ui.settings.site_gsm }}
                </div>
                <div class="info_item">
                    Adres : {{ $store.state.ui.settings.site_address }}
                </div>
            </div>
            <div class="customer-info">
                Müşteri Bilgileri
                <div class="customer-info-item">
                    <div class="customer-info-item-key">
                        Müşteri :
                    </div>
                    <div class="customer-info-item-val">
                        {{ form.first_name }} {{ form.last_name }}
                    </div>
                </div>
                <div class="customer-info-item">
                    <div class="customer-info-item-key">
                        Sipariş Tarihi :
                    </div>
                    <div class="customer-info-item-val">
                        {{ form.payment_at }}
                    </div>
                </div>
                <div class="customer-info-item">
                    <div class="customer-info-item-key">
                        Telefon :
                    </div>
                    <div class="customer-info-item-val">
                        {{ form.user.phone }}
                    </div>
                </div>

                <div class="customer-info-item" v-if="isAddressLoaded">
                    <div class="customer-info-item-key">
                        Açık Adres :
                    </div>
                    <div class="customer-info-item-val">

                        {{ this.$refs.address.$data.items[0].address_1 }}
                        <br>
                        {{ this.$refs.address.$data.items[0].address_2 }}
                    </div>
                </div>

                <div class="customer-info-item" v-if="isAddressLoaded">
                    <div class="customer-info-item-key">
                        Adres :
                    </div>
                    <div class="customer-info-item-val">
                        {{ this.$refs.address.$data.items[0].city }} - {{ this.$refs.address.$data.items[0].country }}
                        <br>
                        {{ this.$refs.address.$data.items[0].town }} - {{ this.$refs.address.$data.items[0].district }}
                        - {{
                            this.$refs.address.$data.items[0].neighborhood
                        }}
                    </div>
                </div>
            </div>

            <div class="delivery-content" v-if="isProductLoaded">
                Kargo İçeriği
                <template v-for="item in this.$refs.product.$data.items">
                    <div class="delivery-content-item" :key="item.id" v-if="item.is_shipping">
                        <div class="delivery-content-item-key" style="font-weight:bold;">
                            #{{ item.accounting_no }}
                        </div>
                        <div class="delivery-content-item-key">
                            {{ item.title }}
                        </div>
                        <div class="delivery-content-item-key">
                            {{ item.quantity }} Adet
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Note from "../../../Components/Note";
import Product from "../Order/Edit/Product";
import Address from "../Order/Edit/Address";
import Status from "../Delivery/Status"

export default {
    name: 'Delivery',
    components: {
        Address,
        Product,
        Note,
        Status
    },
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },

            isProductLoaded: false,

            orderDialog: false,

            isAddressLoaded: false,
        }
    },
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        update() {
            this.pageLoading = true;

            this.$http
                .put(this.createShowPageLink(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.orderDialog = false;
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
        addressLoaded() {
            this.isAddressLoaded = true;
        },
        productLoaded() {
            this.isProductLoaded = true;
        },
        printDelivery() {
            var divContents = document.getElementById("print-area").innerHTML;
            var htmlContent = window.open('', '', 'height=500, width=500');
            htmlContent.document.write('<html id="DeliveryAddress">');
            htmlContent.document.write('<head><link rel="preconnect" href="https://fonts.gstatic.com"><link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600&subset=latin-ext&display=swap" rel="stylesheet"><style>body {font-family : Montserrat, sans-serif !important;}.logo{text-align:center}img{height:60px}.title{padding-top:20px;text-align:center;font-weight:700;font-size:17px}.delivery-content{padding-top:20px}.delivery-content-item{display:flex;justify-content:start;padding:5px;border:1px solid}.delivery-content-item-key{margin-right:20px}.customer-info{padding-top:20px}.customer-info-item{display:flex;justify-content:space-between;padding:5px;border:1px solid}</style></head>');
            htmlContent.document.write('<body >');
            htmlContent.document.write(divContents);
            htmlContent.document.write('</body></html>');
            htmlContent.document.close();
            htmlContent.print();
        }
    }
}
</script>