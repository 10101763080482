<template>
    <div v-loading="pageLoading">

        <app-tab :items="appTabs" :active="activeTab" :update="handleChangeTab" v-if="form.id" />

        <div class="margin-top-20">
            <el-card class="custom-padding" v-if="activeTab === 'default'">
                <template slot="header">
                    <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
                </template>
                <el-form :label-position="'top'" ref="form" :model="form">
                    <div class="card-custom-padding-box-items">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Baslik">
                                    <el-input v-model="form.title" placeholder="Baslik" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Yayinlanma Basligi">
                                    <el-input v-model="form.public_title" placeholder="Yayinlanma Basligi" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item label="Yayınlanma Tarihi">
                            <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayınlanma Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </div>

                    <div class="card-sub-title">Medya</div>
                    <div class="card-custom-padding-box-items">
                        <el-row :gutter="24">
                            <el-col :span="4">
                                <el-form-item label="Pdf On Kapak Resmi" class="margin-bottom-0">
                                    <fs v-model="form.meta.page_front_cover" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item label="Pdf Arka Kapak Resmi" class="margin-bottom-0">
                                    <fs v-model="form.meta.page_back_cover" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="card-sub-title">Baglantilar</div>
                    <div class="card-custom-padding-box-items" :key="form.id">
                        <el-form-item label="Etiketler">
                            <definition-box alias="readings" :container="form" model="tag_requests" :multiple="true" placeholder="Etiketler" />
                        </el-form-item>

                        <el-form-item label="Kelime Listeleri">
                            <education-content-remote-select alias="word_lists" :container="form" model="word_list_requests" :multiple="true" />
                        </el-form-item>

                        <el-form-item label="Testler">
                            <education-content-remote-select alias="tests" :container="form" model="test_requests" :multiple="true" />
                        </el-form-item>

                        <el-form-item label="Urunler">
                            <product-transfer :container="form" model="product_requests" :types="['group', 'subscription']" />
                        </el-form-item>
                    </div>
                </el-form>
            </el-card>

            <div v-if="form.id && activeTab === 'pages'" >
                <ReadingPage :id="form.id" />
            </div>
        </div>
    </div>
</template>

<script>
import CrudHeader from "../../../Components/CrudHeader";
import ReadingPage from "@/app/Pages/Education/Reading/Page";

export default {
    name: 'ReadingShow',
    components: {
        ReadingPage,
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                tag_requests: [],
                test_requests: [],
                word_list_requests: [],
                product_requests: [],
                meta: {
                    //
                },
            },

            activeTab: 'default',
            appTabs: [
                {
                    key: 'default',
                    title: 'Okuma Parçası'
                },
                {
                    key: 'pages',
                    title: 'Sayfalar'
                }
            ],
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            //
                        };
                    }

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        handleChangeTab(event) {
            this.activeTab = event
        },
    }
}
</script>
