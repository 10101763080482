<template>
    <div>
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    {{ this.$route.meta.title }}
                </div>
                <div class="actions">
                    <el-tooltip content="Ekle" placement="left">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="goRoute($route.fullPath + '/create')" v-if="!$route.meta.createDisable" />
                    </el-tooltip>
                </div>
            </template>
            <Datatable :apiPoint="$route.meta.apiPoint" :key="$route.fullPath" />
        </el-card>
    </div>
</template>

<script>
import Datatable from "@/app/Components/Datatable";
import {required} from "vuelidate/lib/validators";

export default {
    name: 'DatatableContainer',
    components: {Datatable},
    data() {
        return {
            pageLoading : true,
            form : {
                //
            },
            submitted: false,
            plugins : []
        }
    },
    validations: {
        form: {
            plugin_id: {required},
        }
    },
    methods : {

    }

}
</script>