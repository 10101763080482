<template>
	<div v-loading="itemLoading">
		<el-form :label-position="'top'" ref="form" :model="form">

			<div class="card-sub-title mb-2">
				Block Ozellikleri
			</div>

			<el-form-item label="Baslik">
				<el-input v-model="block.meta.block_title" placeholder="Baslik" />
			</el-form-item>

			<el-form-item label="Aciklama" class="is-editor">
				<editor :container="block.meta" model="block_description" :small="true" />
			</el-form-item>

			<el-form-item label="Buton Baslik">
				<el-input v-model="block.meta.button_title" placeholder="Buton Baslik" />
			</el-form-item>

			<el-form-item label="Buton Link">
				<el-input v-model="block.meta.button_url" placeholder="Buton Link" />
			</el-form-item>

			<div class="tab-add-button text-right mb-2">
				<el-button class="modern secondary large extract" icon="el-icon-plus" @click="createItem">Ekle</el-button>
			</div>
			<el-tabs v-model="tabValue" type="border-card" closable @tab-remove="destroyRowItem">
				<el-tab-pane :label="'Tab ' + key" v-for="(item, key) in items" :key="key" :name="'key-' + key">
					<el-form-item label="Sira">
						<el-input v-model="item.sort" placeholder="Sira" />
					</el-form-item>

					<el-form-item label="Ikon">
						<el-input v-model="item.variables.icon" placeholder="Ikon" />
					</el-form-item>

					<el-form-item label="Renk">
						<el-input v-model="item.variables.color" placeholder="Renk" />
					</el-form-item>

					<el-form-item label="Link">
						<el-input v-model="item.variables.link" placeholder="Link" />
					</el-form-item>

					<el-form-item label="Baslik">
						<el-input v-model="item.variables.title" placeholder="Baslik" />
					</el-form-item>

					<el-form-item label="Aciklama" class="is-editor">
						<editor :container="item.variables" model="description" :small="true"  />
					</el-form-item>

					<el-form-item label="List Icerik" class="is-editor">
						<editor :container="item.variables" model="list_content" />
					</el-form-item>
				</el-tab-pane>
			</el-tabs>
		</el-form>
	</div>
</template>

<script>
	export default {
		name: 'BoxList',
		props: ['block', 'items'],
		data() {
			return {
				itemLoading: true,
				tabValue: 'key-0',
				form: {
					//
				}
			}
		},
		created() {
			this.itemLoading = false;
		},
		methods: {

			createItem() {
				this.items.push({
					sort: 100,
					variables: {
						//
					}
				})
			},

			destroyRowItem(targetName) {

				let key = targetName;

				if (!key) {
					key = this.tabValue;
				}

				key = key.replace('key-', '');

				this
					.$confirm('Tab ' + key + ' kalici olarak silinecek!', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {

						this.items.splice(key, 1);

						return true;

					})
					.catch((response) => {
						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},
		}
	}
</script>