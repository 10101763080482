<template>
    <div v-loading="pageLoading">
        <div style="display: flex; align-items: center; justify-content: space-between">
            <div class="ui message blue fluid" style="margin: 0 !important">
                Adres onayı alınan veya fatura bekleyenler
            </div>
            <el-button class="modern large extract" icon="el-icon-refresh" style="margin-left: 40px" @click="index">
                Yenile
            </el-button>
        </div>

        <el-card class="card-flat-list margin-top-40" v-loading="pageLoading">
            <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                <template v-for="item in items">
                    <div class="item" :key="item.reference">
                        <div class="text display-flex align-items-center">
                            <div class="title" style="min-width: 100px; flex-shrink: 0">
                                #{{ item.reference }}
                            </div>
                            <div class="title">{{ item.title }}</div>
                        </div>
                        <div class="actions active" style="right: 20px">
                            <div class="badge bg-blue text-white fs-20">
                                {{ item.count }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'PrintMonitor',
    components: {
        //
    },
    data() {
        return {
            pageLoading: false,
            items: []
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {params: {perPage: 1000, sorts: {sort: 'asc'}}})
                .then(response => {
                    this.items = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>