<template>
	<el-select v-model="container[model]" :allow-create="iscreate" :placeholder="placeholder ? placeholder : 'Seçiminiz'" :multiple="multiple" clearable filterable :disabled="disabled" @change="handleSelected">
		<el-option v-for="item in sortItems(options)" :key="item.value" :label="item.label" :value="item.value" />
	</el-select>
</template>

<script>
    export default {
        name: 'LabelValueSelect',
        props: ['container', 'model', 'label', 'multiple', 'options', 'change', 'placeholder', 'disabled', 'iscreate'],
        methods: {
            handleSelected(e) {
                if (!this.change) {
                    return;
                }

                this.change(e)
            },

			sortItems(items) {
				return this.lodash.sortBy(items, [(o) => {
					return o.label
				}])
			},
        },
    };
</script>
