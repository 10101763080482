<template>
	<div v-loading="pageLoading">
        <div class="branches">
            <el-row :gutter="24">
                <template v-for="(item, index) in branches">
                    <el-col :span="8" :key="index" >
                        <div class="item" :style="{'background-color' : item.color}" @click="store(item.id)">
                            <div class="media">
                                <icon :icon="item.icon" />
                            </div>
                            <div class="text">
                                {{ item.title }}
                            </div>
                        </div>
                    </el-col>
                </template>
            </el-row>
        </div>
	</div>
</template>

<script>
	import CrudHeader from "../../../Components/CrudHeader";
	import {mapState} from "vuex";

	export default {
		name: 'CrudItem',
		components: {
			CrudHeader
		},
		computed: mapState(['account', 'ui']),
		data() {
			return {
				pageLoading: false,
				branches: [],
			}
		},
		mounted() {
			this.getBranches();
		},
		methods: {
			getBranches() {
				this.pageLoading = true;

				this.$http
					.get('archives/branches', {params: {perPage: 10000}})
					.then(response => {
						this.pageLoading = false;
						this.branches = response.body.rows;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

            store(branchId) {
                this.pageLoading = true;

                this.$http
                    .post(this.$route.meta.apiPoint, {
                        archive_branch_id : branchId
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.responseSuccess(response);

                        this.goRoute('/archives/question-contents/' + response.body.data.id)
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            }
		}
	}
</script>