import LayoutConfig from '../config/layout'
import Container from '../app/Container/Main'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Datatable'

import File from '../app/Pages/Tools/File'
import Note from '../app/Pages/Tools/Note'
import SeoFile from '../app/Pages/Tools/SeoFile'
import SeoRedirect from '../app/Pages/Tools/SeoRedirect'
import SeoLink from '../app/Pages/Tools/SeoLink'
import LogProcess from '../app/Pages/Tools/Logs/Process'
import LogRequest from '../app/Pages/Tools/Logs/Request'
import LogEducationNotification from '../app/Pages/Tools/Logs/EducationNotification'
import FileManager from '../app/Pages/Tools/FileManager'
import Message from '../app/Pages/Tools/Message'
import Export from '../app/Pages/Tools/Export'

export default {
    path: '/tools',
    name: 'api.tools.index',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Araclar',
    },
    children: [
        {
            path: 'file-manager',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Dosya Yoneticisi',
                breadcrumb: 'api.tools.file_manager.index',
            },
            children: [
                {
                    name: 'api.tools.file_manager.index',
                    path: '',
                    component: FileManager,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Dosya Yoneticisi',
                    },
                }
            ]
        },
        {
            path: 'files',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Dosyalar',
                breadcrumb: 'api.tools.files.index',
            },
            children: [
                {
                    name: 'api.tools.files.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Dosyalar',
                        apiPoint: 'tools/files',
                        createDisable: true
                    },
                },
                {
                    name: 'api.tools.files.edit',
                    path: ':id',
                    component: File,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Dosya Bilgileri',
                        apiPoint: 'tools/files',
                        createDisable: true
                    },
                },
            ]
        },
        {
            path: 'notes',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Notlar',
                breadcrumb: 'api.tools.notes.index',
            },
            children: [
                {
                    name: 'api.tools.notes.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Notlar',
                        apiPoint: 'tools/notes',
                        createDisable: true
                    },
                },
                {
                    name: 'api.tools.notes.edit',
                    path: ':id',
                    component: Note,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Not Bilgileri',
                        apiPoint: 'tools/notes',
                        createDisable: true
                    },
                },
            ]
        },
        {
            path: 'seo/files',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Seo Dosyalar',
                breadcrumb: 'api.tools.seo.files.index',
            },
            children: [
                {
                    name: 'api.tools.seo.files.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Seo Dosyalari',
                        apiPoint: 'tools/seo/files',
                    },
                },
                {
                    name: 'api.tools.seo.files.create',
                    path: 'create',
                    component: SeoFile,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Seo Dosyasi Olustur',
                        apiPoint: 'tools/seo/files',
                    },
                },
                {
                    name: 'api.tools.seo.files.edit',
                    path: ':id',
                    component: SeoFile,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Seo Dosya Bilgileri',
                        apiPoint: 'tools/seo/files',
                    },
                },
            ]
        },
        {
            path: 'seo/redirects',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Seo Yonlendirmeleri',
                breadcrumb: 'api.tools.seo.redirects.index',
            },
            children: [
                {
                    name: 'api.tools.seo.redirects.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Seo Yonlendirmeleri',
                        apiPoint: 'tools/seo/redirects',
                    },
                },
                {
                    name: 'api.tools.seo.redirects.create',
                    path: 'create',
                    component: SeoRedirect,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Seo Yonlendirmesi Olustur',
                        apiPoint: 'tools/seo/redirects',
                    },
                },
                {
                    name: 'api.tools.seo.redirects.edit',
                    path: ':id',
                    component: SeoRedirect,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Seo Yonlendirme Bilgileri',
                        apiPoint: 'tools/seo/redirects',
                    },
                },
            ]
        },
        {
            path: 'seo/links',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Seo Linkleri',
                breadcrumb: 'api.tools.seo.links.index',
            },
            children: [
                {
                    name: 'api.tools.seo.links.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Seo Linkleri',
                        apiPoint: 'tools/seo/links',
                        createDisable: true
                    },
                },
                {
                    name: 'api.tools.seo.links.edit',
                    path: ':id',
                    component: SeoLink,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Seo Link Bilgileri',
                        apiPoint: 'tools/seo/links',
                        createDisable: true
                    },
                },
            ]
        },
        {
            path: 'logs/processes',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Islem Loglari',
                breadcrumb: 'api.tools.logs.processes.index',
            },
            children: [
                {
                    name: 'api.tools.logs.processes.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Islem Loglari',
                        apiPoint: 'tools/logs/processes',
                        createDisable: true
                    },
                },
                {
                    name: 'api.tools.logs.processes.edit',
                    path: ':id',
                    component: LogProcess,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Log Bilgileri',
                        apiPoint: 'tools/logs/processes',
                        createDisable: true
                    },
                },
            ]
        },
        {
            path: 'logs/requests',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Request Loglari',
                breadcrumb: 'api.tools.logs.requests.index',
            },
            children: [
                {
                    name: 'api.tools.logs.requests.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Request Loglari',
                        apiPoint: 'tools/logs/requests',
                        createDisable: true
                    },
                },
                {
                    name: 'api.tools.logs.requests.edit',
                    path: ':id',
                    component: LogRequest,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Log Bilgileri',
                        apiPoint: 'tools/logs/requests',
                        createDisable: true
                    },
                },
            ]
        },
        {
            path: 'logs/education-notifications',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Egitim Bildirim Loglari',
                breadcrumb: 'api.tools.logs.education_notifications.index',
            },
            children: [
                {
                    name: 'api.tools.logs.education_notifications.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Egitim Bildirim Loglari',
                        apiPoint: 'tools/logs/education-notifications',
                        createDisable: true
                    },
                },
                {
                    name: 'api.tools.logs.education_notifications.edit',
                    path: ':id',
                    component: LogEducationNotification,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Log Bilgileri',
                        apiPoint: 'tools/logs/education-notifications',
                        createDisable: true
                    },
                },
            ]
        },
        {
            path: 'messages',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Mesajlar',
                breadcrumb: 'api.tools.messages.index',
            },
            children: [
                {
                    name: 'api.tools.messages.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Mesajlar',
                        apiPoint: 'tools/messages',
                        createDisable: true
                    },
                },
                {
                    name: 'api.tools.messages.edit',
                    path: ':id',
                    component: Message,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Mesaj Bilgileri',
                        apiPoint: 'tools/messages',
                        createDisable: true
                    },
                },
            ]
        },
        {
            path: 'exports',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Excel Ciktilari',
                breadcrumb: 'api.tools.exports.index',
            },
            children: [
                {
                    name: 'api.tools.exports.index',
                    path: '',
                    component: Export,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Excel Ciktilari',
                        apiPoint: 'tools/exports',
                        createDisable: true
                    },
                }
            ]
        },
    ]
}