<template>
	<el-dialog title="Eklenti Yukle" :visible.sync="visible" class="dialog-with-page" append-to-body :before-close="dialogClose" v-loading="pageLoading">
		<div class="el-dialog__body-wrapper">
			<div class="plugin-page">
				<div class="plugin-list">
					<div class="plugin-list-wrapper" v-for="(items, key) in plugins" :key="key">
						<div class="plugin margin-bottom-40">
							<el-card class="custom-padding">
								<div class="items">
									<div class="item" v-for="plugin in items" :key="plugin.id">
										<div class="content">
											<div class="media">
												<img :src="'https://' + $store.state.ui.tenant.domain + plugin.preview" alt="preview">
											</div>
											<div class="text">
												<div class="title">{{ plugin.name }}</div>
												<div class="description">{{ plugin.description }}</div>
											</div>
										</div>
										<div class="actions">
											<el-button @click="store(plugin)">
												Yukle
											</el-button>
										</div>
									</div>
								</div>
							</el-card>
						</div>
					</div>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: 'PluginCreate',
		data() {
			return {
				visible: true,
				pageLoading: false,
				form: {
					meta : {
						//
					}
				},

				plugins: [],
			}
		},
		mounted() {
			this.dialogShow();
			this.create();
		},
		methods: {

			dialogShow() {
				this.$store.commit('visibleFullPageDialog');
			},

			dialogClose() {
				this.$store.commit('hideFullPageDialog');
				this.$router.push('/plugins')
			},

			/**
			 * Create page
			 */
			create() {
				this.pageLoading = true;

				this.$http
					.get(this.generateApiPointWithAlias(this.$route.meta.apiPoint) + '?view=plugins')
					.then(response => {

						this.plugins = response.body.data;

						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			/**
			 * Store form
			 */
			store(item) {
				this.form = item;
				this.pageLoading = true;

				this.$http
					.post(this.generateApiPointWithAlias(this.$route.meta.apiPoint), this.form)
					.then(response => {
						this.pageLoading = false;

						this.goShowPage(response.body.data.id);

						return this.responseSuccess(response);
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},
		},

	}
</script>
