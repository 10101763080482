<template>
    <div class="item search">
        <input type="text" placeholder="arama yapmak icin birseyler yazin..." v-model="searchQ" @keyup="handleSearch"
               @focusin="resultShow" @focusout="resultClose" v-loading="loading">
        <div class="results" v-if="result" v-loading="loading" id="results">
            <div class="category" v-for="(category, key) in items"
                 v-if="elementSize(items) && elementSize(category.items)" :key="key">
                <div class="name">
                    {{ category.title }}
                </div>
                <div class="result-items">
                    <div class="result" v-for="(item, itemKey) in category.items" :key="itemKey"
                         @click="handleClick(key, item)">
                        <div>{{ item.title }}</div>
                        <div style="font-size: 12px">
                            <div v-if="item.alias">{{ item.alias }}</div>
                            <div v-if="item.first_name">{{ item.first_name }} {{ item.last_name }}</div>
                            <div v-if="item.email">{{ item.email }}</div>
                            <div v-if="item.phone">{{ item.phone }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Search',
    data() {
        return {
            loading: false,
            result: false,
            searchQ: null,
            items: [],
        };
    },
    mounted() {
        //
    },
    methods: {
        handleSearch() {

            if (this.lodash.size(this.searchQ) <= 3) {
                return false;
            }

            this.loading = true;

            setTimeout(() => {
                this.$http
                    .get('tools/search', {params: {q: this.searchQ}})
                    .then(response => {
                        this.items = response.body;

                        this.loading = false;
                        this.result = true;
                    })
                    .catch(response => {
                        this.loading = false;
                        this.result = false;
                        return this.responseError(response);
                    })
            }, 1000)
        },

        resultShow() {
            if (this.lodash.size(this.searchQ) <= 3) {
                return this.result = false;
            }

            return this.result = true;
        },

        resultClose() {
            setTimeout(() => {
                return this.result = false;
            }, 500)
        },

        handleClick(key, item) {

            switch (key) {
                case 'users':
                    this.$router.push('/crm/users/' + item.id)
                    break;

                case 'orders':
                    this.$router.push('/shop/orders/' + item.id)
                    break;

                case 'products':
                    this.$router.push('/catalog/contents/' + item.alias + '/' + item.id)
                    break;

                case 'contents':
                    this.$router.push('/cms/contents/' + item.alias + '/' + item.id)
                    break;

                case 'cards':
                    this.$router.push('/boards/' + item.board_id + '/pipelines/' + item.pipeline_id + '/stages/' + item.stage_id + '/cards/' + item.id)
                    break;
            }
        }
    },
}
</script>
