<template>
	<div class="card-custom-padding-box margin-0">
        <div class="card-sub-title">E-Posta Ayarlari</div>
        <div class="card-custom-padding-box-items">
            <el-row :gutter="24">
                <el-col :span="16">
                    <el-form-item label="Host">
                        <el-input v-model="form.mail_host" placeholder="Host" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Port">
                        <el-input v-model="form.mail_port" placeholder="Port" type="number" />
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Encryption">
                        <el-input v-model="form.mail_encryption" placeholder="Encryption" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="Kullanici Adi">
                        <el-input v-model="form.mail_username" placeholder="Kullanici Adi" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Sifre">
                        <el-input v-model="form.mail_password" placeholder="Sifre" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="Varsayilan Gonderici Adresi">
                <el-input v-model="form.mail_from_address" placeholder="Varsayilan Gonderi Adresi" />
            </el-form-item>

            <el-form-item label="Varsayilan Gonderici Adi">
                <el-input v-model="form.mail_from_name" placeholder="Varsayilan Gonderici Adi" />
            </el-form-item>
        </div>

        <div class="card-sub-title">Servisler</div>
        <div class="card-custom-padding-box-items">
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item label="Varsayilan IYS Servisi">
                        <plugin-dropdown :container="form" model="iys_plugin_id" :options="plugins.others.items" sub="iys_modul" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Varsayilan Eposta Gonderim Servisi">
                        <plugin-dropdown :container="form" model="email_plugin_id" :options="plugins.services.items" sub="mail" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Varsayilan Sms Gonderim Servisi">
                        <plugin-dropdown :container="form" model="sms_plugin_id" :options="plugins.services.items" sub="sms" />
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Varsayilan Mobil Bildirim Servisi">
                        <plugin-dropdown :container="form" model="mobile_plugin_id" :options="plugins.services.items" sub="mobile" />
                    </el-form-item>
                </el-col>
            </el-row>

        </div>

        <div class="card-sub-title">Personel Bildirimleri</div>
        <div class="card-custom-padding-box-items">
            <div class="theme-page">
                <div class="theme_list">
                    <el-card class="custom-padding">
                        <template v-for="template in $store.state.ui.draft.heads">
                            <div class="item" style="cursor: pointer" :key="template.id" @click="showWorkerNotificationDialog(template)" v-if="template.user_type === 'worker'">
                                <div class="content">
                                    <div class="text">
                                        <div class="title">{{ template.title }}</div>
                                    </div>
                                </div>
                                <div class="actions">
                                    <el-button icon="el-icon-edit" />
                                </div>
                            </div>
                        </template>
                    </el-card>
                </div>
            </div>
        </div>

		<el-dialog :title="workerNotificationForm.title" :visible.sync="workerNotificationDialogVisible" append-to-body width="80%">
			<el-form :label-position="'top'" ref="emailNotificationForm" :model="workerNotificationForm">
				<el-form-item label="Eposta Adresleri">
					<el-select v-model="form[workerNotificationForm.uid + '_emails']" multiple filterable allow-create default-first-option placeholder="Eposta Adresleri">
						<el-option v-for="item in options" :key="item" :label="item" :value="item" />
					</el-select>
				</el-form-item>

                <el-form-item label="Telefon Numaralari">
                    <el-select v-model="form[workerNotificationForm.uid + '_phones']" multiple filterable allow-create default-first-option placeholder="Telefon Numaralari">
                        <el-option v-for="item in options" :key="item" :label="item" :value="item" />
                    </el-select>
                </el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'MessageSetting',
		props: ['form', 'plugins'],
		data() {
			return {
				options : [],
                workerNotificationDialogVisible : false,
                workerNotificationForm : {
                    //
                }
			}
		},
		mounted() {
			//
		},
		methods: {
            showWorkerNotificationDialog(template) {
				this.workerNotificationDialogVisible = true;
				this.workerNotificationForm = template;
			},
		}
	}
</script>