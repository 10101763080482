<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding card-flat-list margin-top-30">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">
                        Ders Bilgileri
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri" placement="bottom">
                            <el-button class="flat dark grey" icon="el-icon-back" @click="processHandle('choice')" />
                        </el-tooltip>
                        <el-tooltip content="Filtre" placement="bottom">
                            <el-button class="flat dark blue" icon="el-icon-search"
                                       @click="filterDialogVisible = true" />
                        </el-tooltip>
                        <el-tooltip content="Kaydet" placement="bottom">
                            <el-button class="flat dark green" icon="el-icon-check" @click="store"
                                       v-if="elementSize(items)" />
                        </el-tooltip>
                    </div>
                </div>
            </template>

            <div class="card-flat-list-items sm">
                <div class="item" v-for="(item, index) in items" :key="index" v-if="!item.disable"
                     :class="{'bg-red' : !item.creatable, 'text-white' : !item.creatable}">
                    <div class="text display-flex align-items-center">
                        <div>
                            <div class="title">{{ item.name }}</div>
                            <div class="description">{{ item.description }}</div>
                            <div class="metas">
                                <div>
                                    {{ $d(new Date(item.start_at), 'long') }} - {{ $d(new Date(item.end_at), 'long') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="actions active">
                        <el-button icon="el-icon-edit" class="flat dark green" @click="edit(index, item)" />
                        <el-button icon="el-icon-delete" class="flat dark red" @click="destroy(index)" />
                    </div>
                </div>
            </div>
        </el-card>

        <el-dialog title="Ders Bilgileri" :visible.sync="dialogVisible" width="60%">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Servis">
                    <lv-select :container="form" model="plugin_id" :options="plugins" placeholder="Servis" />
                </el-form-item>

                <el-form-item label="Ders Adı">
                    <el-input v-model="form.name" placeholder="Ders Adı" />
                </el-form-item>

                <el-form-item label="Ders Aciklama">
                    <el-input v-model="form.description" placeholder="Ders Aciklama" type="textarea" rows="4" />
                </el-form-item>

                <el-form-item label="Ders Süresi (Dk)">
                    <el-input type="number" placeholder="Süre DK" v-model="form.duration" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Başlangıç Tarihi">
                            <el-date-picker v-model="form.start_at" type="datetime"
                                            placeholder="Başlangıç Tarihibi Seçiniz"
                                            value-format="yyyy-MM-dd HH:mm:ss" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitiş Tarihi">
                            <el-date-picker v-model="form.end_at" type="datetime" placeholder="Bitiş Tarihibi Seçiniz"
                                            value-format="yyyy-MM-dd HH:mm:ss" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog title="Filtre" :visible.sync="filterDialogVisible" width="80%">
            <el-form :label-position="'top'" ref="filterForm" :model="filterForm">
                <div v-loading="dialogLoading">
                    <el-form-item class="margin-bottom-20">
                        <product-dropdown :container="filterForm" model="product_id" :types="['group', 'subscription']"  />
                    </el-form-item>
                    <el-alert :closable="false" type="warning">
                        Ogrenci secimini sadece <b>abonelik</b> ile urun almis kullanicilar icin kullaniniz.
                    </el-alert>
                    <el-row :gutter="24" class="margin-top-20">
                        <el-col :span="12">
                            <el-form-item label="Ogretmen">
                                <user :container="filterForm" model="teacher_id" placeholder="Ogretmen" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Ogrenci">
                                <user :container="filterForm" model="customer_id" placeholder="Ogrenci" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item :class="{ 'is-error': filterSubmitted && $v.filterForm.plugin_id.$error }">
                                <lv-select :container="filterForm" model="plugin_id" :options="plugins" placeholder="Servis" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0" :class="{ 'is-error': filterSubmitted && $v.filterForm.start_at.$error }">
                                <el-date-picker v-model="filterForm.start_at" type="date" placeholder="Başlangıç Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item class="margin-bottom-0" :class="{ 'is-error': filterSubmitted && $v.filterForm.end_at.$error }">
                                <el-date-picker v-model="filterForm.end_at" type="date" placeholder="Bitiş Tarihibi Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" />
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="Baslangic Gunu" :class="{ 'is-error': filterSubmitted && $v.filterForm.start_day.$error }">
                        <el-input v-model="filterForm.start_day" placeholder="Baslangic Gunu" type="number" />
                    </el-form-item>

                    <el-form-item :class="{ 'is-error': filterSubmitted && $v.filterForm.days.$error }" class="margin-bottom-20">
                        <lv-select :container="filterForm" model="days" :options="days" placeholder="Gunler" :multiple="true" :change="handleChangeDays" />
                    </el-form-item>

                    <el-row :gutter="24" class="margin-bottom-20" v-for="day in filterForm.fdays" :key="day.id">
                        <el-col :span="12">
                            <div class="day-title" style="display: flex; align-items: center; justify-content: space-between">
                                <div>{{ day.label }}</div>
                                <el-tooltip content="Saat Ekle" placement="bottom">
                                    <el-button class="flat dark blue" icon="el-icon-plus" style="margin-right: 15px" @click="handleAddDayTime(day)" />
                                </el-tooltip>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <el-row :gutter="24" v-for="(time, index) in day.values" :key="index" style="margin-bottom: 15px">
                                <el-col :span="10">
                                    <el-form-item class="margin-bottom-0">
                                        <el-time-select placeholder="Baslangic Saati" v-model="time.start_at" :picker-options="{start: '00:00', step: '00:01',end: '23:00'}" value-format="YYYY-MM-DD" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="10">
                                    <el-form-item class="margin-bottom-0">
                                        <el-time-select placeholder="Bitis Saati" v-model="time.end_at" :picker-options="{start: '00:00', step: '00:01',end: '23:00'}" value-format="YYYY-MM-DD" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <div class="text-right">
                                        <el-tooltip content="Sil" placement="bottom">
                                            <el-button class="flat dark red icon large" icon="el-icon-delete" @click="handleDeleteDayTime(day, index)" style="width: 48px; margin-top: 5px" />
                                        </el-tooltip>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
            </el-form>

            <template slot="footer">
                <el-button class="modern large fluid blue" icon="el-icon-search" @click="filterCreate">
                    Filtreye Uygun Dersleri Olustur
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
    name: 'LessonManagementCreate',
    props: ['processHandle'],
    data() {
        return {
            pageLoading: false,
            dialogLoading: false,
            dialogVisible: false,
            filterDialogVisible: false,
            filterSubmitted: false,
            filterForm: {
                delete: [],
                days: [],
                fdays: [],
                day_start_time: [],
                day_end_time: [],
                start_day: 1,
            },

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
                firstDayOfWeek: 1,
            },
            products: [],
            plugins: [],
            types: [
                {
                    value: 'webinar',
                    label: 'Webinar'
                },
                {
                    value: 'meeting',
                    label: 'Meeting'
                }
            ],
            days: [
                {
                    value: 1,
                    label: 'Pazartesi'
                },
                {
                    value: 2,
                    label: 'Sali'
                },
                {
                    value: 3,
                    label: 'Carsamba'
                },
                {
                    value: 4,
                    label: 'Persembe'
                },
                {
                    value: 5,
                    label: 'Cuma'
                },
                {
                    value: 6,
                    label: 'Cumartesi'
                },
                {
                    value: 7,
                    label: 'Pazar'
                }
            ],

            items: [],
            counter: 1,
            form: {
                //
            },
        }
    },
    validations: {
        filterForm: {
            product_id: {required},
            start_at: {required},
            end_at: {required},
            plugin_id: {required},
            days: {required},
            start_day: {required},
        }
    },
    mounted() {
        this.getPlugins();
    },
    methods: {

        getPlugins() {
            this.pageLoading = true;

            this.$http
                .get('tools/search/plugins', {params: {alias: 'services', sub_alias: 'meetings'}})
                .then(response => {
                    this.plugins = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        filterCreate() {
            this.filterForm.delete = [];
            this.create();
        },

        create() {
            this.filterSubmitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.pageLoading = true;
            this.dialogLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint + '/create', {params: this.filterForm})
                .then(response => {
                    this.pageLoading = false;
                    this.dialogLoading = false;
                    this.filterDialogVisible = false;
                    this.items = response.body.data;
                    this.counter = response.body.counter;
                })
                .catch(response => {
                    this.pageLoading = false;
                    this.dialogLoading = false;
                    return this.responseError(response);
                })
        },

        edit(index, item) {
            this.form = item;
            this.form.index = index;

            this.dialogVisible = true;
        },

        store() {
            this.$confirm('Ders programınız aşağıdaki listeye göre oluştuurlacaktır. Onaylıyor musunuz?', 'Uyari', {
                confirmButtonText: 'Onayla',
                cancelButtonText: 'Iptal',
                type: 'warning'
            }).then(() => {

                let items = [];

                this.lodash.forEach(this.items, (item) => {
                    if (!item.disable) {
                        items.push(item);
                    }
                });

                this.pageLoading = true;

                this.$http
                    .post(this.$route.meta.apiPoint, {filter: this.filterForm, items: items})
                    .then(response => {
                        this.pageLoading = false;

                        this.goRoute('/educations/lessons');

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })


                return true;
            }).catch((response) => {
                this.$notify({
                    type: 'success',
                    message: 'Isleminiz basarili bir sekilde iptal edildi!'
                });

                return false;
            });
        },

        destroy(index) {
            this.$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                confirmButtonText: 'Onayla',
                cancelButtonText: 'Iptal',
                type: 'warning'
            }).then(() => {
                //this.items.splice(index, 1);

                this.filterForm.delete.push(index)

                this.create();

                return true;
            }).catch((response) => {
                this.$notify({
                    type: 'success',
                    message: 'Isleminiz basarili bir sekilde iptal edildi!'
                });

                return false;
            });
        },

        handleChangeDays() {
            this.lodash.forEach(this.filterForm.days, (day) => {

                let status = false;

                this.lodash.forEach(this.filterForm.fdays, (fday)=> {
                    if (fday.id === day) {
                        status = true;
                    }
                })

                let item = {
                    id: day,
                    label: this.days[day - 1].label,
                    values: [
                        {
                            start_at: null,
                            end_at: null,
                        }
                    ]
                }

                if (!status) {
                    this.filterForm.fdays.push(item);
                }
            })
        },

        handleAddDayTime(day) {
            day.values.push({
                start_at: null,
                end_at: null,
            })
        },

        handleDeleteDayTime(day, key) {
            day.values.splice(key, 1);
        }
    }
}
</script>
