<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" :disable="true" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form" v-if="form.id">
                <div class="ui header" v-if="form.subject">
                    {{ form.subject.title }}

                    <div class="sub header" v-if="form.teacher">
                        {{ form.teacher.name }}
                    </div>
                    <div class="sub header" v-if="form.lesson">
                        {{ form.lesson.name }}
                    </div>
                </div>

                <div class="ui message">
                    {{ form.message }}
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'Feedback',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    this.handleSelectedContent();

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>