<template>
	<div v-loading="pageLoading">
		<el-card class="card-flat-list overflow-max-400">
			<template slot="header">
				<div class="title">
					Notlar
				</div>
			</template>
			<div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
				<div class="item" v-for="item in items" :key="item.id">
					<div class="text display-flex align-items-center">
						<div class=" margin-right-20">
							<img src="@/resources/img/avatar.png" class="avatar" alt="avatar" style="width: 45px; height: 45px"  v-if="!item.user.avatar" >
							<img :src="appConfig.s3Path + '/' + item.user.avatar" class="avatar" alt="avatar" style="width: 45px; height: 45px" v-if="item.user.avatar">
						</div>
						<div class="fluid">
							<div class="title mb-0 d-flex align-items-center justify-content-between fluid">
								<div>{{ item.user.name }}</div>
								<div class="fs-12">{{ item.human_date }}</div>
							</div>
							<div>{{ item.content }}</div>
						</div>
					</div>
					<div class="actions" style="right: 20px">
						<el-dropdown @command="handleRowActionCommand">
							<el-button type="text" class="flat dark grey" icon="el-icon-arrow-down" />
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="{type : 'edit', id : item.id}">
									<i class="el-icon-edit text-green"></i> Duzenle
								</el-dropdown-item>
								<el-dropdown-item :command="{type : 'destroy', id : item.id}">
									<i class="el-icon-delete text-red"></i> Sil
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>

			<div class="card-footer" style="position: sticky; bottom: 0; width: 100%; z-index: 9; background: rgb(245, 247, 250)">
				<el-form :label-position="'top'" ref="form" :model="form" class="d-flex align-items-center">
					<el-form-item class="mb-0 fluid mr-3 message">
						<el-input v-model="form.content" placeholder="Mesajiniz"/>
					</el-form-item>

					<el-button icon="el-icon-s-promotion" class="flat dark blue large" style="width: 50px" @click="store"/>
				</el-form>
			</div>
		</el-card>

		<el-dialog title="Not Bilgileri" :visible.sync="dialogVisible" v-loading="pageLoading" width="70%" append-to-body :before-close="dialogClose">
			<el-form :label-position="'top'" ref="form" :model="form">
				<el-form-item label="Mesajiniz">
					<el-input v-model="form.content" type="textarea" rows="4" placeholder="Mesajiniz"  />
				</el-form-item>
			</el-form>

			<template slot="footer" class="dialog-footer">
				<el-button class="fluid flat dark green large" @click="update">
					Guncelle
				</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'Note',
		props : ['path'],
        data() {
            return {
                pageLoading : false,
                dialogVisible : false,
                form : {
                    //
				},
                items: []
            }
        },
        mounted() {
            this.index();
            this.form.url = this.path;
        },
        methods: {

			dialogClose() {
				this.$store.commit('visibleFullPageDialog');
				this.dialogVisible = false;
			},

            /**
             * Generate api point
             */
            endPoint(suffix) {

                const path = 'tools/notes'

                if (suffix) {
                    return path + '/' + suffix;
                }

                return path;
            },

            /**
             * All items
             */
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(), {
                        params: {
                            perPage: 1000,
							sorts: {
                                created_at: 'desc'
                            },
                            filters: [
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'url',
                                            field: 'url',
                                            type: 'singleSelect',
                                            value: this.path
                                        }
                                    ]
                                },
                            ],
                        }
                    })
                    .then(response => {
                        this.items = response.body.rows;
                        this.form = {
                            url : this.path
						}

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Edit modal
             */
            edit(id) {
                this.form = {
                    url : this.path
				};

				this.$store.commit('hideFullPageDialog');
                this.dialogVisible = true;

                this.show(id);
            },

            /**
             * Show page
             */
            show(id) {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint(id))
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form save
             */
            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.endPoint(), this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Form update
             */
            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.endPoint(this.form.id), this.form)
                    .then(response => {
                        this.pageLoading = false;

                        this.dialogVisible = false;

                        this.index();

                        return this.responseSuccess(response);
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            /**
             * Destroy item
             */
            destroy(id) {

                this
                    .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                        confirmButtonText: 'Onayla',
                        cancelButtonText: 'Iptal',
                        type: 'warning'
                    })
                    .then(() => {

                        this.$http
                            .delete(this.endPoint(id))
                            .then(response => {

                                this.index();

                                this.responseSuccess(response);

                            })
                            .catch(response => {
                                this.responseError(response);
                            });


                        return true;

                    })
                    .catch((response) => {
                        this.$notify({
                            type: 'success',
                            message: 'Isleminiz basarili bir sekilde iptal edildi!'
                        });

                        return false;
                    });
            },

            /**
             * Item dropdown actions
             *
             * @param command
             */
            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroy(command.id);
                }

                this.edit(command.id);
            }
        }
    }
</script>

<style scoped lang="scss">
	.message .el-input__inner {
		height: 50px !important;
	}
</style>