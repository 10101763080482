<template>
    <div class="catalog-page catalog-page-detail" v-loading="pageLoading">
        <div v-if="form.id">
            <el-card class="catalog-page-detail-head">
                <el-row :gutter="24">
                    <el-col :span="19">
                        <el-form :label-position="'top'" ref="form" :model="form">
                            <el-form-item class="margin-bottom-0">
                                <el-input v-model="form.title" placeholder="Baslik" prefix-icon="mdi mdi-subtitles-outline"/>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="5">
                        <el-button class="fluid modern green large" icon="icon mdi mdi-check-bold" @click="update">
                            Kaydet
                        </el-button>
                    </el-col>
                </el-row>
            </el-card>

            <el-row :gutter="24">
                <el-col :span="19">
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <div class="catalog-page-detail-content">
                            <ProductText :tab="tab" :form="form" v-show="tab.key === 'text'"/>
                            <ProductConnect :tab="tab" :form="form" v-if="tab.key === 'connected'"/>
                            <ProductSeo :tab="tab" :form="form" v-show="tab.key === 'seo'"/>
                            <ProductProduct :tab="tab" :form="form" v-show="tab.key === 'product' && ($route.meta.type !== 'group' && $route.meta.type !== 'subscription')"/>
                            <ProductProducts :tab="tab" :id="form.id" v-if="tab.key === 'products' && ($route.meta.type === 'group' || $route.meta.type === 'subscription')"/>
                            <ProductImages :tab="tab" :form="form" v-if="tab.key === 'images'"/>
                            <ProductFeatures :tab="tab" :form="form" v-if="tab.key === 'features'"/>
                            <ProductTabs :tab="tab" :form="form" v-if="tab.key === 'tabs'"/>
                        </div>
                    </el-form>
                </el-col>
                <el-col :span="5">
                    <aside class="catalog-page-detail-sidebar">
                        <div class="tabs">
                            <template v-for="(item, index) in tabs">
                                <div class="tab" :key="index" :class="{active : tab.key === item.key}"
                                     @click="tab = item">
                                    <div class="title">{{ item.title }}</div>
                                    <div class="media">
                                        <icon :icon="item.icon"/>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </aside>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import ProductText from "@/app/Pages/Catalog/Products/_tabs/Text";
import ProductConnect from "@/app/Pages/Catalog/Products/_tabs/Connect";
import ProductSeo from "@/app/Pages/Catalog/Products/_tabs/Seo";
import ProductProducts from "@/app/Pages/Catalog/Products/_tabs/Products";
import ProductProduct from "@/app/Pages/Catalog/Products/_tabs/Product";
import ProductImages from "@/app/Pages/Catalog/Products/_tabs/Images";
import ProductFeatures from "@/app/Pages/Catalog/Products/_tabs/Features";
import ProductTabs from "@/app/Pages/Catalog/Products/_tabs/Tabs";

export default {
    name: 'Show',
    components: {
        ProductTabs,
        ProductFeatures,
        ProductImages,
        ProductProduct,
        ProductProducts,
        ProductSeo,
        ProductConnect,
        ProductText
    },
    data() {
        return {
            pageLoading: false,
            form: {
                meta: {
                    levels: [],
                    faq_requests: []
                },

                file: {
                    //
                },

                seo: {
                    //
                },

                category_requests: [],
                badge_requests: [],
                suggestion_requests: [],
                suggestion_sale_requests: [],
            },

            tab: {
                //
            },
            tabs: [
                {
                    key: 'text',
                    icon: 'text-box-outline',
                    title: 'Metin',
                },
                {
                    key: 'connected',
                    icon: 'cast-connected',
                    title: 'Baglantilar',
                },
                {
                    key: 'seo',
                    icon: 'xml',
                    title: 'Seo',
                },
                {
                    key: 'images',
                    icon: 'image-multiple-outline',
                    title: 'Medya',
                },
                {
                    key: 'features',
                    icon: 'feature-search-outline',
                    title: 'Ozellikler',
                },
                {
                    key: 'tabs',
                    icon: 'table-column',
                    title: 'Sekmeler',
                },
                {
                    key: 'product',
                    icon: 'cart-outline',
                    title: 'Urun',
                },
                {
                    key: 'products',
                    icon: 'cart-outline',
                    title: 'Urunler',
                },
            ]
        }
    },
    mounted() {

        let tabs = [];

        this.lodash.forEach(this.tabs, (tab) => {

            if (tab.key === 'products' && (this.$route.meta.type === 'group' || this.$route.meta.type === 'subscription')) {
                tabs.push(tab);
            }

            if (tab.key === 'product' && (this.$route.meta.type !== 'group' && this.$route.meta.type !== 'subscription')) {
                tabs.push(tab);
            }

            if (tab.key !== 'products' && tab.key !== 'product') {
                tabs.push(tab);
            }
        });

        this.tabs = tabs
        this.tab = this.tabs[0];

        this.show();
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink(), {params: {type: this.$route.meta.type}})
                .then(response => {
                    this.initForm(response.body.data)
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        update() {
            this.pageLoading = true;
            this.form.content_type = this.$route.meta.type

            this.$http
                .put(this.createShowPageLink(), this.form)
                .then(response => {
                    this.initForm(response.body.data)
                    this.pageLoading = false;

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        initForm(response) {
            this.form = response;
            this.meta = this.form.meta;

            this.form.content_type = this.$route.meta.type;

            if (!this.lodash.size(this.form.meta)) {
                this.form.meta = {};
            }

            if (!this.elementSize(this.form.seo)) {
                this.form.seo = {};
            }

            if (!this.elementSize(this.form.file)) {
                this.form.file = {};
            }

            if (!this.lodash.size(this.form.product.meta)) {
                this.form.product.meta = {};
            }
        }
    }
}
</script>
