<template>
	<el-card v-loading="pageLoading" :element-loading-text="pageLoadingText" class="custom-padding">
		<template slot="header">
			<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" :store="site" />
		</template>
		<el-form :label-position="'top'" ref="form" :model="form">
			<div class="card-sub-title">Genel</div>
			<div class="card-custom-padding-box-items">
				<el-form-item label="Baslik">
					<el-input v-model="form.title" placeholder="Baslik" />
				</el-form-item>

				<el-form-item label="Domain">
					<el-input v-model="form.domain" placeholder="remzihoca.com" />
				</el-form-item>
			</div>

			<div class="card-sub-title">Erisim</div>
			<div class="card-custom-padding-box-items">
				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="Public Key">
							<el-input v-model="form.public_key" placeholder="Public Key" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Private Key">
							<el-input v-model="form.private_key" placeholder="Private Key" />
						</el-form-item>
					</el-col>
				</el-row>
			</div>

			<div class="card-sub-title">Ayarlar</div>
			<div class="card-custom-padding-box-items">
				<el-form-item label="Dosya Yoneticisi">
					<el-input v-model="form.storage_path" placeholder="Dosya Yoneticisi" />
				</el-form-item>
				<el-form-item label="Panel Link">
					<el-input v-model="form.meta.panel_url" placeholder="Panel Link" />
				</el-form-item>
				<el-form-item label="Platform Link">
					<el-input v-model="form.meta.platform_url" placeholder="Platform Link" />
				</el-form-item>
				<el-form-item label="Platform Varsayilan Dil">
					<ti-select :options="languages" :container="form.meta" model="platform_language_id" placeholder="Platform Varsayilan Dil" />
				</el-form-item>
			</div>

			<div class="card-sub-title">Platform: Ogrenci Ozellikleri</div>
			<div class="card-custom-padding-box-items">
				<el-row :gutter="24">
					<el-col :span="6" v-for="module in $store.state.ui.platform.student" :key="module.value">
						<el-form-item :label="module.label">
							<lv-select :container="form.meta" :model="'has_access_' + module.value" :options="approveOptionsInteger" />
						</el-form-item>
					</el-col>
				</el-row>
			</div>
            <div class="card-sub-title">Platform: Ogretmen Ozellikleri</div>
            <div class="card-custom-padding-box-items">
                <el-row :gutter="24">
                    <el-col :span="6" v-for="module in $store.state.ui.platform.teacher" :key="module.value">
                        <el-form-item :label="module.label">
                            <lv-select :container="form.meta" :model="'has_access_' + module.value" :options="approveOptionsInteger" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
		</el-form>
	</el-card>
</template>

<script>
	import CrudHeader from "../../Components/CrudHeader";

	export default {
		name: 'CrudItem',
		components: {
			CrudHeader
		},
		data() {
			return {
				pageLoading: false,
				pageLoadingText: 'Pika ...',
				form: {
					meta: {
						//
					}
				},

				languages : [],
			}
		},
		mounted() {
			if (this.$route.params.id) {
				this.show();
			}

			this.getLanguages();
		},
		methods: {

			getLanguages() {
				this.pageLoading = true;

				this.$http
					.get('system/languages')
					.then(response => {
						this.languages = response.body.rows;
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			show() {
				this.pageLoading = true;

				this.$http
					.get(this.createShowPageLink())
					.then(response => {
						this.form = response.body.data;

						if (!this.lodash.size(this.form.meta)) {
							this.form.meta = {
								//
							};
						}

						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			store(nextStep, isLast) {
				this.pageLoading = true;

				this.$http
					.post(this.generateApiPointWithAlias(this.$route.meta.apiPoint), this.form)
					.then(response => {
						if (isLast) {
							setTimeout(() => {
								this.pageLoadingText = 'Tebrikler web siteniz basarili bir sekilde olusturuldu. Detay sayfasina yonlendiriliyorsunuz.';
								this.goShowPage(response.body.data.id);
							}, 1500)
						}

						if (nextStep) {
							setTimeout(() => {
								this[nextStep]();
							}, 2000)
						}
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			site() {
				this.pageLoadingText = 'Web siteniz kayit ediliyor...';

				this.store('definitions', false);
			},

			definitions() {
				this.pageLoadingText = 'Sistem uzerinde kullanacaginiz tanimlar yukleniyor...';
				this.form.process = 'definitions';

				this.store('statuses', false);
			},

			statuses() {
				this.pageLoadingText = 'Sistem uzerinde kullanacaginiz durumlar yukleniyor...';
				this.form.process = 'statuses';

				this.store('permissions', false);
			},

			permissions() {
				this.pageLoadingText = 'Sistem yetkileri yukleniyor...';
				this.form.process = 'permissions';

				this.store('roles', false);
			},

			roles() {
				this.pageLoadingText = 'Varsayilan rol bilgileriniz yukleniyor...';
				this.form.process = 'roles';

				this.store('users', false);
			},

			users() {
				this.pageLoadingText = 'Kullanici bilgileriniz yukleniyor...';
				this.form.process = 'users';

				this.store('contents', false);
			},

			contents() {
				this.pageLoadingText = 'Varsayilan icerik bilgileriniz yukleniyor...';
				this.form.process = 'contents';

				this.store('themes', false);
			},

			themes() {
				this.pageLoadingText = 'Web sitenizin tema bilgileri yukleniyor...';
				this.form.process = 'themes';

				this.store('settings', false);
			},

			settings() {
				this.pageLoadingText = 'Web sitenizin ayarlari yukleniyor...';
				this.form.process = 'settings';

				this.store('view', false);
			},

			view() {
				this.pageLoadingText = 'Web sitenizin resim ve diger materiyalleri yukleniyor...';
				this.form.process = 'view';

				this.store(false, true);
			},
		}
	}
</script>
