<template>
	<div class="card-custom-padding-box margin-0" v-loading="pageLoading">
		<div class="card-sub-title">Kayittan</div>
		<div class="card-custom-padding-box-items">
            <el-form-item label="Videolar Icin Guvenlik Korusumasini Aktif Et">
                <lv-select :container="form" model="lesson_video_security" :options="approveOptionsInteger" />
            </el-form-item>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LessonSetting',
		props: ['form'],
		data() {
			return {
				pageLoading : false,
				branches : []
			}
		},
		mounted() {
			//
		},
		methods: {
			//
		}
	}
</script>