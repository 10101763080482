<template>
    <div v-loading="pageLoading">
        <el-form :label-position="'top'" ref="form" :model="form">
            <el-form-item>
                <el-input v-model="form.number" placeholder="Kart Numarasi" @change="handleInstallments" v-mask="'#### #### #### ####'" />
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.name" placeholder="Kart Uzerindeki Isim" @change="handleInstallments" />
            </el-form-item>
            <el-row :gutter="24">
                <el-col :span="16">
                    <el-form-item>
                        <el-input v-model="form.expiry" placeholder="Son Kullanma Tarihi" @change="handleInstallments" v-mask="'## / ##'" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item>
                        <el-input v-model="form.ccv" placeholder="Cvc" @change="handleInstallments" v-mask="'###'" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item class="margin-bottom-0" v-loading="installmentLoading">
                <el-select v-model="form.installment_no" placeholder="Taksit Secenekeleri" @change="handleSelectedInstallment">
                    <el-option v-for="installment in installments" :key="installment.id" :value="installment.id" :label="installment.title + ' ' + $n(installment.total, 'currency')" />
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'CreditCard',
    props: ['basket', 'plugin', 'handle', 'orderData', 'handleBasket'],
    data() {
        return {
            pageLoading: false,
            installmentLoading: false,
            form: {
                //
            },

            installments: []
        }
    },
    mounted() {
        this.clearInstallments(this.basket.total);
    },
    methods: {
        getInstallments() {
            this.pageLoading = true;

            this.$http
                    .post('shop/installments', this.orderData)
                    .then(response => {
                        this.installments = response.body.data;
                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
        },

        clearInstallments() {
            this.installments = [
                {
                    id: 1,
                    title: 'Tek Cekim',
                    total: this.basket.total,
                    price: 0,
                    paid: 0,
                }
            ];
        },

        handleSelectedInstallment() {

            let installment = null;

            this.lodash.forEach(this.installments, (item) => {
                if (item.id === this.form.installment_no) {
                    installment = item;
                }
            })

            this.handle({
                installment : JSON.stringify(installment),
                credit_card: {
                    installment_no: this.form.installment_no,
                    name: this.form.name,
                    number: this.form.number,
                    expiry: this.form.expiry,
                    ccv: this.form.ccv,
                }
            });

            this.handleBasket();
        },

        handleInstallments() {
            this.handle({
                credit_card: {
                    installment_no: 1,
                }
            });

            if (!this.form.name) {
                return false;
            }

            if (!this.form.number) {
                return false;
            }

            if (!this.form.expiry) {
                return false;
            }

            if (!this.form.ccv) {
                return false;
            }

            if (_.size(this.form.number.toString()) < 19) {
                return false;
            }

            if (_.size(this.form.expiry.toString()) < 7) {
                return false;
            }

            if (_.size(this.form.ccv.toString()) < 3) {
                return false;
            }

            this.handle({
                credit_card: {
                    installment_no: this.form.installment_no ? this.form.installment_no : 1,
                    name: this.form.name,
                    number: this.form.number,
                    expiry: this.form.expiry,
                    ccv: this.form.ccv,
                }
            });

            this.getInstallments();
        },
    }
}
</script>