<template>
    <div class="order-page">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="title">
                    {{ $route.meta.title }}
                </div>
                <div class="actions">
                    <el-tooltip content="Hedeye Dagit" placement="bottom">
                        <el-button class="flat dark orange" icon="el-icon-present" @click="giftFormVisible = true" />
                    </el-tooltip>

                    <el-tooltip content="Filtrele" placement="bottom">
                        <el-button class="flat dark secondary" icon="el-icon-search" @click="handleSearch" />
                    </el-tooltip>
                </div>
            </template>
            <div class="el-card__filters">
                <el-form :label-position="'top'" ref="filter" :model="filter" @submit.native.prevent="handleSearch">
                    <el-form-item>
                        <el-input placeholder="ad soyad, eposta, telefon, veya siparis kodu ile ara..." prefix-icon="mdi mdi-shopping-outline" v-model="filter.name" />
                    </el-form-item>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item>
                                <el-select v-model="filter.plugin_id" placeholder="Odeme Yontemleri" clearable filterable>
                                    <el-option v-for="plugin in plugins" :key="plugin.id" :label="plugin.name" :value="plugin.id">
                                        <div class="el-option__media">
                                            <div class="media">
                                                <img :src="'https://' + $store.state.ui.tenant.domain + plugin.preview" alt="preview">
                                            </div>
                                            <div class="text">
                                                <div class="title">{{ plugin.name }}</div>
                                                <div class="description">{{ plugin.title }}</div>
                                            </div>
                                        </div>
                                    </el-option>
                                    <template slot="prefix">
                                        <icon icon="credit-card-multiple-outline" class="el-input__icon" />
                                    </template>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item>
                                <statuses :container="filter" model="status_id" alias="orders" placeholder="Durumlar" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item class="margin-bottom-0">
                        <product-dropdown :container="filter" model="products" :multiple="true" />
                    </el-form-item>

                    <el-button native-type="submit" style="display: none"></el-button>
                </el-form>
            </div>
        </el-card>

        <div v-loading="pageLoading">
            <template v-for="(row, index) in rows">
                <el-card :key="index" class="order-row">
                    <router-link :to="'/shop/orders/' + row.id" class="content">
                        <div class="top">
                            <div class="products">
                                <template v-for="(product, productIndex) in row.products">
                                    <el-tooltip :content="product.title">
                                        <div class="product-item" :key="productIndex" v-if="productIndex <= 2">
                                            <icon :icon="findProductIcon(product.type)" />
                                        </div>
                                    </el-tooltip>
                                </template>
                                <div class="product-item count" v-if="elementSize(row.products) > 3">
                                    +{{ elementSize(row.products) - 3 }}
                                </div>
                            </div>
                            <div class="customer">
                                <div class="media">
                                    <pic :src="row.customer.avatar" type="avatar" />
                                </div>
                                <div class="text">
                                    <div class="title">{{ row.customer.name }}</div>
                                    <div class="description">{{ row.customer.email }}</div>
                                    <div class="description">{{ row.customer.phone }}</div>
                                </div>
                            </div>
                            <div class="payment">
                                <div class="method">
                                    {{ row.payment.title }}
                                </div>
                                <div class="amount">
                                    {{ $n(row.payment.discount, 'currency') }} / {{ $n(row.payment.total, 'currency') }}
                                </div>
                            </div>
                            <div class="status" :style="{color: row.status.bg_color}">
                                {{ row.status.title }}
                            </div>
                        </div>
                    </router-link>
                    <div class="bottom">
                        <div class="metas">
                            <div class="meta">
                                <icon icon="calendar-outline" />
                                {{ row.created_at }}
                            </div>
                            <div class="meta">
                                #{{ row.code }}
                            </div>
                            <div class="meta">
                               {{ row.accounting_no }}
                            </div>
                        </div>
                        <div class="metas">
                            <div class="meta btn text-right text-red cursor-pointer" @click="destroy(row.id)">
                                <icon icon="trash-can-outline" /> Siparisi Sil
                            </div>
                        </div>
                    </div>
                </el-card>
            </template>
        </div>

        <div class="pagination" v-if="pagination && pagination.total > pagination.per_page">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="pagination.total"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page"
                @current-change="handleChangePage"
            />
        </div>

        <el-dialog title="Hediye Dagit" :visible.sync="giftFormVisible" append-to-body width="80%">
            <el-form :label-position="'top'" ref="stage" :model="giftForm">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslangic Tarihi">
                            <el-date-picker v-model="giftForm.start_at" type="datetime" placeholder="Baslangic Tarihi" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitis Tarihi">
                            <el-date-picker v-model="giftForm.end_at" type="datetime" placeholder="Bitis Tarihi" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="Satin Alinan Urun">
                    <product-dropdown :container="giftForm" model="product_id" />
                </el-form-item>

                <el-form-item label="Hediye Urunler" class="margin-bottom-0">
                    <product-dropdown :container="giftForm" model="give_products" :multiple="true" />
                </el-form-item>
            </el-form>
            <template slot="footer">
                <el-button class="modern green large fluid" icon="el-icon-edit" @click="giftStore" :loading="giftFormLoading" :disable="giftFormLoading">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Index',
    data() {
        return {
            pageLoading: false,
            filter: {
                products : []
            },
            requestProducts: [],

            rows: [],
            pagination: null,
            page: 1,
            perPage: 10,

            plugins: [],

            giftFormVisible : false,
            giftFormLoading : false,
            giftForm : {
                process : 'gift',
                start_at : '',
                end_at : '',
                product_id : '',
                give_products : []
            }
        }
    },
    mounted() {
        this.getPlugins();
        this.index();
    },
    methods: {
        getPlugins() {
            this.$http
                .get('plugins')
                .then(response => {
                    this.plugins = response.body.data.payments.items;
                })
                .catch(response => {
                    return this.responseError(response);
                })
        },

        handleChangePage(value) {
            this.page = value;
            this.index();
        },

        handleSearch() {
            this.page = 1;
            this.index();
        },

        index() {
            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {
                    params: {
                        page: this.page,
                        perPage: this.perPage,
                        process : 'shop',
                        name : this.filter.name,
                        plugin_id : this.filter.plugin_id,
                        status_id : this.filter.status_id,
                        products : this.filter.products,
                    }
                })
                .then(response => {
                    this.rows = response.body.data;
                    this.pagination = response.body.pagination;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {
            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.$route.meta.apiPoint + '/' + id)
                        .then(response => {
                            this.index();
                            this.responseSuccess(response);
                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },

        giftStore() {

            this.giftFormLoading = true

            this.$http
                .get(this.$route.meta.apiPoint, {
                    params: this.giftForm
                })
                .then(response => {
                    this.giftFormLoading = false
                    this.giftFormVisible = false
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.giftFormLoading = false
                    return this.responseError(response);
                })
        }
    }
}
</script>
