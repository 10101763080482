<template>
	<header id="Header" class="header" v-if="$store.state.ui">
		<Logo />
		<div class="items">
			<el-tooltip content="Eklentiler" placement="bottom">
				<router-link to="/plugins" class="item icon-link">
					<icon icon="rocket-outline" />
				</router-link>
			</el-tooltip>
			<el-tooltip content="Temalar" placement="bottom">
				<router-link to="/themes" class="item icon-link margin-left-10">
					<icon icon="palette-outline" />
				</router-link>
			</el-tooltip>
			<el-tooltip content="Dosya Yoneticisi" placement="bottom">
				<router-link to="/tools/file-manager" class="item icon-link margin-left-10">
					<icon icon="folder-multiple-outline" />
				</router-link>
			</el-tooltip>
			<el-tooltip content="Takvim" placement="bottom">
				<router-link to="/crm/events" class="item icon-link margin-left-10">
					<icon icon="calendar-outline" />
				</router-link>
			</el-tooltip>
			<el-tooltip content="Gorev Yonetimi" placement="bottom">
				<router-link to="/boards" class="item icon-link margin-left-10">
					<icon icon="chart-box-outline" />
				</router-link>
			</el-tooltip>
			<Search />
            <el-tooltip content="Web Sitesine Git" placement="bottom">
                <a :href="'https://' + $store.state.ui.tenant.domain" target="_blank" class="item icon-link icon-link-text margin-right-10">
                   <span class="text">{{ $store.state.ui.tenant.title }}</span>
                </a>
            </el-tooltip>
			<el-tooltip content="Platform'a Git" placement="bottom">
				<a :href="$store.state.ui.tenant.platform_url" target="_blank" class="item icon-link margin-right-10">
					<icon icon="human-male-board" />
				</a>
			</el-tooltip>
			<Notifications />
			<Account />
		</div>
	</header>
</template>

<script>
	import Logo from "./_header/Logo";
	import Search from "./_header/Search";
	import Notifications from "./_header/Notifications";
	import Account from "./_header/Account";

	export default {
		name: 'Header',
		components: {Account, Notifications, Search, Logo},
		data() {
			return {
				pageLoading: true,
			}
		},
		mounted() {
			this.pageLoading = false;
		}
	}
</script>
