<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Etiketler">
                    <definition-box alias="sentences" :container="form" model="tag_requests" :multiple="true" placeholder="Etiketler" />
                </el-form-item>

                <el-form-item label="Cumle">
                    <el-input v-model="form.description" placeholder="Cumle" type="textarea" rows="3" />
                </el-form-item>

                <el-form-item label="Ceviri">
                    <el-input v-model="form.translation" placeholder="Ceviri" type="textarea" rows="3" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../Components/CrudHeader";

export default {
    name: 'CrudItem',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                meta: {
                    //
                },
                tag_requests : []
            },
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.pageLoading = false;
                    this.form = response.body.data;

                    if (!this.lodash.size(this.form.meta)) {
                        this.form.meta = {
                            //
                        };
                    }
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        }
    }
}
</script>