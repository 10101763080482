<template>
	<div v-scroll:throttle="{ throttle: 500 }" class="page dashboard" v-loading="pageLoading" style="min-height: 400px">
		<div v-if="!pageLoading">
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-card class="stat-card">
                        <div class="text">
                            <div class="number">{{ form.customer_count }}</div>
                            <div class="title">Musteri Sayisi</div>
                        </div>
                        <div class="media bg-teal-opack text-teal">
                            <icon icon="account" />
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card class="stat-card">
                        <div class="text">
                            <div class="number">{{ form.page_view }}</div>
                            <div class="title">Sayfa Goruntulenme</div>
                        </div>
                        <div class="media bg-purple-opack text-purple">
                            <icon icon="eye-outline" />
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card class="stat-card">
                        <div class="text">
                            <div class="number">{{ form.order_count }}</div>
                            <div class="title">Siparis Sayisi</div>
                        </div>
                        <div class="media bg-yellow-opack text-yellow">
                            <icon icon="shopping-outline" />
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card class="stat-card">
                        <div class="text">
                            <div class="number">{{ form.return_count }}</div>
                            <div class="title">Iade Sayisi</div>
                        </div>
                        <div class="media bg-navy-blue-opack text-navy-blue">
                            <icon icon="shopping-search" />
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <el-card>
                <template slot="header">
                    <div class="title">
                        {{ monthName }} Ayi Hareketleri
                    </div>
                </template>

                <highcharts :options="chartOptions"></highcharts>
            </el-card>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-card class="card-flat-list overflow-600">
                        <template slot="header">
                            <div class="title">
                                Son 20 Siparis
                            </div>
                        </template>
                        <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                            <div class="item" v-for="item in form.orders" :key="item.id">
                                <div class="text">
                                    <div class="title">{{ item.first_name }} {{ item.last_name }}</div>
                                    <div class="vertical-flex-items has-dimmer">
                                        <div>
                                            <a href="javascript:void(0)" @click="goRoute('/shop/orders/' + item.id)">
                                                #{{ item.code }}
                                            </a>
                                        </div>
                                        <div>{{ item.created_at }}</div>
                                        <div>{{ item.payment_plugin.name }}</div>
                                        <div v-html="item.status_badge"></div>
                                    </div>
                                </div>
                                <div class="actions">
                                    <el-button class="modern" @click="goRoute('/shop/orders/' + item.id)">
                                        Detay
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card class="card-flat-list overflow-600">
                        <template slot="header">
                            <div class="title">
                                Son 20 Musteri
                            </div>
                        </template>
                        <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                            <div class="item" v-for="item in form.users.last" :key="item.id">
                                <div class="text display-flex align-items-center">
                                    <div class="avatar margin-right-20">
                                        <avatar :name="item.name" />
                                    </div>
                                    <div>
                                        <div class="title">{{ item.name }}</div>
                                        <div class="vertical-flex-items has-dimmer">
                                            <div>
                                                <a href="javascript:void(0)" @click="goRoute('/crm/users/' + item.id)">
                                                    #{{ item.id }}
                                                </a>
                                            </div>
                                            <div>{{ item.email }}</div>
                                            <div>{{ item.phone }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="actions">
                                    <el-button class="modern" @click="goRoute('/crm/users/' + item.id)">
                                        Detay
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
	</div>
</template>

<script>
    export default {
        name: 'Dashboard',
        data() {
            return {
                pageLoading: true,
				mothhName : null,
                form: {
                    orders: {},
                    users: {}
                },

                monthName : null,
                monthNames: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],

                chartOptions: {
                    chart: {
                        type: 'spline'
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },
                    yAxis: {
                        title: {
                            text: ''
                        }
                    },
                    xAxis: {
                        categories: []
                    },
                    plotOptions: {
                        line: {
                            dataLabels: {
                                enabled: true
                            },
                            enableMouseTracking: false
                        }
                    },
                    series: [],
                },
            }
        },

        mounted() {
            this.index();

            this.monthName = this.monthNames[(new Date()).getMonth()];
        },

        methods: {
            index() {
                this.pageLoading = true;

                this.$http
                    .get('dashboard')
                    .then(response => {
                        this.form = response.body.data;

                        this.chartOptions.xAxis.categories = this.form.chart.categories;

                        this.chartOptions.series = this.form.chart.series;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            }
        }
    }
</script>
