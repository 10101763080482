<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list overflow-400">
            <template slot="header">
                <div class="title">Rota Izinleri</div>
                <div class="action">
                    <el-input placeholder="İzin Filtreleme" v-model="filterString">
                        <template slot="append">
                            <el-button style="color:green" @click="handleFiltered(true)"
                            >Tümünü Onayla
                            </el-button
                            >
                            <el-button style="color:red" @click="handleFiltered(false)"
                            >Tümünü Kaldır
                            </el-button
                            >
                        </template>
                    </el-input>
                </div>
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div
                    class="card-flat-list-items sm"
                    v-scroll:throttle="{ throttle: 500 }"
                >
                    <div
                        v-for="permission in filteredPermissions"
                        :key="permission.name"
                        class="item"
                    >
                        <div class="title">
                            <b>{{ permission.name }}</b>
                        </div>
                        <div class="methods">
                            <div
                                class="method"
                                v-for="(method, name) in permission.methods"
                                :key="method.id"
                            >
                                <input
                                    type="checkbox"
                                    v-model="method.status"
                                    @change="store(method)"
                                    :id="'method-' + method.id"
                                />
                                <label :for="'method-' + method.id" style="padding-left: 10px">
                                    {{ name }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-card>
    </div>
</template>

<script>
export default {
    name: "RoleRoutePermission",
    props: ["id"],
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },
            filterString: "",
            filterCheck: false,

            permissions: [],
            formattedPermissions: [],
        };
    },
    methods: {
        handleFiltered(status) {
            this.pageLoading = true;

            var bulkData = {bulk: this.filteredPermissions, bulk_status: status};
            this.$http
                .post(this.createShowPageLink() + "/routes", bulkData)
                .then((response) => {
                    this.pageLoading = false;

                    this.index();
                    return this.responseSuccess(response);
                })
                .catch((response) => {
                    this.pageLoading = false;
                    return this.responseError(response);
                });
        },

        formatPermissionsData() {
            this.formattedPermissions = [];
            Object.entries(this.permissions).forEach((value) =>
                this.formattedPermissions.push({name: value[0], methods: value[1]})
            );
        },

        index() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink() + "/routes")
                .then((response) => {
                    this.permissions = response.body.data;

                    this.formatPermissionsData();

                    this.pageLoading = false;
                })
                .catch((response) => {
                    this.pageLoading = false;
                    return this.responseError(response);
                });
        },

        store(item) {
            item.loading = true;

            this.form = item;
            this.form.role_id = this.id;

            this.$http
                .post(this.createShowPageLink() + "/routes", this.form)
                .then((response) => {
                    item.loading = false;
                    return this.responseSuccess(response);
                })
                .catch((response) => {
                    item.loading = false;
                    return this.responseError(response);
                });
        },
    },
    mounted() {
        this.index();
    },
    computed: {
        filteredPermissions() {
            if (this.filterString.length <= 0) return this.formattedPermissions;

            return this.formattedPermissions.filter((permission) =>
                permission.name.includes(this.filterString)
            );
        },
    },
};
</script>