<template>
	<div v-loading="pageLoading">
		<el-card>
			<template slot="header">
				<CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
			</template>
			<el-form :label-position="'top'" ref="form" :model="form">
				<el-form-item label="Bildirim Turu">
					<lv-select :options="types" :container="form" model="type" />
				</el-form-item>

                <el-form-item>
                    <el-transfer filterable :filter-method="filterMethod" filter-placeholder="Ürünlerde Ara" :titles="['Tüm ürünler', 'Seçili Olanlar']" v-model="form.products" :props="{key: 'id',label: 'title'}" :data="products" />
                </el-form-item>

				<el-form-item label="Mesajiniz" class="is-editor">
					<editor :container="form" model="message" />
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
    import CrudHeader from "../../Components/CrudHeader";

    export default {
        name: 'CrudItem',
        components: {
            CrudHeader
        },
        data() {
            return {
                pageLoading: false,
                form: {
                    products : []
                },

                types: [
                    {
                        value: 'basic',
                        label: 'Genel Bildirim',
                    },
                    {
                        value: 'slider',
                        label: 'Slider',
                    }
                ],

                filterMethod(query, item) {
                    return item.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
                },
            }
        },
        mounted() {
            if (this.$route.params.id) {
                this.show();
            }

            this.getProducts();
        },
        methods: {
            show() {
                this.pageLoading = true;

                this.$http
                    .get(this.createShowPageLink())
                    .then(response => {
                        this.form = response.body.data;

                        this.pageLoading = false;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },

            getProducts() {
                this.pageLoading = true;

                this.$http
                    .get('catalog/products', {
                        params: {
                            view: 'dropdown',
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.products = response.body.rows;
                    })
                    .catch(response => {
                        this.pageLoading = false;
                        return this.responseError(response);
                    })
            },
        }
    }
</script>
