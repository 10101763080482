<template>
    <div v-loading="pageLoading">
        <div class="padding-20">
            <el-form-item label="Başlık">
                <el-input v-model="form.title" placeholder="Başlık" />
            </el-form-item>

            <el-form-item label="Version">
                <el-input v-model="form.meta.version" placeholder="Version" />
            </el-form-item>

            <el-form-item label="Site Rengi" v-if="elementSize(form.file_data.site_colors)">
                <lv-select :options="form.file_data.site_colors" :container="form.meta" model="site_color" />
            </el-form-item>

            <el-form-item label="Platform Rengi" v-if="elementSize(form.file_data.platform_colors)">
                <lv-select :options="form.file_data.platform_colors" :container="form.meta" model="platform_color" />
            </el-form-item>
        </div>

        <div class="card-sub-title">Menuler</div>
        <div class="padding-20">
            <el-row :gutter="24">
                <el-col :span="8" v-for="menu in form.file_data.menus" :key="menu.value">
                    <el-form-item :label="menu.label">
                        <ti-select :options="menus" :container="form.meta" :model="menu.value" />
                    </el-form-item>
                </el-col>
            </el-row>
        </div>

        <div class="card-sub-title">Html Meta Tags</div>
        <div class="padding-20">
            <el-form-item label="Html Meta">
                <vue-prism-editor v-model="form.html_meta" language="markup" :lineNumbers="true" />
            </el-form-item>
        </div>

        <div class="card-sub-title">Styles</div>

        <div class="padding-20">
            <el-form-item label="Style Links">
                <el-input v-model="form.style_links" placeholder="Style Links" />
            </el-form-item>

            <el-form-item label="Style Code">
                <vue-prism-editor v-model="form.style_code" language="css" :lineNumbers="true" />
            </el-form-item>
        </div>

        <div class="card-sub-title">Javascript</div>
        <div class="padding-20">
            <el-form-item label="Js Scripts">
                <el-input v-model="form.js_scripts" placeholder="Js Scripts" />
            </el-form-item>

            <el-form-item label="Js Code">
                <vue-prism-editor v-model="form.js_code" language="js" :lineNumbers="true" />
            </el-form-item>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BasicShowTheme',
    props: ['form'],
    data() {
        return {
            pageLoading: false,
            menus : [],

        }
    },
    mounted() {
        this.getMenus();
    },
    methods: {
        getMenus() {
            this.pageLoading = true;

            this.$http
                .get('system/menus')
                .then(response => {
                    this.menus = response.body.rows;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>
