<template>
	<el-breadcrumb separator-class="mdi mdi-chevron-double-right" class="dark">
		<el-breadcrumb-item :to="{ path: '/dashboard' }">
			<i class="mdi mdi-home-outline"></i>
		</el-breadcrumb-item>

        <template v-for="(item, key) in items">
            <el-breadcrumb-item  :key="key" :to="item.path" v-if="item.title">
                {{ item.title }}
            </el-breadcrumb-item>
        </template>

		<el-breadcrumb-item>
			{{ findTitle() }}
		</el-breadcrumb-item>
	</el-breadcrumb>
</template>

<script>
    export default {
        name: 'Breadcrumb',

        data() {
            return {
                items: [],
            }
        },
        methods: {
            setItems() {
                this.lodash.forEach(this.$route.matched, (route, key) => {
                    let name = route.meta.breadcrumb ? route.meta.breadcrumb : route.name;

                    if (this.$route.params.alias) {
                        route.path = route.path.replace(':alias', this.$route.params.alias)
					}

                    route.path = route.path.replace('/:id', '')

                    if (name !== this.$route.name) {
                        this.items.push({
                            title: route.meta.title,
                            path: route.path
                        })
                    }
                });
            },

            findTitle() {

                let names = this.$route.name.split('.');
				let method = names[this.lodash.size(names) - 1];

                if (method === 'create') {
                    return 'Olustur'
				}

                if (method === 'edit') {
                    return 'Duzenle'
                }

                return this.$route.meta.title
			}
        },
        mounted() {
            this.setItems();
        },
    }
</script>
