import SettingConfig from '../config/settings'
import LayoutConfig from '../config/layout'

import Container from '../app/Container/Main'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Datatable'
import ViewContainer from "../app/Container/ViewContainer";

import Comment from "../app/Pages/Cms/Comment";
import Feedback from "../app/Pages/Cms/Feedback";
import Feature from "../app/Pages/Cms/Feature";
import Category from '../app/Pages/Cms/Category'
import Content from '../app/Pages/Cms/Contents/Edit'

import Pages from '../app/Pages/Cms/Pages'
import Posts from '../app/Pages/Cms/Posts'
import Faqs from '../app/Pages/Cms/Faqs'
import References from '../app/Pages/Cms/References'
import Campaigns from '../app/Pages/Cms/Campaigns'
import Branches from '../app/Pages/Cms/Branches'
import Careers from '../app/Pages/Cms/Careers'
import Forums from '../app/Pages/Cms/Forums'
import ExamPromo from '../app/Pages/Cms/ExamPromo'
import Encyclopedia from '../app/Pages/Cms/Encyclopedia'

let categories = [];
let contents = [];

_.forEach(SettingConfig.alias.cms, (alias, key) => {
    let apiPoint = 'cms/' + alias.key + '/categories';
    let uiPoint = '/cms/categories/' + alias.key;
    let name = 'api.cms.categories.' + alias.key;

    categories.push({
        path: alias.key,
        component: PageContainer,
        meta: {
            auth: true,
            layout: LayoutConfig.full,
            title: alias.title + ' Kategorileri',
            breadcrumb: name + '.index',
            alias: alias.key
        },
        children: [
            {
                name: name + '.index',
                path: '',
                component: DatatableContainer,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Kategorileri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            },
            {
                name: name + '.create',
                path: 'create',
                component: Category,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Kategorisi Olustur',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            },
            {
                name: name + '.edit',
                path: ':id',
                component: Category,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Kategori Bilgileri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            }
        ]
    },);

    apiPoint = 'cms/' + alias.key + '/contents';
    uiPoint = '/cms/contents/' + alias.key;
    name = 'api.cms.contents.' + alias.key;

    let ContentAliasFile = null;

    switch (alias.key) {
        case 'pages':
            ContentAliasFile = Pages;
            break;
        case 'posts':
            ContentAliasFile = Posts;
            break;
        case 'faqs':
            ContentAliasFile = Faqs;
            break;
        case 'references':
            ContentAliasFile = References;
            break;
        case 'campaigns':
            ContentAliasFile = Campaigns;
            break;
        case 'branches':
            ContentAliasFile = Branches;
            break;
        case 'careers':
            ContentAliasFile = Careers;
            break;
        case 'exam_promos':
            ContentAliasFile = ExamPromo;
            break;
        case 'forums':
            ContentAliasFile = Forums;
            break;
        case 'encyclopedia':
            ContentAliasFile = Encyclopedia;
            break;
        default:
            ContentAliasFile = Content;
            break;
    }

    contents.push({
        path: alias.key,
        component: PageContainer,
        meta: {
            auth: true,
            layout: LayoutConfig.full,
            title: alias.title + ' Icerikleri',
            breadcrumb: name + '.index',
            alias: alias.key
        },
        children: [
            {
                name: name + '.index',
                path: '',
                component: DatatableContainer,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Icerikleri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            },
            {
                name: name + '.create',
                path: 'create',
                component: ContentAliasFile,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Icerigi Olustur',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            },
            {
                name: name + '.edit',
                path: ':id',
                component: ContentAliasFile,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Icerik Bilgileri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias: alias.key
                }
            }
        ]
    },)
});

export default {
    path: '/cms',
    name: 'api.cms.index',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Cms',
    },
    children: [
        {
            path: 'comments',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Yorumlar',
                breadcrumb: 'api.cms.comments.index',
                createDisable: true
            },
            children: [
                {
                    name: 'api.cms.comments.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Yorumlar',
                        apiPoint: 'cms/comments',
                        createDisable: true
                    },
                },
                {
                    name: 'api.cms.comments.edit',
                    path: ':id',
                    component: Comment,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Yorum Bilgileri',
                        apiPoint: 'cms/comments',
                        createDisable: true
                    },
                },
            ]
        },
        {
            path: 'feedbacks',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Geri Bildirimler',
                breadcrumb: 'api.cms.feedbacks.index',
                createDisable: true
            },
            children: [
                {
                    name: 'api.cms.feedbacks.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Geri Bildirimler',
                        apiPoint: 'cms/feedbacks',
                    },
                },
                {
                    name: 'api.cms.feedbacks.create',
                    path: 'create',
                    component: Feedback,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Geri Bildirim Olustur',
                        apiPoint: 'cms/feedbacks',
                    },
                },
                {
                    name: 'api.cms.feedbacks.edit',
                    path: ':id',
                    component: Feedback,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Geri Bildirim Bilgileri',
                        apiPoint: 'cms/feedbacks',
                    },
                },
            ]
        },
        {
            path: 'features',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Ozellikler',
                breadcrumb: 'api.cms.features.index',
            },
            children: [
                {
                    name: 'api.cms.features.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ozellikler',
                        apiPoint: 'cms/features',
                    },
                },
                {
                    name: 'api.cms.features.create',
                    path: 'create',
                    component: Feature,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ozellik Olustur',
                        apiPoint: 'cms/features',
                    },
                },
                {
                    name: 'api.cms.features.edit',
                    path: ':id',
                    component: Feature,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ozellik Bilgileri',
                        apiPoint: 'cms/features',
                    },
                },
            ]
        },
        {
            path: 'categories',
            component: ViewContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Kategoriler',
                breadcrumb: 'api.cms.categories.index',
            },
            children: categories
        },
        {
            path: 'contents',
            component: ViewContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Icerikler',
                breadcrumb: 'api.cms.contents.index',
            },
            children: contents
        },
    ]
}
