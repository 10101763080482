<template>
	<div v-loading="itemLoading">
		<el-form :label-position="'top'" ref="form" :model="form">

			<div class="tab-add-button text-right mb-2">
				<el-button class="modern secondary large extract" icon="el-icon-plus" @click="createItem">Ekle
				</el-button>
			</div>

			<el-tabs v-model="tabValue" type="border-card" closable @tab-remove="destroyRowItem">
				<el-tab-pane :label="'Tab ' + key" v-for="(item, key) in items" :key="key" :name="'key-' + key">
					<el-form-item label="Sira">
						<el-input v-model="item.sort" placeholder="Sira" />
					</el-form-item>

					<el-form-item label="Kategoriler">
						<el-select v-model="item.variables.categories" placeholder="Kategoriler" clearable filterable multiple>
							<el-option-group v-for="category in $store.state.ui.categories" :key="category.value" :label="category.label">
								<el-option v-for="item in category.options" :key="item.value" :label="item.label" :value="item.value" />
							</el-option-group>
						</el-select>
					</el-form-item>

					<el-form-item label="Limit">
						<el-input v-model="item.variables.limit" placeholder="Limit" type="number"/>
					</el-form-item>

                    <el-form-item label="Icerik Siralama Turu">
                        <lv-select :options="sorts" :container="item.variables" model="sort_type" />
                    </el-form-item>

					<el-form-item label="Buton Baslik">
						<el-input v-model="item.variables.button_title" placeholder="Buton Baslik"/>
					</el-form-item>

					<el-form-item label="Buton Link">
						<el-input v-model="item.variables.button_url" placeholder="Buton Link"/>
					</el-form-item>

					<el-form-item label="Baslik">
						<el-input v-model="item.variables.title" placeholder="Baslik"/>
					</el-form-item>

					<el-form-item label="Aciklama" class="is-editor">
						<editor :container="item.variables" model="description" :small="true"/>
					</el-form-item>
				</el-tab-pane>
			</el-tabs>
		</el-form>
	</div>
</template>

<script>
	export default {
		name: 'Contents',
		props: ['block', 'items'],
		data() {
			return {
				itemLoading: true,
				tabValue: 'key-0',
				categories: [],
                sorts: [
                    {
                        value: 'created_at_desc',
                        label: 'Olusturulma Tarihine Gore: Azalan',
                    },
                    {
                        value: 'created_at_asc',
                        label: 'Olusturulma Tarihine Gore: Artan',
                    },
                    {
                        value: 'updated_at_desc',
                        label: 'Guncellenme Tarihine Gore: Azalan',
                    },
                    {
                        value: 'updated_at_asc',
                        label: 'Guncellenme Tarihine Gore: Artan',
                    },
                    {
                        value: 'sort_desc',
                        label: 'Siralama Numarasina Gore: Azalan',
                    },
                    {
                        value: 'sort_asc',
                        label: 'Siralama Numarasina Gore: Artan',
                    },
                    {
                        value: 'publish_at_desc',
                        label: 'Yayinlanma Tarihine Gore: Azalan',
                    },
                    {
                        value: 'publish_at_asc',
                        label: 'Yayinlanma Tarihine Gore: Artan',
                    }
                ],
				form: {
					//
				}
			}
		},
		created() {
			this.itemLoading = false;

			this.getCategories();
		},
		methods: {

			getCategories() {
				//
			},

			createItem() {
				this.items.push({
					sort: 100,
					variables: {
						//
					}
				})
			},

			destroyRowItem(targetName) {

				let key = targetName;

				if (!key) {
					key = this.tabValue;
				}

				key = key.replace('key-', '');

				this
					.$confirm('Tab ' + key + ' kalici olarak silinecek!', 'Uyari', {
						confirmButtonText: 'Onayla',
						cancelButtonText: 'Iptal',
						type: 'warning'
					})
					.then(() => {

						this.items.splice(key, 1);

						return true;

					})
					.catch((response) => {
						this.$notify({
							type: 'success',
							message: 'Isleminiz basarili bir sekilde iptal edildi!'
						});

						return false;
					});
			},
		}
	}
</script>