<template>
	<div v-loading="pageLoading" style="padding: 0 5px">
		<el-card class="custom-padding">
			<template slot="header">
				<div class="crud-header">
					<div class="title">
						{{ cardCrudTitle() }}
					</div>
					<div class="actions">
						<el-tooltip content="E-Posta Listesini Indir" placement="left">
							<el-button class="flat dark blue" icon="el-icon-download" @click="download" />
						</el-tooltip>
					</div>
				</div>
			</template>
		</el-card>

		<div class="theme-page" >
			<div class="theme_list">
				<el-card class="custom-padding">
					<div class="item" v-for="row in response.rows" :key="row.id">
						<div class="content">
							<div class="text">
								<div class="title">{{ row.name }}</div>
								<div class="description">{{ row.email }}</div>
							</div>
						</div>
					</div>
				</el-card>
			</div>
		</div>

		<div class="text-center margin-top-20" v-if="response.pagination.total > response.pagination.per_page">
			<el-pagination
					background
					layout="prev, pager, next"
					:total="response.pagination.total"
					:page-size="response.pagination.per_page"
					:current-page.sync="response.pagination.current_page"
					@current-change="handleChangePage"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Newsletter',
		components: {
			//
		},
		data() {
			return {
				pageLoading: false,
				page: 1,
				response: {
					pagination: {
						//
					},
					rows: []
				}
			}
		},
		mounted() {
			this.index();
		},
		methods: {
			index() {
				this.pageLoading = true;

				this.$http
					.get(this.$route.meta.apiPoint, {params: {page: this.page, sorts: {created_at: 'desc'}}})
					.then(response => {
						this.response = response.body;
						this.pageLoading = false;
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			},

			handleChangePage(page) {
				this.page = page;
				this.index();
			},

			download() {
				this.pageLoading = true;

				this.$http
					.get(this.$route.meta.apiPoint, {params : {process : 'download'}})
					.then(response => {
						this.pageLoading = false;

						this.openBlankWindow(response.body.data.link);
					})
					.catch(response => {
						this.pageLoading = false;
						return this.responseError(response);
					})
			}
		},
	}
</script>