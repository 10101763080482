<template>
    <el-dialog :visible.sync="visible" class="dialog-with-page" append-to-body :before-close="dialogClose" v-loading="pageLoading">
        <div slot="title" class="el-dialog__crud-title">
            <div class="el-dialog__title">
                {{ form.name }}
            </div>
            <div class="actions">
                <el-tooltip content="Guncelle" placement="bottom">
                    <el-button class="flat dark green " icon="el-icon-check" @click="update" v-loading="pageLoading"
                               style="font-size: 22px; min-width: 50px" />
                </el-tooltip>
            </div>
        </div>
        <div class="el-dialog__body-wrapper">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item>
                    <el-switch v-model="form.is_active" active-text="Aktif" />
                </el-form-item>

                <el-form-item label="Başlık">
                    <el-input v-model="form.meta.title" placeholder="Başlık" />
                </el-form-item>

                <el-form-item label="Siralama">
                    <el-input v-model="form.sort" placeholder="Siralama" type="number" prefix-icon="mdi mdi-counter" />
                </el-form-item>

                <Block v-if="form.alias === 'modules'" :id="$route.params.id" :name="form.name" :inputs="form.inputs" />

                <template v-for="(input, inputIndex) in form.inputs" v-if="form.alias !== 'modules'">
                    <component
                        :is="input.type + 'Input'"
                        :key="inputIndex"
                        :form="form"
                        :container="form.meta"
                        :item="input"
                        :tab="tabValue"
                        :create="createRowItem"
                        :destroy="destroyRowItem"
                    />
                </template>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import Block from "./Block";
import StringInput from "./Inputs/String";
import TextInput from "./Inputs/Text";
import NumberInput from "./Inputs/Number";
import TimeInput from "./Inputs/Time";
import DateInput from "./Inputs/Date";
import DatetimeInput from "./Inputs/Datetime";
import ImageInput from "./Inputs/Image";
import FileInput from "./Inputs/File";
import SelectInput from "./Inputs/Select";
import ApproveInput from "./Inputs/Approve";
import LoopInput from "./Inputs/Loop";
import DefinitionInput from "./Inputs/Definition";
import StatusInput from "./Inputs/Status";
import ProductInput from "./Inputs/Product";
import UiInput from "./Inputs/Ui";
import DraftInput from "./Inputs/Draft";
import LanguageFileInput from "./Inputs/LanguageFile";
import CodeInput from "./Inputs/Code";
import TextEditorInput from "./Inputs/TextEditor";

export default {
    name: 'PluginShow',
    components: {
        Block,
        StringInput,
        TextInput,
        NumberInput,
        TimeInput,
        DateInput,
        DatetimeInput,
        ImageInput,
        FileInput,
        SelectInput,
        ApproveInput,
        LoopInput,
        DefinitionInput,
        StatusInput,
        ProductInput,
        UiInput,
        DraftInput,
        LanguageFileInput,
        CodeInput,
        TextEditorInput,
    },
    data() {
        return {
            visible: true,
            pageLoading: false,
            form: {
                meta: {
                    //
                },
                rows: [],
            },
            tabValue: 'key-0',
            dropdown: {
                stages: []
            }
        }
    },
    mounted() {
        this.dialogShow();
        this.show();
    },
    methods: {
        dialogShow() {
            this.$store.commit('visibleFullPageDialog');
        },

        dialogClose() {
            this.$store.commit('hideFullPageDialog');
            this.$router.push('/plugins')
        },

        getConfigItems(key) {
            let keys = key.split('.');
            let items = this;

            this.lodash.forEach(keys, (item) => {
                items = items[item]
            })

            return items;
        },

        getVuexItem(key) {
            let keys = key.split('.');
            let items = this.$store.state;

            this.lodash.forEach(keys, (item) => {
                items = items[item]
            })

            return items;
        },

        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(result => {

                    let response = result.body.data;

                    if (!this.lodash.size(response.meta)) {
                        response.meta = {}
                    }

                    if (!this.lodash.size(response.rows)) {
                        response.rows = []
                    }

                    this.lodash.forEach(response.inputs, (input) => {
                        if (input.data_type === 'object') {
                            if (!response.meta[input.name]) {
                                response.meta[input.name] = {};
                            }
                        }
                    })

                    this.form = response;
                    this.dropdown = response.body.dropdown;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;
            this.form.meta.rows = this.form.rows;

            this.$http
                .put(this.createShowPageLink(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        createRowItem() {
            this.form.rows.push({
                //
            });
        },

        destroyRowItem(targetName) {

            let key = targetName;

            if (!key) {
                key = this.tabValue;
            }

            key = key.replace('key-', '');

            this
                .$confirm('Tab ' + key + ' kalici olarak silinecek!', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.form.rows.splice(key, 1);

                    return true;

                })
                .catch(() => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        },
    }
}
</script>
