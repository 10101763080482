<template>
	<div v-loading="pageLoading">
		<el-card class="custom-padding">
			<template slot="header">
				<div class="crud-header">
					<div class="title">
						{{ account.user.name }}
					</div>
				</div>
			</template>
			<div class="box-list">
				<div class="box-list-item box-list-item-media is-title">
					<div class="box-list-item-media-item">
						<div class="media">
							<img src="@/resources/img/dummy.png" width="100px" height="100px" alt="avatar"
								 v-if="!account.user.avatar">
							<img :src="appConfig.s3Path + '/' + account.user.avatar" width="100px" height="100px"
								 alt="avatar" v-if="account.user.avatar">
						</div>
						<div class="text">
							<div class="name">
								<el-tooltip content="E-Posta Adresi" placement="right">
									<span>{{ account.user.email }}</span>
								</el-tooltip>
							</div>
							<div class="name">
								<el-tooltip content="Telefon Numarasi" placement="right">
									<span>{{ account.user.phone }}</span>
								</el-tooltip>
							</div>
							<div class="name">
								<el-tooltip content="Dogum Tarihi" placement="right">
									<span>{{ account.user.created_at }}</span>
								</el-tooltip>
							</div>
						</div>
					</div>
				</div>
				<div class="box-list-item">
					<div class="name">Cinsiyet</div>
					<div class="description">
						{{ account.user.gender }}
					</div>
				</div>
				<div class="box-list-item">
					<div class="name">Egitim Durumu</div>
					<div class="description">
						{{ account.user.education }}
					</div>
				</div>
				<div class="box-list-item">
					<div class="name">Meslek</div>
					<div class="description">
						{{ account.user.job }}
					</div>
				</div>
				<div class="box-list-item">
					<div class="name">Dogum Tarihi</div>
					<div class="description">
						{{ account.user.birthday }}
					</div>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Profile',
        computed: mapState(['account']),
        data() {
            return {
                pageLoading: false,
                form: {
                    //
                },
            }
        },
        mounted() {
            //
        },
        methods: {
            //
        }
    }
</script>