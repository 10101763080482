<template>
    <div class="card-custom-padding-box margin-0">
        <div class="card-sub-title">Gorev Yonetimi Islem Hatlari</div>
        <div class="card-custom-padding-box-items">

            <el-row :gutter="24">
                <template v-for="(item, index) in cards">
                    <el-col :span="12" :key="index">
                        <el-form-item :label="item.label">
                            <el-select v-model="form[item.id]" placeholder="Seciminiz">
                                <el-option-group v-for="board in boards" :key="board.label" :label="board.label">
                                    <el-option-group v-for="pipeline in board.children" :key="pipeline.label" :label="pipeline.label">
                                        <el-option
                                            v-for="stage in pipeline.children"
                                            :key="stage.value"
                                            :label="stage.label"
                                            :value="stage.value">
                                        </el-option>
                                    </el-option-group>
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoardSetting',
    props: ['form', 'boards'],
    data() {
        return {
            cards: [
                {
                    id: 'we_call_you_stage_id',
                    label: 'Beni Ara Istekleri'
                },
                {
                    id: 'contact_form_stage_id',
                    label: 'Iletisim Formu'
                },
                {
                    id: 'carrer_form_stage_id',
                    label: 'Is Basvuru Formu'
                },
                {
                    id: 'franchising_form_stage_id',
                    label: 'Franchising Basvuru Formu'
                },
                {
                    id: 'materials_form_stage_id',
                    label: 'Ucretsiz Kaynak Indirme'
                },
                {
                    id: 'teacher_message_form_stage_id',
                    label: 'Egitmene Mesaj Gonder Formu'
                },
                {
                    id: 'lp_register_form_stage_id',
                    label: 'Sinav Basvuru Formu'
                },
                {
                    id: 'product_request_form_stage_id',
                    label: 'Eğitimin Açılmasını İstiyorum Formu'
                },
                {
                    id: 'private_course_form_stage_id',
                    label: 'Ozel Ders Talepleri'
                },
                {
                    id: 'reference_customer_form_stage_id',
                    label: 'Referens Ogrenci Talepleri'
                },
                {
                    id: 'reference_company_form_stage_id',
                    label: 'Referens Kurum Talepleri'
                },
            ]
        }
    },
    methods: {
        handleCascaderChange(model) {
            this.form[model] = this.form[model].pop()
        }
    }
}
</script>
