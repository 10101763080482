<template>
	<div class="card-custom-padding-box margin-0">
		<div class="card-sub-title">Kategoriler</div>
		<div class="card-custom-padding-box-items">
			<el-form-item label="Takim Kategorisi">
				<el-select v-model="form.cms_team_category_id" placeholder="Takim Kategorisi" clearable filterable>
					<el-option-group v-for="category in $store.state.ui.categories" :key="category.value" :label="category.label">
						<el-option v-for="item in category.options" :key="item.value" :label="item.label" :value="item.value" />
					</el-option-group>
				</el-select>
			</el-form-item>

			<el-form-item label="Egitim Urun Kategorisi">
				<el-select v-model="form.cms_product_category_id" placeholder="Kategori Seciniz" clearable filterable>
					<el-option-group v-for="category in $store.state.ui.categories" :key="category.value" :label="category.label">
						<el-option v-for="item in category.options" :key="item.value" :label="item.label" :value="item.value" />
					</el-option-group>
				</el-select>
			</el-form-item>

            <el-form-item label="Egitim Webinar Kategorisi">
                <el-select v-model="form.cms_event_category_id" placeholder="Kategori Seciniz" clearable filterable>
                    <el-option-group v-for="category in $store.state.ui.categories" :key="category.value" :label="category.label">
                        <el-option v-for="item in category.options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-option-group>
                </el-select>
            </el-form-item>

            <el-form-item label="Yardimci Kaynak Urun Kategorisi">
                <el-select v-model="form.cms_product_helper_category_id" placeholder="Kategori Seciniz" clearable filterable>
                    <el-option-group v-for="category in $store.state.ui.categories" :key="category.value" :label="category.label">
                        <el-option v-for="item in category.options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-option-group>
                </el-select>
            </el-form-item>

            <el-form-item label="Forum Kategorisi">
                <el-select v-model="form.cms_forum_category_id" placeholder="Kategori Seciniz" clearable filterable>
                    <el-option-group v-for="category in $store.state.ui.categories" :key="category.value" :label="category.label">
                        <el-option v-for="item in category.options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-option-group>
                </el-select>
            </el-form-item>

            <el-form-item label="Platform Magaza Kategoriler">
                <el-select v-model="form.cms_shop_category_ids" placeholder="Kategori Seciniz" clearable filterable :multiple="true">
                    <el-option-group v-for="category in $store.state.ui.categories" :key="category.value" :label="category.label">
                        <el-option v-for="item in category.options" :key="item.value" :label="item.label" :value="item.value" />
                    </el-option-group>
                </el-select>
            </el-form-item>
		</div>

        <div class="card-sub-title">Urun Ayarları</div>
        <div class="card-custom-padding-box-items">
            <el-form-item label="Ozel Derslerin Listelenmesine Izin Ver">
                <lv-select :container="form" model="private_product_view" :options="approveOptionsInteger" />
            </el-form-item>
        </div>

		<div class="card-sub-title">Sözleşmeler</div>
		<div class="card-custom-padding-box-items">
			<el-row :gutter="24">
				<el-col :span="12">
					<el-form-item label="Gizlilik Sözleşmesi">
						<el-select v-model="form.privacy_contract_id" placeholder="Select">
							<el-option v-for="page in pages" :key="page.id" :label="page.title" :value="page.id" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Mesafeli Satış Sözleşmesi">
						<el-select v-model="form.sales_contract_id" placeholder="Select">
							<el-option v-for="page in pages" :key="page.id" :label="page.title" :value="page.id" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Üyelik Sözleşmesi">
						<el-select v-model="form.membership_contract_id" placeholder="Select">
							<el-option v-for="page in pages" :key="page.id" :label="page.title" :value="page.id" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Teslimat ve İade Şartları">
						<el-select v-model="form.order_return_contract_id" placeholder="Select">
							<el-option v-for="page in pages" :key="page.id" :label="page.title" :value="page.id" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Aydınlatma Metni">
						<el-select v-model="form.aydinlatma_metni_contract_id" placeholder="Select">
							<el-option v-for="page in pages" :key="page.id" :label="page.title" :value="page.id" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Açık Rıza Metni">
						<el-select v-model="form.acik_riza_metni_contract_id" placeholder="Select">
							<el-option v-for="page in pages" :key="page.id" :label="page.title" :value="page.id" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CmsSetting',
		props: ['form', 'pages'],
		data() {
			return {
				//
			}
		},
		mounted() {
			if (!this.form.cms_shop_category_ids) {
			    this.form.cms_shop_category_ids = [];
            }
		},
		methods: {
			//
		}
	}
</script>