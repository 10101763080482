<template>
    <div class="plugin-loop-input">
        <el-tabs v-model="tab" type="border-card" closable editable @edit="handleTabsEdit">
            <el-tab-pane :label="'Tab ' + itemRowKey" v-for="(itemRow, itemRowKey) in form.rows" :key="itemRowKey" :name="'key-' + itemRowKey">
                <template v-for="(input, inputIndex) in item.inputs">
                    <component
                        :is="input.type + 'Input'"
                        :key="inputIndex"
                        :container="itemRow"
                        :item="input"
                    />
                </template>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import StringInput from "./String";
import TextInput from "./Text";
import NumberInput from "./Number";
import TimeInput from "./Time";
import DateInput from "./Date";
import DatetimeInput from "./Datetime";
import ImageInput from "./Image";
import FileInput from "./File";
import ApproveInput from "./Approve";

export default {
    name: 'Text',
    props : ['form', 'container', 'item', 'tab', 'create', 'destroy'],
    components: {
        StringInput,
        TextInput,
        NumberInput,
        TimeInput,
        DateInput,
        DatetimeInput,
        ImageInput,
        FileInput,
        ApproveInput,
    },
    methods: {
        handleTabsEdit(targetName, action) {
            if (action == 'add') {
                return this.create();
            }

            return this.destroy(targetName);
        }
    }
}
</script>