<template>
    <div v-loading="pageLoading">
        <el-card>
            <template slot="header">
                <CrudHeader ref="CrudHeader" :key="$route.name" :form="form" />
            </template>
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Sinav">
                    <definition-box alias="exams" :container="form" model="exam_definition_id" placeholder="Sinav" />
                </el-form-item>

                <el-form-item label="Yil">
                    <definition-box alias="years" :container="form" model="year_definition_id" placeholder="Yil" />
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Sinav Tarihi">
                            <el-date-picker v-model="form.start_at" type="datetime" placeholder="Sinav Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Sonuc Tarihi">
                            <el-date-picker v-model="form.result_at" type="datetime" placeholder="Sonuc Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Basvuru Baslangic Tarihi">
                            <el-date-picker v-model="form.application_start_at" type="datetime" placeholder="Basvuru Baslangic Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Basvuru Bitis Tarihi">
                            <el-date-picker v-model="form.application_end_at" type="datetime" placeholder="Basvuru Bitis Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Gec Basvuru Baslangic Tarihi">
                            <el-date-picker v-model="form.late_application_start_at" type="datetime" placeholder="Gec Basvuru Baslangic Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Gec Basvuru Bitis Tarihi">
                            <el-date-picker v-model="form.late_application_end_at" type="datetime" placeholder="Gec Basvuru Bitis Tarihini Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Link">
                    <el-input v-model="form.link" placeholder="Link" />
                </el-form-item>

                <el-form-item label="Aciklama">
                    <el-input v-model="form.description" placeholder="Aciklama" type="textarea" rows="4" />
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_active" active-text="Aktif" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import CrudHeader from "../../../Components/CrudHeader";

export default {
    name: 'Event',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            form: {
                //
            },
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.show();
        }
    },
    methods: {
        show() {
            this.pageLoading = true;

            this.$http
                .get(this.createShowPageLink())
                .then(response => {
                    this.form = response.body.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>