<template>
	<div>
		<el-menu @select="goRoute">
			<el-menu-item :index="item.path" v-for="(item, key) in links" :key="key">
				<span>{{ item.meta.title }}</span>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
    export default {
        name: 'Map',
        data() {
            return {
                links: []
            }
        },
        methods: {
            initLinks() {
                let links = this.lodash.filter(this.$router.options.routes, (route) => {
                    return route.path === this.$route.path
				});

                if (this.lodash.size(links)) {
                    this.links = links[0].children;
                }
            },

            goRoute(link) {
                this.$router.push(this.$route.path + '/' + link);
            },
        },
        mounted() {
            this.initLinks();
        },
    }
</script>