import LayoutConfig from '../config/layout'
import Container from '../app/Container/Main'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Datatable'
import ViewContainer from "../app/Container/ViewContainer";

/**
 * Books
 */
import BookContentCreate from '../app/Pages/Education/Book/Create';
import BookContentEdit from '../app/Pages/Education/Book/Edit';
/**
 * Lessons
 */
import LessonContainer from '../app/Container/Lesson'
import LessonRecord from '../app/Pages/Education/Lessons/LessonRecords/Index'
import LessonTry from '../app/Pages/Education/Lessons/Try/Index'
import LessonManagement from '../app/Pages/Education/Lessons/LessonManagement/Index'
import LessonIndex from '../app/Pages/Education/Lessons/Lessons/Index'
import LessonShow from '../app/Pages/Education/Lessons/Lessons/Show'
import LessonCreate from '../app/Pages/Education/Lessons/Lessons/Create'

/**
 * Tests
 */
import TestCreate from '../app/Pages/Education/Test/Create'
import TestEdit from '../app/Pages/Education/Test/Edit'

/**
 * Exams
 */
import ExamAnnouncement from '../app/Pages/Education/Exams/Announcement'
import ExamEvent from '../app/Pages/Education/Exams/Event'

/**
 * Reading
 */
import Reading from '../app/Pages/Education/Reading/Show'

/**
 * Homeworks
 */
import Homework from '../app/Pages/Education/Homework/Show'

/**
 * Forums
 */
import Forum from '../app/Pages/Education/Forum/Show'

/**
 * Other
 */
import Announcement from '../app/Pages/Education/Announcement'
import InformationLetter from '../app/Pages/Education/InformationLetter'
import Document from '../app/Pages/Education/Document'
import Video from '../app/Pages/Education/Video'
import Listening from '../app/Pages/Education/Listening'
import WordList from '../app/Pages/Education/WordList'
import ContentShare from '../app/Pages/Education/ContentShare'
import TeacherFeedback from '../app/Pages/Education/TeacherFeedback'

import IndividualAssessmentReportShowView from '../app/Pages/Education/IndividualAssessmentReports/ShowView.vue'


export default {
	path: '/educations',
	name: 'api.educations.index',
	component: Container,
	meta: {
		auth: true,
		layout: LayoutConfig.full,
		title: 'Egitim',
	},
	children: [
		{
			path: 'lessons',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Ders Yonetimi',
			},
			children: [
				{
					path: '',
					component: LessonContainer,
					children: [
						{
							path: 'lessons',
							component: ViewContainer,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Dersler',
								breadcrumb: 'api.educations.lessons.index',
							},
							children: [
								{
									name: 'api.educations.lessons.index',
									path: '',
									component: LessonIndex,
									meta: {
										auth: true,
										layout: LayoutConfig.full,
										title: 'Dersler',
										apiPoint: 'educations/lessons',
									},
								},
								{
									name: 'api.educations.lessons.create',
									path: 'create',
									component: LessonCreate,
									meta: {
										auth: true,
										layout: LayoutConfig.full,
										title: 'Ders Olustur',
										apiPoint: 'educations/lessons',
										uiPoint: '/educations/lessons/lessons',
									}
								},
								{
									name: 'api.educations.lessons.edit',
									path: ':id',
									component: LessonShow,
									meta: {
										auth: true,
										layout: LayoutConfig.full,
										title: 'Ders Bilgileri',
										apiPoint: 'educations/lessons',
										uiPoint: '/educations/lessons/lessons',
									},
								},
							]
						},
						{
							path: 'lesson-management',
							component: ViewContainer,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Ders Yonetimi',
								breadcrumb: 'api.educations.lesson_management.index',
							},
							children: [
								{
									name: 'api.educations.lesson_management.index',
									path: '',
									component: LessonManagement,
									meta: {
										auth: true,
										layout: LayoutConfig.full,
										title: 'Ders Yonetimi',
										apiPoint: 'educations/lesson-management',
									},
								}
							]
						},
						{
							path: 'lesson-records',
							component: ViewContainer,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Ders Kayıtları',
								breadcrumb: 'api.educations.lesson_records.index',
							},
							children: [
								{
									name: 'api.educations.lesson_records.index',
									path: '',
									component: LessonRecord,
									meta: {
										auth: true,
										layout: LayoutConfig.full,
										title: 'Ders Kayıtları',
										apiPoint: 'educations/lessons',
									},
								}
							]
						},
                        {
                            path: 'try',
                            component: ViewContainer,
                            meta: {
                                auth: true,
                                layout: LayoutConfig.full,
                                title: 'Deneme Dersi Olustur',
                                breadcrumb: 'api.educations.try.index',
                            },
                            children: [
                                {
                                    name: 'api.educations.try.index',
                                    path: '',
                                    component: LessonTry,
                                    meta: {
                                        auth: true,
                                        layout: LayoutConfig.full,
                                        title: 'Deneme Dersi Olustur',
                                        apiPoint: 'educations/lessons',
                                    },
                                }
                            ]
                        },
					]
				},
			]
		},
		{
			path: 'books',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Kitaplar',
				breadcrumb: 'api.educations.books.index',
			},
			children: [
				{
					path: 'contents',
					component: ViewContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kitaplar',
						breadcrumb: 'api.educations.books.contents.index',
					},
					children: [
						{
							name: 'api.educations.books.contents.index',
							path: '',
							component: DatatableContainer,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Kitaplar',
								apiPoint: 'educations/books/contents',
							}
						},
						{
							name: 'api.educations.books.contents.create',
							path: 'create',
							component: BookContentCreate,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Kitap Olustur',
								apiPoint: 'educations/books/contents',
							}
						},
						{
							name: 'api.educations.books.contents.edit',
							path: ':id',
							component: BookContentEdit,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Kitap Bilgileri',
								apiPoint: 'educations/books/contents',
							}
						}
					]
				},
			]
		},
		{
			path: 'documents',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Dokümanlar',
				breadcrumb: 'api.educations.documents.index',
			},
			children: [
				{
					name: 'api.educations.documents.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Dokümanlar',
						apiPoint: 'educations/documents',
					},
				},
				{
					name: 'api.educations.documents.create',
					path: 'create',
					component: Document,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Doküman Ekle',
						apiPoint: 'educations/documents',
					}
				},
				{
					name: 'api.educations.documents.edit',
					path: ':id',
					component: Document,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Doküman Bilgileri',
						apiPoint: 'educations/documents',
					},
				},
			]
		},
		{
			path: 'videos',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Videolar',
				breadcrumb: 'api.educations.videos.index',
			},
			children: [
				{
					name: 'api.educations.videos.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Videolar',
						apiPoint: 'educations/videos',
					},
				},
				{
					name: 'api.educations.videos.create',
					path: 'create',
					component: Video,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Video Ekle',
						apiPoint: 'educations/videos',
					}
				},
				{
					name: 'api.educations.videos.edit',
					path: ':id',
					component: Video,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Video Bilgileri',
						apiPoint: 'educations/videos',
					},
				},
			]
		},
		{
			path: 'listenings',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Dinlemeler',
				breadcrumb: 'api.educations.listenings.index',
			},
			children: [
				{
					name: 'api.educations.listenings.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Dinlemeler',
						apiPoint: 'educations/listenings',
					},
				},
				{
					name: 'api.educations.listenings.create',
					path: 'create',
					component: Listening,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Dinleme Ekle',
						apiPoint: 'educations/listenings',
					}
				},
				{
					name: 'api.educations.listenings.edit',
					path: ':id',
					component: Listening,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Dinleme Bilgileri',
						apiPoint: 'educations/listenings',
					},
				},
			]
		},
		{
			path: 'readings',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Okuma Parçaları',
				breadcrumb: 'api.educations.readings.index',
			},
			children: [
				{
					name: 'api.educations.readings.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Okuma Parçaları',
						apiPoint: 'educations/readings',
					},
				},
				{
					name: 'api.educations.readings.create',
					path: 'create',
					component: Reading,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Okuma Parçası Ekle',
						apiPoint: 'educations/readings',
					}
				},
				{
					name: 'api.educations.readings.edit',
					path: ':id',
					component: Reading,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Okuma Parçası Bilgileri',
						apiPoint: 'educations/readings',
					},
				},
			]
		},
		{
			path: 'tests',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Testler',
				breadcrumb: 'api.educations.tests.index',
			},
			children: [
				{
					name: 'api.educations.tests.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Testler',
						apiPoint: 'educations/tests',
					},
				},
				{
					name: 'api.educations.tests.create',
					path: 'create',
					component: TestCreate,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Test Ekle',
						apiPoint: 'educations/tests',
					}
				},
				{
					name: 'api.educations.tests.edit',
					path: ':id',
					component: TestEdit,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Test Bilgileri',
						apiPoint: 'educations/tests',
					},
				},
			]
		},
		{
			path: 'announcements',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Duyurular',
				breadcrumb: 'api.educations.announcements.index',
			},
			children: [
				{
					name: 'api.educations.announcements.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Duyurular',
						apiPoint: 'educations/announcements',
					},
				},
				{
					name: 'api.educations.announcements.create',
					path: 'create',
					component: Announcement,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Duyuru Olustur',
						apiPoint: 'educations/announcements',
					}
				},
				{
					name: 'api.educations.announcements.edit',
					path: ':id',
					component: Announcement,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Duyuru Bilgileri',
						apiPoint: 'educations/announcements',
					},
				},
			]
		},
		{
			path: 'information-letters',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Bilgilendirme Mektuplari',
				breadcrumb: 'api.educations.information_letters.index',
			},
			children: [
				{
					name: 'api.educations.information_letters.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Bilgilendirme Mektuplari',
						apiPoint: 'educations/information-letters',
					},
				},
				{
					name: 'api.educations.information_letters.create',
					path: 'create',
					component: InformationLetter,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Bilgilendirme Mektubu Olustur',
						apiPoint: 'educations/information-letters',
					}
				},
				{
					name: 'api.educations.information_letters.edit',
					path: ':id',
					component: InformationLetter,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Bilgilendirme Mektubu Bilgileri',
						apiPoint: 'educations/information-letters',
					},
				},
			]
		},
		{
			path: 'word-lists',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Kelime Listeleri',
				breadcrumb: 'api.educations.word_lists.index',
			},
			children: [
				{
					name: 'api.educations.word_lists.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kelime Listeleri',
						apiPoint: 'educations/word-lists',
					},
				},
				{
					name: 'api.educations.word_lists.create',
					path: 'create',
					component: WordList,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kelime Listesi Olustur',
						apiPoint: 'educations/word-lists',
					}
				},
				{
					name: 'api.educations.word_lists.edit',
					path: ':id',
					component: WordList,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Kelime Listesi Bilgileri',
						apiPoint: 'educations/word-lists',
					},
				},
			]
		},
		{
			path: 'homeworks',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Odevler',
				breadcrumb: 'api.educations.homeworks.index',
			},
			children: [
				{
					name: 'api.educations.homeworks.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Odevler',
						apiPoint: 'educations/homeworks',
					},
				},
				{
					name: 'api.educations.homeworks.create',
					path: 'create',
					component: Homework,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Odev Olustur',
						apiPoint: 'educations/homeworks',
					}
				},
				{
					name: 'api.educations.homeworks.edit',
					path: ':id',
					component: Homework,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Odev Bilgileri',
						apiPoint: 'educations/homeworks',
					},
				},
			]
		},
		{
			path: 'forums',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Tartismalar',
				breadcrumb: 'api.educations.forums.index',
			},
			children: [
				{
					name: 'api.educations.forums.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Tartismalar',
						apiPoint: 'educations/forums',
					},
				},
				{
					name: 'api.educations.forums.create',
					path: 'create',
					component: Forum,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Tartisma Olustur',
						apiPoint: 'educations/forums',
					}
				},
				{
					name: 'api.educations.forums.edit',
					path: ':id',
					component: Forum,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Tartisma Bilgileri',
						apiPoint: 'educations/forums',
					},
				},
			]
		},
		{
			path: 'content-share',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Icerik Dagitimi',
				breadcrumb: 'api.educations.content_share.index',
			},
			children: [
				{
					name: 'api.educations.content_share.index',
					path: '',
					component: ContentShare,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Icerik Dagitimi',
						apiPoint: 'educations/content-share',
					},
				},
			]
		},
		{
			path: 'feedbacks',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Ogretmen Geri Bildirimleri',
				breadcrumb: 'api.educations.feedbacks.index',
				createDisable: true,
			},
			children: [
				{
					name: 'api.educations.feedbacks.index',
					path: '',
					component: DatatableContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Ogretmen Geri Bildirimleri',
						apiPoint: 'educations/feedbacks',
						createDisable: true,
					},
				},
				{
					name: 'api.educations.feedbacks.edit',
					path: ':id',
					component: TeacherFeedback,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Ogretmen Geri Bildirimi Bilgileri',
						apiPoint: 'educations/feedbacks',
						createDisable: true,
					},
				},
			]
		},
		{
			path: 'exams',
			component: PageContainer,
			meta: {
				auth: true,
				layout: LayoutConfig.full,
				title: 'Sinav Yonetimi',
			},
			children: [
				{
					path: 'announcements',
					component: ViewContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Duyurular',
						breadcrumb: 'api.educations.exams.announcements.index',
					},
					children: [
						{
							name: 'api.educations.exams.announcements.index',
							path: '',
							component: DatatableContainer,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Duyurular',
								apiPoint: 'educations/exams/announcements',
							},
						},
						{
							name: 'api.educations.exams.announcements.create',
							path: 'create',
							component: ExamAnnouncement,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Duyuru Ekle',
								apiPoint: 'educations/exams/announcements',
							}
						},
						{
							name: 'api.educations.exams.announcements.edit',
							path: ':id',
							component: ExamAnnouncement,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Duyuru Bilgileri',
								apiPoint: 'educations/exams/announcements',
							},
						},
					]
				},
				{
					path: 'events',
					component: ViewContainer,
					meta: {
						auth: true,
						layout: LayoutConfig.full,
						title: 'Sinavlar',
						breadcrumb: 'api.educations.exams.events.index',
					},
					children: [
						{
							name: 'api.educations.exams.events.index',
							path: '',
							component: DatatableContainer,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Sinavlar',
								apiPoint: 'educations/exams/events',
							},
						},
						{
							name: 'api.educations.exams.events.create',
							path: 'create',
							component: ExamEvent,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Sinav Ekle',
								apiPoint: 'educations/exams/events',
							}
						},
						{
							name: 'api.educations.exams.events.edit',
							path: ':id',
							component: ExamEvent,
							meta: {
								auth: true,
								layout: LayoutConfig.full,
								title: 'Sinav Bilgileri',
								apiPoint: 'educations/exams/events',
							},
						},
					]
				},
			]
		},
        {
            path: 'individual-assessment-reports/items',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Bireysel Değerlendirme Raporları',
                breadcrumb: 'api.educations.individual_assessment_reports.index',
            },
            children: [
                {
                    name: 'api.educations.individual_assessment_reports.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Bireysel Değerlendirme Raporları',
                        apiPoint: 'educations/individual-assessment-reports/items',
                    },
                },
                {
                    name: 'api.educations.individual_assessment_reports.create',
                    path: 'create',
                    component: IndividualAssessmentReportShowView,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Bireysel Değerlendirme Raporu Olustur',
                        apiPoint: 'educations/individual-assessment-reports/items',
                    }
                },
                {
                    name: 'api.educations.individual_assessment_reports.edit',
                    path: ':id',
                    component: IndividualAssessmentReportShowView,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Bireysel Değerlendirme Raporu',
                        apiPoint: 'educations/individual-assessment-reports/items',
                    },
                },
            ]
        },
	]
}
