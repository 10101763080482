import SettingConfig from '../config/settings'
import LayoutConfig from '../config/layout'

import Container from '../app/Container/Main'
import ViewContainer from '../app/Container/ViewContainer'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Datatable'

import Tenant from '../app/Pages/System/Tenant'
import Setting from '../app/Pages/System/Setting'
import Menu from '../app/Pages/System/Menu'
import Language from '../app/Pages/System/Language'
import Currency from '../app/Pages/System/Currency'
import Definition from '../app/Pages/System/Definition'
import Status from '../app/Pages/System/Status'
import Zone from '../app/Pages/System/Zone'

let definitions = [];
let statuses = [];
let zones = [];

_.forEach(SettingConfig.alias.definitions, (alias, key) => {
    let apiPoint = 'system/' + alias.key + '/definitions';
    let uiPoint = '/system/definitions/' + alias.key;
    let name = 'api.system.definitions.' + alias.key;

    definitions.push({
        path: alias.key,
        component: PageContainer,
        meta: {
            auth: true,
            layout: LayoutConfig.full,
            title: alias.title + ' Tanimlari',
            breadcrumb: name + '.index',
            alias : alias.key
        },
        children: [
            {
                name: name + '.index',
                path: '',
                component: DatatableContainer,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Tanimlari',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias : alias.key
                }
            },
            {
                name: name + '.create',
                path: 'create',
                component: Definition,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Tanimi Olustur',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias : alias.key
                }
            },
            {
                name: name + '.edit',
                path: ':id',
                component: Definition,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Tanimi Bilgileri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias : alias.key
                }
            }
        ]
    },)
});
_.forEach(SettingConfig.alias.statuses, (alias, key) => {
    let apiPoint = 'system/' + alias.key + '/statuses';
    let uiPoint = '/system/statuses/' + alias.key;
    let name = 'api.system.statuses.' + alias.key;

    statuses.push({
        path: alias.key,
        component: PageContainer,
        meta: {
            auth: true,
            layout: LayoutConfig.full,
            title: alias.title + ' Durumlari',
            breadcrumb: name + '.index',
            alias : alias.key
        },
        children: [
            {
                name: name + '.index',
                path: '',
                component: DatatableContainer,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Durumlari',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias : alias.key
                }
            },
            {
                name: name + '.create',
                path: 'create',
                component: Status,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Durum Olustur',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias : alias.key
                }
            },
            {
                name: name + '.edit',
                path: ':id',
                component: Status,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Durum Bilgileri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias : alias.key
                }
            }
        ]
    },)
});
_.forEach(SettingConfig.alias.zones, (alias, key) => {
    let apiPoint = 'system/' + alias.key + '/zones';
    let uiPoint = '/system/zones/' + alias.key;
    let name = 'api.system.zones.' + alias.key;

    zones.push({
        path: alias.key,
        component: PageContainer,
        meta: {
            auth: true,
            layout: LayoutConfig.full,
            title: alias.title,
            breadcrumb: name + '.index',
            alias : alias.key
        },
        children: [
            {
                name: name + '.index',
                path: '',
                component: DatatableContainer,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title,
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias : alias.key
                }
            },
            {
                name: name + '.create',
                path: 'create',
                component: Zone,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Olustur',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias : alias.key
                }
            },
            {
                name: name + '.edit',
                path: ':id',
                component: Zone,
                meta: {
                    auth: true,
                    layout: LayoutConfig.full,
                    title: alias.title + ' Bilgileri',
                    apiPoint: apiPoint,
                    uiPoint: uiPoint,
                    alias : alias.key
                }
            }
        ]
    },)
});

export default {
    path: '/system',
    name: 'api.system.index',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Sistem',
    },
    children: [
        {
            path: 'tenants',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Siteler',
                breadcrumb: 'api.system.tenants.index',
            },
            children: [
                {
                    name: 'api.system.tenants.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Siteler',
                        apiPoint: 'system/tenants',
                    }
                },
                {
                    name: 'api.system.tenants.create',
                    path: 'create',
                    component: Tenant,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Site Olustur',
                        apiPoint: 'system/tenants',
                    }
                },
                {
                    name: 'api.system.tenants.edit',
                    path: ':id',
                    component: Tenant,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Site Bilgileri',
                        apiPoint: 'system/tenants',
                    }
                }
            ]
        },
        {
            path: 'settings',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Ayarlar',
                breadcrumb: 'api.system.settings.index',
            },
            children: [
                {
                    name: 'api.system.settings.index',
                    path: '',
                    component: Setting,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Site Ayarlari',
                        apiPoint: 'system/settings',
                    },
                },
            ]
        },
        {
            path: 'menus',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Menuler',
                breadcrumb: 'api.system.menus.index',
            },
            children: [
                {
                    name: 'api.system.menus.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Menuler',
                        apiPoint: 'system/menus',
                    }
                },
                {
                    name: 'api.system.menus.create',
                    path: 'create',
                    component: Menu,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Menu Olustur',
                        apiPoint: 'system/menus',
                    }
                },
                {
                    name: 'api.system.menus.edit',
                    path: ':id',
                    component: Menu,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Menu Bilgileri',
                        apiPoint: 'system/menus',
                    }
                }
            ]
        },
        {
            path: 'languages',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Diller',
                breadcrumb: 'api.system.languages.index',
            },
            children: [
                {
                    name: 'api.system.languages.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Diller',
                        apiPoint: 'system/languages',
                    }
                },
                {
                    name: 'api.system.languages.create',
                    path: 'create',
                    component: Language,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Dil Olustur',
                        apiPoint: 'system/languages',
                    }
                },
                {
                    name: 'api.system.languages.edit',
                    path: ':id',
                    component: Language,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Dil Bilgileri',
                        apiPoint: 'system/languages',
                    }
                }
            ]
        },
        {
            path: 'currencies',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Para Birimleri',
                breadcrumb: 'api.system.currencies.index',
            },
            children: [
                {
                    name: 'api.system.currencies.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Para Birimleri',
                        apiPoint: 'system/currencies',
                    }
                },
                {
                    name: 'api.system.currencies.create',
                    path: 'create',
                    component: Currency,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Para Birimi Olustur',
                        apiPoint: 'system/currencies',
                    }
                },
                {
                    name: 'api.system.currencies.edit',
                    path: ':id',
                    component: Currency,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Para Birimi Bilgileri',
                        apiPoint: 'system/currencies',
                    }
                }
            ]
        },
        {
            path: 'definitions',
            component: ViewContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Tanimlar',
                breadcrumb: 'api.system.definitions.index',
            },
            children: definitions
        },
        {
            path: 'statuses',
            component: ViewContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Durumlar',
                breadcrumb: 'api.system.statuses.index',
            },
            children: statuses
        },
        {
            path: 'zones',
            component: ViewContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Bolgeler',
                breadcrumb: 'api.system.zones.index',
            },
            children: zones
        },
    ]
}