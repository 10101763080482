export default {
    content: {
        header: false,
        main: true,
        footer: false
    },
    full: {
        header: true,
        main: true,
        footer: true
    }
}